export const environment = {
  production: false,
  apiUrl: 'https://localhost:6001/api',
  code: '',
  dashboard: '/dashboard',
  bugsnagApiKey: '691cd6149de59c8693b21e6e9826e173',
  bugsnagEnv: 'Development',
  recaptchaApi: '6LfeW9saAAAAAPWtOiD0ly77pxy3mxibLWJd2-yH',
  ga: '',
  b2cClientId: '',
  b2cPolicySignIn: '',
  b2cSignInAuthority: '',
  b2cPolicyElevatedSignIn: '',
  b2cElevatedSignInAuthority: '',
  b2cAuthorityDomain: '',
  b2cReadScope: '',
  b2cWriteScope: '',
  b2cApiEndpoint: 'https://localhost:6001/api/**',
  b2cLogoutUrl: 'http://localhost:4300',
  postLogoutRedirectUri: '/',
};
