import { SwitchTransactionsResponseAction, ResetFormAction } from './actions';
import { ISwitchTransactionListState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';

export const cashTransactionListState: ISwitchTransactionListState = {
  list: undefined,
};

const reducer = createReducer(cashTransactionListState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, (state, { }) => {
    return {
      list: undefined
    }
  }),
  on(SwitchTransactionsResponseAction, (state, { payload }) => {
    return {
      ...state,
      list: payload
    }
  }),
);

export function switchTransactionListReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
