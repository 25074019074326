<div id="dealer-detail-edit-component" *ngIf="form$|async; let form">

  <app-readonly title="Adviser name" [value]="form.value.adviserName" icon="person"
    class="p-1 flex-fill"></app-readonly>

  <app-readonly title="Adviser AR Number" [value]="form.value.adviserArNumber" icon="tag"
    class="p-1 flex-fill"></app-readonly>

  <app-readonly title="Adviser Number" [value]="form.value.adviserNumber" icon="tag"
    class="p-1 flex-fill"></app-readonly>

  <app-readonly title="Adviser Business Name" [value]="form.value.adviserBusinessName" icon="business"
    class="p-1 flex-fill"></app-readonly>

  <app-entry title="Adviser Phone" [control]="form.controls.adviserPhone" icon="phone">
  </app-entry>

  <app-entry title="Adviser Mobile" [control]="form.controls.adviserMobile" icon="phone">
  </app-entry>

  <app-entry title="Adviser Email" [control]="form.controls.adviserEmail" icon="email">
  </app-entry>

  <app-entry-address-accurity title="Adviser address" [address]="form.controls.adviserAddress" [isSearchMode]="false">
  </app-entry-address-accurity>
</div>
