import { IBeneficiariesState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';
import { Helper } from '@ifaa-components/ui-components';
import { ResetFilterAction } from '../cash-transactions/actions';
import { MemberViewBeneficiariesGroupedByAccountRequestAction, MemberViewBeneficiariesGroupedByAccountResponseAction } from './actions';

var helper = new Helper();

export const cashTransactionListState: IBeneficiariesState = {
  beneficiaryGroupedByAccountList: undefined
};

const reducer = createReducer(cashTransactionListState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(MemberViewBeneficiariesGroupedByAccountResponseAction, (state, { payload }) => {
    return {
      ...state,
      beneficiaryGroupedByAccountList: payload,
    };
  }),
  on(MemberViewBeneficiariesGroupedByAccountRequestAction, (state, { memberId, page }) => {
    return {
      ...state,
      beneficiaryGroupedByAccountList: undefined,
    };
  }),
  on(ResetFilterAction, (state, { }) => {
    return {
      ...state,
      beneficiaryGroupedByAccountList: [],
    }
  }),

);

export function beneficiariesReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
