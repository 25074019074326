import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBeneficiariesState } from './state';
import { AppState } from 'src/app/store/app.states';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.beneficiariesState);

export const beneficiaries_BeneficiaryGroupedByAccountList = createSelector(
  state,
  (state: IBeneficiariesState) => state.beneficiaryGroupedByAccountList
);
