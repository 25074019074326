import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helper/helper-functions';
import { memberView_SelectedAccountId } from '../selectors';
import { AppState } from 'src/app/store/app.states';
import { RequestAction, ResetFormAction } from './actions';
import { memberPension_Data } from './selectors';
import { distinctUntilChanged } from 'rxjs/operators';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
  selector: 'app-member-pension-view',
  templateUrl: './pension-view.component.html',
  styleUrls: ['./pension-view.component.scss']
})
export class MemberPensionViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(memberPension_Data));

  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));
  currentAccount: number = 0;
  private _memberid: number;
  @Input() set memberid(value: number) {
    this._memberid = value;
    this.store.dispatch(ResetFormAction());
  }
  get memberid(): number {
    return this._memberid;
  }


  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccountId$
      .pipe(distinctUntilChanged((prev, curr) => prev.value?.value === curr.value?.value))
      .subscribe(x => {
        var val = x?.value?.value;
        if (val) {
          this.store.dispatch(RequestAction({ memberId: this.memberid, accountId: val }));
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
