
import { AddressModel } from '@ifaa-components/ui-components';
import { AdviserDetailsModel, DealerGroupDetailsModel, IAdviserDetailsState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';

export const formName = 'adviserDetailsForm';


export const state: IAdviserDetailsState = {

  model:
    {
      adviserName: 'name',
      adviserArNumber: '3333',
      adviserPhone: '4445554',
      adviserEmail: 'gg@gg.com',
      adviserAddress: {
        lineOne: '400 Ann st',
        suburb: 'Brisbane City',
        country: 'Australia',
        postCode: '4000',
        state: 'QLD'
      } as AddressModel,
      adviserNumber: '444',
      adviserBusinessName: 'test',
      adviserMobile: '444333444333',
      dealer: {
        adviserDealerGroupName: 'Group name',
        dealerGroupAFSL: '3333'
      } as DealerGroupDetailsModel
    } as AdviserDetailsModel
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  // on(SelectContactAction, (state, { payload }) => {
  //   return {
  //     ...state,
  //     selectedContact: payload
  //   };
  // })
);

export function adviserDetailsReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
