import { AccountFeatureToggle } from "../model/account-feature-toggles.model";
import { AccountModel } from "../model/account.model";
import { FeatureToggleLevel } from "../model/feature-toggle-level.model";
import { FeatureToggleValue } from "../model/feature-toggle-value.model";

export class FeatureToggleHelper{
    constructor(){

    }

    checkToggle = (value:string, featureToggles:AccountFeatureToggle[], selectedAccount:AccountModel) => {
        // if (!value) return true;

        // var globalFlag = featureToggles.find(x => x.level === FeatureToggleLevel.global &&
        //   x.name === value);

        // if (!globalFlag || globalFlag.value === FeatureToggleValue.hidden) {
        //   return false;
        // }

        // var accountFlag = featureToggles.find(x => x.level === FeatureToggleLevel.accountClassification &&
        //   x.name === value &&
        //   Number(x.levelReference) === selectedAccount.accountClassificationId);

        // if (!accountFlag || accountFlag.value === FeatureToggleValue.hidden)
        //   return false;

        return true;
    }
}
