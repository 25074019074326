import { AppState } from '../store/app.states';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AdvisorPortalSharedState } from '../modules/shared/store/shared.states';
import { selectorWidgetView_Selected } from '../modules/shared/components/selector/selectors';
import { FeatureToggleService } from '../services/featureToggle.service';

@Injectable()
export class FeatureToggleGuardService implements CanActivate {

  selected$ = this.appstore.pipe(select(selectorWidgetView_Selected));

  constructor(public store: Store<AppState>,
    public appstore: Store<AdvisorPortalSharedState>,
    public featureToggleService: FeatureToggleService,

    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let featureFlag = route.data.flag as [any];
    return new Observable<boolean>(obs => {
      this.featureToggleService.checkFeatureToggles(featureFlag).subscribe(allowed => {
        if (!allowed) {
          this.router.navigate(["/"])
          return false;
        }
        else {
          obs.next(true);
        }
      })
    });
  }
}
