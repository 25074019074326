import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { InsuranceService } from 'src/app/services/insurance.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class MemberInsuranceEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private insuranceService: InsuranceService) {
  }

  getInsuranceInformationView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.MemberInsuranceInformationRequestAction),
    switchMap((action: any) => this.insuranceService.getMemberInsurance(action.memberId, action.page).pipe(
      map((data: any) => {
        return ActionsList.MemberInsuranceInformationResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
