import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberPensionOverviewModel } from 'src/app/model/pension.model';

export const RequestAction = createAction('[Member Pension] request',
  props<{ memberId: number, accountId: number }>());

export const ResponseAction = createAction('[member Pension] response',
  props<{ payload: MemberPensionOverviewModel }>());

export const ResetFormAction = createAction('[Member Pension] reset form');
