import { FileRequestAction, ResetFileAction, ResponseAction } from './actions';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { IDocumentViewerListState } from './state';
import { environment } from 'src/environments/environment';

export const state: IDocumentViewerListState = {
  list: [],
  selectedFile: null
};
const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      list: payload
    }
  }),
  on(FileRequestAction, (state, { documentId }) => {

    var link = environment.apiUrl + `/broker/document/${documentId}/file-content`;

    return {
      ...state,
      selectedFile: link
    }
  }),
  on(ResetFileAction, (state) => {
    return {
      ...state,
      selectedFile: null
    }
  }),

);

export function documentViewerListReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
