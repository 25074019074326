import { Component, AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-search-filter',
  templateUrl: './app-search-filter.component.html',
  styleUrls: ['./app-search-filter.component.scss'],
  providers: []
})
export class AppSearchFilterComponent implements AfterViewInit {
  constructor() {

  }

  ngAfterViewInit(): void {

  }
}
