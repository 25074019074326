<div id="dealer-group-detail-component" *ngIf="model$|async; let model">
  <div class="d-flex flex-column">
    <app-component-wrapper icon="grid_view" header="Details" class="m-3 ">
      <div class="d-flex flex-column" slot="start">
        <div class="details d-flex flex-row flex-fill align-items-center">
          <div class="data-col-0 w-25 ">
            <svg class="animated" id="freepik_stories-leadership" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 750 500" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
              xmlns:svgjs="http://svgjs.com/svgjs">
              <style>
                svg#freepik_stories-leadership:not(.animated) .animable {
                  opacity: 0;
                }

                svg#freepik_stories-leadership.animated #freepik--Character_3--inject-18 {
                  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
                  animation-delay: 0s;
                }

                svg#freepik_stories-leadership.animated #freepik--Character_4--inject-18 {
                  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
                  animation-delay: 0s;
                }

                svg#freepik_stories-leadership.animated #freepik--Character_2--inject-18 {
                  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) fadeIn;
                  animation-delay: 0s;
                }

                svg#freepik_stories-leadership.animated #freepik--Character_1--inject-18 {
                  animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
                  animation-delay: 0s;
                }

                @keyframes slideDown {
                  0% {
                    opacity: 0;
                    transform: translateY(-30px);
                  }

                  100% {
                    opacity: 1;
                    transform: translateY(0);
                  }
                }

                @keyframes slideUp {
                  0% {
                    opacity: 0;
                    transform: translateY(30px);
                  }

                  100% {
                    opacity: 1;
                    transform: inherit;
                  }
                }

                @keyframes fadeIn {
                  0% {
                    opacity: 0;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @keyframes slideLeft {
                  0% {
                    opacity: 0;
                    transform: translateX(-30px);
                  }

                  100% {
                    opacity: 1;
                    transform: translateX(0);
                  }
                }
              </style>
              <g id="freepik--background-complete--inject-18" class="animable"
                style="transform-origin: 375px 251.568px;">
                <g id="el4yyojx2b3n" class="animable" style="transform-origin: 375px 250px;">
                  <g id="elr8m65oaz5sg" class="animable" style="transform-origin: 375px 232.879px;">
                    <g id="elf5o24jak6kj" class="animable" style="transform-origin: 375px 202.284px;">
                      <g id="el8ofv0sdoq7m" class="animable" style="transform-origin: 479.147px 202.284px;">
                        <path
                          d="M673.73379,339.7828h-180.7461c-3.2324,0-5.8623-2.6298-5.8623-5.8623V70.6471c0-3.2324,2.6299-5.8617,5.8623-5.8617h180.7461c3.2323,0,5.86221,2.6293,5.86221,5.8617V333.9205c0,3.2325-2.62991,5.8623-5.86221,5.8623ZM492.98769,65.0354c-3.09469,0-5.6123,2.5175-5.6123,5.6117V333.9205c0,3.0948,2.51761,5.6123,5.6123,5.6123h180.7461c3.0946,0,5.61221-2.5175,5.61221-5.6123V70.6471c0-3.0942-2.51761-5.6117-5.61221-5.6117h-180.7461Z"
                          style="fill: rgb(224, 224, 224); transform-origin: 583.361px 202.284px;" id="elh87utq5yxwi"
                          class="animable"></path>
                        <path
                          d="M465.30609,339.7828h-180.7466c-3.2324,0-5.8618-2.6298-5.8618-5.8623V70.6471c0-3.2324,2.6294-5.8617,5.8618-5.8617h180.7466c3.2324,0,5.8623,2.6293,5.8623,5.8617V333.9205c0,3.2325-2.6299,5.8623-5.8623,5.8623ZM284.5595,65.0354c-3.0942,0-5.6118,2.5175-5.6118,5.6117V333.9205c0,3.0948,2.5176,5.6123,5.6118,5.6123h180.7466c3.0947,0,5.6123-2.5175,5.6123-5.6123V70.6471c0-3.0942-2.5176-5.6117-5.6123-5.6117h-180.7466Z"
                          style="fill: rgb(224, 224, 224); transform-origin: 374.933px 202.284px;" id="elp5ltxxqwhjc"
                          class="animable"></path>
                      </g>
                      <path
                        d="M257.0123,339.7828H76.2658c-3.2324,0-5.8618-2.6298-5.8618-5.8623V70.6471c0-3.2324,2.6294-5.8617,5.8618-5.8617h180.7465c3.2324,0,5.8623,2.6293,5.8623,5.8617V333.9205c0,3.2325-2.6299,5.8623-5.8623,5.8623ZM76.2658,65.0354c-3.0942,0-5.6118,2.5175-5.6118,5.6117V333.9205c0,3.0948,2.5176,5.6123,5.6118,5.6123h180.7465c3.0948,0,5.6123-2.5175,5.6123-5.6123V70.6471c0-3.0942-2.5175-5.6117-5.6123-5.6117H76.2658Z"
                        style="fill: rgb(224, 224, 224); transform-origin: 166.639px 202.284px;" id="el5hlyu7zvw6e"
                        class="animable"></path>
                    </g>
                    <rect y="400.8522" width="750" height=".1204"
                      style="fill: rgb(224, 224, 224); transform-origin: 375px 400.912px;" id="elsbo2r1excbb"
                      class="animable"></rect>
                  </g>
                  <g id="eldtw8p47lq58" class="animable" style="transform-origin: 375px 426.323px;">
                    <rect x="653.62639" y="430.2283" width="55.7291" height=".4206"
                      style="fill: rgb(235, 235, 235); transform-origin: 681.491px 430.439px;" id="eln96dxqpn0ii"
                      class="animable"></rect>
                    <rect x="495.04359" y="434.794" width="14.6268" height=".4206"
                      style="fill: rgb(235, 235, 235); transform-origin: 502.357px 435.004px;" id="el2y3n98uyw9v"
                      class="animable"></rect>
                    <rect x="40.6445" y="417.431" width="72.6745" height=".4207"
                      style="fill: rgb(235, 235, 235); transform-origin: 76.9818px 417.641px;" id="elxts2pprcsqe"
                      class="animable"></rect>
                    <rect x="128.2991" y="417.431" width="10.6555" height=".4207"
                      style="fill: rgb(235, 235, 235); transform-origin: 133.627px 417.641px;" id="eliokreecbnp8"
                      class="animable"></rect>
                    <rect x="173.5856" y="424.5351" width="157.61289" height=".4206"
                      style="fill: rgb(235, 235, 235); transform-origin: 252.392px 424.745px;" id="elux4ru1vvtir"
                      class="animable"></rect>
                  </g>
                </g>
                <path
                  d="M430.32089,320.7617c-.5567-2.6861-1.6212-5.1836-3.077-7.4015l2.6504-3.2011-5.666-5.6662-3.20129,2.65c-2.21821-1.4553-4.71541-2.52-7.40121-3.0767l-.3887-4.1263h-8.013l-.3889,4.1263c-2.6857,.5569-5.1834,1.6214-7.4009,3.0767l-3.2014-2.65-5.6661,5.6662,2.6501,3.2013c-1.4556,2.2177-2.51989,4.7152-3.077,7.4013l-4.1263,.389v8.0125l4.1263,.3889c.55711,2.686,1.6214,5.1828,3.077,7.401l-2.6501,3.2013,5.6661,5.6662,3.2014-2.6506c2.2175,1.456,4.7152,2.5201,7.4009,3.0771l.3889,4.1262h8.013l.3887-4.1258c2.6858-.5568,5.183-1.6215,7.40121-3.0775l3.20129,2.6506,5.666-5.6662-2.6504-3.2009c1.4558-2.2186,2.5203-4.7154,3.077-7.4014l4.1259-.3889v-8.0125l-4.1259-.389Zm-10.2428,4.3951c0,5.9907-4.8565,10.8475-10.8476,10.8475s-10.8476-4.8568-10.8476-10.8475c0-5.991,4.8565-10.8476,10.8476-10.8476s10.8476,4.8566,10.8476,10.8476Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 409.23px 325.157px;" id="elxxfhja1jpe"
                  class="animable"></path>
                <path
                  d="M388.74229,368.6417c-.6038-2.9133-1.75829-5.6219-3.33729-8.0274l2.87449-3.4718-6.1451-6.1453-3.472,2.874c-2.4057-1.5782-5.1141-2.733-8.027-3.3368l-.4215-4.4751h-8.6905l-.4218,4.4751c-2.9129,.604-5.6218,1.7586-8.0268,3.3368l-3.472-2.874-6.1453,6.1453,2.8743,3.472c-1.5787,2.4053-2.7331,5.1139-3.3373,8.0272l-4.4752,.4218v8.6901l4.4752,.4217c.6042,2.9131,1.7586,5.6211,3.3373,8.0268l-2.8743,3.472,6.1453,6.1453,3.472-2.8747c2.405,1.5792,5.1139,2.7333,8.0268,3.3373l.4218,4.4751h8.6905l.4215-4.4747c2.9129-.6038,5.6213-1.7585,8.027-3.3377l3.472,2.8747,6.1451-6.1453-2.87449-3.4716c1.579-2.4061,2.73349-5.1141,3.33729-8.0272l4.47471-.4217v-8.6901l-4.47471-.4218Zm-11.1089,4.7667c0,6.4972-5.2672,11.7647-11.7649,11.7647s-11.7649-5.2675-11.7649-11.7647c0-6.4976,5.2673-11.7649,11.7649-11.7649s11.7649,5.2673,11.7649,11.7649Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 365.868px 373.408px;" id="elkhif6rl0q"
                  class="animable"></path>
                <g id="el7dgpwi6xuh" class="animable" style="transform-origin: 689.317px 398.877px;">
                  <g id="elohllgvoyfj" class="animable" style="transform-origin: 687.776px 397.206px;">
                    <path
                      d="M686.87229,430.9695c-.01429-.4451-1.5121-44.6527-8.7028-51.6447-6.9938-6.7988-15.0208-.2529-15.3582,.0284l-.7211-.8639c.0907-.0769,9.1497-7.4714,16.8641,.0286,7.5136,7.3066,8.9836,50.5777,9.0423,52.4164l-1.1243,.0352Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 675.043px 403.065px;" id="el4khcz22zih3"
                      class="animable"></path>
                    <path
                      d="M687.48949,439.6575c-.0517-.5285-4.9144-53.0714,14.2741-75.0932l.84841,.7386c-18.87031,21.657-14.05311,73.7216-14.00261,74.2447l-1.1199,.1099Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 694.757px 402.111px;" id="elx6eckd9l9lm"
                      class="animable"></path>
                    <path
                      d="M686.78009,434.0995l-1.1232-.0747c.0396-.5956,3.7555-59.7338-10.3197-72.6796l.7617-.8288c14.4696,13.3096,10.8428,71.128,10.6812,73.5831Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 681.318px 397.308px;" id="elwm4uatfgz"
                      class="animable"></path>
                    <path
                      d="M688.61219,434.0666l-1.1254-.0088c.0137-1.6288,.4643-39.9453,11.3483-46.1824,2.4195-1.3881,4.7886-1.6189,7.0423-.687,6.5812,2.7223,9.8425,14.435,9.9783,14.9319l-1.0859,.2968c-.03179-.1167-3.252-11.6787-9.3239-14.189-1.9138-.7902-3.9493-.5813-6.0513,.6232-10.328,5.9183-10.7791,44.823-10.7824,45.2153Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 701.671px 410.347px;" id="el5fzjpp19jb5"
                      class="animable"></path>
                    <path
                      d="M684.37359,434.0941l-1.123-.0637c.0169-.3023,1.6418-30.2792-6.4483-40.8412-1.9602-2.5584-4.088-3.7785-6.3362-3.6213-5.3007,.3705-9.7459,8.1275-9.7899,8.2056l-.98019-.5539c.19289-.3408,4.78529-8.3605,10.68989-8.7739,2.6417-.1879,5.104,1.1804,7.3099,4.06,8.344,10.8927,6.7498,40.3409,6.6778,41.5884Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 672.118px 411.261px;" id="el34evaa63pkg"
                      class="animable"></path>
                    <path
                      d="M686.14749,438.1716c-.2202-2.9081-5.2886-71.3554,3.6292-83.4165l.9045,.6682c-8.6711,11.7282-3.4648,81.9548-3.4116,82.6626l-1.1221,.0857Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 687.598px 396.463px;" id="el4vw54dv00dd"
                      class="animable"></path>
                  </g>
                  <path
                    d="M708.30259,394.1253s-5.3499,1.9722-3.7592,6.6284c1.5904,4.6563,18.5073,12.3194,18.5073,12.3194,0,0,7.085-6.4939,.57832-17.0552-6.50651-10.5614-15.18171-5.2247-15.32641-1.8926Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 715.313px 401.429px;" id="elye78nwcfxv"
                    class="animable"></path>
                  <path
                    d="M685.04549,385.2619s5.6968-.2386,6.0207,4.6712c.3236,4.9096-12.3416,18.4927-12.3416,18.4927,0,0-9.0383-3.2676-7.0971-15.5194,1.9413-12.2518,12.0022-10.6646,13.418-7.6445Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 681.213px 395.772px;" id="elcbgvj5rkjx"
                    class="animable"></path>
                  <path
                    d="M680.22239,361.7598s3.7981-.4079,3.4923-2.7212c-.3058-2.3136-14.7648-8.2678-23.2955,1.3604,0,0,5.4624,9.383,13.3585,9.5056,7.8962,.1227,6.4447-8.1448,6.4447-8.1448Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 672.076px 362.497px;" id="elahjpurrcpbn"
                    class="animable"></path>
                  <path
                    d="M687.29989,356.1416s-3.4391,1.6629-4.4031-.4623c-.964-2.125,8.1579-14.8256,20.4903-11.1662,0,0,.3267,10.8523-6.3098,15.1325-6.6364,4.2805-9.7774-3.504-9.7774-3.504Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 693.108px 352.393px;" id="eltnig6bolw5p"
                    class="animable"></path>
                  <path
                    d="M669.31719,392.2538s2.6213-2.1165-.2076-3.1496c-2.829-1.0331-16.7095-.7395-16.8541,13.0103,0,0,16.1664,2.6865,18.16751-2.5508,2.00109-5.2375-1.10581-7.3099-1.10581-7.3099Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 661.675px 395.772px;" id="el0d3i9kjnmojf"
                    class="animable"></path>
                  <path
                    d="M702.28349,361.415s-2.6212-2.1165,.2077-3.1496c2.82881-1.0331,16.7092-.7396,16.8539,13.0101,0,0-16.1665,2.6869-18.1674-2.5508-2.001-5.2374,1.1058-7.3097,1.1058-7.3097Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 709.926px 364.933px;" id="eltz8er0swhn"
                    class="animable"></path>
                  <path
                    d="M693.73709,372.4911s-.2308-2.5728,1.8464-1.5642c2.0771,1.0083,9.3132,8.8149,1.8124,16.223,0,0-10.097-7.4781-8.2771-11.3724,1.8201-3.8943,4.6183-3.2864,4.6183-3.2864Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 694.811px 378.921px;" id="elrxguxnqd6bn"
                    class="animable"></path>
                  <path
                    d="M666.97369,376.1574s1.5671-2.0535-.72769-2.3079c-2.29491-.2545-12.57941,2.4906-10.18431,12.7573,0,0,12.5292-.9416,13.0662-5.2065,.537-4.265-2.1542-5.2429-2.1542-5.2429Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 662.451px 380.22px;" id="el0f5bjzxdsjfd"
                    class="animable"></path>
                  <polygon
                    points="699.14479 453.8989 673.87269 453.8989 667.15039 422.8933 705.22849 422.8933 699.14479 453.8989"
                    style="fill: rgb(240, 240, 240); transform-origin: 686.189px 438.396px;" id="elnjiq4dnk2rd"
                    class="animable"></polygon>
                  <rect x="664.85789" y="422.4445" width="42.56411" height="8.9161"
                    style="fill: rgb(230, 230, 230); transform-origin: 686.14px 426.903px;" id="elrpyq11446q"
                    class="animable"></rect>
                </g>
                <g id="el4n1tmuosyge" class="animable" style="transform-origin: 189.656px 81.066px;">
                  <g id="el1tzw1urzylp">
                    <rect x="167.75988" y="52.934" width="43.79227" height="56.26409" rx="3.51173" ry="3.51173"
                      style="fill: rgb(230, 230, 230); transform-origin: 189.656px 81.066px; transform: rotate(-11.1308deg);"
                      class="animable"></rect>
                  </g>
                  <g id="el5dldl9oaxu6">
                    <path
                      d="M174.9328,65.45221h24.03745c.97943,0,1.7746,.79517,1.7746,1.7746v.18767c0,.97914-.79494,1.77407-1.77407,1.77407h-24.03729c-.97979,0-1.77526-.79547-1.77526-1.77526v-.18652c0-.97941,.79516-1.77457,1.77457-1.77457Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 186.952px 67.3204px; transform: rotate(-11.1308deg);"
                      class="animable"></path>
                  </g>
                  <g id="elzkq18khuz6m">
                    <path
                      d="M176.77775,74.83097h24.03847c.97907,0,1.77395,.79488,1.77395,1.77395v.18783c0,.97939-.79514,1.77454-1.77454,1.77454h-24.03744c-.97948,0-1.77469-.79521-1.77469-1.77469v-.18736c0-.97924,.79502-1.77426,1.77426-1.77426Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 188.797px 76.6991px; transform: rotate(-11.1308deg);"
                      class="animable"></path>
                  </g>
                  <g id="elqvznf2fns7m">
                    <path
                      d="M178.62318,84.2098h24.0375c.97954,0,1.7748,.79526,1.7748,1.7748v.18735c0,.97918-.79497,1.77416-1.77416,1.77416h-24.03778c-.97955,0-1.77482-.79527-1.77482-1.77482v-.18704c0-.97935,.79511-1.77445,1.77445-1.77445Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 190.642px 86.078px; transform: rotate(-11.1309deg);"
                      class="animable"></path>
                  </g>
                  <g id="elgf11jb81nu">
                    <path
                      d="M180.59094,94.84815h10.98787c.97944,0,1.77461,.79518,1.77461,1.77461v.18745c0,.97929-.79506,1.77434-1.77434,1.77434h-10.98773c-.97942,0-1.77459-.79517-1.77459-1.77459v-.18763c0-.9792,.79498-1.77418,1.77418-1.77418Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 186.085px 96.7164px; transform: rotate(-11.131deg);"
                      class="animable"></path>
                  </g>
                </g>
                <g id="elo4r7nmpkm9" class="animable" style="transform-origin: 74.2998px 398.877px;">
                  <g id="elihtwjaldhtc" class="animable" style="transform-origin: 72.7588px 397.206px;">
                    <path
                      d="M71.8553,430.9695c-.0142-.4451-1.5121-44.6527-8.7028-51.6447-6.9937-6.7988-15.0208-.2529-15.3582,.0284l-.7211-.8639c.0908-.0769,9.1498-7.4714,16.8641,.0286,7.5137,7.3066,8.9836,50.5777,9.0424,52.4164l-1.1244,.0352Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 60.0265px 403.065px;" id="elagu33whevr"
                      class="animable"></path>
                    <path
                      d="M72.4725,439.6575c-.0517-.5285-4.9144-53.0714,14.2741-75.0932l.8485,.7386c-18.8703,21.657-14.0531,73.7216-14.0026,74.2447l-1.12,.1099Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 79.7404px 402.111px;" id="elu5bkvboeu9d"
                      class="animable"></path>
                    <path
                      d="M71.7631,434.0995l-1.1232-.0747c.0396-.5956,3.7555-59.7338-10.3197-72.6796l.7618-.8288c14.4696,13.3096,10.8427,71.128,10.6811,73.5831Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 66.3013px 397.308px;" id="elaa5itvymlrh"
                      class="animable"></path>
                    <path
                      d="M73.5952,434.0666l-1.1254-.0088c.0137-1.6288,.4643-39.9453,11.3483-46.1824,2.4195-1.3881,4.7887-1.6189,7.0423-.687,6.5812,2.7223,9.8426,14.435,9.9783,14.9319l-1.0858,.2968c-.0319-.1167-3.2521-11.6787-9.3239-14.189-1.9139-.7902-3.9494-.5813-6.0513,.6232-10.3281,5.9183-10.7791,44.823-10.7825,45.2153Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 86.6543px 410.347px;" id="elet09i5l1k0w"
                      class="animable"></path>
                    <path
                      d="M69.3567,434.0941l-1.1231-.0637c.0169-.3023,1.6418-30.2792-6.4483-40.8412-1.9601-2.5584-4.088-3.7785-6.3361-3.6213-5.3008,.3705-9.7459,8.1275-9.79,8.2056l-.9802-.5539c.1929-.3408,4.7853-8.3605,10.69-8.7739,2.6416-.1879,5.104,1.1804,7.3098,4.06,8.3441,10.8927,6.7499,40.3409,6.6779,41.5884Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 57.1015px 411.261px;" id="elg49cuhsyx3"
                      class="animable"></path>
                    <path
                      d="M71.1306,438.1716c-.2203-2.9081-5.2886-71.3554,3.6291-83.4165l.9045,.6682c-8.6711,11.7282-3.4648,81.9548-3.4115,82.6626l-1.1221,.0857Z"
                      style="fill: rgb(224, 224, 224); transform-origin: 72.5812px 396.463px;" id="elhu3jnhnwmr9"
                      class="animable"></path>
                  </g>
                  <path
                    d="M93.2856,394.1253s-5.3499,1.9722-3.7592,6.6284c1.5905,4.6563,18.5073,12.3194,18.5073,12.3194,0,0,7.085-6.4939,.5783-17.0552-6.5064-10.5614-15.1817-5.2247-15.3264-1.8926Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 100.296px 401.429px;" id="elx78vi1cj9y"
                    class="animable"></path>
                  <path
                    d="M70.0285,385.2619s5.6969-.2386,6.0207,4.6712c.3237,4.9096-12.3415,18.4927-12.3415,18.4927,0,0-9.0383-3.2676-7.0972-15.5194,1.9414-12.2518,12.0023-10.6646,13.418-7.6445Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 66.1963px 395.772px;" id="el1ulnvmos1gq"
                    class="animable"></path>
                  <path
                    d="M65.2054,361.7598s3.7982-.4079,3.4924-2.7212c-.3058-2.3136-14.7648-8.2678-23.2956,1.3604,0,0,5.4624,9.383,13.3586,9.5056,7.8961,.1227,6.4446-8.1448,6.4446-8.1448Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 57.0588px 362.497px;" id="elbmcx2f9dsro"
                    class="animable"></path>
                  <path
                    d="M72.283,356.1416s-3.4391,1.6629-4.4032-.4623c-.964-2.125,8.158-14.8256,20.4904-11.1662,0,0,.3267,10.8523-6.3098,15.1325-6.6364,4.2805-9.7774-3.504-9.7774-3.504Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 78.0914px 352.393px;" id="el2v74b13jn5i"
                    class="animable"></path>
                  <path
                    d="M54.3002,392.2538s2.6213-2.1165-.2076-3.1496c-2.829-1.0331-16.7095-.7395-16.8541,13.0103,0,0,16.1665,2.6865,18.1676-2.5508,2.0011-5.2375-1.1059-7.3099-1.1059-7.3099Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 46.6582px 395.772px;" id="elanez26f1ylm"
                    class="animable"></path>
                  <path
                    d="M87.2666,361.415s-2.6212-2.1165,.2076-3.1496,16.7093-.7396,16.854,13.0101c0,0-16.1665,2.6869-18.1675-2.5508-2.001-5.2374,1.1059-7.3097,1.1059-7.3097Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 94.9086px 364.933px;" id="ellvg1l29eiyb"
                    class="animable"></path>
                  <path
                    d="M78.7201,372.4911s-.2307-2.5728,1.8464-1.5642c2.0771,1.0083,9.3133,8.8149,1.8124,16.223,0,0-10.097-7.4781-8.2771-11.3724,1.8201-3.8943,4.6183-3.2864,4.6183-3.2864Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 79.7939px 378.921px;" id="elakkqf43xidp"
                    class="animable"></path>
                  <path
                    d="M51.9567,376.1574s1.5671-2.0535-.7277-2.3079c-2.2949-.2545-12.5794,2.4906-10.1843,12.7573,0,0,12.5292-.9416,13.0662-5.2065,.537-4.265-2.1542-5.2429-2.1542-5.2429Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 47.4337px 380.22px;" id="elq3uzpbkm2xr"
                    class="animable"></path>
                  <polygon points="84.1278 453.8989 58.8557 453.8989 52.1335 422.8933 90.2116 422.8933 84.1278 453.8989"
                    style="fill: rgb(240, 240, 240); transform-origin: 71.1726px 438.396px;" id="elo7ms5x3ckn"
                    class="animable"></polygon>
                  <rect x="49.8409" y="422.4445" width="42.5642" height="8.9161"
                    style="fill: rgb(230, 230, 230); transform-origin: 71.123px 426.903px;" id="el8zpkt5sbb7j"
                    class="animable"></rect>
                </g>
                <path
                  d="M437.05779,375.3642c-.2791-1.3467-.81279-2.5989-1.5427-3.7109l1.3288-1.6049-2.8407-2.8408-1.605,1.3286c-1.1121-.7296-2.36419-1.2634-3.7107-1.5425l-.1949-2.0688h-4.0174l-.195,2.0688c-1.3465,.2792-2.5988,.8129-3.7106,1.5425l-1.605-1.3286-2.8408,2.8408,1.3287,1.605c-.7298,1.1119-1.2634,2.3641-1.5427,3.7108l-2.06879,.195v4.0172l2.06879,.195c.2793,1.3466,.8129,2.5985,1.5427,3.7106l-1.3287,1.605,2.8408,2.8408,1.605-1.3289c1.1118,.73,2.3641,1.2635,3.7106,1.5427l.195,2.0688h4.0174l.1949-2.0686c1.34651-.2791,2.5986-.8129,3.7107-1.5429l1.605,1.3289,2.8407-2.8408-1.3288-1.6048c.72991-1.1123,1.2636-2.3642,1.5427-3.7108l2.0686-.195v-4.0172l-2.0686-.195Zm-5.1353,2.2036c0,3.0035-2.435,5.4385-5.4387,5.4385s-5.4386-2.435-5.4386-5.4385c0-3.0038,2.4349-5.4387,5.4386-5.4387s5.4387,2.4349,5.4387,5.4387Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 426.484px 377.568px;" id="elp5pqj9kfi4"
                  class="animable"></path>
                <path
                  d="M443.967,136.0292c-1.48941-7.1853-4.33671-13.8655-8.23061-19.7982l7.089-8.5626-15.1556-15.1566-8.5634,7.0881c-5.9329-3.8921-12.6132-6.7402-19.79739-8.2297l-1.03971-11.0369h-21.4338l-1.0401,11.0369c-7.1843,1.4899-13.8649,4.3376-19.797,8.2297l-8.563-7.0881-15.15639,15.1566,7.08859,8.5632c-3.89359,5.9321-6.7405,12.6123-8.2307,19.7976l-11.037,1.0405v21.4325l11.037,1.0406c1.4902,7.1843,4.33711,13.8633,8.2307,19.7966l-7.08859,8.563,15.15639,15.1564,8.563-7.09c5.9321,3.8949,12.6127,6.7413,19.797,8.2312l1.0401,11.0369h21.4338l1.03971-11.036c7.18419-1.4891,13.86449-4.3372,19.79739-8.2321l8.5634,7.09,15.1556-15.1564-7.089-8.5622c3.8939-5.9341,6.7412-12.6131,8.23061-19.7974l11.03609-1.0406v-21.4325l-11.03609-1.0405Zm-27.39851,11.7563c0,16.0246-12.9909,29.0158-29.0164,29.0158s-29.01609-12.9912-29.01609-29.0158,12.99089-29.0162,29.01609-29.0162,29.0164,12.991,29.0164,29.0162Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 387.552px 147.785px;" id="elesp016kx3is"
                  class="animable"></path>
                <path
                  d="M315.0156,107.1992c-.4367-2.1067-1.2715-4.0653-2.4133-5.8048l2.0786-2.5106-4.4437-4.4439-2.5108,2.0783c-1.7395-1.1412-3.6981-1.9763-5.8046-2.413l-.3048-3.236h-6.2844l-.305,3.236c-2.1064,.4369-4.0652,1.2718-5.8044,2.413l-2.5107-2.0783-4.4439,4.4439,2.0784,2.5108c-1.1416,1.7393-1.9763,3.6979-2.4132,5.8046l-3.2361,.3051v6.284l3.2361,.3051c.4369,2.1065,1.2716,4.0648,2.4132,5.8044l-2.0784,2.5106,4.4439,4.4439,2.5107-2.0788c1.7392,1.142,3.698,1.9766,5.8044,2.4134l.305,3.2361h6.2844l.3048-3.2358c2.1065-.4366,4.0651-1.2717,5.8046-2.4137l2.5108,2.0788,4.4437-4.4439-2.0786-2.5104c1.1418-1.7398,1.9766-3.6981,2.4133-5.8046l3.2357-.3051v-6.284l-3.2357-.3051Zm-8.0333,3.447c0,4.6984-3.8089,8.5074-8.5076,8.5074s-8.5075-3.809-8.5075-8.5074,3.8089-8.5076,8.5075-8.5076,8.5076,3.809,8.5076,8.5076Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 298.474px 110.646px;" id="el81niddaup5h"
                  class="animable"></path>
                <path
                  d="M489.10659,148.1434c-.28-1.351-.8154-2.6071-1.54759-3.7226l1.333-1.61-2.84971-2.8498-1.6101,1.3328c-1.1156-.7319-2.3716-1.2674-3.7224-1.5474l-.1955-2.0753h-4.0301l-.1956,2.0753c-1.3508,.2801-2.607,.8155-3.7223,1.5474l-1.6101-1.3328-2.8498,2.8498,1.3329,1.6101c-.7321,1.1154-1.2674,2.3715-1.5476,3.7225l-2.0753,.1956v4.0299l2.0753,.1956c.2802,1.3509,.8155,2.6067,1.5476,3.7223l-1.3329,1.6101,2.8498,2.8498,1.6101-1.3331c1.1153,.7323,2.3715,1.2675,3.7223,1.5476l.1956,2.0753h4.0301l.1955-2.0751c1.3508-.28,2.6068-.8155,3.7224-1.5478l1.6101,1.3331,2.84971-2.8498-1.333-1.6099c.73219-1.1158,1.26759-2.3716,1.54759-3.7225l2.0751-.1956v-4.0299l-2.0751-.1956Zm-5.15159,2.2105c0,3.013-2.44261,5.4557-5.45581,5.4557s-5.4558-2.4427-5.4558-5.4557c0-3.0132,2.44261-5.4558,5.4558-5.4558s5.45581,2.4426,5.45581,5.4558Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 478.499px 150.354px;" id="elyfsxs9ryhda"
                  class="animable"></path>
                <g id="elukpocykaqhe" class="animable" style="transform-origin: 96.858px 108.912px;">
                  <rect x="69.4651" y="113.0278" width="13.2873" height="15.7239" rx="1.5714" ry="1.5714"
                    style="fill: rgb(224, 224, 224); transform-origin: 76.1088px 120.89px;" id="el6mf3i89ik6"
                    class="animable"></rect>
                  <rect x="89.4494" y="100.7985" width="13.2874" height="27.9532" rx="1.5714" ry="1.5714"
                    style="fill: rgb(224, 224, 224); transform-origin: 96.0931px 114.775px;" id="elu5yhsuy88g"
                    class="animable"></rect>
                  <rect x="110.9635" y="88.722" width="13.28738" height="40.3794" rx="1.5713" ry="1.5713"
                    style="fill: rgb(224, 224, 224); transform-origin: 117.607px 108.912px;" id="el9j0tr2kqhmk"
                    class="animable"></rect>
                </g>
                <g id="elojojqzk2y5m" class="animable" style="transform-origin: 647.353px 136.571px;">
                  <path
                    d="M680.66589,127.367h-24.5264c-2.5172,0-4.558-2.0408-4.558-4.558v-24.2223c0-4.4655-1.044-8.6511-4.1296-10.5962-2.67589-1.6868-6.2066,.1666-6.6973,3.2915-1.1301,7.1987-4.4913,21.7916-13.8679,30.8673-2.9128,2.8194-5.1222,8.6166-5.1222,12.6704v43.297c0,4.2375,7.4513,7.6727,16.5064,7.6727h37.1324c7.1884,0,13.01571-5.8273,13.01571-13.0157v-5.6111c0-4.1266,1.26639-8.0842,3.16609-11.7477,1.37491-2.6515,2.0963-5.5996,2.0963-8.5971v-6.4352c0-7.1884-5.8271-13.0156-13.0155-13.0156Z"
                    style="fill: rgb(235, 235, 235); transform-origin: 657.723px 136.571px;" id="elwfu7vft7y3"
                    class="animable"></path>
                  <rect x="601.02559" y="128.9432" width="15.84541" height="53.6269"
                    style="fill: rgb(235, 235, 235); transform-origin: 608.948px 155.757px;" id="ela5f3cdgy45v"
                    class="animable"></rect>
                </g>
                <g id="elk8lamn8i4m" class="animable" style="transform-origin: 561.759px 89.5802px;">
                  <path
                    d="M575.65379,74.5531l-12.7451,4.7549c-1.3081,.4881-2.7642-.1768-3.2523-1.4849l-4.696-12.5871c-.8657-2.3205-2.2197-4.2931-4.2003-4.7057-1.7175-.3578-3.1929,1.2898-2.84209,3.0089,.80839,3.9599,1.89089,12.1947-1.22211,18.7288-.9671,2.0298-.9913,5.4706-.2054,7.5772l8.3941,22.4993c.8216,2.202,5.3597,2.5425,10.0651,.787l19.2959-7.199c3.7355-1.3936,5.6339-5.5516,4.2402-9.287l-1.08779-2.9158c-.8-2.1444-.90931-4.4465-.63231-6.7185,.2004-1.6444,.0037-3.3163-.5774-4.8739l-1.2476-3.3441c-1.3936-3.7354-5.5514-5.6338-9.2869-4.2401Z"
                    style="fill: rgb(240, 240, 240); transform-origin: 567.438px 87.3665px;" id="elc9n8og6gzpf"
                    class="animable"></path>
                  <g id="elaco99es5eon">
                    <rect x="539.49549" y="88.33806" width="8.78849" height="29.74347"
                      style="fill: rgb(240, 240, 240); transform-origin: 543.89px 103.21px; transform: rotate(-20.4597deg);"
                      class="animable"></rect>
                  </g>
                </g>
                <path
                  d="M504.56549,210.3307c-.5537-2.6712-1.6122-5.1546-3.0598-7.3601l2.6354-3.1832-5.6342-5.6345-3.1834,2.6351c-2.2056-1.4469-4.6891-2.5057-7.3598-3.0595l-.3865-4.103h-7.9681l-.3867,4.103c-2.6708,.5539-5.1543,1.6126-7.3596,3.0595l-3.1833-2.6351-5.63449,5.6345,2.63529,3.1835c-1.4475,2.2052-2.5058,4.6886-3.0599,7.3598l-4.103,.3868v7.9676l4.103,.3868c.5541,2.6709,1.6124,5.1538,3.0599,7.3595l-2.63529,3.1833,5.63449,5.6345,3.1833-2.6357c2.2053,1.4479,4.6888,2.5061,7.3596,3.0599l.3867,4.1031h7.9681l.3865-4.1027c2.6707-.5536,5.1542-1.6124,7.3598-3.0603l3.1834,2.6357,5.6342-5.6345-2.6354-3.183c1.4476-2.206,2.5061-4.6889,3.0598-7.3598l4.1027-.3868v-7.9676l-4.1027-.3868Zm-10.18549,4.3704c0,5.9572-4.82941,10.7868-10.787,10.7868s-10.78681-4.8296-10.78681-10.7868,4.8294-10.7869,10.78681-10.7869,10.787,4.8295,10.787,10.7869Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 483.593px 214.701px;" id="eliwgtz7o80a"
                  class="animable"></path>
                <path
                  d="M340.76719,235.9537c-.77419-3.7354-2.2545-7.2082-4.2788-10.2924l3.6853-4.4514-7.8788-7.8794-4.45189,3.6849c-3.08431-2.0234-6.5572-3.504-10.292-4.2784l-.5405-5.7377h-11.1427l-.5407,5.7377c-3.7349,.7746-7.2079,2.255-10.2918,4.2784l-4.4516-3.6849-7.8794,7.8794,3.6852,4.4517c-2.0241,3.0839-3.5042,6.5567-4.2789,10.2921l-5.7378,.5409v11.1421l5.7378,.541c.7747,3.7349,2.2548,7.207,4.2789,10.2916l-3.6852,4.4516,7.8794,7.8793,4.4516-3.6859c3.0839,2.0249,6.5569,3.5046,10.2918,4.2791l.5407,5.7378h11.1427l.5405-5.7373c3.7348-.7741,7.20769-2.2547,10.292-4.2796l4.45189,3.6859,7.8788-7.8793-3.6853-4.4512c2.0243-3.085,3.50461-6.5571,4.2788-10.292l5.7373-.541v-11.1421l-5.7373-.5409Zm-14.2435,6.1118c0,8.3306-6.7536,15.0843-15.08469,15.0843s-15.0845-6.7537-15.0845-15.0843,6.7535-15.0846,15.0845-15.0846,15.08469,6.7536,15.08469,15.0846Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 311.439px 242.065px;" id="el0dvrnhyv0jnr"
                  class="animable"></path>
                <path
                  d="M217.607,172.4647c-.7109-3.4298-2.0701-6.6185-3.9288-9.4504l3.3839-4.0872-7.2343-7.2347-4.0876,3.3834c-2.832-1.8579-6.0208-3.2174-9.45-3.9284l-.4963-5.2683h-10.2311l-.4965,5.2683c-3.4293,.7112-6.6182,2.0705-9.4498,3.9284l-4.0874-3.3834-7.2347,7.2347,3.3837,4.0875c-1.8585,2.8316-3.2175,6.0203-3.9288,9.4501l-5.2684,.4967v10.2304l5.2684,.4967c.7113,3.4294,2.0703,6.6175,3.9288,9.4496l-3.3837,4.0875,7.2347,7.2346,4.0874-3.3843c2.8316,1.8592,6.0205,3.2179,9.4498,3.9291l.4965,5.2683h10.2311l.4963-5.2679c3.4292-.7108,6.618-2.0703,9.45-3.9295l4.0876,3.3843,7.2343-7.2346-3.3839-4.0871c1.8587-2.8325,3.2179-6.0206,3.9288-9.45l5.2679-.4967v-10.2304l-5.2679-.4967Zm-13.0783,5.6117c0,7.6491-6.201,13.8502-13.8505,13.8502s-13.8504-6.2011-13.8504-13.8502,6.201-13.8505,13.8504-13.8505,13.8505,6.2011,13.8505,13.8505Z"
                  style="fill: rgb(240, 240, 240); transform-origin: 190.678px 178.076px;" id="elviuiin6pvla"
                  class="animable"></path>
              </g>
              <g id="freepik--Shadow--inject-18" class="animable" style="transform-origin: 381.811px 458.815px;">
                <path
                  d="M703.52009,458.8151c0,3.3867-144.0339,6.1322-321.70869,6.1322s-321.70869-2.7455-321.70869-6.1322,144.0339-6.1323,321.70869-6.1323,321.70869,2.7455,321.70869,6.1323Z"
                  style="fill: rgb(245, 245, 245); transform-origin: 381.811px 458.815px;" id="el7gwb0u95xes"
                  class="animable"></path>
              </g>
              <g id="freepik--Speech_Bubble_3--inject-18" class="animable"
                style="transform-origin: 559.204px 208.697px;">
                <g id="eltrkdqs1888p" class="animable" style="transform-origin: 559.204px 208.697px;">
                  <g id="elp40horuoiag" class="animable" style="transform-origin: 559.204px 208.697px;">
                    <path
                      d="M575.64269,194.8089c-8.1739-9.0789-22.1599-9.8127-31.2388-1.6389-9.0788,8.1741-9.8126,22.1602-1.6386,31.2392,6.3103,7.0088,16.0781,9.0206,24.3845,5.8233l5.9097,3.035,.66-6.9885c.0929-.0809,.1928-.1487,.2847-.2316,9.0789-8.1736,9.8122-22.1598,1.6385-31.2385Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 559.204px 210.378px;" id="el7wvla7eodxw"
                      class="animable"></path>
                    <path
                      d="M545.92773,188.56152c-.07812,0-.15527-.03662-.20312-.10498-.08105-.1123-.05469-.26855,.05762-.34863,5.90332-4.20605,14.05957-5.16943,20.77832-2.45947,.12793,.05176,.18945,.19727,.1377,.32568-.05176,.12744-.19531,.1875-.3252,.13818-6.56738-2.64941-14.53418-1.70508-20.2998,2.40283-.04395,.03125-.09473,.04639-.14551,.04639Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 556.197px 186.344px;" id="el7eqf8oubg7u"
                      class="animable"></path>
                    <path
                      d="M572.32422,189.0835c-.05859,0-.11816-.02051-.16504-.0625-.79395-.69824-1.74609-1.23779-2.75293-1.56006-.13184-.04248-.2041-.18311-.16211-.31445s.18457-.20166,.31445-.16211c1.07227,.34326,2.08594,.91748,2.93066,1.66162,.10352,.09082,.11426,.24902,.02246,.35254-.04883,.05615-.11816,.08496-.1875,.08496Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 570.903px 188.028px;" id="elfkbo49ordcb"
                      class="animable"></path>
                  </g>
                  <path
                    d="M557.12039,220.341c-.558-.5373-.8367-1.1914-.8367-1.9625,0-.7702,.2736-1.4145,.8215-1.9316,.5475-.517,1.247-.7756,2.099-.7756,.8516,0,1.5511,.2586,2.0991,.7756,.5474,.5171,.821,1.1614,.821,1.9316,0,.7711-.2789,1.4252-.83629,1.9625-.55821,.5375-1.25271,.8059-2.08381,.8059-.8319,0-1.5264-.2684-2.0838-.8059Zm-.7453-20.7312h5.6581l-.9429,13.9018h-3.7722l-.943-13.9018Z"
                    style="fill: rgb(255, 255, 255); transform-origin: 559.204px 210.378px;" id="elii06adh2ev"
                    class="animable"></path>
                </g>
              </g>
              <g id="freepik--Speech_Bubble_2--inject-18" class="animable"
                style="transform-origin: 338.369px 56.1768px;">
                <g id="eloaydebiq3zf" class="animable" style="transform-origin: 338.369px 56.1768px;">
                  <g id="elia99btypgfb" class="animable" style="transform-origin: 338.369px 56.1768px;">
                    <path
                      d="M345.16549,37.3144c-11.1851-4.0787-23.5588,1.6821-27.6374,12.8672-4.0787,11.1851,1.6821,23.5589,12.8673,27.6375,8.635,3.1487,17.9619,.416,23.6503-6.1325l6.4738-.0887-2.626-6.3169c.043-.1121,.0979-.216,.1393-.3294,4.0788-11.1848-1.6823-23.5586-12.8673-27.6372Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 338.369px 57.5651px;" id="elv1mxgy4mega"
                      class="animable"></path>
                    <path
                      d="M316.61865,45.55322c-.0376,0-.07568-.0083-.11182-.02637-.12354-.06201-.17334-.21191-.11182-.33545,3.15918-6.31934,9.74023-10.87891,16.76611-11.6167,.13135-.01367,.26025,.08496,.27441,.22266,.01465,.13721-.08496,.26025-.22266,.27441-6.85986,.72021-13.28564,5.17285-16.37061,11.34326-.04395,.0874-.13232,.13818-.22363,.13818Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 324.903px 39.5633px;" id="el6bsso5ecpx"
                      class="animable"></path>
                    <path
                      d="M339.57861,33.93799c-.01904,0-.03857-.00195-.05811-.00684-1.00244-.23877-2.06738-.26807-3.08057-.08447-.13379,.02295-.26611-.06543-.29053-.20166-.02441-.13574,.06592-.26562,.20166-.29053,1.07861-.1958,2.21436-.16455,3.28564,.09033,.13428,.03174,.21729,.1665,.18506,.30127-.02734,.11475-.12988,.19189-.24316,.19189Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 337.987px 33.5828px;" id="eldba4wyzexjf"
                      class="animable"></path>
                  </g>
                  <g id="elafp37lck1m" class="animable" style="transform-origin: 339.578px 57.5651px;">
                    <rect x="329.12489" y="54.6555" width="16.9238" height="5.8191"
                      style="fill: rgb(255, 255, 255); transform-origin: 337.587px 57.5651px;" id="elzse023ff8fk"
                      class="animable"></rect>
                    <polygon
                      points="350.03209 57.5651 347.25029 60.7773 344.46839 63.9895 344.46839 57.5651 344.46839 51.1407 347.25029 54.3529 350.03209 57.5651"
                      style="fill: rgb(255, 255, 255); transform-origin: 347.25px 57.5651px;" id="elha57kg8isb9"
                      class="animable"></polygon>
                  </g>
                </g>
              </g>
              <g id="freepik--Speech_Bubble_1--inject-18" class="animable"
                style="transform-origin: 173.709px 123.928px;">
                <g id="elf0z6y56ypt8" class="animable" style="transform-origin: 173.709px 123.928px;">
                  <g id="elq4taqw7j23f" class="animable" style="transform-origin: 173.709px 123.928px;">
                    <path
                      d="M190.3566,136.4819c6.9337-10.058,4.4011-23.8323-5.6569-30.766-10.0581-6.9335-23.8326-4.4009-30.7662,5.6572-5.3527,7.7648-5.0424,17.7328-.0043,25.0702l-1.5805,6.4527,6.9509-.9801c.1002,.0716,.1894,.153,.2913,.2232,10.0578,6.9337,23.8322,4.4006,30.7657-5.6572Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 172.15px 123.928px;" id="el2hp7gwg4z2b"
                      class="animable"></path>
                    <path
                      d="M196.91162,125.73438c-.0127,0-.0249-.00098-.0376-.00293-.13672-.02051-.23047-.14746-.20996-.28418,1.05273-7.00195-1.71436-14.53369-7.04932-19.18896-.104-.09082-.11475-.24902-.02393-.35303,.09082-.10449,.24854-.11426,.35303-.02393,5.45996,4.76465,8.29199,12.47412,7.21436,19.64014-.01855,.12402-.125,.21289-.24658,.21289Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 193.457px 115.777px;" id="elgrts07bmx16"
                      class="animable"></path>
                    <path
                      d="M195.39844,132.11719c-.03955,0-.08008-.00928-.11719-.0293-.12207-.06445-.16846-.21582-.10352-.33789,.49512-.93311,.79883-1.98438,.87842-3.04053,.01074-.13721,.12549-.24463,.26855-.23047,.13721,.01074,.24072,.13086,.23047,.26855-.08496,1.12402-.40869,2.24316-.93604,3.23682-.04492,.08447-.13135,.13281-.2207,.13281Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 195.852px 130.297px;" id="elnoeiccob1vc"
                      class="animable"></path>
                  </g>
                  <path
                    d="M169.8827,134.3835c-.558-.5372-.8367-1.1913-.8367-1.9625,0-.7702,.2736-1.4144,.8215-1.9315,.5475-.5171,1.247-.7756,2.099-.7756,.8515,0,1.5511,.2585,2.099,.7756,.5474,.5171,.8211,1.1613,.8211,1.9315,0,.7712-.2789,1.4253-.8364,1.9625-.5581,.5375-1.2526,.8059-2.0837,.8059-.8319,0-1.5264-.2684-2.0838-.8059Zm-.7453-20.7312h5.6581l-.943,13.9019h-3.7721l-.943-13.9019Z"
                    style="fill: rgb(255, 255, 255); transform-origin: 171.966px 124.421px;" id="el66dd1clj7v"
                    class="animable"></path>
                </g>
              </g>
              <g id="freepik--Character_3--inject-18" class="animable" style="transform-origin: 399.437px 253.848px;">
                <g id="elh5vukolsn8g" class="animable" style="transform-origin: 399.437px 253.848px;">
                  <g id="elpsz3ae8uslq" class="animable" style="transform-origin: 461.867px 296.705px;">
                    <polygon
                      points="442.94259 279.1697 447.96029 303.4041 458.51739 303.3082 456.58219 277.1125 442.94259 279.1697"
                      style="fill: rgb(38, 50, 56); transform-origin: 450.73px 290.258px;" id="elc48kzeuhfv"
                      class="animable"></polygon>
                    <g id="elhaq9dracm7" class="animable" style="transform-origin: 463.808px 306.711px;">
                      <path
                        d="M449.21919,301.4518c1.4156,1.2788,4.4566,1.0471,5.8119-.0407l.0158-1.9844c.226-1.6759,.8162-2.4747,2.6178-2.2697,.88331,.0992,2.0051,1.1533,2.7559,2.7429,1.346,2.8501,2.8907,3.9885,2.8907,3.9885,0,0,8.0808,3.8858,10.9734,4.7305,1.7258,.5039,4.0339,1.2681,5.2905,3.0914,.3585,.5214,.6845,1.1397,.9694,1.8718,.2339,.6007,.2469,1.9652,.2473,1.9949l-20.346,.2584s-2.0914-1.1832-2.9725-1.1401c-1.5682,.0769-2.253,1.4529-2.253,1.4529l-7.3976,.149s-.1572-1.1398-.383-2.7987l-.0019-.0005c-.1016-.7473-.21689-1.5993-.3405-2.4996-.5272-3.8625-.1006-8.4781-.2413-9.5715-.2458-1.9158,2.3631,.0249,2.3631,.0249Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 463.808px 306.711px;" id="elxc72ienpy4a"
                        class="animable"></path>
                      <path
                        d="M467.66359,305.7961c-.0928-.0811-.2335-.0873-.3382-.0194-.4806,.3105-.8805,.7435-1.1472,1.2818-.1407,.2838,.3056,.5458,.4477,.2594,.237-.4785,.5868-.8613,1.0064-1.1335,.1403-.091,.1599-.2761,.0354-.3847l-.0041-.0036Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 466.951px 306.586px;" id="el2grqv4kbqo9"
                        class="animable"></path>
                      <path
                        d="M466.08279,304.9714c-.0929-.0811-.2336-.0873-.3384-.0196-.48039,.3108-.8805,.7438-1.1472,1.2818-.1406,.2838,.30581,.5459,.4477,.2595,.23711-.4783,.5869-.861,1.0066-1.1333,.1402-.0911,.1598-.2761,.0352-.3849l-.0039-.0035Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 465.37px 305.761px;" id="elpgpcfgz49d7"
                        class="animable"></path>
                      <path
                        d="M464.454,304.2045c-.09291-.0812-.23351-.0873-.33841-.0194-.4803,.3104-.8804,.7436-1.1472,1.2818-.1405,.2838,.3058,.5458,.4477,.2593,.2371-.4783,.5868-.8611,1.0066-1.1333,.1402-.0911,.1596-.2762,.03531-.3848l-.004-.0036Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 463.741px 304.994px;" id="elqj8juu0vcx"
                        class="animable"></path>
                    </g>
                    <path
                      d="M464.06641,304.93652l-.04688-.46973,.24902-.02441-.25,.01465c-.07031-.70215-.14258-1.42871-.08691-2.1543,.06348-.8418,.29395-1.55664,.68457-2.12305,.28809-.41895,.64941-.62207,1.01074-.62305,.47754,.02344,.80371,.43848,.94922,.81934,.24707,.64355,.19531,1.42188-.14258,2.13477-.32617,.69141-1.61719,1.7998-2.00488,2.12402l-.3623,.30176Zm1.51953-4.88086c-.25098,0-.47266,.2832-.55762,.40723-.33984,.49316-.54102,1.125-.59863,1.87695-.04004,.52734-.00684,1.06738,.04102,1.59961,.6416-.56445,1.32227-1.24316,1.51172-1.64258,.2793-.58984,.3252-1.22461,.12695-1.74219-.08496-.22266-.26953-.4873-.50684-.49805l-.0166-.00098Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 465.321px 302.247px;" id="elt5c4kbjtq6a"
                      class="animable"></path>
                    <path
                      d="M464.59473,304.75586l-.45605-.06641c-.49707-.08984-2.16504-.41602-2.79297-.85742-.64551-.4541-1.0752-1.10547-1.17773-1.78809-.06055-.4043,.01953-.92578,.42285-1.17969,.30859-.19434,.71875-.18262,1.18457,.04395,.61914,.29883,1.17188,.80664,1.64355,1.50781,.4043,.60156,.69922,1.26562,.98438,1.90918l.19141,.43066Zm-3.50977-3.52734c-.08203,0-.16016,.01562-.22754,.05859-.2002,.12598-.23047,.44727-.19531,.68262,.08301,.54785,.43652,1.07715,.9707,1.4541,.36328,.25488,1.29004,.50879,2.125,.68262-.2207-.4873-.45898-.97363-.75293-1.41113-.42188-.62695-.90918-1.07715-1.44727-1.33789-.11621-.05566-.30078-.12891-.47266-.12891Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 462.372px 302.742px;" id="elom3gek035o"
                      class="animable"></path>
                  </g>
                  <g id="elcpet98olcmb" class="animable" style="transform-origin: 348.783px 320.54px;">
                    <polygon
                      points="341.62219 301.7507 332.35659 324.6992 341.19429 330.4745 354.11259 307.6037 341.62219 301.7507"
                      style="fill: rgb(38, 50, 56); transform-origin: 343.235px 316.113px;" id="elg3pr3bvclfi"
                      class="animable"></polygon>
                    <g id="elc080hndvce" class="animable" style="transform-origin: 348.783px 329.744px;">
                      <path
                        d="M334.19369,324.4845c1.4156,1.2788,4.4566,1.0471,5.8119-.0407l.0158-1.9844c.226-1.6759,.8163-2.4747,2.6179-2.2697,.8832,.0992,2.0051,1.1533,2.7558,2.7429,1.346,2.8501,2.8908,3.9885,2.8908,3.9885,0,0,8.0808,3.8858,10.9733,4.7305,1.7259,.5039,4.034,1.2681,5.2905,3.0914,.3586,.5214,.6845,1.1397,.9695,1.8718,.2339,.6007,.24681,1.9652,.2472,1.9949l-20.346,.2584s-2.09139-1.1832-2.97239-1.1401c-1.56831,.0769-2.25311,1.4529-2.25311,1.4529l-7.3976,.149s-.1571-1.1398-.383-2.7987l-.0019-.0005c-.1015-.7473-.2168-1.5993-.3405-2.4996-.5272-3.8625-.1006-8.4781-.2412-9.5715-.2459-1.9158,2.363,.0249,2.363,.0249Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 348.783px 329.744px;" id="elkaj1z8p10d"
                        class="animable"></path>
                      <path
                        d="M352.63819,328.8288c-.0928-.0811-.2336-.0873-.3383-.0194-.4806,.3105-.8805,.7435-1.1472,1.2818-.1406,.2838,.3057,.5458,.4478,.2594,.2369-.4785,.5867-.8613,1.0064-1.1335,.1402-.091,.1598-.2761,.0354-.3847l-.0041-.0036Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 351.925px 329.619px;" id="ells2vl4ew0j"
                        class="animable"></path>
                      <path
                        d="M351.05729,328.0041c-.0929-.0811-.2336-.0873-.3384-.0196-.4803,.3108-.8804,.7438-1.1472,1.2818-.1405,.2838,.3058,.5459,.4477,.2595,.2372-.4783,.5869-.861,1.00661-1.1333,.14019-.0911,.15979-.2761,.03519-.3849l-.0039-.0035Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 350.344px 328.794px;" id="elasa5m8mo1jd"
                        class="animable"></path>
                      <path
                        d="M349.42849,327.2372c-.0929-.0812-.23349-.0873-.3384-.0194-.4803,.3104-.8804,.7436-1.1472,1.2818-.1405,.2838,.3058,.5458,.4478,.2593,.237-.4783,.5867-.8611,1.0066-1.1333,.1402-.0911,.1596-.2762,.0352-.3848l-.004-.0036Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 348.716px 328.027px;" id="elf0arx2b48df"
                        class="animable"></path>
                    </g>
                    <path
                      d="M349.0415,327.96777l-.04688-.46777c-.0708-.70508-.14404-1.43262-.08887-2.16406,.06445-.84277,.29492-1.55762,.68506-2.12402,.28809-.41797,.63232-.62598,1.01025-.62305,.47803,.02441,.80371,.43848,.94922,.81836,.24707,.64453,.1958,1.42285-.1416,2.13477-.32812,.69531-1.61816,1.80176-2.00537,2.125l-.36182,.30078Zm1.51904-4.87988c-.24023,0-.45215,.25391-.55762,.40723-.33984,.49316-.54102,1.125-.59814,1.87793-.04053,.52832-.00781,1.06836,.04053,1.59961,.64111-.56348,1.32178-1.24219,1.51123-1.64355,.2793-.59082,.32568-1.22559,.12695-1.74219-.08545-.22266-.26904-.48633-.50684-.49805l-.01611-.00098Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 350.296px 325.278px;" id="elyn93wong2d"
                      class="animable"></path>
                    <path
                      d="M349.57666,327.80664l-.46436-.08398c-.49707-.09082-2.16846-.41895-2.79248-.8584-.64502-.45312-1.07422-1.10449-1.17676-1.78711-.06104-.40234,.01807-.92383,.42139-1.17969,.30762-.19531,.72949-.17969,1.18604,.04297,.61914,.2998,1.17188,.80762,1.64355,1.50879,.40967,.60938,.70557,1.27832,.9917,1.92676l.19092,.43066Zm-3.51709-3.54688c-.08154,0-.16016,.0166-.22754,.05957-.20068,.12793-.23047,.44824-.19482,.68359,.08252,.54785,.43652,1.07715,.97021,1.45312,.36035,.25293,1.28809,.50781,2.12549,.68262-.22021-.48535-.45801-.9707-.75391-1.41016-.42188-.62695-.9082-1.07715-1.44678-1.33887-.11621-.05566-.30078-.12988-.47266-.12988Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 347.351px 325.784px;" id="eluprre49nvqa"
                      class="animable"></path>
                  </g>
                  <g id="el6o0gfp0qumb" class="animable" style="transform-origin: 397.38px 242.886px;">
                    <path
                      d="M361.36279,176.3766c-.0405,1.35-8.2803,78.0658-8.2803,78.0658l-20.264,65.5956,16.0514,4.0972,30.4899-69.6928c2.1778-15.1531,8.2917-50.9906,10.792-67.1986l8.766-17.5663-32.0082-8.0415-5.5468,14.7406Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 365.868px 242.886px;" id="el7g8wuu795sq"
                      class="animable"></path>
                    <g id="elanzojxdjzx">
                      <path
                        d="M361.36279,176.3766c-.0405,1.35-8.2803,78.0658-8.2803,78.0658l-20.264,65.5956,16.0514,4.0972,30.4899-69.6928c2.1778-15.1531,8.2917-50.9906,10.792-67.1986l8.766-17.5663-32.0082-8.0415-5.5468,14.7406Z"
                        style="fill: rgb(255, 255, 255); opacity: 0.2; transform-origin: 365.868px 242.886px;"
                        class="animable"></path>
                    </g>
                    <g id="elybute7emzuj" class="animable" style="transform-origin: 421.045px 236.706px;">
                      <path
                        d="M403.884,177.2791c1.062,1.1984,40.44359,39.0773,45.39969,46.8655s12.6577,73.9877,12.6577,73.9877l-18.7625,5.2795-14.8683-69.4234s-49.2072-42.4126-48.1452-43.1206c1.0621-.708,17.58561-20.868,17.58561-20.868l6.133,7.2793Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 421.045px 236.706px;" id="elh3hxn9d2obv"
                        class="animable"></path>
                      <g id="elif6iehheya">
                        <path
                          d="M403.884,177.2791c1.062,1.1984,40.44359,39.0773,45.39969,46.8655s12.6577,73.9877,12.6577,73.9877l-18.7625,5.2795-14.8683-69.4234s-49.2072-42.4126-48.1452-43.1206c1.0621-.708,17.58561-20.868,17.58561-20.868l6.133,7.2793Z"
                          style="fill: rgb(255, 255, 255); opacity: 0.2; transform-origin: 421.045px 236.706px;"
                          class="animable"></path>
                      </g>
                    </g>
                  </g>
                  <g id="eldm92jbsi94" class="animable" style="transform-origin: 440.583px 79.4616px;">
                    <path
                      d="M443.51669,71.292s.5306-.4218,2.0009-.2806,1.23141,1.9412,1.4302,1.8464c.1986-.0949,1.1623,.0072,2.0266,.1568,.8642,.1494,1.18,1.0665,.9689,2.797-.211,1.7305-2.2054,8.4146-2.8414,8.9991,0,0-.2268,1.5695-.21089,1.7305,.01579,.161-6.93351,2.4698-6.93351,2.4698l-1.0413-5.6323-2.4551-3.0995s-1.88509-3.2313-1.7456-4.9839c.02451-.3063,.1824-.6314,.2216-.8959,.14491-.9791,1.0351-.7085,1.8995-.223l1.1292,.4058s.39021-2.4031,.553-3.308c.106-.5893,.5511-1.1078,1.1564-1.2878,.1247-.0371,.2501-.0636,.3685-.0716,.7854-.0532,.8656,.7263,.8656,.7263,0,0,1.2459-.7086,2.2231,.3502,.3603,.3905,.3843,.3007,.3843,.3007Z"
                      style="fill: rgb(233, 179, 118); transform-origin: 442.357px 79.4616px;" id="elsv343knrrei"
                      class="animable"></path>
                    <g id="el5wga1tvmtr3" class="animable" style="transform-origin: 440.583px 75.9492px;">
                      <path
                        d="M411.11159,75.9543c0,1.5412,.4491,2.7863,1.0207,2.7863h16.3812c.5512,0,1.0207-1.2451,1.0207-2.7863s-.4389-2.7966-1.0207-2.7966h-16.3813c-.5307,0-1.0206,1.2554-1.0206,2.7966Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 420.323px 75.9492px;" id="elvc8a7m95exr"
                        class="animable"></path>
                      <path
                        d="M427.46229,75.9543c0,1.9596,.6021,3.5518,1.337,3.5518h19.5453c.7348,0,1.3268-1.582,1.3268-3.5518s-.592-3.562-1.3268-3.562h-19.5861c-.7349,0-1.2962,1.6024-1.2962,3.562Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 438.567px 75.9492px;" id="el1zlz2i3i5v5i"
                        class="animable"></path>
                      <path
                        d="M446.42569,75.9543c0,2.3577,.7247,4.2663,1.6025,4.2663h20.41281c.88789,0,1.60239-1.8984,1.60239-4.2663s-.7145-4.2765-1.60239-4.2765h-20.41281c-.8778,0-1.6025,1.9188-1.6025,4.2765Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 458.235px 75.9492px;" id="elrfmpnw6o6cl"
                        class="animable"></path>
                      <g id="elcqsm5gvf9u6">
                        <path
                          d="M468.45119,71.6778c.8879,0,1.6024,1.9188,1.6024,4.2765s-.7145,4.2663-1.6024,4.2663c-.888,0-1.6024-1.9086-1.6024-4.2663,0-2.3679,.7144-4.2765,1.6024-4.2765Z"
                          style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 468.451px 75.9492px;"
                          class="animable"></path>
                      </g>
                      <path
                        d="M467.33869,75.9543c0-2.3577,.7144-3.7968,1.1125-3.7968,.398,0,1.1227,1.4493,1.1227,3.7968s-.7247,3.7866-1.1227,3.7866c-.3981,0-1.1125-1.4289-1.1125-3.7866Z"
                        id="eln0c2g2qbjg" class="animable" style="transform-origin: 468.456px 75.9492px;"></path>
                    </g>
                    <path
                      d="M438.93649,76.9785c.9076-.3495,1.6041-1.1989,1.7686-2.1575,.0213-.1245,.0313-.2633-.0416-.3664-.1217-.1723-.3817-.1402-.5863-.0894-1.3484,.3349-2.6967,.6698-4.0449,1.0047-.564,.1401-1.2114,.3519-1.4074,.899-.163,.4545,.06311,.95,.3074,1.3665,.5262,.8969,1.1651,1.7276,1.8972,2.4663l4.0641,.5243c.1581-1.4578-.6549-2.9732-1.9571-3.6475Z"
                      style="fill: rgb(233, 179, 118); transform-origin: 437.741px 77.4754px;" id="elqal4wmgih"
                      class="animable"></path>
                  </g>
                  <path
                    d="M315.6618,180.1639c-2.1928-1.0983-4.1647-2.5908-5.8172-4.4028-1.4731-1.7449-1.9445-3.3507-1.3281-4.5424h0c1.1757-2.3315,6.1376-1.9785,11.4237,.7926,5.2862,2.7711,8.3652,6.6137,7.135,8.9426-.6138,1.1813-2.2118,1.7097-4.4825,1.4863-.6837-.0702-1.3613-.1909-2.0271-.3612-1.7052-.4404-3.3515-1.0833-4.9038-1.9151Zm-.9537-8.8375c-2.6268-.6538-4.5805-.4234-5.0519,.4957h0c-.3548,.6725,.085,1.8294,1.1684,3.1024,1.5391,1.6936,3.3795,3.0864,5.4277,4.1073,2.0081,1.101,4.2018,1.8227,6.4714,2.129,1.6661,.1721,2.8672-.1353,3.2194-.7974,.7641-1.3865-1.7953-4.7028-6.6038-7.2227-1.4679-.7827-3.0222-1.3916-4.6312-1.8142v-.0001Z"
                    style="fill: rgb(38, 50, 56); transform-origin: 317.799px 176.084px;" id="elhe6x77j3d7v"
                    class="animable"></path>
                  <path
                    d="M295.4348,165.404c.5334-1.008,1.783-1.3927,2.791-.8593l44.9817,23.5779c1.0102,.5339,1.3988,1.7838,.8696,2.7964l-16.1657,30.8394-.00539,.0104c-.53351,1.008-1.78311,1.3927-2.79111,.8593l-44.9817-23.5779-.0294-.0155c-1-.5359-1.3761-1.781-.8402-2.781l16.1658-30.8394,.0054-.0103Z"
                    style="fill: rgb(38, 50, 56); transform-origin: 311.667px 193.586px;" id="eltsonse75nej"
                    class="animable"></path>
                  <g id="elopdsfxqzdsi">
                    <path
                      d="M295.4348,165.404c.5334-1.008,1.783-1.3927,2.791-.8593l44.9817,23.5779c1.0102,.5339,1.3988,1.7838,.8696,2.7964l-16.1657,30.8394-.00539,.0104c-.53351,1.008-1.78311,1.3927-2.79111,.8593l-44.9817-23.5779-.0294-.0155c-1-.5359-1.3761-1.781-.8402-2.781l16.1658-30.8394,.0054-.0103Z"
                      style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.1; transform-origin: 311.667px 193.586px;"
                      class="animable"></path>
                  </g>
                  <path
                    d="M339.67359,188.5769c.536-1,1.7811-1.3761,2.781-.8402l.7529,.3858c1.0102,.534,1.3989,1.7839,.8697,2.7965l-16.1658,30.8394-.00539,.0104c-.53351,1.008-1.78311,1.3927-2.79111,.8592l-.75289-.3858c-.98221-.5568-1.36291-1.7809-.86961-2.7965l16.1658-30.8394,.0154-.0294Z"
                    style="fill: rgb(38, 50, 56); transform-origin: 333.795px 205.18px;" id="el9xgr5vm2nmr"
                    class="animable"></path>
                  <path
                    d="M342.83629,187.931l-45-23.5934c-1.0044-.5269-2.2459-.14-2.7731,.8644l-7.5156,14.337-.0053,.0099c-.4206,.8003-.1128,1.79,.6874,2.2106l41.3235,21.664c.8052,.422,1.8,.1121,2.2231-.6925l7.495-14.2981c.5009-.9765,1.681-1.3869,2.6796-.9318l.8854,.4299Z"
                    style="fill: rgb(38, 50, 56); transform-origin: 315.095px 183.857px;" id="elyvprxg7ufa"
                    class="animable"></path>
                  <g id="elz5ktzkla0df">
                    <path
                      d="M342.83859,187.9217l-44.9984-23.5994c-1.0045-.5271-2.246-.1398-2.7733,.8651l-7.5177,14.345-.0053,.0099c-.4207,.8007-.113,1.7909,.6872,2.2116l41.3221,21.6696c.8052,.4221,1.8001,.1119,2.2233-.6932l7.497-14.306c.501-.977,1.6811-1.3877,2.6798-.9325l.8853,.4299Z"
                      style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 315.097px 183.849px;"
                      class="animable"></path>
                  </g>
                  <path
                    d="M311.4387,191.4179l-4.342,8.2819h0c-.1564,.2983-.525,.4134-.8233,.257l-3.345-1.7537h0c-.2983-.1564-.4134-.525-.257-.8233l4.342-8.2819h0l4.4253,2.32h0Z"
                    style="fill: rgb(38, 50, 56); transform-origin: 307.02px 194.562px;" id="el69rg1e8uxju"
                    class="animable"></path>
                  <path
                    d="M307.5867,198.0773l-3.8945-2.0719c-.2979-.1573-.5205-.4271-.6184-.7493-.0965-.3191-.0662-.663,.0846-.9603l.9686-1.8537c.1573-.2979,.427-.5205,.7493-.6184,.3216-.1071,.6732-.0755,.9706,.0872l3.8945,2.0719c.2986,.1603,.5238,.4297,.6288,.7519,.0981,.3227,.0629,.6713-.0976,.968l-.966,1.8434c-.1528,.3012-.4199,.5285-.7415,.6314-.2237,.0663-.4611,.0719-.6877,.0163-.1026-.0227-.2008-.0621-.2907-.1165Zm-2.1467-5.6392c-.1095-.0216-.2229-.0116-.327,.0289-.1586,.05-.2921,.1587-.3733,.3039l-.9686,1.8538c-.073,.1475-.0877,.3171-.041,.475,.047,.1602,.1566,.2948,.304,.3733l3.8945,2.0719c.1483,.0827,.3247,.0985,.4854,.0436,.1557-.052,.2856-.1617,.3629-.3065l.9686-1.8538c.0828-.1446,.0978-.3183,.041-.4749-.0469-.1603-.1565-.2949-.3039-.3734l-3.8946-2.0719-.1557-.0388,.0077-.0311Z"
                    style="fill: rgb(255, 255, 255); transform-origin: 306.722px 194.996px;" id="elu08c06jl78"
                    class="animable"></path>
                  <path
                    d="M322.93639,159.1896c-.8417,.2216-5.8709,5.5248-6.9072,7.0579-1.0362,1.533-1.2131,2.5697-.5343,3.1649,.6789,.5953,1.4676,1.1975,1.6873,1.2117,.2198,.0141-.875,1.5986,.3356,2.5037,1.2105,.9051,1.8803,.7827,1.8803,.7827,0,0-.4849,2.3959,1.1208,3.161,1.6056,.765,2.3691,.0419,2.6368,.1694,.2675,.1275,1.9308-2.5788,1.9308-2.5788l1.7323,3.0853s.9351-.8179,1.9199-2.4094,1.2298-6.2643,1.2298-6.2643l-.6107-4.2315,1.86821-5.8959s-7.18791-1.4511-7.25351-1.2898c-.0654,.1613-1.0361,1.5331-1.0361,1.5331Z"
                    style="fill: rgb(233, 179, 118); transform-origin: 323.159px 167.696px;" id="eld0lzpljo7u6"
                    class="animable"></path>
                  <g id="elkutzjbzqoi" class="animable" style="transform-origin: 386.159px 134.181px;">
                    <path
                      d="M363.29119,164.4931s-2.0604-.2699-1.199-2.0844c.8614-1.8146,3.4596-3.7805,3.4596-3.7805l-.5326-15.1961,.93781-40.8547,7.65689-1.3804,8.4106-1.3812,3.7424-2.9635,9.6104,.1652,4.5284,2.9069,4.90931,.998c-.04781,.0662,8.29919,22.057,4.73659,39.4978-3.5627,17.4407-6.0109,28.4528-6.4957,29.5503-.9805,2.2202-2.74589,1.3855-2.74589,1.3855l-37.01881-6.8629Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 386.159px 134.181px;" id="el6j0p44o2t77"
                      class="animable"></path>
                    <g id="elyqi3r7pbu4f">
                      <path
                        d="M363.29119,164.4931s-2.0604-.2699-1.199-2.0844c.8614-1.8146,3.205-3.3879,3.205-3.3879l-.278-15.5887,.46881-20.42,.469-20.4347,7.65689-1.3804,8.4106-1.3812,3.7424-2.9635,9.5369,.0664,4.6019,3.0057,4.90931,.998c-.04781,.0662,8.29919,22.057,4.73659,39.4978-3.5627,17.4407-6.0109,28.4528-6.4957,29.5503-.9805,2.2202-2.74589,1.3855-2.74589,1.3855l-37.01881-6.8629Z"
                        style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 386.159px 134.181px;"
                        class="animable"></path>
                    </g>
                    <g id="el7nq30rhd4ah">
                      <path
                        d="M391.22559,169.8291c-.01367,0-.02734-.00098-.04199-.00342-.13574-.02295-.22754-.15186-.2041-.28809,2.66406-15.79736,2.5166-34.92285,2.38672-51.79688-.03027-4.05127-.06055-7.87744-.05371-11.6123,0-.1377,.1123-.24951,.25-.24951h0c.13867,0,.25,.1123,.25,.25049-.00684,3.73242,.02344,7.55762,.05371,11.60742,.12988,16.89453,.27734,36.04346-2.39453,51.88379-.02051,.12207-.12598,.2085-.24609,.2085Z"
                        style="fill: rgb(38, 50, 56); opacity: 0.1; transform-origin: 392.45px 137.854px;"
                        class="animable">
                      </path>
                    </g>
                    <g id="ellzj82wdvlsl">
                      <path
                        d="M382.43879,100.3137c.3375,.7443,5.9685,8.9425,5.9685,8.9425l5.1548-3.1275c.0383-.0431-4.5922,4.4376-4.62,4.4813l-1.285,.0361-5.2183-10.3324Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 388.001px 105.48px;"
                        class="animable">
                      </path>
                    </g>
                    <g id="elx4p2ulg4cv">
                      <path
                        d="M394.035,106.0616l3.584,1.8462s1.84989-3.6256,2-4.6372c.15-1.0115,.193-2.7329,.193-2.7329l.49109,.0316s.2388,2.8487-.6262,4.8893c-.9161,2.1609-1.97789,4.2357-1.97789,4.2357l-3.664-3.6327Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 397.184px 105.116px;"
                        class="animable">
                      </path>
                    </g>
                  </g>
                  <g id="elq2irfa3l1cg" class="animable" style="transform-origin: 395.126px 79.6213px;">
                    <path
                      d="M383.737,80.1639s-8.01261-7.0944-7.60661-9.3933c.4061-2.2989,1.7017-1.5569,1.7017-1.5569,0,0-2.699-5.6371,.1095-6.5287,2.8085-.8917,2.9805,.0493,2.9805,.0493,0,0-.9979-4.5719,1.2436-5.8875,1.7056-1.0142,2.3709,1.0953,2.3709,1.0953,0,0,3.325-4.9054,7.165-4.7431,1.8961,.1893,2.8022,1.4346,2.4996,2.8914-.0131,.0632-.0285,.1257-.0461,.1877,0,0,7.8322-3.1982,11.3847-.9937,3.5525,2.2044-1.1992,4.4207-1.1992,4.4207,0,0,7.5725-.6127,8.9823,2.4608,1.1195,2.4408-2.2796,3.989-2.2796,3.989,0,0,1.1828,.4327,2.1804,1.3773,1.9005,1.7993,.6369,5.0984-1.98,5.1317l-.06069,.0006c-.40161,.0022-1.12481,.0726-1.40991-.2104,0,0,1.2341,2.766-1.5822,3.5796-2.8164,.8138-4.0698-2.4573-4.0698-2.4573l-20.38409,6.5875Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 395.126px 66.6795px;" id="el5l90ny2ko82"
                      class="animable"></path>
                    <g id="el1h20vbmjee8" class="animable" style="transform-origin: 395.429px 83.7551px;">
                      <g id="elwujg3t6vrk" class="animable" style="transform-origin: 390.343px 93.264px;">
                        <path
                          d="M385.89649,98.0024s.5471-3.9291-2.0334-13.5698c.0006-.0102,.5097-.2237,.5104-.234-.3551-1.3481-.7373-2.5951-1.0993-3.6766-.00819-.0314-.0075-.0417-.0075-.0417l12.9412,8.3724c-.3712,3.0582,.6065,9.0027,1.2108,11.517l-3.5353,5.6779-7.9869-8.0452Z"
                          style="fill: rgb(233, 179, 118); transform-origin: 390.343px 93.264px;" id="el2tgdhr2rxsa"
                          class="animable"></path>
                        <g id="elj2ujhbnsjvb">
                          <path
                            d="M396.34379,93.1661l-7.5093-1.8566c.6361,2.3604,2.655,5.1843,7.90051,5.5197,0,0-.46921-2.6366-.39121-3.6631Z"
                            style="opacity: 0.2; transform-origin: 392.785px 94.0694px;" class="animable"></path>
                        </g>
                      </g>
                      <path
                        d="M405.73639,66.3903c1.4802,3.0645,1.8897,9.7455-2.9117,8.2555-4.8012-1.4898,.36731-13.5232,2.9117-8.2555Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 403.795px 69.9622px;" id="el1lpm2svy7uq"
                        class="animable"></path>
                      <path
                        d="M383.29679,74.11c.7123,8.329,.946,13.0408,5.2687,17.1822,6.5021,6.2295,16.4848,2.2621,18.2799-6.1364,1.6158-7.5594,.17961-19.91-8.0792-22.9552-8.1368-3.0003-16.1817,3.5805-15.4694,11.9094Z"
                        style="fill: rgb(233, 179, 118); transform-origin: 395.341px 77.8997px;" id="elb7eofb1usm"
                        class="animable"></path>
                      <path
                        d="M395.29369,76.2322c.0204,.8536,.4897,1.5302,1.0485,1.5114,.5586-.0188,.995-.7262,.9745-1.5798-.0204-.8536-.4898-1.5302-1.0483-1.5114-.5587,.0188-.995,.7261-.9747,1.5798Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 396.305px 76.198px;" id="elef85e7n31nt"
                        class="animable"></path>
                      <path
                        d="M403.66909,76.1982c.0203,.8535,.4897,1.5302,1.0484,1.5114s.9949-.7261,.9746-1.5798c-.0204-.8536-.4897-1.5303-1.0483-1.5113-.55879,.019-.9952,.726-.9747,1.5797Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 404.681px 76.164px;" id="elbel1nnjaq1i"
                        class="animable"></path>
                      <path
                        d="M400.93409,75.7901s2.8037,4.1766,3.7112,5.8829c-1.1624,1.1015-3.2208,.6558-3.2208,.6558l-.4904-6.5387Z"
                        style="fill: rgb(213, 135, 69); transform-origin: 402.79px 79.104px;" id="elr5e82u7fwi"
                        class="animable"></path>
                      <path
                        d="M393.33509,71.6075c.13391,.0383,.2847,.0055,.3925-.0997,1.2829-1.2479,2.7249-.9518,2.73941-.9488,.21549,.0468,.43039-.0895,.47939-.3042,.0488-.2148-.0873-.4272-.3016-.4744-.076-.0169-1.883-.3886-3.4744,1.1602-.1589,.1545-.1629,.4069-.0091,.5636,.0498,.0509,.10971,.085,.1738,.1033Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 395.002px 70.6762px;" id="eljpmvx01kft"
                        class="animable"></path>
                      <path
                        d="M407.31549,72.4294c.0728-.0213,.14051-.0635,.1936-.1262,.14291-.1692,.124-.4223-.04309-.5649-1.67541-1.4324-3.43621-.9075-3.51811-.8942-.2108,.0642-.3322,.2876-.2705,.4994,.0617,.2112,.281,.3308,.4911,.2694,.07251-.0249,1.4341-.4115,2.7788,.738,.1052,.09,.2442,.1147,.3682,.0785Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 405.633px 71.5924px;" id="ellu36m5hwyy"
                        class="animable"></path>
                    </g>
                    <g id="elenph9jbnosl" class="animable" style="transform-origin: 397.979px 84.9109px;">
                      <g id="el1crsor4c3gg" class="animable" style="transform-origin: 397.979px 84.9109px;">
                        <path d="M398.87119,87.8427c-.0766-.0203,.0265,.0108,0,0h0Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 398.859px 87.8403px;" id="elh0t2dkstv2k"
                          class="animable"></path>
                        <path
                          d="M400.24419,84.6477c-1.3298-.4543-3.7799-1.4071-4.9485-2.6914,0,0-.2234,.3619-.361,.9134-.1236,.4752-.199,1.0816-.0543,1.7152,.2693,1.1575,1.24861,2.4247,3.93961,3.2395,.03739,.0125,.04389,.0153,.05119,.0183l.0273,.007s1.4512,.4071,2.2588-2.9099c0,0-.3582-.0961-.9131-.2921Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 397.979px 84.9109px;" id="elbysvqu0e6e6"
                          class="animable"></path>
                      </g>
                      <path
                        d="M400.24419,84.6477l-.5367,.8889c-2.5327-.7737-4.2119-1.6583-4.7727-2.667,.1375-.5514,.361-.9132,.361-.9132,1.1685,1.2842,3.6186,2.2371,4.9484,2.6913Z"
                        style="fill: rgb(255, 255, 255); transform-origin: 397.589px 83.7465px;" id="elwmx9df3a5o"
                        class="animable"></path>
                      <path
                        d="M398.81979,87.8244c-2.69079-.8148-3.6701-2.082-3.9393-3.2394,.9062,.3131,1.9641,.7913,2.80851,1.497,.64789,.537,.96849,1.1604,1.13079,1.7424Z"
                        style="fill: rgb(220, 87, 83); transform-origin: 396.85px 86.2047px;" id="el45jcib11yy3"
                        class="animable"></path>
                    </g>
                    <path
                      d="M405.34379,65.2769c-1.2496-1.1385-2.7956-1.8966-4.3566-2.5454-2.5906-1.0768-5.3089-1.8992-8.1027-2.1529-2.794-.2538-5.6737,.0798-8.2332,1.2285-.5598,.2512-1.1167,.5513-1.5148,1.0182-.378,.4431-.5852,1.0036-.7681,1.5567-1.1122,3.364-1.5895,6.9367-1.3992,10.4748l4.6734,5.7459c.7286-.375,1.4967-.7867,1.9043-1.4977,.3792-.6616,.3784-1.4686,.328-2.2296-.0522-.7895-.1468-1.5762-.2831-2.3556,.73221,.3894,1.6418-.1826,2.0641-.8963,.6314-1.0671,.6236-2.3825,.5639-3.6209,.94021,.2234,2.0151-.0324,2.6534-.758,.6383-.7257,.7249-1.9236,.0943-2.6559,3.0571,.4834,6.2089,.36,9.2188-.3609-.2154,.3771,.0138,.8485,.2703,1.199,1.0397,1.4205,2.6188,2.4358,4.3427,2.7921,.2531,.0522,.5685,.0674,.7146-.1457,.0778-.1135,.0813-.2608,.0762-.3983-.0619-1.6894-.9965-3.2595-2.2463-4.398Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 394.26px 70.5506px;" id="elnq9tdy1xmyj"
                      class="animable"></path>
                    <g id="eldkvqz9g50ei" class="animable" style="transform-origin: 384.069px 81.0661px;">
                      <path
                        d="M380.16919,81.1423c.7183,2.1316,2.3581,3.7232,4.0326,4.5053,2.5187,1.1768,4.2569-.9753,3.958-3.5977-.2691-2.3602-1.9037-5.8167-4.6246-5.8937-2.6808-.0758-4.1871,2.5489-3.366,4.9861Z"
                        style="fill: rgb(233, 179, 118); transform-origin: 384.069px 81.0661px;" id="el0kg0ueoyuwzs"
                        class="animable"></path>
                      <g id="elxgu6amv574">
                        <path
                          d="M385.8877,81.44727c-.12305,0-.23047-.09082-.24805-.21582-.09277-.6748-.3623-1.31592-.77832-1.854-.26758-.34717-.71191-.78857-1.30371-.91895-.52441-.1167-1.08008,.08154-1.32715,.4668-.07422,.1167-.22754,.15137-.3457,.07568-.11621-.07422-.15039-.229-.07617-.34521,.3623-.56494,1.12793-.84619,1.85645-.68555,.73926,.16309,1.27344,.68896,1.59277,1.10156,.46875,.60693,.77246,1.33008,.87793,2.09131,.01855,.13672-.07715,.26318-.21387,.28174-.01172,.00146-.02344,.00244-.03418,.00244Z"
                          style="opacity: 0.2; transform-origin: 383.954px 79.6864px;" class="animable"></path>
                      </g>
                    </g>
                  </g>
                  <g id="elndxmtvdo5uk" class="animable" style="transform-origin: 429.927px 105.947px;">
                    <path
                      d="M447.292,85.7287h-9.22061l-.655,25.104s-16.6117-5.6679-20.5208-7.3292c-3.9087-1.6612-7.6064-2.415-7.6064-2.415l-1.7248,.959,3.5472,15.6182s27.938,13.4889,37.2913,6.4796c9.3534-7.0093-1.11089-38.4166-1.11089-38.4166Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 429.927px 105.947px;" id="eljq25gy37srp"
                      class="animable"></path>
                    <g id="elp3ht6l80b4">
                      <path
                        d="M414.196,119.0569c-.11621-.3936,1.30279-8.6596,1.30279-8.6596l2.1899,10.1081-3.49269-1.4485Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 415.939px 115.451px;"
                        class="animable">
                      </path>
                    </g>
                  </g>
                  <g id="elsgmcwpj10uc">
                    <path
                      d="M364.54029,130.3155l-.1204,5.5925s21.5208-12.2438,25.0869-16.136c4.6065-5.0274,5.1124-7.9255,5.1124-7.9255l-30.0789,18.469Z"
                      style="isolation: isolate; opacity: 0.2; transform-origin: 379.52px 123.877px;" class="animable">
                    </path>
                  </g>
                  <g id="elmzt606rcccp" class="animable" style="transform-origin: 354.595px 149.817px;">
                    <g id="elmssucihlfr">
                      <path
                        d="M359.53429,194.0431l18.40271,7.2699s8.19279-41.752,8.22209-43.669c.0294-1.917,.0712-7.4631,.0712-7.4631l-26.322,40.3244-.374,3.5378Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 372.882px 175.747px;"
                        class="animable">
                      </path>
                    </g>
                    <path
                      d="M365.14339,102.2963c-.7751-.0097-24.6313,15.726-28.5288,19.8771-3.89759,4.1512-15.4763,37.2118-15.4763,37.2118l9.517,3.0367,13.77371-30.3047,22.661-9.7978s5.91919-9.7179,6.099-10.1602c.17969-.4423-8.04561-9.8629-8.04561-9.8629Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 347.165px 132.359px;" id="el845yidis43x"
                      class="animable"></path>
                    <path
                      d="M365.14339,102.2963l14.9608-3.112,3.80881-.8639s4.67919,42.7986,4.08759,45.5145c-.5917,2.7159-11.8015,54.3968-11.8015,54.3968l-25.722-10.7391,13.5113-31.5366,1.155-53.6597Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 369.265px 148.276px;" id="el1v8fzvh09ky"
                      class="animable"></path>
                    <g id="el4owobazvm7b">
                      <path
                        d="M383.32169,98.453s-6.3014,7.8692-4.6546,10.5902c1.64691,2.7211,4.3192,8.6151,4.3192,8.6151,0,0-2.3677,3.9005-2.30329,6.8475,.06439,2.947,6.86849,10.3389,6.86849,10.3389-.6051-10.3495-2.6506-23.2367-3.645-36.5243l-.5848,.1326Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 382.972px 116.583px;"
                        class="animable">
                      </path>
                    </g>
                    <g id="el6vu73fxh41d">
                      <polygon points="364.69409 123.3543 364.69409 115.6743 357.55989 126.4389 364.69409 123.3543"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 361.127px 121.057px;"
                        class="animable">
                      </polygon>
                    </g>
                    <g id="el56nnom9ihyt">
                      <polygon
                        points="365.96769 164.1457 377.47269 168.9801 374.79909 174.0464 362.73409 168.4714 365.96769 164.1457"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 370.103px 169.096px;"
                        class="animable">
                      </polygon>
                    </g>
                  </g>
                  <g id="elvajsv9mofye" class="animable" style="transform-origin: 407.154px 147.113px;">
                    <path
                      d="M400.73589,99.6756s-3.6365,38.0823-4.05289,41.2188c-.41631,3.1365,1.33139,53.6564,1.33139,53.6564l19.6743-5.653-9.2085-32.3757s6.3844-38.4981,6.80081-47.0766l.59989-3.9577s-2.408-3.3149-5.7055-4.1835c-3.3866-.8922-9.0119-1.5418-9.4395-1.6287Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 407.154px 147.113px;" id="elxvm7ygxziu"
                      class="animable"></path>
                    <g id="eld5kmq4lxrs">
                      <path
                        d="M401.33359,99.7624s6.38,7.915,4.7331,10.6361c-1.6468,2.721-4.3192,8.615-4.3192,8.615,0,0,2.3678,3.9005,2.3034,6.8475s-6.7303,8.9837-6.7303,8.9837c.72141-10.1032,2.4208-21.8814,3.4153-35.1691l.5977,.0868Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 401.829px 117.26px;"
                        class="animable">
                      </path>
                    </g>
                    <g id="elu2aib75zri9">
                      <polygon
                        points="411.02209 165.4589 402.41039 168.6331 405.084 173.6994 412.52559 170.7451 411.02209 165.4589"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 407.468px 169.579px;"
                        class="animable">
                      </polygon>
                    </g>
                  </g>
                  <g id="el2f2dl4gm27k" class="animable" style="transform-origin: 399.437px 326.979px;">
                    <polygon
                      points="129.63428 454.50195 116.58447 439.3457 179.30371 385.34082 260.25977 386.05273 323.64746 338.27344 397.72656 338.87402 440.19629 315.45898 504.37207 314.08008 545.26758 274.31445 658.3584 211.34668 668.08691 228.82031 557.31055 290.5 512.6709 333.90625 445.54395 335.34961 402.79883 358.91504 330.26855 358.32812 266.875 406.11133 186.65186 405.40527 129.63428 454.50195"
                      style="fill: rgb(64, 123, 255); transform-origin: 392.336px 332.924px;" id="eldnhyd80lvqi"
                      class="animable"></polygon>
                    <path
                      d="M644.15429,199.4557l26.9851,46.4472c.5684-.791,11.1509-39.4927,11.1509-39.4927l-38.136-6.9545Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 663.222px 222.679px;" id="el88bf6z27ogm"
                      class="animable"></path>
                    <g id="ely5y16f83ad9">
                      <polygon
                        points="648.39789 216.8908 658.39759 234.2147 662.883 231.6918 652.79039 214.3837 648.39789 216.8908"
                        style="opacity: 0.3; transform-origin: 655.64px 224.299px;" class="animable"></polygon>
                    </g>
                  </g>
                  <path
                    d="M390.81779,108.8237l1.7711,3.2121s-29.6403,19.0838-56.157,19.1486l-2.18789,8.8972,8.98419,2.2477s41.1199-12.8795,51.6963-29.9488l3.0899-4.0419-2.8373-2.8129-.97109,.5462-3.38821,2.7518Z"
                    style="fill: rgb(64, 123, 255); transform-origin: 366.129px 123.928px;" id="el7cftc2ui095"
                    class="animable"></path>
                </g>
              </g>
              <g id="freepik--Character_4--inject-18" class="animable" style="transform-origin: 582.978px 345.97px;">
                <g id="elp9k00jbxjri" class="animable" style="transform-origin: 582.978px 345.97px;">
                  <g id="elt4w2g7g67m9" class="animable" style="transform-origin: 597.654px 345.97px;">
                    <g id="el8jgv32qub7s">
                      <path
                        d="M606.56934,254.81445h-.00879c-.1377-.00488-.24609-.12109-.24121-.25879,.03223-.91211,.50977-1.81348,1.24609-2.35156,.7373-.53711,1.73926-.72168,2.61914-.46973,.13281,.03711,.20996,.17578,.17188,.30859-.03809,.13184-.1709,.21191-.30859,.17188-.73145-.20508-1.56934-.05664-2.1875,.39355-.61523,.44922-1.01465,1.20215-1.04102,1.96484-.00488,.13477-.11621,.24121-.25,.24121Z"
                        style="opacity: 0.1; transform-origin: 608.343px 253.219px;" class="animable"></path>
                    </g>
                    <g id="elwggfkz222ic" class="animable" style="transform-origin: 606.089px 413.308px;">
                      <g id="elq1w6mgfbz" class="animable" style="transform-origin: 645.859px 419.233px;">
                        <polygon
                          points="647.323 456.5252 650.77469 456.5252 644.39419 381.9414 640.94259 381.9414 647.323 456.5252"
                          style="fill: rgb(64, 123, 255); transform-origin: 645.859px 419.233px;" id="elcl92jalodbb"
                          class="animable"></polygon>
                        <g id="elqou4jzhn6l">
                          <polygon
                            points="647.323 456.5252 650.77469 456.5252 644.39419 381.9414 640.94259 381.9414 647.323 456.5252"
                            style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.6; transform-origin: 645.859px 419.233px;"
                            class="animable"></polygon>
                        </g>
                      </g>
                      <g id="el5el4duh445c" class="animable" style="transform-origin: 613.478px 419.233px;">
                        <polygon
                          points="612.01389 456.5252 608.56219 456.5252 614.94269 381.9414 618.39429 381.9414 612.01389 456.5252"
                          style="fill: rgb(64, 123, 255); transform-origin: 613.478px 419.233px;" id="elps57xim1iiq"
                          class="animable"></polygon>
                        <g id="elya4dmludenr">
                          <polygon
                            points="612.01389 456.5252 608.56219 456.5252 614.94269 381.9414 618.39429 381.9414 612.01389 456.5252"
                            style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.6; transform-origin: 613.478px 419.233px;"
                            class="animable"></polygon>
                        </g>
                      </g>
                      <path
                        d="M633.21539,381.9505h-70.6057c2.97-7.3539,8.3714-11.8599,14.20831-11.8599h51.64539l4.752,11.8599Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 597.913px 376.021px;" id="eln20ctvfmgw"
                        class="animable"></path>
                      <g id="el4e88y7b33p9" class="animable" style="transform-origin: 598.699px 419.233px;">
                        <polygon
                          points="600.16329 456.5252 603.615 456.5252 597.23439 381.9414 593.78279 381.9414 600.16329 456.5252"
                          style="fill: rgb(64, 123, 255); transform-origin: 598.699px 419.233px;" id="elmk6heqekhlc"
                          class="animable"></polygon>
                        <g id="elieb9rqaeimi">
                          <polygon
                            points="600.16329 456.5252 603.615 456.5252 597.23439 381.9414 593.78279 381.9414 600.16329 456.5252"
                            style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.6; transform-origin: 598.699px 419.233px;"
                            class="animable"></polygon>
                        </g>
                      </g>
                      <g id="elkipwy4hucq" class="animable" style="transform-origin: 566.319px 419.233px;">
                        <polygon
                          points="564.85409 456.5252 561.40249 456.5252 567.78309 381.9414 571.23459 381.9414 564.85409 456.5252"
                          style="fill: rgb(64, 123, 255); transform-origin: 566.319px 419.233px;" id="elaiil5p8lqt9"
                          class="animable"></polygon>
                        <g id="elorl6xzr80uk">
                          <polygon
                            points="564.85409 456.5252 561.40249 456.5252 567.78309 381.9414 571.23459 381.9414 564.85409 456.5252"
                            style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.6; transform-origin: 566.319px 419.233px;"
                            class="animable"></polygon>
                        </g>
                      </g>
                      <path
                        d="M648.98129,381.9505h-38.8059c3.3795-7.3539,9.5167-11.8599,16.15861-11.8599h13.73439c6.6418,0,9.9132,3.8301,8.9129,11.8599Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 629.667px 376.021px;" id="elsdvjy1p0li9"
                        class="animable"></path>
                      <g id="ely476ei0o7pi">
                        <path
                          d="M648.98129,381.9505h-38.8059c3.3795-7.3539,9.5167-11.8599,16.15861-11.8599h13.73439c6.6418,0,9.9132,3.8301,8.9129,11.8599Z"
                          style="isolation: isolate; opacity: 0.5; transform-origin: 629.667px 376.021px;"
                          class="animable">
                        </path>
                      </g>
                    </g>
                    <g id="elozh63kyjio" class="animable" style="transform-origin: 605.661px 306.298px;">
                      <path
                        d="M630.00369,333.4584c-1.8169-2.3981-6.02469-8.1315-6.5578-14.1658l-.0027-.0516c-.0329-.3873-.0524-.7754-.0531-1.1643-.0959-2.5656-.0797-5.604,.0891-9.2499,.0229-.5694,.057-1.173,.0909-1.7764,.10291-1.9132,.2508-3.9744,.4331-6.2176,.3417-4.0198,.8314-8.6087,1.49181-13.8241,.14769-1.2071,1.28509-3.0792,1.45569-4.3545,.76331-5.6821-4.4165-9.7886-10.0078-9.5607-1.4914,.0569-3.0747,.1593-4.67989,.3186-6.27451,.5923-11.42151,1.1274-16.87621,2.4256-1.8105,.4213-3.678,.9338-5.4657,1.4463-6.4226,1.8446-9.3832,10.0663-9.4632,16.6823-.0794,6.1663,4.2448,18.7512,7.2074,29.8051,.4359,6.3406-3.0274,9.7945-1.9845,12.0049,1.0424,2.2103,31.693-3.7955,44.3229,3.7353,0,0,1.9384-3.4942,0-6.0532Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 605.661px 306.298px;" id="elti66iro7hud"
                        class="animable"></path>
                      <g id="elreht44bgc5o">
                        <path
                          d="M630.00369,333.4584c-1.8169-2.3981-6.02469-8.1315-6.5578-14.1658l-.0027-.0516c-.0329-.3873-.0524-.7754-.0531-1.1643-.0959-2.5656-.0797-5.604,.0891-9.2499,.0229-.5694,.057-1.173,.0909-1.7764,.10291-1.9132,.2508-3.9744,.4331-6.2176,.3417-4.0198,.8314-8.6087,1.49181-13.8241,.14769-1.2071,1.28509-3.0792,1.45569-4.3545,.76331-5.6821-4.4165-9.7886-10.0078-9.5607-1.4914,.0569-3.0747,.1593-4.67989,.3186-6.27451,.5923-11.42151,1.1274-16.87621,2.4256-1.8105,.4213-3.678,.9338-5.4657,1.4463-6.4226,1.8446-9.3832,10.0663-9.4632,16.6823-.0794,6.1663,4.2448,18.7512,7.2074,29.8051,.4359,6.3406-3.0274,9.7945-1.9845,12.0049,1.0424,2.2103,31.693-3.7955,44.3229,3.7353,0,0,1.9384-3.4942,0-6.0532Z"
                          style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 605.661px 306.298px;"
                          class="animable"></path>
                      </g>
                    </g>
                    <g id="elb48zxfk66yk">
                      <path
                        d="M625.12149,325.4464s-10.8166,2.3757-15.8499,2.6045c-5.0334,.2288-15.9243,3.6606-15.9243,3.6606l-3.8735,2.4023-1.3652-3.1961,6.5205-5.8973,28.6238-5.8019c.35,2.3932,1.6644,5.7468,1.8686,6.2279Z"
                        style="opacity: 0.2; transform-origin: 606.615px 326.666px;" class="animable"></path>
                    </g>
                    <g id="el5xv2c9je6v" class="animable" style="transform-origin: 588.545px 396.294px;">
                      <path
                        d="M590.37529,335.5044c9.3919-.9375,21.6101-1.6878,37.5103-2.0459,0,0,11.85541,27.9228-2.2034,36.2642-4.248,2.5204-11.2588,3.5175-18.9753,3.6818-8.95289,.1905-16.3316-6.9847-16.3316-15.9397v-21.9604Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 611.467px 353.433px;" id="elf8k9uvl7357"
                        class="animable"></path>
                      <path
                        d="M621.08339,358.5022v13.1193c-11.69239,3.3723-35.9609,2.0883-50.387,.9108-6.4965-.5304-11.49239-5.9566-11.49239-12.4749v-10.7639c0-3.569,2.20629-6.7596,5.54689-8.0162,6.9934-2.6304,19.72-6.5505,33.0335-6.3446,12.90861,.1994,23.299,10.6595,23.299,23.5695Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 590.144px 354.355px;" id="elfxflnae2adq"
                        class="animable"></path>
                      <g id="el1rkxyb2atrp" class="animable" style="transform-origin: 577.104px 447.094px;">
                        <path
                          d="M566.48179,458.6363l14.3544-6.3325,4.53981-5.0685,1.40539,1.9087,1.7155-.7481c.7997-6.3712-2.2957-9.3504-3.9208-10.7045-.1158-.1032-.2837-.2192-.4769-.3739-.0645-.0517-.12919-.1033-.1937-.142-.026-.0128-.0517-.0387-.0771-.0516-.0646-.0515-.142-.1033-.2195-.1677-.0125-.0127-.0258-.0127-.0516-.0257v-.0128c-.0385-.026-.0772-.0517-.1288-.0773l-7.8803,3.4819s-.1289,.4127-.3741,1.0835c-.7866,2.2181-2.734,7.2739-4.8876,9.9693-.09049,.116-.1808,.2322-.2711,.3355-.258,.3093-.5288,.5803-.7995,.7995-6.0358,4.8493-2.7341,6.1262-2.7341,6.1262Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 577.104px 447.738px;" id="el1rtkz88q7xb"
                          class="animable"></path>
                        <path
                          d="M569.98519,451.6724c2.2443,.8769,5.4169-1.3414,7.8287-4.2431,1.354-1.6381,2.6567-4.2563,3.8044-6.3712,.7481-1.3672,1.4318-2.5279,2.0249-3.0824,.3354-.2966,.64481-.4257,.9157-.2966,0,0-.013-.013-.013-.0258-.1158-.1033-.2836-.2193-.477-.374-.0516-.0388-.1161-.0904-.1936-.142-.0259-.0127-.0516-.0387-.077-.0516-.0646-.0515-.1422-.1033-.2195-.1676-.0126-.0128-.0259-.0128-.05179-.0257v-.013c-.03831-.0259-.07711-.0516-.12871-.0772-.82529-.6194-1.74129-1.2512-1.74129-1.2512l-7.41311,1.8558,.5777,2.8386c-.8252,2.9276-2.5663,8.4992-4.5653,11.0915-.0905,.116-.1808,.2322-.2711,.3355Z"
                          style="fill: rgb(233, 179, 118); transform-origin: 577.272px 443.709px;" id="elh7e5ohn6gc"
                          class="animable"></path>
                      </g>
                      <path
                        d="M557.91779,396.473c4.5765,11.3663,11.6817,25.8486,16.9258,44.0091,.1026,.3307,.194,.6613,.285,.992,.1025,.3533,.20541,.6952,.3078,1.0486l5.4151-1.3223,.2372-.9423s1.8709-1.4531,1.5527-1.7824c-.20739-.2145-.0681-.6154-.1027-.9576v-.0113c-1.459-12.6658-7.0796-59.156-12.6541-68.812,0,0,6.3158,1.1742,11.104,.3647,3.4542-.5813,7.638-24.4649,7.638-24.4649,0,0-8.8009-2.5309-17.83-2.5309h-.1255s-17.7479,5.2503-22.2606,9.2257c-4.241,3.7361-4.8766,9.8592-2.5244,17.1366,2.1568,6.6726,6.3615,13.9641,12.0317,28.047Z"
                        style="fill: rgb(233, 179, 118); transform-origin: 566.58px 392.293px;" id="eldsrlr5qt3aa"
                        class="animable"></path>
                      <g id="elp8xm5clz0yg">
                        <path
                          d="M552.84629,357.0341c1.0505-4.1298,8.6545-8.1694,16.5096-10.3346,1.2758-1.6528-1.9878-3.6059-1.9878-3.6059l15.4625-1.3989,2.6989,1.9423,4.6142,.9609-4.1113,18.4747-16.89139,2.9576,10.68549,49.5877,1.2622,9.7976s-18.09869-38.8496-21.88469-47.1104c-3.78591-8.261-8.17921-14.111-6.35771-21.271Z"
                          style="opacity: 0.1; transform-origin: 571.283px 383.555px;" class="animable"></path>
                      </g>
                      <path
                        d="M588.629,344.5979s-24.12971,5.1122-28.84361,8.8354c-4.0145,3.1708-4.1227,7.3099-2.8217,11.2181,3.5965,10.8044,16.4988,28.8205,31.2687,80.5291l8.4628-2.6578c-.81119-.0008-2.9385-26.9971-3.5667-48.6815-.2963-10.2284-5.5413-19.0483-7.5937-22.6102,0,0,8.6246,1.1643,13.4116,.3617,6.1505-1.0312,10.0392-21.5527-10.31739-26.9948Z"
                        style="fill: rgb(233, 179, 118); transform-origin: 580.051px 394.889px;" id="ele4em3d23kfc"
                        class="animable"></path>
                      <g id="el48yly1kotza">
                        <path
                          d="M586.03239,372.2004s-3.2018-5.3096-7.193-7.549c0,0,4.912,.3206,7.9824,6.734l-1.287-.1544,.4976,.9694Z"
                          style="opacity: 0.2; transform-origin: 582.831px 368.426px;" class="animable"></path>
                      </g>
                      <g id="elgu55tsvpsmm" class="animable" style="transform-origin: 588.222px 449.694px;">
                        <path
                          d="M599.56749,453.6561l-1.7634,.3843-1.4656-2.4383c-.1783-.2967-.5843-.3543-.8382-.1191l-5.0118,4.6428-13.6891,3.0041s-3.0476-3.2223,3.7573-5.2754c.3249-.0979,.6067-.3523,.9135-.6004,3.3675-2.5837,7.4049-9.7007,7.4049-9.7007l8.11091-1.78s.37219,.344,.92119,1.0233c1.2777,1.5904,3.6302,4.9999,1.6603,10.8594Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 588.222px 450.452px;" id="el1x7tgdld7mcj"
                          class="animable"></path>
                        <path
                          d="M597.90719,442.7967l.0054,.0245c-1.3329-.9586-5.0562,5.3542-8.2339,7.9221-2.8393,2.2873-6.267,3.7746-8.2085,2.5108,3.3675-2.5837,7.5842-11.5647,7.5842-11.5647l6.52261-1.4313s1.78119,1.8593,2.33019,2.5386Z"
                          style="fill: rgb(233, 179, 118); transform-origin: 589.691px 446.987px;" id="elgwu5k1y7cbq"
                          class="animable"></path>
                      </g>
                    </g>
                    <g id="elmom61bef0c" class="animable" style="transform-origin: 570.295px 265.405px;">
                      <g id="elnr22r1epm7" class="animable" style="transform-origin: 554.901px 245.976px;">
                        <path
                          d="M553.386,238.4269s-.54731-.3285-1.90811,.066c-1.36089,.3945-.9236,2.1461-1.1211,2.0859-.1974-.0602-1.0882,.2079-1.8803,.5061-.792,.298-.9791,1.266-.576,2.9532,.403,1.6871,3.0468,6.7212,3.712,7.1936,0,0-.18649,4.066-.1823,4.2291,.0043,.1631,8.3253-.188,8.3253-.188l-.6248-5.6016,1.9327-3.5109s1.3277-4.1807,.989-5.9022c-.0592-.3009-.1248-.5805-.1929-.8371-.252-.9502-1.4178-1.3036-2.1702-.6709l-1.0645,.8953s-.298-1.2258-.5579-2.0991c-.1692-.5685-.6478-1.0081-1.2364-1.083-.1212-.0154-.2418-.0201-.3538-.0076-.7422,.0826-.7248,.8728-.7248,.8728,0,0-1.2515-.4907-2.0415,.7326-.2913,.4512-.32439,.3658-.32439,.3658Z"
                          style="fill: rgb(233, 179, 118); transform-origin: 554.901px 245.976px;" id="elxxzw0shb2ug"
                          class="animable"></path>
                        <g id="elukisrjr1hnr">
                          <path
                            d="M556.02734,247.66357c-.09473,0-.18555-.05469-.22754-.14648-.18848-.41553-.17578-.9292,.03613-1.40869,.15723-.35645,.41504-.69336,.81055-1.06006,.3916-.36328,.82715-.67969,1.29297-.94141-.61133-.05078-1.2373-.18994-1.77246-.55615-.33008-.22559-.52637-.48828-.58203-.78174-.10547-.55664,.05469-1.10303,.41797-1.42578,.4668-.41553,.98828-.80518,1.5918-1.25684,.39062-.29248,.8125-.60742,1.26367-.96436,.10742-.08398,.26562-.06738,.35156,.0415,.08594,.1084,.06738,.26562-.04102,.35107-.45605,.35986-.88086,.67725-1.27539,.97266-.59375,.44434-1.10742,.82812-1.55859,1.22949-.27734,.24707-.31543,.65771-.25781,.95947,.03711,.19678,.22266,.35986,.37109,.46143,.67285,.46045,1.52246,.50928,2.52637,.49561,.13672-.04883,.27441-.09375,.41406-.13379,.10059-.0293,.20508,.00635,.26855,.08887,.0625,.08154,.06738,.19336,.01465,.28174-.15625,.25586-.46875,.26123-.60645,.2627-.76758,.28711-1.47852,.7251-2.0791,1.28223-.34473,.31885-.56445,.60352-.69336,.89502-.15137,.34424-.16602,.71826-.03809,1,.05762,.12598,.00195,.27393-.12402,.33105-.0332,.01514-.06836,.02246-.10352,.02246Z"
                            style="fill: rgb(38, 50, 56); opacity: 0.1; transform-origin: 557.631px 243.366px;"
                            class="animable"></path>
                        </g>
                        <g id="eldvty9z09lzr">
                          <path
                            d="M550.64258,246.16846c-.38867,0-.77637-.21777-1.18262-.66357-.35352-.38818-.66602-.81689-.92871-1.2749-.08008-.14062-.29199-.51221-.12988-.8623,.05762-.125,.20703-.18066,.33105-.12207,.12598,.05811,.18066,.20654,.12207,.33203-.03613,.07812,0,.21045,.11035,.4043,.24414,.42578,.53516,.8252,.86426,1.18604,.32715,.35938,.61035,.52686,.86621,.49805,.25879-.02441,.45605-.24121,.55566-.43604,.13184-.26123,.16309-.58008,.17578-.85742,.03613-.73389-.0332-1.46289-.20703-2.1748-.06348-.17529-.11426-.35596-.15039-.53906-.02637-.13037,.05469-.25781,.18262-.29102,.13477-.03369,.25977,.04053,.30078,.1665,.05469,.17139,.10352,.34424,.14648,.51807,.17871,.48145,.47266,.92041,.85449,1.26807,.28223,.2583,.68359,.44678,.93457,.27588,.18555-.12207,.26172-.39355,.24707-.87939-.04199-1.34521-.23047-2.68848-.55957-3.99219-.03418-.13379,.04688-.26953,.18066-.30322,.12988-.03711,.26953,.04688,.30371,.18115,.33789,1.33838,.53125,2.71729,.5752,4.09912,.01367,.45703-.02832,1.01904-.4707,1.31152-.50781,.33789-1.15234,.03662-1.54883-.32373-.10742-.09766-.20801-.20215-.30371-.31201,.02637,.33838,.03125,.67822,.01465,1.01904-.01562,.33008-.05566,.71387-.22949,1.05957-.2041,.3999-.56934,.67139-.95508,.70801-.0332,.00293-.06641,.00439-.09961,.00439Z"
                            style="fill: rgb(38, 50, 56); opacity: 0.1; transform-origin: 551.29px 242.291px;"
                            class="animable"></path>
                        </g>
                        <g id="ellybyok7hrx8">
                          <path
                            d="M555.15918,244.01855c-.15918,0-.3252-.02832-.49219-.08496-.35449-.11963-.67383-.34912-.89941-.64746-.08301-.10986-.06152-.26709,.04785-.3501,.11133-.0835,.26758-.06201,.35059,.04834,.16602,.21875,.40039,.3877,.66113,.47559,.13281,.04395,.33203,.08643,.51855,.03467,.15137-.04248,.27637-.14893,.31836-.27148,.04492-.13135,.19141-.19775,.31738-.15527,.13086,.04492,.2002,.18701,.15527,.31738-.0957,.27783-.34668,.50439-.65625,.59082-.10254,.02832-.20996,.04248-.32129,.04248Z"
                            style="fill: rgb(38, 50, 56); opacity: 0.1; transform-origin: 554.934px 243.452px;"
                            class="animable"></path>
                        </g>
                        <g id="elx5bzcf3a1t">
                          <path
                            d="M556.60938,241.31543c-.12402,0-.23145-.0918-.24805-.21777-.18359-1.41504-.375-2.26123-.81836-3.62012-.04297-.13135,.02832-.27246,.16016-.31543,.13086-.04248,.27246,.0293,.31445,.16016,.45508,1.39307,.65137,2.26025,.83984,3.71094,.01758,.13672-.0791,.26221-.21582,.28027-.01074,.00146-.02148,.00195-.03223,.00195Z"
                            style="fill: rgb(38, 50, 56); opacity: 0.1; transform-origin: 556.195px 239.233px;"
                            class="animable"></path>
                        </g>
                      </g>
                      <g id="elhkzlq86ee3" class="animable" style="transform-origin: 571.46px 273.248px;">
                        <path
                          d="M560.62579,252.1357c-.5352,.2491,5.9539,25.1427,5.9539,25.1427h26.3161s-11.8746,15.7127-12.4379,16.6879c-.5632,.9753-20.3673,.6185-25.1846-6.6966-4.8172-7.3151-5.2496-34.3-5.2496-34.3l10.6021-.834Z"
                          style="fill: rgb(64, 123, 255); transform-origin: 571.46px 273.248px;" id="eltmbfkm35h6p"
                          class="animable"></path>
                        <g id="elvuyztl8bh2r">
                          <path
                            d="M560.62579,252.1357c-.5352,.2491,5.9539,25.1427,5.9539,25.1427h26.3161s-11.8746,15.7127-12.4379,16.6879c-.5632,.9753-20.3673,.6185-25.1846-6.6966-4.8172-7.3151-5.2496-34.3-5.2496-34.3l10.6021-.834Z"
                            style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 571.46px 273.248px;"
                            class="animable"></path>
                        </g>
                      </g>
                      <g id="elbi94lp55e6k">
                        <path
                          d="M580.46049,294.3606s.3077-5.4122,3.1248-10.3067c0,0-4.3627,5.5622-5.0171,10.3067h1.8923Z"
                          style="opacity: 0.2; transform-origin: 581.077px 289.207px;" class="animable"></path>
                      </g>
                    </g>
                    <g id="el206i98aurex" class="animable" style="transform-origin: 595.48px 258.163px;">
                      <path
                        d="M611.86979,238.6348c3.302,5.2142,4.51421,8.3701,5.02921,13.4933,.60189,5.9876,.575,11.2993-.69251,12.8994-1.6633,2.0995-20.5737,4.8753-31.2384,4.937-.3866,.0022-.8627-3.362-.8627-3.362,0,0-1.3566,3.3707-1.8578,3.3711-2.91059,.0027-4.7775-.0183-4.7775-.0183,0,0,2.1782-5.0684,4.3008-23.0854,1.0367-8.7999,9.6587-12.6936,14.8036-13.5902,8.5747-1.4945,15.2953,5.3551,15.2953,5.3551Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 597.375px 251.52px;" id="elpt2x1mxflwe"
                        class="animable"></path>
                      <path
                        d="M587.33669,256.6757c-3.01-4.6894-6.3774-17.5462,5.04631-22.0905,14.26029-5.6726,21.17909,3.2695,22.85009,12.3704,1.6712,9.1005-3.1719,16.262-12.7804,16.012-9.6084-.2498-13.034-3.0482-15.116-6.2919Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 599.993px 247.892px;" id="elahv24f8545h"
                        class="animable"></path>
                      <g id="els1gllq42jk" class="animable" style="transform-origin: 599.324px 259.474px;">
                        <g id="elvsxc23hg7ys" class="animable" style="transform-origin: 599.324px 259.474px;">
                          <path
                            d="M611.18229,273.967s-3.2082,2.4669-7.3388,6.0265c-.8604,.7415-1.8079,1.3829-2.8111,1.9157-2.7068,1.4377-5.1742,2.2602-5.8423,.9552-.1487-.2869-.2629-.5976-.3476-.9235-.5673-2.1853-.3346-5.2574,1.4319-6.6634,1.99161-1.5851,2.9519-2.9415,2.5928-5.0873-.1885-1.2167-.6288-2.4725-1.1433-3.6923l1.567-1.8325,8.197-8.2724c-.2048,5.5238-.6232,14.5755,3.6944,17.574Z"
                            style="fill: rgb(233, 179, 118); transform-origin: 602.875px 269.955px;" id="el1fraoyw38fkh"
                            class="animable"></path>
                          <g id="elvcg04el24qn">
                            <path
                              d="M598.93379,270.6613c2.2545-1.3382,4.7279-3.6594,4.4314-6.3116-.13219-1.1167-.3729-2.1748-.6405-2.8531l-4.579,6.0625c.3759,.9772,.5131,1.6231,.7881,3.1022Z"
                              style="opacity: 0.2; transform-origin: 600.768px 266.079px;" class="animable"></path>
                          </g>
                          <path
                            d="M609.80849,247.5179c-.77149,8.4222-.9203,11.9938-5.26649,16.41-6.53721,6.6427-16.59471,4.4082-18.32621-3.94-1.5585-7.5138-.0349-20.0244,8.2333-23.564,8.14591-3.4872,16.13091,2.6719,15.3594,11.094Z"
                            style="fill: rgb(233, 179, 118); transform-origin: 597.767px 251.622px;" id="elyjqjfk1ssfg"
                            class="animable"></path>
                          <path
                            d="M599.24859,250.5694c-.0472,.8483-.5685,1.5461-1.1645,1.5585-.5958,.0125-1.0406-.6649-.9934-1.5132,.0471-.8482,.56831-1.5461,1.1642-1.5586,.5958-.0126,1.0406,.6649,.9937,1.5133Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 598.17px 250.592px;" id="elm640d518wy"
                            class="animable"></path>
                          <path
                            d="M590.49309,250.4156c-.0469,.8097-.5593,1.4761-1.1445,1.4886-.5851,.0122-1.0217-.6342-.9748-1.4439,.0469-.8096,.5593-1.4762,1.1445-1.4886,.5851-.0124,1.0216,.6341,.9748,1.4439Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 589.433px 250.438px;" id="el7t1v3gfl9uf"
                            class="animable"></path>
                          <path
                            d="M600.43959,247.4597c-.1153,.0229-.2355,.0028-.3315-.0655-1.49509-1.0671-3.0456-.4973-3.0636-.4907-.2297,.085-.4761-.015-.54649-.2304-.06961-.2154,.062-.4608,.29449-.5495,.081-.0303,2.0268-.7473,3.8822,.5778,.1848,.132,.208,.3944,.0519,.5858-.07559,.092-.179,.1512-.287,.1725Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 598.655px 246.686px;" id="elm4q4u8swrra"
                            class="animable"></path>
                          <path
                            d="M587.51359,246.3261c-.0928-.0437-.1671-.12-.20159-.2201-.07161-.2068,.05139-.4357,.274-.5113,2.23369-.7576,3.768,.3265,3.83259,.374,.1817,.1319,.2096,.3812,.0615,.5574-.1472,.1759-.4138,.2118-.5958,.0813-.0595-.0434-1.2625-.8672-3.0387-.2644-.1149,.0389-.234,.0296-.332-.0169Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 589.433px 246.006px;" id="elbzykep3o46p"
                            class="animable"></path>
                          <path
                            d="M602.77159,240.0296c-2.6793,2.8137-1.3474,13.4425,6.1749,14.8309,8.6656-2.0388,1.8222-23.2296-6.1749-14.8309Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 607.238px 246.465px;" id="eluzshllc0j9b"
                            class="animable"></path>
                          <path
                            d="M592.713,258.282c.68569,1.0554,1.51,3.1003,1.51,3.1003,.19879-.0412,.39759-.0824,.59349-.134,2.6813-.6275,3.7181-1.8045,4.0539-2.919,.1815-.6103,.1458-1.2077,.05461-1.68-.09971-.5477-.29551-.9154-.29551-.9154-1.2228,1.1819-3.6774,1.9594-5.0058,2.32-.5545,.1566-.91069,.2281-.91069,.2281Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 595.857px 258.558px;" id="el5qoiu6eacwk"
                            class="animable"></path>
                          <path
                            d="M593.62369,258.0539l.4689,.9024c2.524-.5972,4.2209-1.3563,4.83241-2.307-.09971-.5477-.29551-.9154-.29551-.9154-1.2228,1.1819-3.6774,1.9594-5.0058,2.32Z"
                            style="fill: rgb(255, 255, 255); transform-origin: 596.274px 257.345px;" id="el7ae7pen8tfs"
                            class="animable"></path>
                          <path
                            d="M594.81649,261.2483c2.6813-.6275,3.7181-1.8045,4.0539-2.919-.90539,.2491-1.9695,.6501-2.8391,1.2869-.6671,.4842-1.0195,1.0734-1.2148,1.6321Z"
                            style="fill: rgb(220, 87, 83); transform-origin: 596.843px 259.789px;" id="elmqzeh6fj2ae"
                            class="animable"></path>
                          <path
                            d="M592.85359,249.824s-1.5867,3.7959-3.2418,5.6538c1.0771,1.0412,2.9998,.4939,2.9998,.4939l.242-6.1477Z"
                            style="fill: rgb(213, 135, 69); transform-origin: 591.233px 252.97px;" id="el51itoc6tru5"
                            class="animable"></path>
                        </g>
                        <path d="M596.57449,248.6555l1.933,.4566c-.8759,.9849-1.933-.4566-1.933-.4566Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 597.541px 249.056px;" id="elybb66hvfh1"
                          class="animable"></path>
                        <path d="M587.936,248.5711l1.933,.4567c-.876,.9849-1.933-.4567-1.933-.4567Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 588.902px 248.972px;" id="el9nup6j5do7p"
                          class="animable"></path>
                      </g>
                      <path
                        d="M594.50739,234.6186c-3.3257,1.1283-6.8588,6.8841-7.44739,9.0988,4.72949,.7743,17.47889-3.6562,17.47889-3.6562,0,0,8.50711,2.3495,4.1977-1.964-4.3093-4.3135-14.2292-3.4786-14.2292-3.4786Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 598.507px 239.181px;" id="elxj7evq1m0mm"
                        class="animable"></path>
                      <path
                        d="M612.35669,254.382c-.9948,1.7273-2.6113,2.812-4.0935,3.1837-2.2293,.5591-3.3805-3.0252-2.6195-5.2588,.685-2.0104,2.8756-3.3415,5.0451-2.903,2.1375,.432,2.8053,3.0032,1.6679,4.9781Z"
                        style="fill: rgb(233, 179, 118); transform-origin: 609.157px 253.472px;" id="el4pzez8q6le"
                        class="animable"></path>
                      <path
                        d="M573.95605,269.29395l-.27734-.41602c3.06152-2.04297,5.72168-9.53809,6.85742-12.73926,1.24512-3.50977,1.72754-7.27637,2.19336-10.91895l.49609,.06348c-.46973,3.66895-.95508,7.46289-2.21875,11.02344-1.25,3.52246-3.85059,10.85156-7.05078,12.9873Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 578.452px 257.257px;" id="el1ncvx2jubye"
                        class="animable"></path>
                    </g>
                    <g id="elfa0qfohenes" class="animable" style="transform-origin: 604.029px 306.67px;">
                      <g id="elbu52jint7ft">
                        <polygon
                          points="612.73409 289.8209 611.36779 308.3141 590.37529 320.8046 609.52709 317.6612 615.36339 314.1393 618.38229 303.741 612.73409 289.8209"
                          style="opacity: 0.2; transform-origin: 604.379px 305.313px;" class="animable"></polygon>
                      </g>
                      <g id="el2p7pqgnhzd5" class="animable" style="transform-origin: 585.226px 330.918px;">
                        <path
                          d="M577.10939,337.8658c.1318-1.3408,2.2578-6.257,3.5568-8.2181,1.2989-1.9611,6.4196-6.8277,6.4196-6.8277l6.2529,3.4971s.2195,2.8553-1.5516,4.3733c-1.02109,.8753-2.116,1.6507-2.116,1.6507,0,0,.28591,1.0661,.2928,2.2596,.0077,1.3223-.26189,2.9535-1.35,2.9026-1.087-.0509-1.4882-3.5531-1.5406-4.0587,.0505,.5124,.3525,4.0976-1.1034,4.2231-1.4043,.1212-1.63589-3.6199-1.63589-3.6199,0,0-.07351,1.7432-.11761,2.6336-.0442,.8904-.9378,1.6657-1.76339,.6752-.95721-1.1482-1.04181-3.1302-1.04181-3.1302,0,0-.7568,1.1595-1.8903,3.6867-.8158,1.8189-2.5231,1.0865-2.4115-.0473Z"
                          style="fill: rgb(233, 179, 118); transform-origin: 585.226px 330.918px;" id="el0to90yx6jxm"
                          class="animable"></path>
                        <g id="el514lln32lkg">
                          <path
                            d="M586.86914,334.12598l.25-.01172-.25-.00098c-.03223-.67188-.06543-1.36621,.0293-2.05176,.11035-.80371,.38281-1.46777,.80859-1.97461,.08887-.10449,.24805-.11816,.35254-.03027,.10547,.08887,.11914,.24707,.03027,.35254-.36426,.43262-.59863,1.01172-.69531,1.7207-.08984,.63965-.05762,1.31055-.02637,1.95996l-.49902,.03613Z"
                            style="opacity: 0.1; transform-origin: 587.492px 332.062px;" class="animable"></path>
                        </g>
                        <g id="elydb5z4nve1d">
                          <path
                            d="M584.27344,334.83789c-.13281,0-.24316-.10352-.25-.2373-.0332-.6748-.06738-1.37305,.02832-2.06543,.1123-.80273,.38379-1.4668,.80859-1.97363,.08789-.10645,.24707-.11914,.35156-.03125,.10645,.08887,.12012,.24609,.03125,.35156-.36328,.43457-.59766,1.01367-.69531,1.72168-.08984,.64648-.05664,1.32031-.02441,1.97168,.00684,.13867-.09961,.25586-.2373,.2627h-.0127Z"
                            style="opacity: 0.1; transform-origin: 584.646px 332.655px;" class="animable"></path>
                        </g>
                        <g id="elcnqagq3z0e8">
                          <path
                            d="M581.43848,334.58984c-.13281,0-.24316-.10352-.25-.2373-.0332-.6748-.06738-1.37305,.02832-2.06543,.1123-.80273,.38379-1.4668,.80859-1.97363,.08789-.10645,.24609-.11914,.35156-.03125,.10645,.08887,.12012,.24609,.03125,.35156-.36328,.43457-.59766,1.01367-.69531,1.72168-.08984,.64648-.05664,1.32031-.02441,1.97168,.00684,.13867-.09961,.25586-.2373,.2627h-.0127Z"
                            style="opacity: 0.1; transform-origin: 581.811px 332.407px;" class="animable"></path>
                        </g>
                      </g>
                      <g id="el405vgsmfttq" class="animable" style="transform-origin: 609.019px 301.44px;">
                        <path
                          d="M613.33189,288.074c0,.5804,1.8632,21.2427,1.8632,21.2427-1.1608,2.1762-25.0514,10.4681-28.1093,13.5033l5.5729,5.7342s28.4247-5.7342,33.0138-9.0703c3.9156-2.8465,3.5861-7.8408,3.5861-14.6598s-1.4908-16.7501-1.4908-16.7501h-14.4359Z"
                          style="fill: rgb(233, 179, 118); transform-origin: 608.176px 308.314px;" id="eljg8lpvgkfhb"
                          class="animable"></path>
                        <path
                          d="M615.74169,275.3247c-5.8685,2.4512-1.3885,33.8728-1.3885,33.8728l-22.9169,10.6278,4.3523,8.7289s23.45141-4.8841,30.5606-6.334c7.5147-1.5326,3.7699-17.9504,2.9162-32.3993-.8577-14.5174-6.6583-17.3637-13.5237-14.4962Z"
                          style="fill: rgb(64, 123, 255); transform-origin: 611.195px 301.44px;" id="elummlsz9d2w"
                          class="animable"></path>
                        <g id="elda4b3b7dbdv">
                          <path
                            d="M615.74169,275.3247c-5.8685,2.4512-1.3885,33.8728-1.3885,33.8728l-22.9169,10.6278,4.3523,8.7289s23.45141-4.8841,30.5606-6.334c7.5147-1.5326,3.7699-17.9504,2.9162-32.3993-.8577-14.5174-6.6583-17.3637-13.5237-14.4962Z"
                            style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 611.195px 301.44px;"
                            class="animable"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="elf9dqfhoq4o" class="animable" style="transform-origin: 553.548px 319.825px;">
                    <g id="elvghtl2va0sj" class="animable" style="transform-origin: 539.048px 319.825px;">
                      <g id="elr3p039hbol" class="animable" style="transform-origin: 539.048px 319.825px;">
                        <path
                          d="M531.37609,336.6814h30.592c.52791-.0035,.9523-.3498,.948-.7734-.0008-.0797-.0172-.1588-.0482-.2345l-13.6091-31.1999c-.3437-.8912-1.3682-1.5001-2.5306-1.5044h-30.6106c-.5177,.0002-.9372,.337-.937,.7525,.0001,.087,.019,.1735,.0559,.2554l13.6089,31.2001c.3489,.8881,1.3702,1.4953,2.5307,1.5042Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 539.048px 319.825px;" id="elael4etyuzkk"
                          class="animable"></path>
                        <g id="el35we7qplp3i">
                          <path
                            d="M516.11759,302.9691h30.6106c1.1624,.0043,2.1869,.6133,2.5306,1.5045l13.6091,31.1999c.031,.0757,.0474,.1548,.04811,.2345,.00439,.4236-.42,.7699-.94791,.7734h-30.592c-1.1605-.0089-2.1818-.6161-2.5308-1.5042l-13.6089-31.2001c-.0368-.0819-.0558-.1684-.0558-.2554-.0002-.4155,.4193-.7523,.937-.7526Z"
                            style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 539.048px 319.825px;"
                            class="animable"></path>
                        </g>
                        <path
                          d="M535.07619,320.5201c.6612,1.4974,2.0012,2.5256,3.0002,2.3163,.9991-.2092,1.2654-1.5738,.6238-3.0752-.6416-1.5015-1.9971-2.5059-2.99619-2.2966-.99891,.2092-1.29721,1.5189-.62781,3.0555Z"
                          style="fill: rgb(255, 255, 255); transform-origin: 536.883px 320.15px;" id="elk3cj3o70yv"
                          class="animable"></path>
                      </g>
                      <g id="elv8ncziiaok">
                        <path
                          d="M546.54629,302.9691h.1819c1.1624,.0043,2.1869,.6133,2.5306,1.5045l13.6091,31.1999c.031,.0757,.0474,.1548,.04811,.2345,.00439,.4236-.42,.7699-.94791,.7734h-.9797l-14.4421-33.7123Z"
                          style="opacity: 0.3; transform-origin: 554.731px 319.825px;" class="animable"></path>
                      </g>
                    </g>
                    <path d="M591.91589,336.6814h-31.8474v-2.1474h29.6999c1.186,0,2.1475,.9614,2.1475,2.1474h0Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 575.992px 335.608px;" id="elc2ixk9dwg2w"
                      class="animable"></path>
                    <g id="eluwf6pyvadi">
                      <path d="M591.91589,336.6814h-31.8474v-2.1474h29.6999c1.186,0,2.1475,.9614,2.1475,2.1474h0Z"
                        style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 575.992px 335.608px;"
                        class="animable"></path>
                    </g>
                  </g>
                  <g id="freepik--freepik--Desk--inject-2--inject-18" class="animable"
                    style="transform-origin: 582.978px 396.86px;">
                    <polygon
                      points="531.36219 455.8515 487.14439 455.8515 487.14439 342.1046 488.90109 342.1046 488.90109 454.0474 529.60539 454.0474 529.60539 342.1046 531.36219 342.1046 531.36219 455.8515"
                      style="fill: rgb(38, 50, 56); transform-origin: 509.253px 398.978px;" id="elhzuak1xqzxs"
                      class="animable"></polygon>
                    <rect x="471.13819" y="336.6814" width="223.67899" height="5.4232"
                      style="fill: rgb(64, 123, 255); transform-origin: 582.978px 339.393px;" id="elcs4nkzlpr2r"
                      class="animable"></rect>
                    <g id="el0loxqgf9wb2">
                      <rect x="471.13819" y="336.6814" width="223.67899" height="5.4232"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 582.978px 339.393px;"
                        class="animable">
                      </rect>
                    </g>
                    <g id="el51xi144gywj">
                      <rect x="628.09319" y="336.6814" width="66.724" height="5.4232"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 661.455px 339.393px;"
                        class="animable">
                      </rect>
                    </g>
                    <polygon
                      points="691.57139 457.0395 647.35349 457.0395 647.35349 342.1046 649.11029 342.1046 649.11029 455.2166 689.81459 455.2166 689.81459 342.1046 691.57139 342.1046 691.57139 457.0395"
                      style="fill: rgb(38, 50, 56); transform-origin: 669.462px 399.572px;" id="el3xazlhpsylb"
                      class="animable"></polygon>
                  </g>
                </g>
              </g>
              <g id="freepik--Character_2--inject-18" class="animable animator-active"
                style="transform-origin: 220.411px 308.611px;">
                <g id="el4lze8pbbvep" class="animable" style="transform-origin: 220.411px 308.611px;">
                  <g id="elxn9z4gs8uhp" class="animable" style="transform-origin: 240.706px 237.161px;">
                    <path
                      d="M226.0637,220.0837s9.9878,24.9963,11.5723,25.1476c1.5846,.1513,21.8451-7.7446,21.8451-7.7446l3.1512,3.5607s-10.611,14.1895-27.4087,18.6939c-3.0177,.81-6.8276-2.6082-10.4595-7.2817-.7122-.908-1.4066-1.8694-2.1009-2.8576-3.7375-5.5404-7.0896-11.3313-10.0323-17.3318l13.4328-12.1865Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 237.632px 239.972px;" id="elqcnecyh3qo"
                      class="animable"></path>
                    <path
                      d="M234.2704,248.0081c-.946-.268-1.4956-1.2521-1.2276-2.1982l6.7742-28.4858c.4025-1.35,1.619-2.2946,3.0266-2.35l21.7115-.454c.236-.0293,.4754-.0109,.7041,.0539,.946,.268,1.4956,1.2521,1.2276,2.1982l-6.7742,28.4858c-.4017,1.3519-1.6176,2.2995-3.0266,2.359l-21.7116,.4451c-.2359,.0292-.4753,.0108-.704-.054Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 249.765px 231.291px;" id="elof65wk7xlcs"
                      class="animable"></path>
                    <g id="elulu1y1509ep">
                      <path
                        d="M234.2704,248.0081c-.946-.268-1.4956-1.2521-1.2276-2.1982l6.7742-28.4858c.4025-1.35,1.619-2.2946,3.0266-2.35l21.7115-.454c.236-.0293,.4754-.0109,.7041,.0539,.946,.268,1.4956,1.2521,1.2276,2.1982l-6.7742,28.4858c-.4017,1.3519-1.6176,2.2995-3.0266,2.359l-21.7116,.4451c-.2359,.0292-.4753,.0108-.704-.054Z"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 249.765px 231.291px;"
                        class="animable"></path>
                    </g>
                    <path
                      d="M236.4973,247.9629c-.9462-.2674-1.4965-1.2513-1.2291-2.1975l6.7743-28.4859c.4029-1.3473,1.6129-2.2931,3.0177-2.3589l21.7204-.4451c.2359-.0292,.4753-.0108,.704,.054,.9461,.268,1.4957,1.2521,1.2277,2.1982l-6.7831,28.4857c-.403,1.3474-1.613,2.2933-3.0178,2.3591l-21.7205,.445c-.2325,.0276-.4683,.009-.6936-.0546Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 251.99px 231.246px;" id="elv5go7g77jd"
                      class="animable"></path>
                    <g id="elw5y5zdaxae9">
                      <path
                        d="M236.4973,247.9629c-.9462-.2674-1.4965-1.2513-1.2291-2.1975l6.7743-28.4859c.4029-1.3473,1.6129-2.2931,3.0177-2.3589l21.7204-.4451c.2359-.0292,.4753-.0108,.704,.054,.9461,.268,1.4957,1.2521,1.2277,2.1982l-6.7831,28.4857c-.403,1.3474-1.613,2.2933-3.0178,2.3591l-21.7205,.445c-.2325,.0276-.4683,.009-.6936-.0546Z"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.3; transform-origin: 251.99px 231.246px;"
                        class="animable"></path>
                    </g>
                    <g id="eld4rm4vl25fl">
                      <path
                        d="M256.0983,231.173c-.1186-1.2481-1.2265-2.1637-2.4746-2.0451-1.7676,.0967-3.2762,1.3113-3.7476,3.0177-.0842,.2822-.1121,.5781-.0824,.871,.1256,1.2374,1.2306,2.1388,2.4681,2.0132,1.7675-.0944,3.2783-1.3045,3.7565-3.0089,.08-.275,.107-.5628,.08-.8479Z"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 252.945px 232.08px;"
                        class="animable"></path>
                    </g>
                    <path
                      d="M263.4336,232.5284c-.4274-.0356-2.2433,.8635-3.0979,.2671-.8546-.5965-.0534-1.0861-.0534-1.0861,1.5668-.3828,3.2848-2.3412,4.2372-1.7803,.9525,.5608,2.5282,1.2641,2.2967,2.1542-.2615,.8239-.5766,1.6298-.9436,2.4124,.2484,.3966,.2818,.8911,.089,1.3175-.1959,.4921-.6284,.8509-1.1483,.9525,.2985,.3015,.454,.716,.4273,1.1394-.0711,.6765-1.8515,1.5668-1.8515,1.5668,0,0,.3472,1.0592-.0624,1.4687-.6948,.5717-1.6183,.7828-2.4925,.5697-1.0415-.2492-1.7537-.6053-1.6825-1.6557,.0713-1.0504,1.9496-1.0504,1.9496-1.0504,0,0-3.5251-1.0682-2.7773-1.7359,.7476-.6676,3.5607-.5875,3.5607-.5875,0,0-4.7715-.9436-4.0148-1.9227,.7567-.9792,5.5102-.7122,5.5102-.7122,0,0,.4807-1.2819,.0535-1.3175Z"
                      style="fill: rgb(185, 121, 100); transform-origin: 262.314px 235.71px;" id="eliqws6llmhq"
                      class="animable"></path>
                  </g>
                  <g id="el3mps813yinf" class="animable" style="transform-origin: 200.496px 440.808px;">
                    <polygon
                      points="191.2171 447.5358 198.778 447.1967 201.5818 427.5292 190.0619 427.9208 191.2171 447.5358"
                      style="fill: rgb(185, 121, 100); transform-origin: 195.822px 437.533px;" id="el10xjb03355ca"
                      class="animable"></polygon>
                    <path
                      d="M199.6995,443.7472h-2.6891c-.6086,0-1.1714,.3077-1.531,.7988-1.5508,2.1179-2.7163,1.3174-4.4031-.6738-1.0724-.125-1.361,1.1057-1.4485,1.4363l-2.0104,7.313c-.1747,.6106,.1789,1.2472,.7896,1.4219,.1085,.0311,.2211,.0459,.3341,.0441h21.3066c3.3886,0,4.0909-3.4149,2.695-3.731-6.2768-1.387-10.7215-3.2342-12.6618-5.0602-.3598-.3197,.0098-.5589-.3814-1.5491Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 200.496px 448.917px;" id="elun460ev9reg"
                      class="animable"></path>
                    <path
                      d="M204.9627,446.1923c.283-.2034,.4524-.5292,.4565-.8779,.0237-.1869-.0615-.3711-.2195-.474-.8427-.597-4.205,.9745-4.5827,1.1588-.0419,.0151-.0749,.0481-.0899,.0902-.0279,.0775,.0124,.1629,.0899,.1908,.8264,.1956,1.6709,.3044,2.5196,.3248,.6372,.0606,1.277-.084,1.8261-.4127Zm.0264-1.0972c.0684,.0423,.1035,.1229,.0878,.2019-.0141,.2515-.1355,.485-.3337,.6408-.7637,.5707-2.6073,.3248-3.5993,.1404,1.3958-.6145,3.3798-1.308,3.8452-.9831Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 202.971px 445.662px;" id="eljk79ma0707r"
                      class="animable"></path>
                    <path
                      d="M200.7313,446.3153c1.0096-.4213,3.1253-2.0806,3.0112-2.9586-.0177-.1842-.1493-.4037-.6058-.4652-1.8436-.2107-2.6337,3.0989-2.6337,3.2395-.0181,.0566-.0009,.1188,.0439,.158,.0344,.0102,.0709,.0102,.1053,0l.0791,.0263Zm2.3439-3.1517c.3425,.0352,.3425,.1581,.3425,.2019,.0965,.5355-1.4399,1.9139-2.5372,2.4933,.2282-.7725,.9042-2.704,2.0719-2.704l.1228,.0088Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 202.121px 444.599px;" id="elbrrlgxvvhjd"
                      class="animable"></path>
                    <path
                      d="M198.71045,447.90918c-.0459,0-.0918-.0127-.1333-.03809-.1167-.07422-.15186-.22852-.07812-.34473,.53906-.85645,1.45947-1.44922,2.46143-1.58594,.13232-.0127,.26221,.07715,.28125,.21484,.01855,.13672-.07715,.2627-.21387,.28125-.85742,.11621-1.64453,.62305-2.10596,1.35547-.04736,.07617-.12842,.11719-.21143,.11719Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 199.852px 446.924px;" id="elt3vwjepdhyr"
                      class="animable"></path>
                    <path
                      d="M200.38135,449.16797c-.0459,0-.0918-.0127-.1333-.03809-.1167-.07422-.15186-.22852-.07812-.34473,.53857-.85645,1.45898-1.44922,2.46191-1.58691,.13086-.01562,.26221,.07715,.28125,.21387s-.07666,.2627-.21387,.28223c-.85791,.11719-1.64551,.625-2.10645,1.35645-.04736,.07617-.12842,.11719-.21143,.11719Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 201.523px 448.182px;" id="eldpadnf5hkoh"
                      class="animable"></path>
                    <path
                      d="M202.46094,450.22559c-.0459,0-.0918-.0127-.1333-.03809-.1167-.07422-.15186-.22852-.07812-.34473,.53857-.85645,1.45898-1.44922,2.46094-1.58691,.13525-.01172,.2627,.07715,.28174,.21387s-.07666,.2627-.21338,.28223c-.85791,.11719-1.64502,.625-2.10645,1.35645-.04736,.07617-.12842,.11719-.21143,.11719Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 203.603px 449.24px;" id="el7pl02kfqti"
                      class="animable"></path>
                    <g id="eljfwbu12olya">
                      <polygon
                        points="200.0532 438.2514 199.6925 440.7813 190.8102 440.6253 190.6703 438.2514 200.0532 438.2514"
                        style="opacity: 0.3; transform-origin: 195.362px 439.516px;" class="animable"></polygon>
                    </g>
                  </g>
                  <g id="elsaikoojv0k" class="animable" style="transform-origin: 232.142px 439.52px;">
                    <polygon
                      points="222.7222 445.5912 230.9287 444.5518 232.7367 426.0108 221.4792 425.6969 222.7222 445.5912"
                      style="fill: rgb(185, 121, 100); transform-origin: 227.108px 435.644px;" id="elb7ae3wnjy6b"
                      class="animable"></polygon>
                    <path
                      d="M231.3455,443.0027h-2.6892c-.6085,0-1.1714,.3077-1.5309,.7987-1.5509,2.118-2.7163,1.3175-4.4032-.6737-1.0723-.125-1.361,1.1056-1.4485,1.4364l-2.0104,7.3128c-.1746,.6107,.179,1.2474,.7896,1.422,.1086,.031,.2212,.046,.3341,.0442h21.3066c3.3887,0,4.091-3.4151,2.6951-3.7311-6.2768-1.3871-10.7216-3.2342-12.6618-5.0601-.3598-.3198,.0097-.5589-.3814-1.5492Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 232.142px 448.173px;" id="elwhjb7y94pr"
                      class="animable"></path>
                    <path
                      d="M237.1812,445.082c.283-.2035,.4524-.5294,.4566-.8779,.0237-.1871-.0615-.3712-.2196-.474-.8428-.597-4.2051,.9744-4.5826,1.1588-.0419,.0151-.075,.0481-.09,.09-.0279,.0777,.0124,.1631,.09,.1909,.8263,.1956,1.6707,.3044,2.5196,.3248,.6371,.0606,1.2769-.0839,1.826-.4126Zm.0263-1.0974c.0686,.0425,.1036,.1229,.0879,.2019-.0141,.2517-.1356,.4851-.3337,.641-.7638,.5706-2.6073,.3248-3.5994,.1404,1.3959-.6145,3.38-1.3081,3.8452-.9833Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 235.189px 444.552px;" id="elihv358e91vp"
                      class="animable"></path>
                    <path
                      d="M232.9498,445.2049c1.0095-.4214,3.1253-2.0806,3.0111-2.9585-.0175-.1844-.1492-.4037-.6057-.4652-1.8436-.2107-2.6337,3.0989-2.6337,3.2394-.0182,.0567-.001,.1187,.0439,.158,.0344,.0103,.071,.0103,.1053,0l.0791,.0263Zm2.3439-3.1516c.3424,.035,.3424,.1579,.3424,.2019,.0966,.5355-1.4397,1.9138-2.5371,2.4933,.2282-.7726,.9041-2.704,2.0718-2.704l.1229,.0088Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 234.34px 443.488px;" id="elez8b0po0qv"
                      class="animable"></path>
                    <path
                      d="M230.92871,446.79883c-.0459,0-.0918-.0127-.1333-.03809-.1167-.07422-.15186-.22852-.07812-.34473,.53906-.85645,1.45947-1.44922,2.46143-1.58594,.13477-.01172,.26221,.07715,.28125,.21484,.01855,.13672-.07715,.2627-.21387,.28125-.85742,.11621-1.64453,.62305-2.10596,1.35547-.04736,.07617-.12842,.11719-.21143,.11719Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 232.07px 445.814px;" id="elkxjak9wa5be"
                      class="animable"></path>
                    <path
                      d="M232.6001,448.05664c-.0459,0-.0918-.0127-.1333-.03809-.1167-.07422-.15186-.22852-.07812-.34473,.53906-.85645,1.45947-1.44922,2.46143-1.58594,.1333-.01465,.2627,.07715,.28125,.21484,.01855,.13672-.07715,.2627-.21387,.28125-.85742,.11621-1.64453,.62305-2.10596,1.35547-.04736,.07617-.12842,.11719-.21143,.11719Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 233.742px 447.072px;" id="el5hhefq59jzn"
                      class="animable"></path>
                    <path
                      d="M234.67969,449.11523c-.0459,0-.0918-.0127-.1333-.03809-.1167-.07422-.15186-.22852-.07812-.34473,.53857-.85645,1.45898-1.44922,2.46094-1.58691,.13086-.0127,.2627,.07715,.28174,.21387s-.07666,.2627-.21338,.28223c-.85791,.11719-1.64502,.625-2.10645,1.35645-.04736,.07617-.12842,.11719-.21143,.11719Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 235.822px 448.13px;" id="elglg2gio5rk9"
                      class="animable"></path>
                    <g id="elj6i9i579kcf">
                      <polygon
                        points="231.6514 437.141 231.404 439.6785 222.3492 439.6202 222.1942 437.141 231.6514 437.141"
                        style="opacity: 0.3; transform-origin: 226.923px 438.41px;" class="animable"></polygon>
                    </g>
                  </g>
                  <g id="eldx8esgctgy7" class="animable" style="transform-origin: 205.241px 351.856px;">
                    <path
                      d="M205.3173,273.9576l21.0801,.0967s7.3651,41.4986,6.9333,88.0273c.8599,27.3155,.17,77.5434,.17,77.5434h-13.7091s-8.7342-40.7937-5.2372-73.1141c-1.2721-5.5731-2.9717-15.2235-3.9709-20.1488-2.7983-13.2773-4.904-26.7256-6.3063-40.2767-2.4079-25.3997,1.0401-32.1278,1.0401-32.1278Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 218.593px 356.791px;" id="elsaz3w3md0s"
                      class="animable"></path>
                    <g id="elxq23vm5yg5">
                      <path
                        d="M207.1847,290.2553s7.6003,38.2051,3.3988,56.1068c-2.7983-13.2773-4.904-26.7256-6.3063-40.2767l2.9075-15.8301Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 208.063px 318.309px;"
                        class="animable">
                      </path>
                    </g>
                    <path
                      d="M187.5976,263.0901c-1.8398,9.2191-9.0495,20.4796-10.508,32.2732-2.4913,20.1419,8.3277,45.1952,11.1597,68.8272-4.2752,34.951,2.279,77.3339,1.4108,76.4172l13.7629,.0146c.4178-3.1256,3.24-50.4827,4.6405-78.5407,1.1875-24.5564,3.0851-86.3182,3.0851-86.3182l-23.551-12.6733Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 193.934px 351.856px;" id="elkp4npvjvk5j"
                      class="animable"></path>
                  </g>
                  <g id="elre2rboze0t7" class="animable" style="transform-origin: 205.339px 247.391px;">
                    <g id="elgkxjzazvmtl">
                      <path
                        d="M226.0637,223.3951c.7832,3.4607,2.616,8.6555,4.0637,12.3422,.4683,1.193,.6741,2.4856,.4571,3.7488-.6251,3.6392-3.7686,4.8031-4.8146,7.7036-.5851,1.6996-.9239,3.4742-1.0058,5.2699-.7122-.908-1.4066-1.8694-2.1009-2.8576l-1.5489-6.1244,4.9494-20.0825Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 225.893px 237.927px;"
                        class="animable">
                      </path>
                    </g>
                    <path
                      d="M224.7997,244.9019c8.1807-5.7238,1.7804-10.8958,2.5014-19.3793,.4962-6.4785-4.0268-12.2673-10.433-13.3527-1.4777-.2492-3.0088-.4985-4.5131-.6943l-.8903-.1157c-4.0155-.457-8.0569-.6474-12.0976-.5697-1.4776,0-3.0088,0-4.6111,.0445-1.6023,.0444-3.1512,.0978-4.6289,.2047-5.125,.3585-8.9891,4.8039-8.6305,9.929,.0228,.3271,.0631,.6528,.1204,.9757l5.4676,36.1636-7.0766,20.846c21.1759,9.5833,49.4404,3.285,48.356-.525-3.0502-10.716-4.6236-26.254-3.5643-33.5268Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 204.507px 247.391px;" id="elmwxf8kxzk7"
                      class="animable"></path>
                  </g>
                  <g id="eli1nnw2epfvg" class="animable" style="transform-origin: 211.196px 192.226px;">
                    <path
                      d="M224.5976,175.8037l-27.3926-4.8493c-2.1672,.5697-3.8796,2.5291-4.871,4.7636-.9914,2.2344-1.3642,4.741-1.6867,7.2103-.8659,6.6314-1.4286,13.5803,.6011,19.8844,13.6417,2.5021,27.6513,2.4648,41.2822-.1098-5.9684-6.063-4.4428-19.0182-7.933-26.8992Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 211.196px 187.808px;" id="elgwryqjldwpj"
                      class="animable"></path>
                    <path
                      d="M197.4156,210.6698s3.3515,2.7323,7.6549,6.6597c.8965,.8182,1.8888,1.532,2.9441,2.1316,2.8473,1.6178,5.4575,2.5715,6.2108,1.1979,.1675-.3017,.299-.6302,.3993-.9758,.6725-2.3167,.5173-5.605-1.3266-7.1602-2.0789-1.7537-3.0633-3.2315-2.6145-5.5125,.2384-1.2937,.7468-2.6217,1.3336-3.9087l-2.4145-1.5448-8.503-9.0849c.8471,5.446,1.0185,15.1265-3.6841,18.1977Z"
                      style="fill: rgb(185, 121, 100); transform-origin: 206.203px 206.895px;" id="elhclzppk700p"
                      class="animable"></path>
                    <g id="elnxoegyhbv7p">
                      <path
                        d="M210.5982,207.5117c-2.3672-1.4979-4.9384-4.0524-4.541-6.876,.1752-1.1887,.4644-2.3115,.7708-3.0277l4.7063,6.6143c-.4312,1.0322-.5976,1.718-.9361,3.2894Z"
                        style="opacity: 0.2; transform-origin: 208.775px 202.56px;" class="animable"></path>
                    </g>
                    <path
                      d="M199.6875,182.4625c.5678,9.0188,.618,12.8381,5.1256,17.6869,6.78,7.2936,17.59,5.2131,19.6933-3.6506,1.8931-7.9777,.6464-21.386-8.0768-25.418-8.5942-3.9722-17.3099,2.3629-16.7421,11.3817Z"
                      style="fill: rgb(185, 121, 100); transform-origin: 212.466px 187.222px;" id="elu1skoxf5ub"
                      class="animable"></path>
                    <path
                      d="M211.7267,185.7569c.0172,.953,.5103,1.749,1.1017,1.7781,.5912,.0291,1.0563-.7198,1.0391-1.6728-.017-.953-.5101-1.7491-1.1014-1.7782-.5911-.0291-1.0563,.7197-1.0394,1.6729Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 212.797px 185.81px;" id="eltgdlbjktrq"
                      class="animable"></path>
                    <path
                      d="M220.8657,185.9697c.0184,.9234,.5113,1.6957,1.1007,1.7249,.5893,.029,1.0522-.6961,1.0338-1.6197-.0186-.9234-.5114-1.6957-1.1008-1.7248-.5894-.0293-1.0522,.6959-1.0337,1.6196Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 221.933px 186.022px;" id="elckorzaoocy8"
                      class="animable"></path>
                    <path
                      d="M209.4726,182.3543c.1142,.0161,.2315-.014,.3227-.0934,1.4223-1.2437,2.9698-.7488,2.9878-.743,.2293,.074,.467-.0502,.5267-.2847,.0591-.2342-.0808-.4859-.3129-.5636-.0809-.0263-2.023-.6496-3.7879,.8943-.1759,.154-.1874,.4351-.0258,.6275,.0783,.0925,.1824,.148,.2894,.1629Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 211.196px 181.441px;" id="el9uoq7t3n7dg"
                      class="animable"></path>
                    <path
                      d="M223.2389,181.5195c.0915-.0278,.1689-.0931,.2113-.1905,.0878-.2016-.005-.4631-.2066-.5842-2.0231-1.2143-3.5723-.3792-3.6377-.3422-.1838,.1025-.2348,.3566-.1133,.5681,.1209,.2113,.3678,.2994,.5517,.1982,.0602-.0336,1.2724-.6612,2.881,.3048,.1041,.0623,.2169,.0754,.3136,.0458Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 221.455px 180.811px;" id="elhlcr2h5x2t7"
                      class="animable"></path>
                    <path
                      d="M217.7261,185.4414s1.3114,3.4071,2.8392,5.5513c.267,.3747,.0801,.9053-.3561,1.0519-1.1224,.3772-2.4118-.0298-2.4118-.0298l-.0713-6.5734Z"
                      style="fill: rgb(162, 78, 63); transform-origin: 219.208px 188.823px;" id="elq01n5b8ztr"
                      class="animable"></path>
                    <path d="M212.4263,184.1136l2.0784-.4289s-1.1729,1.5074-2.0784,.4289Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 213.465px 184.094px;" id="el2cbntpocqdm"
                      class="animable"></path>
                    <path d="M221.5878,184.3747l2.0784-.4291s-1.1728,1.5075-2.0784,.4291Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 222.627px 184.355px;" id="elz1ingkswm4c"
                      class="animable"></path>
                    <path
                      d="M201.1889,194.603c-25.6614-23.8012,9.3846-29.6985,9.3846-29.6985,14.9151-6.6156,12.6356,7.4411,12.6356,7.4411,5.228,8.1449-5.2477,12.3245-5.2477,12.3245,5.0217-4.8705-.3824-9.4236-.3824-9.4236-.5354,7.676-8.4228,9.1846-8.4228,9.1846,4.1848-6.202,3.0446-10.0545,3.0446-10.0545-4.456,11.639-10.9522,12.8755-10.9522,12.8755l-.0597,7.3509Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 208.233px 178.869px;" id="el7bl4gr3k8tm"
                      class="animable"></path>
                    <path
                      d="M196.7572,189.7162c1.0099,1.8751,2.7034,3.0827,4.2751,3.5249,2.364,.6649,3.7026-3.1283,2.9578-5.5369-.6705-2.1683-2.9697-3.6564-5.3003-3.2542-2.296,.3964-3.0875,3.1223-1.9326,5.2662Z"
                      style="fill: rgb(185, 121, 100); transform-origin: 200.214px 188.851px;" id="elsou1q35cb1b"
                      class="animable"></path>
                    <g id="el9jaj5at92uc">
                      <path
                        d="M201.80273,189.63037c-.13379,0-.24463-.10596-.25-.24023-.03027-.79248-.38086-1.55664-.96191-2.09668-.58057-.54004-1.37598-.83887-2.16113-.80518-.14404-.00684-.25391-.10352-.25879-.24121s.10303-.25391,.24121-.25879c.92334-.02881,1.84277,.31006,2.51953,.93896,.67676,.62939,1.08545,1.52002,1.12109,2.44336,.00488,.13818-.10254,.25439-.24023,.25977h-.00977Z"
                        style="opacity: 0.2; transform-origin: 200.112px 187.808px;" class="animable"></path>
                    </g>
                    <path
                      d="M214.94287,194.90137c-2.29443,0-3.0376-2.13184-3.04541-2.15527-.04395-.13086,.02637-.27197,.15674-.31592,.13281-.0459,.27295,.02588,.31689,.15625,.03076,.08984,.77637,2.18408,3.20752,1.75684,.13086-.02783,.26514,.06641,.28955,.20264,.02393,.13574-.06641,.26562-.20264,.28955-.25635,.04541-.49707,.06592-.72266,.06592Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 213.878px 193.659px;" id="el7x7gn06swbj"
                      class="animable"></path>
                  </g>
                  <g id="el03d50jd0zg0i" class="animable" style="transform-origin: 199.662px 242.357px;">
                    <g id="elsu2ck7tj6r">
                      <path
                        d="M185.0014,264.2442l-1.4778,4.3533s20.9361-3.6387,31.4023-12.9977c7.4521-6.6638,6.2866-9.4702,6.2866-9.4702l-8.4409,.8149-27.7702,17.2997Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 202.401px 257.364px;"
                        class="animable">
                      </path>
                    </g>
                    <g id="elffa9sul29y">
                      <path
                        d="M193.0832,233.0591l-1.1352,18.8767c-3.1522,2.0053-5.2393-.7744-5.7191-3.6467v-5.4346l6.8543-9.7954Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 189.656px 242.838px;"
                        class="animable">
                      </path>
                    </g>
                    <path
                      d="M194.2128,230.1992s-7.9064,19.5839-6.4803,20.4873c1.4259,.9034,24.1859-5.3232,24.1859-5.3232l3.5872,5.1461c-.7706,3.3835-40.0425,24.6987-43.2488,6.8691-1.9486-10.8324,9.9754-40.7204,9.9754-40.7204,9.6809-3.7909,11.9806,13.5411,11.9806,13.5411Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 193.774px 240.261px;" id="elhv3y0y2jf6e"
                      class="animable"></path>
                    <path
                      d="M215.8469,251.0099c2.6909,.2129,2.7853-3.8397,4.1847-3.9991,.8145-.0782,1.6121-.2813,2.3649-.6023,0,0-.8858,2.4357,0,2.2408,.8857-.1948,3.7289-.3365,4.4287-2.6571,.6996-2.3206-1.8335-7.2807-2.4092-7.9715-.5758-.6909-3.6049-.4783-3.6049-.4783,0,0,3.2594-.8414,5.0486-1.0895,1.7892-.2479,2.1289-2.2924-.3675-1.8404-1.0251,.1856-9.1186,.2462-9.9955,1.0787-.8769,.8326-4.1278,9.0145-4.1278,9.0145-1.2046,.6908,1.8563,7.553,4.478,6.3042Z"
                      style="fill: rgb(185, 121, 100); transform-origin: 219.191px 242.854px;" id="ele0di5bgah1"
                      class="animable"></path>
                    <g id="elp70aqizkvnp">
                      <g style="opacity: 0.3; transform-origin: 222.187px 242.01px;" class="animable">
                        <path
                          d="M220.7801,237.2855c-.83-.058-1.6641-.0043-2.4799,.1594-.1228,.0334-.1953,.1598-.162,.2826,.0292,.1073,.1309,.1785,.2417,.1691,.7722-.1546,1.5611-.2082,2.3471-.1594,.2923,0,.3455-.434,.0531-.4517Z"
                          id="el2ztqd782zor" class="animable" style="transform-origin: 219.552px 237.58px;"></path>
                        <path
                          d="M224.952,239.7389c.2569,.1329,.5226-.2391,.2657-.3719-1.5147-.8061-3.1089-.3543-4.6412,.1594-.2746,.0886-.0797,.5048,.1949,.4163,1.3462-.4517,2.8343-.8858,4.1806-.2038Z"
                          id="elabgq60m3pg5" class="animable" style="transform-origin: 222.883px 239.441px;"></path>
                        <path
                          d="M226.1389,241.7408c-1.3065-.7802-2.9178-.8501-4.287-.1861-.1076,.0678-.1399,.21-.0721,.3176,.0545,.0866,.1597,.1268,.2581,.0986,1.2248-.5969,2.6666-.5438,3.844,.1418,.2481,.1505,.5138-.2215,.257-.3719Z"
                          id="eljm8qax00nw" class="animable" style="transform-origin: 223.994px 241.625px;"></path>
                        <path
                          d="M223.9635,245.1375c.1167-.2376,.2116-.487,.2753-.7485,.0621-.2835-.3632-.4429-.4339-.1594-.1888,.8713-.731,1.6251-1.4969,2.0814-.2569,.1418-.0621,.5581,.186,.4163h-.0266c.4814-.2711,.8814-.6498,1.1934-1.092,.0306,.0012,.0571,.0157,.0909,.0026,.4763-.1673,1.0064-.0268,1.3375,.3543,.1948,.2214,.5402-.0709,.3454-.2923-.3721-.4282-.9244-.6232-1.4711-.5624Z"
                          id="elwuvr33hxm8" class="animable" style="transform-origin: 223.84px 245.419px;"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="freepik--Character_1--inject-18" class="animable" style="transform-origin: 119.243px 298.28px;">
                <g id="elkbuvytjg94p" class="animable" style="transform-origin: 119.243px 298.28px;">
                  <g id="el27ejrcvdkl9" class="animable" style="transform-origin: 142.696px 439.917px;">
                    <polygon
                      points="124.00541 422.5688 128.9611 446.5036 139.3876 446.4089 137.4764 420.5371 124.00541 422.5688"
                      style="fill: rgb(224, 224, 224); transform-origin: 131.697px 433.52px;" id="elzvnw4wzryks"
                      class="animable"></polygon>
                    <g id="elfa55vrdwkni" class="animable" style="transform-origin: 144.613px 449.77px;">
                      <path
                        d="M130.20434,444.5754c1.3981,1.263,4.40151,1.0341,5.74006-.0402l.0156-1.9599c.2232-1.6551,.8062-2.4441,2.5855-2.2416,.8723,.098,1.9803,1.139,2.7218,2.709,1.3293,2.8148,2.855,3.9392,2.855,3.9392,0,0,7.9809,3.8378,10.8376,4.672,1.7046,.4977,3.9841,1.2524,5.2251,3.0532,.3541,.5149,.6761,1.1256,.9575,1.8486,.231,.5933,.2438,1.941,.2442,1.9703l-20.0945,.2551s-2.0655-1.1685-2.9356-1.1259c-1.5489,.0759-2.2252,1.4349-2.2252,1.4349l-7.30616,.1472s-.15521-1.1257-.37826-2.7641l-.00187-.0005c-.1003-.7381-.21417-1.5796-.33628-2.4687-.52071-3.8147-.09936-8.3733-.23827-9.4532-.2428-1.8921,2.33378,.0246,2.33378,.0246Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 144.613px 449.77px;" id="el2numlx42kpy"
                        class="animable"></path>
                      <path
                        d="M148.4208,448.8659c-.0917-.08-.2307-.0861-.3341-.0191-.4747,.3067-.8696,.7343-1.133,1.266-.1389,.2803,.3019,.539,.4422,.2561,.234-.4725,.5795-.8506,.994-1.1194,.1385-.0899,.1578-.2726,.0349-.38l-.004-.0036Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 147.717px 449.646px;" id="elu5og3krhe2o"
                        class="animable"></path>
                      <path
                        d="M146.8594,448.0515c-.0917-.0801-.2307-.0863-.3342-.0194-.4744,.307-.8695,.7346-1.133,1.266-.1388,.2803,.3021,.5391,.4422,.2563,.2342-.4725,.5796-.8504,.9942-1.1193,.1384-.09,.1578-.2727,.0347-.3801l-.0039-.0035Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 146.155px 448.832px;" id="el3cu6zy06vdf"
                        class="animable"></path>
                      <path
                        d="M145.2508,447.294c-.0918-.0802-.2306-.0861-.3342-.0191-.4744,.3066-.8695,.7345-1.1331,1.2659-.1387,.2804,.3021,.5391,.4423,.2562,.2341-.4724,.5794-.8505,.9941-1.1194,.1385-.0899,.1577-.2727,.0348-.38l-.0039-.0036Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 144.547px 448.074px;" id="el1wruvz5hu3c"
                        class="animable"></path>
                    </g>
                    <g id="elgqrvksvl92n">
                      <path
                        d="M160.7287,455.6951c.1453,.2862,.2853,.5885,.4146,.9205,.231,.5936,.2438,1.941,.2442,1.9703l-20.0945,.2553s-2.0655-1.1686-2.9357-1.1259c-1.5488,.0758-2.2251,1.4349-2.2251,1.4349l-7.3062,.1472s-.15516-1.1259-.37812-2.7641l-.00201-.0005c-.01744-.129-.03752-.2766-.05594-.4114l32.33877-.4263Z"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 144.889px 457.496px;"
                        class="animable"></path>
                    </g>
                    <path
                      d="M144.86621,448.02344l-.04688-.46875c-.06982-.69727-.14209-1.41699-.08789-2.13867,.06348-.83301,.2915-1.53906,.67676-2.09863,.28662-.41504,.64014-.61719,1.00098-.61719,.47266,.02344,.79541,.43359,.93994,.81055,.24463,.63574,.19385,1.40527-.13965,2.11133-.31348,.66211-1.49023,1.68848-1.98145,2.09863l-.36182,.30273Zm1.50244-4.82422c-.23584,0-.44434,.25-.54883,.40137-.33447,.48633-.53271,1.10938-.58936,1.85254-.03955,.51953-.00781,1.0498,.03955,1.57324,.63232-.55762,1.30225-1.22656,1.4873-1.61816,.27539-.58203,.3208-1.20898,.125-1.71777-.08398-.21973-.26465-.47949-.49756-.49023l-.01611-.00098Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 146.107px 445.362px;" id="elfka5lgevjcu"
                      class="animable"></path>
                    <path
                      d="M145.40137,447.86035l-.46338-.08398c-.49121-.08887-2.14062-.41309-2.75879-.84766-.63867-.44922-1.06299-1.09375-1.16406-1.76758-.06006-.39941,.01807-.91504,.41748-1.16797,.3042-.19238,.7207-.17773,1.17432,.04199,.61279,.29688,1.15918,.79883,1.625,1.49121,.40332,.59961,.6958,1.26172,.979,1.90332l.19043,.43066Zm-3.47754-3.50293c-.08008,0-.15771,.01562-.22363,.05762-.19678,.125-.22559,.43945-.19092,.67188,.08105,.53906,.43018,1.06152,.95752,1.43359,.35547,.24902,1.26709,.5,2.09131,.67188-.2168-.47852-.45117-.95605-.74121-1.38672-.41553-.61816-.896-1.0625-1.42822-1.32129-.11426-.05469-.29541-.12695-.46484-.12695Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 143.199px 445.86px;" id="elnd1pbyuwrpk"
                      class="animable"></path>
                  </g>
                  <g id="el40wlktdhqm" class="animable" style="transform-origin: 88.4089px 442.564px;">
                    <polygon
                      points="77.8965 421.0964 75.0866 445.3767 85.0156 448.5605 91.3241 423.397 77.8965 421.0964"
                      style="fill: rgb(224, 224, 224); transform-origin: 83.2053px 434.828px;" id="elfpbwbqn78lp"
                      class="animable"></polygon>
                    <g id="elogbrrtu99nm" class="animable" style="transform-origin: 88.4144px 452.724px;">
                      <path
                        d="M76.5109,444.1937c1.1241,1.5119,4.1143,1.8748,5.6371,1.0829l.3985-1.9189c.5426-1.5796,1.2685-2.2393,2.9739-1.6929,.8363,.2667,1.7194,1.5043,2.1396,3.189,.7532,3.0204,2.0296,4.4214,2.0296,4.4214,0,0,7.0764,5.3242,9.7149,6.701,1.5743,.8213,3.6623,2.0071,4.5272,4.0158,.2466,.5743,.4429,1.2361,.5775,2.0002,.1106,.627-.1404,1.9512-.1457,1.98l-19.7565-3.6789s-1.7972-1.5499-2.6588-1.6783c-1.5338-.2284-2.4628,.9721-2.4628,.9721l-7.1939-1.2843s.0679-1.1343,.1695-2.7846l-.0017-.0009c.0459-.7435,.0988-1.591,.1529-2.4868,.2353-3.8429,1.5398-8.2311,1.6147-9.3172,.1319-1.9032,2.284,.4804,2.284,.4804Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 88.4144px 452.724px;" id="elqh9xbtwhyt"
                        class="animable"></path>
                      <path
                        d="M93.5367,451.9634c-.0742-.0964-.2093-.1296-.3239-.0842-.5255,.208-.9964,.5502-1.3587,1.02-.191,.2478,.1907,.5877,.3836,.3377,.322-.4176,.7347-.7209,1.1937-.9035,.1534-.061,.2081-.2365,.1086-.3658l-.0033-.0042Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 92.6961px 452.592px;" id="el26dusupxnks"
                        class="animable"></path>
                      <path
                        d="M92.1648,450.8593c-.0743-.0965-.2094-.1297-.324-.0843-.5253,.2082-.9964,.5503-1.3587,1.02-.1909,.2477,.1908,.5877,.3836,.3378,.322-.4176,.7347-.7207,1.1938-.9033,.1534-.0612,.2081-.2366,.1084-.366l-.0031-.0042Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 91.3242px 451.488px;" id="el5np0gu0p6pu"
                        class="animable"></path>
                      <path
                        d="M90.7353,449.8019c-.0743-.0966-.2093-.1296-.324-.0841-.5252,.2079-.9963,.5503-1.3587,1.02-.1909,.2478,.1908,.5877,.3836,.3376,.3219-.4175,.7346-.7207,1.1938-.9033,.1534-.0611,.2079-.2367,.1085-.3659l-.0032-.0043Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 89.8946px 450.43px;" id="el29cnu4jzx7e"
                        class="animable"></path>
                    </g>
                    <g id="elhh02ks27ou4">
                      <path
                        d="M104.2717,461.0673c.0866,.3091,.1647,.6329,.2266,.9838,.1105,.6273-.1404,1.9512-.1458,1.98l-19.7565-3.6788s-1.7971-1.5499-2.6588-1.6782c-1.5338-.2285-2.4628,.9721-2.4628,.9721l-7.1939-1.2843s.068-1.1344,.1696-2.7846l-.0018-.0009c.0081-.1299,.0172-.2786,.0255-.4144l31.7979,5.9053Z"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 88.4034px 459.597px;"
                        class="animable"></path>
                    </g>
                    <path
                      d="M90.21533,450.44141l.04541-.46973c.06836-.69727,.13818-1.41797,.33301-2.11328,.2251-.80469,.58643-1.45215,1.07373-1.92578,.36279-.35156,.75244-.49512,1.10303-.4082,.4585,.11426,.69482,.58008,.7627,.97754,.11523,.67188-.08545,1.41699-.55029,2.04395-.45068,.60547-1.91553,1.43164-2.35352,1.66992l-.41406,.22559Zm2.34766-4.44238c-.21094,0-.42822,.17676-.54688,.29297-.42334,.41113-.74023,.9834-.94092,1.70117-.13965,.5-.2124,1.02637-.26855,1.54883,.72852-.42188,1.51562-.94629,1.7749-1.29492,.38379-.5166,.55127-1.12207,.4585-1.66113-.03955-.23047-.16553-.52051-.3916-.57715-.02832-.00684-.05664-.00977-.08545-.00977Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 91.8902px 447.971px;" id="el44bevylxauh"
                      class="animable"></path>
                    <path
                      d="M90.77295,450.3877l-.43848-.17383c-.5957-.23535-2.03564-.84082-2.54053-1.37012-.53809-.56543-.82812-1.28027-.79541-1.96191,.01855-.40332,.19629-.89355,.6377-1.06445,.3374-.12891,.74316-.03223,1.14355,.27148,.54297,.41113,.98047,1.00977,1.30176,1.78027,.27979,.6709,.43652,1.37598,.58887,2.05762l.10254,.46094Zm-2.8252-4.12695c-.04541,0-.08984,.00684-.13232,.02344-.2168,.08398-.30664,.38672-.31787,.62109-.02588,.5459,.21436,1.12598,.65869,1.59375,.2998,.31348,1.125,.73047,1.91992,1.06836-.11865-.51074-.25537-1.02441-.45654-1.50586-.28613-.6875-.67041-1.2168-1.1416-1.57422-.12158-.0918-.33301-.22656-.53027-.22656Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 88.8845px 448.075px;" id="ele1rl3f1rdy"
                      class="animable"></path>
                  </g>
                  <g id="elldacgz5046" class="animable" style="transform-origin: 107.497px 356.747px;">
                    <path
                      d="M142.4552,369.7859c1.004,38.483-1.4503,71.4652-1.4503,71.4652l-14.98621,.9206s-8.44136-49.9336-5.03259-70.3314c-.16115-4.2883-5.63998-57.9153-7.5241-68.0524-3.4088-18.1975-7.0531-30.6809-7.0531-30.6809l35.2653-1.7849s.1612,74.238,.781,98.4638Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 124.553px 356.747px;" id="el83ltly26pf7"
                      class="animable"></path>
                    <g id="elp48c5w29ylo">
                      <path
                        d="M142.4552,369.7859c1.004,38.483-1.4503,71.4652-1.4503,71.4652l-14.98621,.9206s-8.44136-49.9336-5.03259-70.3314c-.16115-4.2883-5.63998-57.9153-7.5241-68.0524-3.4088-18.1975-7.0531-30.6809-7.0531-30.6809l35.2653-1.7849s.1612,74.238,.781,98.4638Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 124.553px 356.747px;"
                        class="animable">
                      </path>
                    </g>
                    <g id="el3vw7783fusp">
                      <path
                        d="M122.40863,288.8953s-1.14039,42.1539-3.17326,65.2684c-1.05363-12.4946-3.81783-38.4381-5.70197-48.5752l8.87523-16.6932Z"
                        style="opacity: 0.3; transform-origin: 117.971px 321.529px;" class="animable"></path>
                    </g>
                    <path
                      d="M72.298,442.1717l16.299,3.8916s13.1202-51.0231,19.2312-75.9899c5.925-27.8294,16.98186-97.2851,16.98186-97.2851l-31.33636-5.3578c-15.0482,24.6187-6.5444,86.9376-9.2095,103.44-8.5085,23.5951-11.9662,71.3012-11.9662,71.3012Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 98.554px 356.747px;" id="elud3rx6ahnt"
                      class="animable"></path>
                    <g id="eljha9qs4u7ic">
                      <path
                        d="M72.298,442.1717l16.299,3.8916s13.1202-51.0231,19.2312-75.9899c5.925-27.8294,16.98186-97.2851,16.98186-97.2851l-31.33636-5.3578c-15.0482,24.6187-6.5444,86.9376-9.2095,103.44-8.5085,23.5951-11.9662,71.3012-11.9662,71.3012Z"
                        style="isolation: isolate; opacity: 0.2; transform-origin: 98.554px 356.747px;"
                        class="animable">
                      </path>
                    </g>
                  </g>
                  <g id="elmqqfd47yatm" class="animable" style="transform-origin: 122.084px 218.828px;">
                    <g id="elig8t2r7xvu" class="animable" style="transform-origin: 144.098px 224.691px;">
                      <path
                        d="M130.46614,188.526c1.85315-4.0907,6.66476-6.2791,10.75546-4.4259,1.9159,.8679,3.4278,2.8189,4.206,4.773l.1735,.4339c1.0719,3.0307,1.4942,6.2531,1.2395,9.4578-.0743,3.1608-.1735,6.3713-.1239,9.433s0,14.1185,.0991,17.1182c.0992,2.7766,5.7144,15.3333,11.156,33.468,.1106,.3486,.2473,.6884,.4091,1.0164l1.004,1.9957s-1.4998,.9545-3.0369,1.8717c-1.2444,.936-2.6074,1.7029-4.0533,2.2808-.8082-.7325-1.4761-1.6062-1.9709-2.5783-4.3385-6.8423-18.50657-32.2284-19.25029-35.947-.73133-3.446-1.23955-14.8747-1.56183-18.3454-.32228-3.4708-.57021-6.8548-.66935-10.2759-.07634-1.7173-.00999-3.4379,.19833-5.1442,.21899-1.7756,.69935-3.5091,1.42548-5.1441v.0123Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 144.098px 224.691px;" id="el7ld7t1n6o6t"
                        class="animable"></path>
                      <g id="elzizakvsbw2f">
                        <path
                          d="M130.46614,188.526c1.85315-4.0907,6.66476-6.2791,10.75546-4.4259,1.9159,.8679,3.4278,2.8189,4.206,4.773l.1735,.4339c1.0719,3.0307,1.4942,6.2531,1.2395,9.4578-.0743,3.1608-.1735,6.3713-.1239,9.433s0,14.1185,.0991,17.1182c.0992,2.7766,5.7144,15.3333,11.156,33.468,.1106,.3486,.2473,.6884,.4091,1.0164l1.004,1.9957s-1.4998,.9545-3.0369,1.8717c-1.2444,.936-2.6074,1.7029-4.0533,2.2808-.8082-.7325-1.4761-1.6062-1.9709-2.5783-4.3385-6.8423-18.50657-32.2284-19.25029-35.947-.73133-3.446-1.23955-14.8747-1.56183-18.3454-.32228-3.4708-.57021-6.8548-.66935-10.2759-.07634-1.7173-.00999-3.4379,.19833-5.1442,.21899-1.7756,.69935-3.5091,1.42548-5.1441v.0123Z"
                          style="isolation: isolate; opacity: 0.3; transform-origin: 144.098px 224.691px;"
                          class="animable">
                        </path>
                      </g>
                    </g>
                    <rect x="124.6148" y="249.311" width="41.5901" height="33.2752" rx="2.658" ry="2.658"
                      style="fill: rgb(38, 50, 56); transform-origin: 145.41px 265.949px;" id="elofkbrhdo04"
                      class="animable"></rect>
                    <g id="ely3fxijsdi5">
                      <rect x="124.6148" y="249.311" width="41.5901" height="33.2752" rx="2.658" ry="2.658"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.3; transform-origin: 145.41px 265.949px;"
                        class="animable"></rect>
                    </g>
                    <g id="el7kp9p5xx6am" class="animable" style="transform-origin: 113.492px 229.761px;">
                      <path
                        d="M84.4303,203.0008c2.1527,34.9282,10.0279,49.5877,8.9201,77.4947,0,0,44.6903,1.2411,46.2262,1.2411,.6546-51.4171-1.2211-47.5917,2.9845-79.6869,.277-10.638-1.8271-16.781-6.1955-17.8448l-19.96505-6.4195-23.51305,8.9487c-4.8386,1.1648-8.8949,9.3808-8.4572,16.2667Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 113.492px 229.761px;" id="elex4ko3rn38i"
                        class="animable"></path>
                      <g id="eleycokef9cfa">
                        <path
                          d="M84.4303,203.0008c2.1527,34.9282,10.0279,49.5877,8.9201,77.4947,0,0,44.6903,1.2411,46.2262,1.2411,.6546-51.4171-1.2211-47.5917,2.9845-79.6869,.277-10.638-1.8271-16.781-6.1955-17.8448l-19.96505-6.4195-23.51305,8.9487c-4.8386,1.1648-8.8949,9.3808-8.4572,16.2667Z"
                          style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 113.492px 229.761px;"
                          class="animable"></path>
                      </g>
                    </g>
                    <path
                      d="M130.59796,190.7175c1.39545,16.367-.49199,32.8719-5.56106,48.4755l3.71867,41.4596c.73133,6.0125,5.30163,11.3277,8.63603,11.6481l6.8423-.564-1.8175-43.1066c-.0089-.1169-.0148,.1163,0,0,.3792-2.9823,4.0423-44.5414,2.8004-54.0503-.8552-6.5125-2.9377-9.0509-4.4252-10.3713-.6382-.5546-1.6508-.7037-2.4683-.8725-2.603-.564-10.50962-2.137-10.16254-1.996,.10412,.0423,.38683,.6061,.65092,1.3299,1.00884,2.7648,1.5353,5.1039,1.78628,8.0476Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 135.255px 236.816px;" id="el8y2dtmuzaic"
                      class="animable"></path>
                    <path
                      d="M109.1786,295.2206c11.15594,.8049,13.49075-4.4666,15.04019-13.7299,0,0,2.95015-33.2132,2.36756-40.5631,0,0-2.51138-13.9913-6.12164-27.4346-2.87518-12.6446-9.88571-33.1438-11.88141-32.9854-3.8302,.2771-17.051,3.1021-20.539,5.3835-3.0741,4.3413,.8586,11.4121,1.5899,16.7959,2.3304,17.2466,2.1618,21.5197,3.0419,38.9114,0,0-13.825,49.899-12.6846,50.0573,.8677,.0924,20.2871,2.9183,29.1871,3.5649Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 103.294px 237.904px;" id="el0f38q4ia6muw"
                      class="animable"></path>
                    <g id="elsm6cmoyvpyi">
                      <path
                        d="M95.0893,256.6897h19.0395v.8552c0,2.5193-2.0422,4.5616-4.5615,4.5616h-9.9165c-2.5192,0-4.5615-2.0423-4.5615-4.5616v-.8552Z"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 104.609px 259.398px;"
                        class="animable"></path>
                    </g>
                    <g id="el85uwj4tcxaj">
                      <path
                        d="M143.0419,263.4655l-2.9239-.0035c-2.5197,.098-4.6459-1.8566-4.7598-4.3756v-.8429l7.4503-.3136,.2334,5.5356Z"
                        style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 139.2px 260.698px;"
                        class="animable"></path>
                    </g>
                    <g id="elazalyo3u7a" class="animable" style="transform-origin: 123.361px 160.825px;">
                      <path
                        d="M119.52757,135.9667c-1.22576-.4957-4.69807-.4475-5.88707,.1946-3.2431,1.3901-5.1206,2.9192-5.6721,6.9011-.362,.6521-.5603,1.3857-.5761,2.1314-.0469,.7705,.3615,1.4813,1.0302,1.793,1.2245-.7809,2.6116-1.2294,4.0312-1.3035,2.87243-.1953,5.7496-.1222,8.59699,.2183,5.18913,.5504,10.41963,.6985,15.64861,.443,1.0347-.0384,2.2458-.1957,2.7721-1.1289,.5264-.9331-1.0881-3.1264-.6642-3.3603,.6693-.3946,1.525-.6783,1.731-1.4405,.0743-.3044,.0757-.6214,.0042-.9228-.1342-1.1054-.6857-2.099-1.5329-2.762-.9044-.6308-2.144-.3808-2.7923,.563,.0336-.3227,.0612-.6459,.0828-.9693,.086-1.2865-.5695-2.491-1.66485-3.1713l-.01228-.0077c-1.2511-.7769-3.97295-.8089-5.31599-.146-1.32323,.6636-2.49876,1.7031-3.55452,2.7733-2.04403,1.5027-2.47543,1.1073-6.22479,.1946Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 123.992px 139.758px;" id="elsth593wmhwm"
                        class="animable"></path>
                      <g id="elye64ym2m4vc" class="animable" style="transform-origin: 118.122px 174.371px;">
                        <path
                          d="M112.9931,179.8378s.6311-4.5327-2.3459-15.6549c.0008-.0118,.5881-.2581,.5889-.27-.4097-1.5552-.8506-2.9937-1.2683-4.2415-.0094-.0361-.0086-.0481-.0086-.0481l14.9297,9.6589c-.4282,3.5281,.69972,10.3861,1.39689,13.2867l-4.07848,6.5504-9.21421-9.2815Z"
                          style="fill: rgb(238, 193, 187); transform-origin: 118.122px 174.371px;" id="elvyitq1flioh"
                          class="animable"></path>
                        <g id="elnh29zowj92r">
                          <path
                            d="M125.04579,174.2584l-8.66327-2.1419c.73393,2.7232,3.06302,5.9809,9.11451,6.3679,0,0-.54127-3.0417-.45124-4.226Z"
                            style="opacity: 0.2; transform-origin: 120.94px 175.3px;" class="animable"></path>
                        </g>
                      </g>
                      <path
                        d="M135.8816,143.3683c1.7076,3.5354,2.18,11.2429-3.35912,9.524-5.53895-1.7188,.42372-15.6011,3.35912-9.524Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 133.641px 147.489px;" id="elupkwl09ns4k"
                        class="animable"></path>
                      <path
                        d="M109.994,152.2742c.8217,9.6088,1.0913,15.0446,6.0782,19.8224,7.50127,7.1867,19.0179,2.6097,21.0888-7.0793,1.864-8.721,.2073-22.9694-9.32064-26.4826-9.38707-3.4612-18.66816,4.1307-17.84636,13.7395Z"
                        style="fill: rgb(238, 193, 187); transform-origin: 123.889px 156.646px;" id="eltuj37bfauep"
                        class="animable"></path>
                      <path
                        d="M123.83424,154.7224c.02354,.9848,.56503,1.7654,1.20966,1.7437,.64441-.0217,1.14784-.8378,1.12418-1.8225-.02344-.9848-.56503-1.7654-1.20933-1.7436-.64452,.0217-1.14784,.8376-1.12451,1.8224Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 125.001px 154.683px;" id="el1ugdjhlfhzo"
                        class="animable"></path>
                      <path
                        d="M133.49668,154.6832c.02333,.9847,.56492,1.7654,1.20944,1.7437,.64448-.0217,1.14788-.8377,1.12438-1.8226-.0235-.9847-.565-1.7654-1.20942-1.7435-.64464,.0219-1.14806,.8376-1.1244,1.8224Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 134.664px 154.644px;" id="eldyurkypvfi7"
                        class="animable"></path>
                      <path
                        d="M130.34142,154.2124s3.23449,4.8184,4.28145,6.7868c-1.34099,1.2709-3.71568,.7567-3.71568,.7567l-.56577-7.5435Z"
                        style="fill: rgb(223, 138, 138); transform-origin: 132.482px 158.036px;" id="eleoqc2eb5x0i"
                        class="animable"></path>
                      <path
                        d="M121.57473,149.3871c.1545,.0442,.32846,.0064,.45285-.115,1.47994-1.4396,3.14354-1.098,3.16026-1.0946,.24866,.0541,.4965-.1032,.55306-.3509,.05634-.2479-.10066-.4928-.34797-.5473-.08767-.0195-2.1723-.4483-4.00818,1.3384-.1833,.1783-.18795,.4695-.01054,.6503,.05746,.0586,.12657,.0981,.20052,.1191Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 123.498px 148.313px;" id="elkpx2g6gvsi9"
                        class="animable"></path>
                      <path
                        d="M137.7033,150.3354c.0841-.0247,.1622-.0733,.2234-.1457,.1649-.1951,.143-.4871-.0497-.6516-1.9328-1.6525-3.96424-1.047-4.05867-1.0317-.24328,.0741-.3833,.3318-.31208,.5761,.07117,.2437,.32417,.3817,.56647,.3108,.08367-.0286,1.65448-.4747,3.20588,.8514,.1213,.1039,.2817,.1324,.4247,.0907Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 135.762px 149.37px;" id="ell4uimz3ssv"
                        class="animable"></path>
                      <g id="elsfu74wewmul" class="animable" style="transform-origin: 126.933px 164.735px;">
                        <g id="el3122gxded0x" class="animable" style="transform-origin: 126.933px 164.735px;">
                          <path d="M127.96146,168.117c-.0883-.0234,.03064,.0125,0,0h0Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 127.947px 168.114px;" id="elpyrb1ki73vg"
                            class="animable"></path>
                          <path
                            d="M129.54552,164.4311c-1.53417-.5241-4.36082-1.6233-5.70889-3.105,0,0-.25781,.4175-.4165,1.0538-.14263,.5482-.22965,1.2478-.06268,1.9788,.31073,1.3353,1.4405,2.7972,4.54502,3.7373,.04314,.0144,.05057,.0176,.05899,.021l.03148,.0081s1.67419,.4697,2.60591-3.357c0,0-.41322-.1109-1.05333-.337Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 126.933px 164.735px;" id="elsbiqc8uw6o"
                            class="animable"></path>
                        </g>
                        <path
                          d="M129.54552,164.4311l-.61921,1.0255c-2.92185-.8926-4.85908-1.9131-5.50606-3.0768,.15857-.6362,.41648-1.0536,.41648-1.0536,1.34797,1.4816,4.17461,2.581,5.70879,3.1049Z"
                          style="fill: rgb(255, 255, 255); transform-origin: 126.483px 163.391px;" id="elxc42k86eh6s"
                          class="animable"></path>
                        <path
                          d="M127.90224,168.0959c-3.10429-.94-4.23406-2.4019-4.54468-3.7372,1.04545,.3613,2.26592,.9129,3.24009,1.7271,.7475,.6195,1.1173,1.3387,1.30459,2.0101Z"
                          style="fill: rgb(220, 87, 83); transform-origin: 125.63px 166.227px;" id="eliiyb8h3ivod"
                          class="animable"></path>
                      </g>
                      <path
                        d="M135.4287,142.0839c-1.44171-1.3135-3.22517-2.1881-5.0261-2.9367-2.98863-1.2421-6.12462-2.1909-9.34777-2.4837-3.22322-.2928-6.54553,.0921-9.49833,1.4174-.6457,.2898-1.2882,.636-1.7475,1.1745-.436,.5112-1.2256,1.3219-1.4365,1.9599-1.2831,3.8809-2.2452,6.7335-1.1108,14.641l7.7072,5.4698c.84058-.4327,1.63727-.8824,2.10745-1.7028,.43741-.7631-.63577-3.3624-.69385-4.2403-.06027-.9108-.58251-2.0955-.73981-2.9947,.84483,.4493,1.20027,.1481,1.68747-.6754,.72836-1.2311,.71947-2.7485,.65062-4.1773,1.08463,.2578,2.3246-.0374,3.0611-.8745,.73642-.8371,.83624-2.219,.10878-3.0639,3.52684,.5577,7.16299,.4152,10.63528-.4164-.24839,.435,.01597,.9789,.31189,1.3832,1.19945,1.6388,3.02127,2.8101,5.00997,3.2212,.292,.0602,.6559,.0778,.8244-.1681,.0898-.131,.0938-.3008,.088-.4595-.0715-1.9491-1.1497-3.7603-2.5915-5.0737Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 122.39px 148.949px;" id="el8rvbz9ueprf"
                        class="animable"></path>
                      <path
                        d="M106.3858,160.387c.8286,2.4592,2.7204,4.2953,4.6522,5.1976,2.9057,1.3577,4.91096-1.1252,4.56618-4.1505-.31041-2.7228-2.19618-6.7105-5.33528-6.7993-3.0927-.0874-4.8304,2.9406-3.8831,5.7522Z"
                        style="fill: rgb(238, 193, 187); transform-origin: 110.885px 160.299px;" id="el91dit4diasp"
                        class="animable"></path>
                      <g id="el9rq7dknrmbt">
                        <path
                          d="M112.98242,160.70068c-.12256,0-.22998-.09082-.24707-.21582-.10791-.78467-.4209-1.53076-.90527-2.15723-.31299-.40527-.83203-.9209-1.52734-1.07422-.61963-.13867-1.27881,.09668-1.57227,.55518-.07471,.1167-.23047,.15039-.34521,.07568-.11621-.07422-.15039-.229-.07568-.34521,.40869-.63867,1.27148-.95801,2.10107-.77393,.84131,.18604,1.45166,.78613,1.81494,1.25684,.5376,.6958,.88525,1.52344,1.00488,2.39453,.01904,.13672-.07666,.26318-.21338,.28174-.01172,.00146-.02295,.00244-.03467,.00244Z"
                          style="opacity: 0.2; transform-origin: 110.751px 158.707px;" class="animable"></path>
                      </g>
                    </g>
                    <g id="elszvtflhzvul">
                      <path
                        d="M92.8155,190.6623s-5.3238,3.8552-6.3334,15.6959c-1.1469,13.4496-3.7473,45.7263-3.7473,47.724s15.8782,43.9065,15.8782,43.9065l13.5469-6.3042-11.5243-41.753s.5229-42.5302-.7083-48.2787c-.9008-4.2055-7.1118-10.9905-7.1118-10.9905Z"
                        style="opacity: 0.3; transform-origin: 97.4473px 244.325px;" class="animable"></path>
                    </g>
                    <path
                      d="M97.283,289.5165c-.4636,.8328-.0922,8.8279,.3321,10.8094,.4242,1.9815,1.1086,2.9078,2.0937,2.8234s2.0591-.2502,2.237-.413,.5984,1.9039,2.2316,1.6348c1.6332-.269,2.0448-.8908,2.0448-.8908,0,0,1.5239,2.2009,3.3464,1.5135,1.8223-.6873,1.8307-1.8389,2.1345-1.9535,.3036-.1146-.5713-3.4814-.5713-3.4814l.7547,.1959c1.5269,.3961,2.9516-.978,2.5654-2.5075l-.0151-.0594c-.5094-1.9852-4.0133-5.7243-4.0133-5.7243l-3.8046-2.7285-3.238-5.9488s-6.5998,4.5746-6.5222,4.7488c.0776,.174,.4243,1.9814,.4243,1.9814Z"
                      style="fill: rgb(238, 193, 187); transform-origin: 105.687px 293.957px;" id="el5jivl21r2on"
                      class="animable"></path>
                    <path
                      d="M88.0443,185.8911s-5.3238,3.8552-6.3334,15.6959c-1.1469,13.4496-3.7473,45.7263-3.7473,47.724s15.8782,43.9065,15.8782,43.9065l13.5469-6.3042-11.5243-41.753s3.7485-43.3359,4.592-47.5533-12.4121-11.7159-12.4121-11.7159Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 92.6761px 239.554px;" id="el64tnc3r9nhr"
                      class="animable"></path>
                    <g id="elyme42yzb25">
                      <polygon
                        points="85.0691 269.7553 95.3486 293.7179 91.4911 293.2175 84.0774 273.7623 85.0691 269.7553"
                        style="opacity: 0.3; transform-origin: 89.713px 281.737px;" class="animable"></polygon>
                    </g>
                    <g id="elhiio682v4ui">
                      <path
                        d="M122.00966,219.5293c-.48727-1.9919-1.00345-4.0199-1.54497-6.0363-1.10992-4.8813-2.83647-10.9308-4.65253-16.5128-.91116-2.6601-2.06346-6.012-3.19016-9.0265-1.7433-4.4708-3.2666-7.5073-4.0386-7.4461-.327,.0236-.7432,.0721-1.1952,.1309-.0108,.0718-2.1991,14.5833,.6734,16.1947,2.8796,1.6153,4.9866,2.8795,4.9866,2.8795,0,0-1.9666,4.6355-1.9666,6.6723,0,1.7142,8.29918,10.4273,10.92806,13.1443Z"
                        style="opacity: 0.3; transform-origin: 114.295px 200.018px;" class="animable"></path>
                    </g>
                    <g id="elsi7vi41o4hp">
                      <path
                        d="M129.84713,219.5293s5.15018-11.7792,6.04017-14.9435c.8899-3.1643-3.0201-5.7353-3.0201-5.7353,0,0,1.33904-2.571,1.83346-4.3509,.49444-1.7799-3.02597-13.3652-6.75382-13.1595,0,0,.94945,1.68,1.68487,4.5494,.69533,2.7131,1.17732,6.4998,1.22837,10.5871,.04167,3.3365,.18624,7.7434-.0109,11.7383-.29978,6.0748-1.00205,11.3144-1.00205,11.3144Z"
                        style="opacity: 0.3; transform-origin: 131.983px 200.433px;" class="animable"></path>
                    </g>
                  </g>
                </g>
              </g>
              <defs>
                <filter id="active" height="200%">
                  <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
                  <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
                  <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
                  <feMerge>
                    <feMergeNode in="OUTLINE"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                  </feMerge>
                </filter>
                <filter id="hover" height="200%">
                  <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
                  <feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
                  <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
                  <feMerge>
                    <feMergeNode in="OUTLINE"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                  </feMerge>
                  <feColorMatrix type="matrix"
                    values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 ">
                  </feColorMatrix>
                </filter>
              </defs>
            </svg>
          </div>
          <div class="data-col-1 flex-fill">

            <app-readonly title="Dealer group name" [value]="model.dealerGroupName" icon="business"
              class="p-1 flex-fill"></app-readonly>

            <app-readonly title="Dealer Group AFSL" [value]="model.dealerGroupAFSL" icon="tag"
              class="p-1 flex-fill"></app-readonly>

            <app-readonly title="Dealer Group phone" [value]="model.dealerGroupPhone" icon="phone"
              class="p-1 flex-fill"></app-readonly>

            <app-readonly title="Dealer Group Address" [value]="helper.formatAddress(model.dealerGroupAddress)"
              icon="room" class="p-1 flex-fill"></app-readonly>

          </div>
          <div class="data-col-2 flex-fill">
            <app-readonly title="Dealer Group number" [value]="model.dealerGroupNumber" icon="tag"
              class="p-1 flex-fill"></app-readonly>

            <app-readonly title="ABN" [value]="model.abn" icon="tag" class="p-1 flex-fill"></app-readonly>

            <app-readonly title="Dealer Group email" [value]="model.dealerGroupEmail" icon="email"
              class="p-1 flex-fill"></app-readonly>

          </div>

        </div>
      </div>

    </app-component-wrapper>
    <div class="d-flex flex-row contact-list-wrapper">
      <app-component-wrapper icon="people" header="Contact list ({{model.contacts.length}})" class="m-3 flex-fill">
        <div class="d-flex flex-column" slot="header">
          <div class="d-flex flex-row align-items-center header-option">
            Add new contact
            <mat-icon>add</mat-icon>
          </div>

        </div>
        <div class="d-flex flex-column" slot="start">
          <div class="contacts">
            <div *ngFor="let item of model.contacts">
              <div class="card p-3 " [ngClass]="{'active': (selectedContact$ | async)?.id == item.id}"
                (click)="onSelectContactClicked(item)">
                <div class="d-flex flex-row align-items-center">
                  <div class="mr-4">
                    <mat-icon class="status {{item.isActive?'active':'inactive'}}">fiber_manual_record</mat-icon>
                  </div>

                  <div class="w-25">

                    <h4 class="name d-flex flex-row align-items-center">
                      {{item.firstName}} {{item.lastName}}
                    </h4>
                    <div class="position d-flex flex-row align-items-center">
                      <mat-icon class="mr-2">work</mat-icon>
                      {{item.position}}
                    </div>

                  </div>
                  <div class="flex-fill">
                    <a href="mailto:{{item.email}}"> {{item.email}}</a>
                  </div>
                  <div class="w-25">
                    <div *ngIf="item.isPrimary" class="badge badge-primary" style="width: fit-content;">Primary contact
                    </div>
                  </div>
                  <div>
                    <mat-icon>navigate_next</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </app-component-wrapper>

      <app-component-wrapper icon="" header="" class="component-wrapper-contact m-3" style="width: 600px;">
        <div class="d-flex flex-column" slot="start">
          <div class="contact-info" *ngIf="selectedContact$ | async; let contact">
            <div class="background"></div>
            <div class="info">
              <h4>Contact Details</h4>
              <div class="d-flex flex-column align-items-center w-50 m-auto" appanimatecss>
                <div class="account_circle">
                  <mat-icon>account_circle</mat-icon>
                </div>
                <div>
                  <div class="name d-flex flex-row align-items-center ">

                    {{contact.firstName}} {{contact.lastName}}
                  </div>
                  <div class="position d-flex flex-row align-items-center mb-4">
                    <mat-icon class="mr-2">work</mat-icon>
                    {{contact.position}}
                  </div>
                </div>
                <div class="d-flex flex-column w-100">
                  <div class="d-flex flex-row align-items-center mb-2">
                    <div class="cd-icon mr-3">
                      <mat-icon>email</mat-icon>
                    </div>
                    <div class="cd-details">
                      <a href="mailto:{{contact.email}}"> {{contact.email}}</a>
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-2">
                    <div class="cd-icon mr-3">
                      <mat-icon>phone</mat-icon>
                    </div>
                    <div class="cd-details">
                      <a href="tel:{{contact.phone}}"> {{contact.phone}}</a>
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-2">
                    <div class="cd-icon mr-3">
                      <mat-icon class="status {{contact.isActive?'active':'inactive'}}">fiber_manual_record</mat-icon>
                    </div>
                    <div class="cd-details">
                      {{contact.isActive? 'Contact is active':'Contact not active'}}
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center" *ngIf="contact.isPrimary">
                    <div class="cd-icon mr-3">
                      <mat-icon class="status {{contact.isPrimary?'active':'inactive'}}">fiber_manual_record</mat-icon>
                    </div>
                    <div class="cd-details">
                      {{contact.isActive? 'Contact is primary contact':''}}
                    </div>
                  </div>
                  <div class="mt-4">
                    <app-button class="w-100" title="Deactivate contact" icon="do_not_disturb_alt">
                    </app-button>
                  </div>
                  <!-- <app-readonly title="Email" [value]="contact.email" icon="email" class="p-1 w-50"></app-readonly> -->

                  <!-- <app-readonly title="Position" [value]="contact.position" icon="work" class="p-1 w-50"></app-readonly> -->

                </div>
                <!-- <div class="d-flex flex-row w-100">
                <app-readonly title="Is Active" [value]="contact.isActive" icon="fiber_manual_record"
                  class="p-1 w-50 status {{contact.isActive?'active':'inactive'}}"></app-readonly>

                <app-readonly title="Is Primary" [value]="contact.isPrimary" icon="fiber_manual_record"
                  class="p-1 w-50 status {{contact.isActive?'active':'inactive'}}"></app-readonly>

              </div> -->
              </div>
            </div>
          </div>
        </div>

      </app-component-wrapper>
    </div>


  </div>

</div>
