import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDocumentViewerListState } from './state';
import { featureKey } from './../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';

export const state = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const documentViewerList = createSelector(state, (state) => state.documentViewerListState);

export const documentViewer_List = createSelector(
  documentViewerList,
  (state: IDocumentViewerListState) => state.list
);

export const documentViewer_SelectedFile = createSelector(
  documentViewerList,
  (state: IDocumentViewerListState) => state.selectedFile
);
