
import { IMemberListingWidgetViewState, MemberListingSearchModel } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { NextPageAction, RequestAction, ResponseAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'memberListingWidgetForm';

var helper = new Helper();

export const state: IMemberListingWidgetViewState = {
  list: undefined,
  form: createFormGroupState(formName, new MemberListingSearchModel()),
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.page = payload.length == 0 ? -1 : state.form.value.page;

    return {
      ...state,
      list: state.form.value.page === 0 ? payload : state.list.concat(payload),
      form: createFormGroupState(formName, clone)
    };
  }),
  on(RequestAction, (state, { search, page }) => {
    if (state.form.value.page == -1) return state;
    var clone = helper.clone(state.form.value);
    clone.page = page;

    return {
      ...state,
      list: clone.page == 0 ? undefined : state.list,
      form: createFormGroupState(formName, clone)
    };

  }),
  on(NextPageAction, (state, { }) => {
    if (state.form.value.page == -1) return state;
    var clone = helper.clone(state.form.value);
    clone.page++;

    return {
      ...state,
      form: createFormGroupState(formName, clone)
    };
  }),
);

export function memberListingWidgetViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
