import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccountUnderlyingInvestmentSummaryModel } from 'src/app/model/account-underlying-investment-summary.model';
import { HistoricalGraphModel } from 'src/app/model/historical-graph.model';

//Underlying Investment Summary
export const RequestValuationAction = createAction('[Account Underlying Investment Summary view] get data', props<{  memberId: number, accountId: number, to: string }>());
export const ResponseValuationAction = createAction('[Account Underlying Investment Summary view] set data', props<{ payload: AccountUnderlyingInvestmentSummaryModel }>());

export const InvestmentOptionChangedAction = createAction('[Account Underlying Investment Summary view] investment option', props<{ investmentOptionId: string }>());

export const ShowInvestmentOptionsAction = createAction('[Account Underlying Investment Summary view] show invetsmentsplitn');


export const RequestChartDataAction = createAction('[Historical chart view] get data', props<{ accountId: number,memberId: number, from?: Date, to?: Date }>());
export const ResponseChartDataAction = createAction('[Historical chart view] set data', props<{ payload: HistoricalGraphModel }>());
