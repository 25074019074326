import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class MemberAdviserAuthorityService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService) {
    super(toastr);
  }

  getAdviserAuthority(memberId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/members/${memberId}/adviser-authority`, { headers: this.headers() });
  }
}
