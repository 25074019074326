import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { Helper } from 'src/app/helper/helper-functions';
import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { PreviousFinancialYearContributionTotalModel } from 'src/app/model/contribution-detail.model';

@Component({
    selector: 'app-previous-fy-contribution-summary',
    templateUrl: './previous-fy-contribution-summary.component.html',
    styleUrls: ['./previous-fy-contribution-summary.component.scss']
})
export class PreviousFyContributionSummaryComponent extends ComponentBase implements OnInit, OnDestroy {

    @Input() set fySummary(value: PreviousFinancialYearContributionTotalModel) {
        this.previousFySummary = value;
    }

    previousFySummary: PreviousFinancialYearContributionTotalModel;


    constructor(public store: Store<AppState>,
        public helper: Helper) {
        super();
    }

    ngOnInit() {
        super.ngOnInitBase();
    }

    ngOnDestroy() {
        super.ngOnDestroyBase();
    }
}
