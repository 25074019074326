import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { MemberModel } from '../model/member.model';
import { AppState } from '../store/app.states';
import { commomState_LoggedinInfo } from '../store/common/common.selectors';

@Injectable()
export class Helper {


  constructor() {

  }
  formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  readSCSSProperty(name: string): string {
    const bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue('--' + name);
  }
  primaryColor() {
    return this.readSCSSProperty('PrimaryColor').replace(' ', '');
  }
  secondaryColor() {
    return this.readSCSSProperty('SecondaryColor').replace(' ', '');
  }
  red() {
    return this.readSCSSProperty('Red').replace(' ', '');
  }
  green() {
    return this.readSCSSProperty('Green').replace(' ', '');
  }
  grayColor(val) {
    return this.readSCSSProperty('Gray-' + val).replace(' ', '');
  }

  checkPermission(store: Store<AppState>, value: string, callback: any) {
    var loggeinInInfo$ = store.pipe(select(commomState_LoggedinInfo));

    loggeinInInfo$.subscribe(permissionList => {

      if (!permissionList.permission) return;

      var found = permissionList.permission.filter(x => {
        if (x.toLowerCase() == value.toLowerCase() || x.toLowerCase() == 'super user')
          return callback(true);
      });

      if (found.length == 0) {
        return callback(false);
      }
    })
  }

  nFormatter(num, digits) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "B" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  getValue<T>(observable: Observable<T>): Promise<T> {
    return observable
      .pipe(
        filter(this.hasValue),
        first()
      )
      .toPromise();
  }

  getFinancialYear(date: Date) {
    if (date >= new Date(date.getFullYear(), 6, 1)) {
      return {
        from: new Date(date.getFullYear(), 6, 1),
        to: new Date(date.getFullYear() + 1, 5, 30)
      }
    }
    else {
      return {
        from: new Date(date.getFullYear() - 1, 6, 1),
        to: new Date(date.getFullYear(), 5, 30)
      }
    }
  }

  clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object)) as T;
  }
}

