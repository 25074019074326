import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { LogoutRequestAction } from 'src/app/store/common/common.actions';
import { Router } from '@angular/router';


@Component({
  selector: 'app-expiry-date',
  templateUrl: './expiry-date.component.html',
  styleUrls: ['./expiry-date.component.scss']
})
export class ExpiryDateComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() date = 'No data found';
  expiryInDays: number = 0;
  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  checkExpiryDate() {
    let currentDate = new Date();
    let expiryDate = new Date(this.date);

    let Difference_In_Time =
      expiryDate.getTime() - currentDate.getTime();

    this.expiryInDays =
      Math.round
        (Difference_In_Time / (1000 * 3600 * 24));

    if (this.expiryInDays < 30) {
      return 'badge-danger';
    }
    if (this.expiryInDays < 90) {
      return 'badge-warning';
    }

    return 'badge-info';
  }

}

