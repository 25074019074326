import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-adviser-details-header',
  templateUrl: './adviser-details-header.component.html',
  styleUrls: ['./adviser-details-header.component.scss']
})
export class AdviserDetailsHeaderComponent extends ComponentBase implements OnInit, OnDestroy {

  helper = new Helper();

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onEditDetailsClick()
  {}
}

