<div class="section-title d-flex ">
  Employment History
</div>
<div class="card ">
  <mat-progress-bar *ngIf="(list$|async) == undefined;" mode="indeterminate"></mat-progress-bar>
    <app-no-items message="No employment history found" *ngIf="(list$ |async)?.length === 0"></app-no-items>

  <div class="table-responsive">
    <table class="table table-striped table-borderless" *ngIf="(list$ |async)?.length > 0">
      <thead>
        <tr>
          <th scope="col">Employer Name</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list$ | async">
          <td>{{item.employerName}}</td>
          <td>{{item.startDate | date:'dd/MM/yyyy'}}</td>
          <td>{{item.endDate | date:'dd/MM/yyyy'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
