<div class="section-title d-flex ">
  Document list
</div>
<div class="card docs">
  <div class="row h-100">
    <div class="col-md-4 list">
  <mat-progress-bar *ngIf="(list$|async) == undefined;" mode="indeterminate"></mat-progress-bar>

      <app-no-items message="No document found" *ngIf="(list$ |async)?.length == 0"></app-no-items>

      <div class="communication d-flex flex-row" *ngIf="(list$ |async)?.length > 0">
        <div class="communication-items flex-fill">
          <div *ngFor="let item of list$ | async" (click)="selectPDF(item)">
            <div class="timeline-panel click">
              <div class="timeline-heading mb-1 d-flex align-items-center">
                <mat-icon class="mr-3">picture_as_pdf</mat-icon>
                <div class=" flex-grow-1"><b>{{item.title}}</b>
                  <div class="row">
                    <div class="col-12">
                      <small class="timeline-sub-heading">{{item.correspondenceTypeName}}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <small class="text-muted">Account: {{item.accountNumber}}</small>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="flex-fill">
                      <small class="text-muted d-block ">Created on: {{item.correspondenceDate |
                        date:'dd/MM/yyyy'}}</small>
                    </div>
                    <div class="flex-fill">
                      <small class="text-muted d-block ">Viewed on: {{item.lastOpenedOn | date:'dd/MM/yyyy'}}</small>
                    </div>
                  </div>
                </div>
                <mat-icon class="float-right arrow">keyboard_arrow_right</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 bg-light">
      <app-pdf-viewer [url]="selectedFilePdf$ | async"></app-pdf-viewer>
    </div>
  </div>
</div>
