import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { ViewChild } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";
import { membersOverview_Data } from './selectors';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { RequestAction } from './actions';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-members-chart',
  templateUrl: './members-chart.component.html',
  styleUrls: ['./members-chart.component.scss']
})
export class MembersChartComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(membersOverview_Data));

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor(
    public store: Store<AppState>,
    public apstore: Store<AdvisorPortalSharedState>,
    private router: Router) {
    super();
    this.chartOptions = {
      series: [
        {
          name: "Total members",
          data: []
        },
        {
          name: "Active Members",
          data: []
        },
        {
          name: "Expiring members",
          data: []
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          //endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: ""
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " members";
          }
        }
      }
    };
  }


  ngOnInit() {
    super.ngOnInitBase();
    this.apstore.dispatch(RequestAction());

    this.sub = this.data$.subscribe(x => {
      if (x && x.series.length > 0) {
        this.chart.updateOptions({
          xaxis: {
            categories: x.months as any
          },
          series: x.series as any
        });
      }
    })

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}

