<div id="members-listing-widget">

  <app-entry title="Search" [control]="form.controls.q" icon="search" *ngIf="form$|async; let form">
  </app-entry>

  <mat-progress-bar *ngIf="(memberList$|async) == undefined;" mode="indeterminate"></mat-progress-bar>

  <app-no-items message="No members found" *ngIf="(memberList$|async)?.length == 0"></app-no-items>

  <div class="card" *ngFor="let member of memberList$|async" (click)="onMemberViewClick(member)">
    <div class="d-flex flex-row align-items-center">
      <div class="data-icon">
        <mat-icon>person</mat-icon>
      </div>
      <div class="data-member d-flex flex-column flex-fill">
        <div class="d-flex flex-row data-name">
          {{member.firstName}} {{member.lastName}}

        </div>
        <div class="d-flex flex-row data-other">
          <div class="info-1 d-flex flex-row align-items-center">
            <mat-icon>tag</mat-icon>
            {{member.memberNumber}}
          </div>
          <div class="info-2 d-flex flex-row align-items-center">
            <mat-icon>email</mat-icon>
            {{member.email}}
          </div>

        </div>
      </div>
      <div class="data-selectmember">
        <app-expiry-date [date]="member.expiryDate"></app-expiry-date>
      </div>
      <div class="data-selectmember">
        <mat-icon>chevron_right</mat-icon>

      </div>
    </div>
  </div>
  <app-load-more [page]="(form$ |async).value.page" [listCount]="(memberList$ | async)?.length" (onClick)="onLoadMore()"></app-load-more>

</div>
