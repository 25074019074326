<div *ngIf="!(data$|async)" class="d-flex align-items-center">
    <svg-no-data></svg-no-data>
</div>
<div *ngIf="data$|async; let data">
    <div class="section-title d-flex ">
        Pension Details
    </div>
    <div class="card">
        <div class="d-flex">
            <div style="width: 200px;">
                <svg-elderly></svg-elderly>
            </div>
            <div class="flex-fill d-flex flex-column ml-4">
                <div class="d-flex flex-fill flex-row">
                    <app-readonly class="w-50" title="Pension Account Number" value="{{data.accountNumber}}"></app-readonly>
                    <app-readonly class="w-50" title="Member Age" value="{{data.memberAge}}">
                    </app-readonly>
                </div>
                <div class="d-flex flex-fill flex-row">

                    <app-readonly class="w-50" title="Pension Start Date" value="{{data.pensionStartDate | date:'dd/MM/yyyy'}}">
                    </app-readonly>
                    <app-readonly class="w-50" title="Pension Type" value="{{data.pensionTypeName}}">
                    </app-readonly>
                </div>

            </div>
        </div>

    </div>
    <div class="d-flex flex-row align-items-stretch">
        <div class="flex-fill mr-4">
            <div class="section-title d-flex mt-4">
                Payment Schedule
            </div>
            <div class="card">
                <div class="d-flex flex-row">
                    <app-readonly class="w-50" title="Frequency" value="{{data.frequencyDescription}}">
                    </app-readonly>
                    <app-readonly class="w-50" title="Payment Type" value="{{data.pensionPaymentTypeDescription}}">
                    </app-readonly>

                </div>
                <div class="d-flex flex-row">

                    <app-readonly class="w-50" title="Min Annual Amount" value="{{data.minAnnualAmount | currency}}">
                    </app-readonly>
                    <app-readonly class="w-50" title="Amount" value="{{data.amount | currency}}">
                    </app-readonly>
                </div>
                <div class="d-flex flex-row">

                    <app-readonly class="w-50" title="Next Payment" value="{{data.pensionNextPaymentDate | date:'dd/MM/yyyy'}}">
                    </app-readonly>
                    <app-readonly class="w-50" title="Gross payment Amount" value="{{data.grossPaymentAmount | currency}}">
                    </app-readonly>
                </div>
            </div>
        </div>
        <div class="flex-fill">
            <div class="section-title d-flex mt-4">
                Bank Details
            </div>
            <div class="card ">
                <app-readonly title="Account Name" value="{{data.bankAccountName}}">
                </app-readonly>

                <app-readonly class="w-50" title="BSB" value="{{data.bankStateBranch}}">
                </app-readonly>

                <app-readonly class="w-50" title="Account Number" value="{{data.bankAccountNumber}}">
                </app-readonly>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row align-items-stretch">
        <div class="flex-fill ">
            <div class="section-title d-flex mt-4">
                Pension Tax Preferences
            </div>
            <div class="card ">
                <div class="d-flex flex-fill flex-column">
                    <div class="d-flex flex-row">
                        <app-readonly class="w-50" title="Claiming Tax Free Threshold" value="{{data.claimingTaxFreeThreshold | yesNo}}"></app-readonly>

                        <app-readonly class="w-50" title="Tax Free Percentage" value="{{data.taxFreePercentage*100}}%"></app-readonly>
                    </div>
                    <div class="d-flex flex-row">
                        <app-readonly class="w-50" title="PAYG Tax Specified" value="{{data.paygTaxSpecified}}">
                        </app-readonly>

                        <app-readonly class="w-50" title="PAYG Tax Withheld Amount" value="{{data.paygTaxWitheldAmount | currency}}">
                        </app-readonly>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
