import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../component-base';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-list-view',
  templateUrl: './client-list-view.component.html',
  styleUrls: ['./client-list-view.component.scss']
})
export class ClientListViewComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onMemberEditClick() {
    this.router.navigate(['personal-edit']);
  }
}
