import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IValuationViewState } from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';


export const advidorPortalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(advidorPortalState, (state) => state.valuationViewState);



export const getValuationView_Model = createSelector(
  state,
  (state: IValuationViewState) => state.model
);

export const getValuationView_Model_investmentOptions = createSelector(
  state,
  (state: IValuationViewState) => state.model?.investmentOptions
);

export const getValuationView_Form = createSelector(
  state,
  (state: IValuationViewState) => state.form
);

export const getValuationView_SelectedInvestmentOption = createSelector(
  state,
  (state: IValuationViewState) => state.selectedInvestmentOption
);

export const getValuationView_hasMultipleInvestments = createSelector(
  state,
  (state: IValuationViewState) => state.hasMultipleInvestments
);

export const getValuationView_noOfInvestments = createSelector(
  state,
  (state: IValuationViewState) => state.noOfInvestments
);

export const getValuationView_isConsolidatedView = createSelector(
  state,
  (state: IValuationViewState) => state.isConsolidatedView
);


export const getValuationView_chartData = createSelector(
  state,
  (state: IValuationViewState) => state.chartData
);

