<app-no-items message="No beneficiaries found" *ngIf="(beneficiaryGroupedByAccountList$ |async)?.length === 0">
</app-no-items>

<div *ngIf="(beneficiaryGroupedByAccountList$ |async) as beneficiaryAccountList; else loading">
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel *ngFor="let beneficiaryAccount of beneficiaryAccountList" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="beneficiary-account">Account: {{beneficiaryAccount.accountNumber}} | Fund:
            {{beneficiaryAccount.fundCode}}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table class="table table-striped table-borderless">
        <thead>
          <tr>
            <th width="22.5%">Name</th>
            <th width="22.5%">Type</th>
            <th width="22.5%">Percent</th>
            <th width="22.5%">Relationship to you</th>
            <th width="10%">{{checkIfBeneficiaryHaveExpiry(beneficiaryAccount) ? 'Expiry date' : ''}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of beneficiaryAccount.beneficiaries">
            <td>{{item.name}} </td>
            <td>{{item.beneficiaryTypeName }}</td>
            <td>{{item.percentage+'%'}}</td>
            <td>{{item.relationship}}</td>
            <td>{{item.expirationDate | date:'dd/MM/yyyy'}}</td>
          </tr>
        </tbody>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
