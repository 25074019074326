import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IWidgetExpiringMembersViewState } from './state';

export const state = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const componentState = createSelector(state, (state) => state.widgetExpiringMemberState);

export const widgetExpiringMember_Data = createSelector(
  componentState,
  (state: IWidgetExpiringMembersViewState) => state.data
);


