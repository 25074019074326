import { adviserAuthority_List } from './selectors';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import {
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helper/helper-functions';
import { AppState } from 'src/app/store/app.states';
import { memberView_SelectedAccountId } from '../selectors';
import { AdviserAuthorityRequestAction, ResetFormAction } from './actions';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
  selector: 'app-adviser-authority-view',
  templateUrl: './adviser-authority-view.component.html',
  styleUrls: ['./adviser-authority-view.component.scss']
})
export class AdviserAuthoritysViewComponent extends ComponentBase implements OnInit, OnDestroy {

  private _memberid: number;

  @Input() set memberid(value: number) {
    this._memberid = value;
    this.store.dispatch(ResetFormAction());
    if (value)
      setTimeout(() => {
        this.store.dispatch(AdviserAuthorityRequestAction({ memberId: this.memberid }));
      }, 200);
  }
  get memberid(): number {
    return this._memberid;
  }

  list$ = this.store.pipe(select(adviserAuthority_List));
  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));

  accountId: number;

  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();


  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
