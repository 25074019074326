
export interface IWidgetNewMembersViewState {
  data:WidgetNewMembersModel;
}


export class WidgetNewMembersModel{
  value: number = 0;
  from: string = '';
  change: number = 0;
}
