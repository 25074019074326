import { FormGroupState } from "ngrx-forms";
import { MemberModel } from "src/app/model/member.model";

export interface IMemberListingViewState {
  list: MemberModel[] | undefined;
  form: FormGroupState<MemberListingSearchModel>;

}

export class MemberListingSearchModel{
  q: string = '';
  page: number = 0;
}

