import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { RollInModel } from 'src/app/model/rollin.model';

export const MemberViewRollInsRequestAction = createAction('[Member View Rollover] get rollin',
  props<{ memberId: number, accountId: number, page: number }>());

export const MemberViewRollInsResponseAction = createAction('[Member View Rollover] set rollin',
  props<{ payload: RollInModel[] }>());

export const ResetFormAction = createAction('[Member view Rollover] reset form');
