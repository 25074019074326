import { createAction, props } from '@ngrx/store';
import { AdviserAuthorityModel } from './state';

export const AdviserAuthorityRequestAction = createAction('[AdviserAuthority] get data',
  props<{ memberId: number }>());

export const AdviserAuthorityResponseAction = createAction('[AdviserAuthority] set data',
  props<{ payload: AdviserAuthorityModel[] }>());

  export const ResetFormAction = createAction('[AdviserAuthority] reset form');
