
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import {
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { Helper } from 'src/app/helper/helper-functions';
import { MemberViewBeneficiariesGroupedByAccountRequestAction, ResetFormAction } from './actions';
import { beneficiaries_BeneficiaryGroupedByAccountList } from './selectors';
import { BeneficiaryGroupedByAccountModel } from 'src/app/model/beneficiary-grouped-by-account.model';

@Component({
  selector: 'app-beneficiaries-view',
  templateUrl: './beneficiaries-view.component.html',
  styleUrls: ['./beneficiaries-view.component.scss']
})
export class BeneficiariesViewComponent extends ComponentBase implements OnInit, OnDestroy {

  private _memberid: number;

  @Input() set memberid(value: number) {
    this._memberid = value;
    this.store.dispatch(ResetFormAction());
    this.store.dispatch(MemberViewBeneficiariesGroupedByAccountRequestAction({ memberId: this._memberid, page: 0 }));

  }
  get memberid(): number {
    return this._memberid;
  }

  beneficiaryGroupedByAccountList$ = this.store.pipe(select(beneficiaries_BeneficiaryGroupedByAccountList));

  accountId: number;

  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  checkIfBeneficiaryHaveExpiry(beneficiaryByAccount: BeneficiaryGroupedByAccountModel) {
    var index = beneficiaryByAccount.beneficiaries.findIndex(x => x.expirationDate !== null && x.beneficiaryTypeId == 2);

    return index !== -1;
  }

}
