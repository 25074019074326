
export class ContributionDetailModel {
  concessionalAmount: number = 0;
  concessionalCap: number = 0;
  nonConcessionalAmount: number = 0;
  nonConcessionalCap: number = 0;
  customWording: { [key: string]: string }
  previousFyContributions: PreviousFinancialYearContributionTotalModel[];
}

export class PreviousFinancialYearContributionTotalModel {
  financialYear: string;
  concessionalContributionCap: number;
  concessionalAmountAllocated: number;
  nonConcessionalContributionCap: number;
  nonConcessionalAmountAllocated: number;
  amountClaimedTaxDeduction: number;
}
