import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { Helper } from '@ifaa-components/ui-components';
import { adviserDetail_Model } from './selectors';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  colors:any;
  labels: any;
};


@Component({
  selector: 'app-adviser-details',
  templateUrl: './adviser-details.component.html',
  styleUrls: ['./adviser-details.component.scss']
})
export class AdviserDetailsComponent extends ComponentBase implements OnInit, OnDestroy {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  model$ = this.store.pipe(select(adviserDetail_Model));
  helper = new Helper();

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.chartOptions = {
      series: [75, 25],
      colors : ['#95d2a4', '#ea8891'],
      chart: {
        type: "donut",

      },
      labels: ["Active", "Expiring"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onEditClick() {
    this.router.navigate(['adviser-details-edit']);
  }
}

