import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AdviserSearchModel } from 'src/app/model/adviser-search.model';

export const RequestAction = createAction('[Adviser Listing -widget view] get data', props<{ search: string, page: number }>());
export const ResponseAction = createAction('[Adviser Listing -widget view] set data',  props<{ payload: AdviserSearchModel[] }>());


export const NextPageAction = createAction('[Adviser Listing -widget view]next page');

