
import { AccountUnderlyingInvestmentSummaryModel, InvestmentSummary } from 'src/app/model/account-underlying-investment-summary.model';
import { KeyValueModel } from '@ifaa-components/ui-components';
import { FormGroupState } from 'ngrx-forms';
import { HistoricalGraphModel } from 'src/app/model/historical-graph.model';

export interface IValuationViewState {
  model: AccountUnderlyingInvestmentSummaryModel | undefined;
  selectedInvestmentOption: InvestmentSummary;
  investmentOptions: KeyValueModel[];
  hasMultipleInvestments: boolean;
  noOfInvestments: number;
  isConsolidatedView: boolean;
  form: FormGroupState<ValuationForm>;
  chartData: HistoricalGraphModel;

}

export class ValuationForm {
  investmentOptions: string = '';
}
