<div id="dealer-group-details">
  <div class="page-header">
    <app-toolbar-options icon="price_change">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Dealer Group Details</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>
    <app-dealer-group-details></app-dealer-group-details>
  </div>

</div>
