import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { SMRTState } from '../../store/smrt.states';
import {
  commomState_LoggedinInfo,
} from 'src/app/store/common/common.selectors';
import { AccountFeatureToggle } from 'src/app/model/account-feature-toggles.model';
import { Router } from '@angular/router';
import { selectorWidgetView_Selected } from 'src/app/modules/shared/components/selector/selectors';
import { AdvisorPortalSharedState } from 'src/app/modules/shared/store/shared.states';
import { FeatureToggleName } from 'src/app/services/featureToggle.service';

@Component({
  selector: 'app-tenant-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  host: {
    // class: 'w-100'
  }
})
export class TenantDashboardComponent extends ComponentBase implements OnInit, OnDestroy {

  canViewAdviserDashboard = FeatureToggleName.canViewAdviserDashboard;
  canViewBrokerDashboard = FeatureToggleName.canViewBrokerDashboard;

  selected$ = this.apstore.pipe(select(selectorWidgetView_Selected));

  loggedIn$ = this.store.pipe(select(commomState_LoggedinInfo));

  addSpacingForWidget: boolean = false;

  limitedServiceToggles: AccountFeatureToggle[] | null = null;

  showMyOtherAccount: boolean = false;

  constructor(public store: Store<AppState>,
    public apstore: Store<AdvisorPortalSharedState>,
    private router: Router,
    public smrtstore: Store<SMRTState>,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onClientListAdvancedClick() {
    this.router.navigate(['/client-list']);
  }
  onAdviserListAdvancedClick() {
    this.router.navigate(['/adviser-list']);
  }

  onAdviserEditClick() {
    this.router.navigate(['adviser-details']);
  }
}
