import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMemberInsuranceState } from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.memberInsuranceState);


export const memberInsurance_InsuranceInformationList = createSelector(
  state,
  (state: IMemberInsuranceState) => state.insuranceInformationList
);

export const memberInsurance_AccountData = createSelector(
  state,
  (state: IMemberInsuranceState) => state.accountData
);

export const memberInsurance_Form = createSelector(
  state,
  (state:IMemberInsuranceState) => state.form
);
