<div id="client-view">
  <div class="page-header">
    <app-toolbar-options icon="price_change">
      <div class="d-flex flex-column" slot="start">
      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" >

    <app-members-view></app-members-view>
  </div>

</div>

<ng-template #noUserData>
  Member details
</ng-template>
