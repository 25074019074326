
import { IAdviserListingViewState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction
} from 'ngrx-forms';
import { NextPageAction, RequestAction, ResponseAction, SetAsAtDateAction } from './actions';
import { AdviserListingSearchModel } from '../adviser-list-widget/state';
import { Helper } from '@ifaa-components/ui-components';

export const formName = 'adviserListingForm';
let helper = new Helper();

export const state: IAdviserListingViewState = {
  list: undefined,
  form: createFormGroupState(formName, new AdviserListingSearchModel()),
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.currentPage = payload.length == 0 ? -1 : state.form.value.currentPage;

    return {
      ...state,
      list: state.form.value.currentPage === 0 ? payload : state.list.concat(payload),
      form: createFormGroupState(formName, clone)
    };
  }),
  on(RequestAction, (state, { search, page }) => {
    if (state.form.value.currentPage == -1) return state;
    var clone = helper.clone(state.form.value);
    clone.currentPage = page;

    return {
    ...state,
      list: clone.currentPage == 0 ? undefined : state.list,
      form: createFormGroupState(formName, clone)
    };
  }),
  on(SetAsAtDateAction, (state, { payload }) => {
    var clone = helper.clone(state.form.value);
    clone.asAt = payload;
    return {
      ...state,
      form: setValue(clone)(state.form),
    };
  }),
  on(NextPageAction, (state, { }) => {
    if (state.form.value.currentPage == -1) return state;
    var clone = helper.clone(state.form.value);
    clone.currentPage++;

    return {
      ...state,
      form: createFormGroupState(formName, clone)
    };
  })
);

export function adviserListingViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
