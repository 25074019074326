import { AppState } from 'src/app/store/app.states';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AdvisorPortalSharedState } from '../store/shared.states';
import { FeatureToggleService } from 'src/app/services/featureToggle.service';

@Pipe({
  name: 'featureToggle'
})
export class FeatureTogglePipe implements PipeTransform {

  constructor(public store: Store<AppState>,
    public appstore: Store<AdvisorPortalSharedState>,
    public featureToggleService: FeatureToggleService,

    protected _sanitizer: DomSanitizer) {
  }

  async transform(value: string): Promise<boolean> {

    if (!value)
      return true;

    var feature = await this.getValue(this.featureToggleService.checkFeatureToggle(value));

    if (feature) {
      return true;
    }
    else
      return false;
  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  getValue<T>(observable: Observable<T>): Promise<T> {
    return observable
      .pipe(
        filter(this.hasValue),
        first()
      )
      .toPromise();
  }

}
