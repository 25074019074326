import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { TransactionService } from 'src/app/services/transaction.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class CashTransactionsEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private transactionervice: TransactionService) {
  }

  getTransactionsView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CashTransactionsRequestAction),
    switchMap((action: any) => this.transactionervice.getMemberTransactions(action.memberId, action.accountId, action.page, action.filter).pipe(
      map((data: any) => {
        return ActionsList.CashTransactionsResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getTransactionTypesView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CashTransactionsTypesRequestAction),
    switchMap((action: any) => this.transactionervice.getMemberTransactionTypes(action.memberId, action.accountId).pipe(
      map((data: any) => {
        return ActionsList.CashTransactionsTypesResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getTransactionDescriptionView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CashTransactionsDescriptionRequestAction),
    switchMap((action: any) => this.transactionervice.getMemberTransactionDescription(action.memberId, action.accountId).pipe(
      map((data: any) => {
        return ActionsList.CashTransactionsDescriptionResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getTransactionDownload$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.CashTransactionsDownloadRequestAction),
    switchMap((action: any) => this.transactionervice.getMemberTransactionsDownload(action.memberId, action.accountId, action.page, action.filter).pipe(
      map((response: any) => {
        let dataType = response.body.type;
        let binaryData = [];
        binaryData.push(response.body);
        let contentDisposition = response.headers.get('content-disposition');
        let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        return ActionsList.CashTransactionsDownloadResponseAction({ payload: '' });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
