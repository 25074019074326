<div class="section-title d-flex ">
  Valuation screen
</div>

<div class="card ">
  <mat-progress-bar *ngIf="(modelSummary$|async) == undefined;" mode="indeterminate"></mat-progress-bar>

  <div id="valuation-view-container">
    <div class="chart">
      <apx-chart class="apx-chart" #chart *ngIf="chartOptions" [series]="chartOptions.series"
        [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis"
        [dataLabels]="chartOptions.dataLabels" [grid]="chartOptions.grid" [stroke]="chartOptions.stroke"
        [title]="chartOptions.title" [colors]="chartOptions.colors" [markers]="chartOptions.markers"
        [tooltip]="chartOptions.tooltip"></apx-chart>
    </div>
    <mat-chip class="ml-3 " *ngIf="selectedDate" appanimatecss="fadeInDown">
      <div class="d-flex flex-row align-items-center">
        <mat-icon class="mr-3">event</mat-icon>
        <div>{{selectedDate | date}}</div>
      </div>
    </mat-chip>
    <app-no-items class="mt-4" message="No data found" *ngIf="!(selectedInvestmentOption$|async)">
    </app-no-items>

    <div *ngIf="selectedInvestmentOption$|async; let currentInvestmentSummary">


      <mat-accordion>
        <mat-expansion-panel [expanded]=" step===i" (opened)="setStep(i, item)" (closed)="panelOpenState = false"
          *ngFor="let item of (modelSummary$|async).investmentSummaries; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{item.investmentOptionName}}
            </mat-panel-title>

            <mat-panel-description>
              <h6 *ngIf="item.noOfUnits">Number of Units</h6>
              {{item.noOfUnits}}
            </mat-panel-description>
            <mat-panel-description>
              <h6 *ngIf="item.unitPrice">Unit price</h6>

              {{item.unitPrice | currency}}
            </mat-panel-description>
            <mat-panel-description>
              <h6>Investment balance</h6>

              {{item.accounBalance | currency}}

            </mat-panel-description>
            <mat-panel-description class="allocation">
              <div class="d-flex flex-row flex-fill">
                <div class=" allocation-chart">
                  <circle-progress [percent]="item?.percentAllocated" [radius]="15" [outerStrokeWidth]="3"
                    [innerStrokeWidth]="1" [outerStrokeColor]="'#78C000'" [innerStrokeColor]="'#C7E596'"
                    [animation]="true" [showTitle]="false" [showSubtitle]="false" [showUnits]="false"
                    [animationDuration]="300"></circle-progress>
                </div>
                <div class="flex-fill allocation-info">
                  <h6>Allocation</h6>

                  <div>{{item?.percentAllocated | number : '1.2-2' }} %</div>
                </div>
              </div>


            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template [ngTemplateOutlet]="templateInvestment">
          </ng-template>
        </mat-expansion-panel>

      </mat-accordion>


      <ng-template #templateInvestment>
        <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark d-flex">
          <a class="navbar-brand flex-fill">{{currentInvestmentSummary?.investmentOptionName}}</a>
          <div class=" badge badge-pill badge-primary" type="button">
            {{currentInvestmentSummary?.percentAllocated | number : '1.2-2' }} %
          </div>
        </nav> -->
        <div class="row mt-2">
          <div class="d-flex flex-column flex-fill">

            <div class="flex-fill d-flex flex-row p-4">
              <div class="flex-fill d-flex flex-column">
                <app-readonly title="Account balance" value="{{currentInvestmentSummary?.accounBalance | currency}}">
                </app-readonly>
                <app-readonly title="Allocation"
                value="{{currentInvestmentSummary?.percentAllocated | number : '1.2-2' }}  %">
              </app-readonly>


              </div>
              <div class="flex-fill d-flex flex-column">
                <app-readonly title="Investment option" value="{{currentInvestmentSummary?.investmentOptionName}}">
                </app-readonly>
                <app-readonly title="No of units" [value]="currentInvestmentSummary?.noOfUnits" *ngIf="currentInvestmentSummary?.noOfUnits"></app-readonly>

              </div>
              <div class="flex-fill d-flex flex-column">
                <app-readonly title="Unit price" [value]="currentInvestmentSummary?.unitPrice" *ngIf="currentInvestmentSummary?.unitPrice"
                  tooltip="{{getUnitPriceDate()}}">
                </app-readonly>
                <app-readonly title="As at" value="{{currentInvestmentSummary?.asAt  | date }}"></app-readonly>

              </div>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-sm-4 adjustment">
            <div id="app-readonly-container" class="info">
              <div class="d-flex align-items-center value-container mb-1">

              </div>
            </div>
          </div>
          <div class="col-sm-4">

          </div>
          <div class="col-sm-4">
          </div>
        </div>

        <div class="d-flex flex-row flex-fill">
          <div>

          </div>
          <div class="d-flex flex-fill flex-row">
            <div class="flex-fill pt-4">
              <apx-chart #chart1 [series]="chartOptions1.series" [states]="chartOptions1.states"
                [chart]="chartOptions1.chart" [labels]="chartOptions1.labels" [responsive]="chartOptions1.responsive"
                [legend]="chartOptions1.legend" [dataLabels]="chartOptions1.dataLabels" [colors]="chartOptions1.colors"
                [xaxis]="chartOptions1.xaxis" [yaxis]="chartOptions1.yaxis">
              </apx-chart>
            </div>
            <div class="assets w-100 flex-fill">
              <div class="asset-type"><strong [innerHtml]="setAssetTitle()"></strong></div>
              <table class="table">

                <tbody>
                  <tr *ngFor="let investmentOptionDetail of currentInvestmentSummary.investmentOptionDetails;">
                    <td>
                      <div class="circle"
                        [ngStyle]="{'background-color': getInvestmentDetailColor(investmentOptionDetail.consolidateByName)}">
                      </div>
                    </td>
                    <td>
                      {{investmentOptionDetail.investmentOptionDetailName}}
                    </td>
                    <td class="number">{{investmentOptionDetail.amountInvested | currency}}</td>
                    <td class="number">{{investmentOptionDetail.percentage | number : '1.2-2'}} %</td>
                  </tr>

                  <tr *ngIf="!isConsolidatedView" class="amount-summary">
                    <td></td>
                    <td>Total</td>
                    <td class="number">{{currentInvestmentSummary.amountInvested | currency}}</td>
                    <td class="number">{{getInvestmentOptionPercentageTotal() | number : '1.2-2'}} %</td>
                  </tr>
                </tbody>
              </table>



            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
