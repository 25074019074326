import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.services';

@Injectable()
export class EmploymentService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService) {
    super(toastr);
  }

  getBalances(memberId: number, accountId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/balances`, { headers: this.headers() });
  }
}
