import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';
import { Helper } from '@ifaa-components/ui-components';
import { ResetFilterAction } from '../cash-transactions/actions';
import { MemberViewRollInsResponseAction } from './actions';
import { IRolloverState } from './state';

export const cashTransactionListState: IRolloverState = {
  rollInList:[]
};

const reducer = createReducer(cashTransactionListState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(MemberViewRollInsResponseAction, (state, { payload }) => {
    return {
      ...state,
      rollInList: payload,
    };
  }),
  on(ResetFilterAction, (state, { }) => {
    return {
      ...state,
      rollInList: [],
    }
  }),

);

export function rolloverReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
