
export interface IWidgetTotalBalanceViewState {
  data: WidgetTotalBalanceModel;
}


export class WidgetTotalBalanceModel {
  value: number = 0;
  monthYear: string = '';
  change: number = 0;
}
