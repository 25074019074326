<div id="personal-details-container">
  <div class="page-header">
    <app-toolbar-options icon="price_change">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Client list</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper icon="people" header="Your current authorized member listing">
      <div class="d-flex flex-column" slot="start">
        <app-members-list></app-members-list>
      </div>

    </app-component-wrapper>
  </div>

</div>
