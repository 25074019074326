import { AppState } from 'src/app/store/app.states';
import { Directive, Renderer2, Input, ViewContainerRef, TemplateRef, OnChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FeatureToggleService } from 'src/app/services/featureToggle.service';

@Directive({
  selector: '[featuretoggle]',
})
export class FeatureToggleDirective implements OnChanges, OnDestroy {

  sub: Subscription = null;

  @Input() featuretoggle: string;

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public featureToggleService: FeatureToggleService,
    public view: ViewContainerRef,
    public template: TemplateRef<any>) {
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges() {
    this.sub = this.featureToggleService.checkFeatureToggle(this.featuretoggle).subscribe(allowed => {
      if (!allowed) {
        this.view.clear();
      }
      else {
        this.view.createEmbeddedView(this.template);
      }
    });
  }
}
