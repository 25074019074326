import * as moment from "moment";
import { FormGroupState } from "ngrx-forms";
import { AdviserSearchModel } from "src/app/model/adviser-search.model";

export interface IAdviserListingWidgetViewState {
  list: AdviserSearchModel[] | undefined;
  form: FormGroupState<AdviserListingSearchModel>;
}

export class AdviserListingSearchModel{
  q: string = '';
  asAt: string = moment(new Date()).format("YYYY-MM-DD");
  currentPage: number = 0;
}

