import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { SelectOptionAction, SelectOptionsAction } from './actions';
import { selectorWidgetView_List, selectorWidgetView_Selected } from './selectors';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent extends ComponentBase implements OnInit, OnDestroy {

  options: string[] = [];
  selected$ = this.store.pipe(select(selectorWidgetView_Selected));
  list$ = this.store.pipe(select(selectorWidgetView_List));
  helper = new Helper();

  @ViewChild('dropdown', { static: true }) dropdown: ElementRef;

  constructor(
    public store: Store<AppState>,
    private authService: MsalService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.authService.initialize().subscribe(async x => {

      var list = await this.helper.getValue(this.list$);
      if (list.length > 0) return;

      this.checkAndSetActiveAccount();
      let accounts = this.authService.instance.getAllAccounts();
      if (accounts.length > 0) {
        var platformUserId = accounts[0].idTokenClaims?.extension_PlatformUserId as any;

        var obj = JSON.parse(platformUserId);
        this.options = Object.keys(obj).map(x => x);

        this.store.dispatch(SelectOptionsAction({ payload: this.options }))

        var option = Object.keys(obj)[0];
        var selected = sessionStorage.getItem('selected');
        if (select && this.options.indexOf(selected) >= 0) {
          this.select(selected);
        }
        else {
          this.select(option);
        }
      }
    });
  }

  getTitle(value: string) {
    if (value == 'group')
      return 'Broker';
    return value;
  }
  select(value: string) {
    setTimeout(() => {
      sessionStorage.setItem('selected', value);
      this.store.dispatch(SelectOptionAction({ payload: value }))
    }, 500);
  }

  onChangeView(value: string) {
    this.store.dispatch(SelectOptionAction({ payload: '' }));
    this.router.navigate(['dashboard']);
    this.dropdown.nativeElement.checked = false;

    setTimeout(() => {
      this.store.dispatch(SelectOptionAction({ payload: value }));
      sessionStorage.setItem('selected', value);
    }, 300);
  }

  checkAndSetActiveAccount() {

    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
