import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "src/app/store/app.states";
import { Store, select } from "@ngrx/store";
import { memberInsurance_AccountData, memberInsurance_Form, memberInsurance_InsuranceInformationList } from "./selectors";
import { Helper } from "src/app/helper/helper-functions";
import { MemberInsuranceInformationRequestAction, MemberInsurance_SetAccountDataAction } from "./actions";
import { AccountModel } from "src/app/model/account.model";
import { commomState_LoggedinInfo, commomState_SystemConfig_FeatureToggle } from "src/app/store/common/common.selectors";
import { KeyValueModel } from "@ifaa-components/ui-components";
import { SetValueAction } from "ngrx-forms";
import { InsuranceModel } from "src/app/model/insurance.model";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { ComponentBase } from "src/app/views/component-base";

@Component({
  selector: 'app-member-insurance',
  templateUrl: './member-insurance.component.html',
  styleUrls: ['./member-insurance.component.scss']
})
export class MemberInsuranceComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() set memberid(value: number) {
    this.memberId = value;
  }
  @Input() set accounts(value: AccountModel[]) {
    this.accountData = value;
  }

  displayInsuranceQuestionnaire: boolean = false;

  accountData: AccountModel[];
  memberId: number;
  insuranceList$ = this.store.pipe(select(memberInsurance_InsuranceInformationList));
  featuretoggles$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));
  loggeinInInfo$ = this.store.pipe(select(commomState_LoggedinInfo));
  accountData$ = this.store.pipe(select(memberInsurance_AccountData));
  form$ = this.store.pipe(select(memberInsurance_Form));
  showEffectiveDate = false;
  effectiveDateColumnName = '';
  effectiveDateGreaterThan = '';
  insuranceQuestionaireFeatureEnabled = false;

  constructor(public store: Store<AppState>,
    public translate: TranslateService,
    public helper: Helper) {
    super();
  }

  async ngOnInit() {
    super.ngOnInitBase();
    var accountKeyValues = this.accountData.map((account) => {
      return { key: account.accountId, value: account.accountNumber } as KeyValueModel;
    })

    var form = await this.helper.getValue(this.form$);
    var firstAccountId = accountKeyValues[0].key;

    this.store.dispatch(new SetValueAction(form.controls.accountId.id, firstAccountId))
    this.store.dispatch(MemberInsurance_SetAccountDataAction({ accountData: accountKeyValues }))

    // this.helper.checkPermission(this.store, 'Members_CanViewInsurance', (permission) => {
    //   if (permission)
    this.store.dispatch(MemberInsuranceInformationRequestAction({ memberId: this.memberId, page: 0 }));
    // });

    this.sub = this.translate.get('VIEWS.Insurance.ShowEffectiveDate').subscribe(x => {
      this.showEffectiveDate = x;
    });

    this.sub = this.translate.get('VIEWS.Insurance.EffectiveDateColumnName').subscribe(x => {
      this.effectiveDateColumnName = x;
    });
    this.sub = this.translate.get('VIEWS.Insurance.EffectiveDateGreaterThan').subscribe(x => {
      this.effectiveDateGreaterThan = x;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  hasLapsed(model: InsuranceModel): boolean {
    if (model.lapseDate)
      return true;
    return false;
  }

  getCoverText(data: InsuranceModel[]) {
    if (this.showEffectiveDate && data.length > 0) {
      var date = null;
      switch (this.effectiveDateColumnName) {
        case 'LastModified':
          var lastModifiedList = data.map(x => moment(x.lastModified));

          if (this.effectiveDateGreaterThan) {
            var dateToCompare = moment(this.effectiveDateGreaterThan);
            lastModifiedList.push(dateToCompare);
            date = moment.max(lastModifiedList);
          } else {
            date = moment.max(lastModifiedList);
          }
          break;
        case 'PremiumStartDate':
          var premiumStartDateList = data.map(x => moment(x.premiumStartDate));

          date = moment.min(premiumStartDateList);
          break;
      }
      let momentDate = moment(date);
      if (!momentDate.isValid()) {
        return null;
      }
      return ' as at ' + momentDate.format('DD-MMMM-YYYY');
    }
    return null;
  }
}
