import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { IMembersOverviewViewState } from './state';
import { AdvisorPortalSharedState } from '../../store/shared.states';

export const state = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const componentState = createSelector(state, (state) => state.membersOverviewViewState);

export const membersOverview_Data = createSelector(
  componentState,
  (state: IMembersOverviewViewState) => state.data
);

