import { commomState_SystemConfig_FeatureToggle } from './../store/common/common.selectors';
import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/app.states';

@Directive({
  selector: '[featuretoggle]',
})
export class FeatureToggleDirective {

  featuretoggle$ = this.store.pipe(select(commomState_SystemConfig_FeatureToggle));

  @Input() featuretoggle: string;

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef) {
  }

  ngOnChanges() {
    if (this.hostElement.nativeElement.style)
      this.hostElement.nativeElement.style.display = 'none';
  }

  ngAfterViewInit(): void {
    this.featuretoggle$.subscribe(x => {
      if (!x) return;

      var features = this.featuretoggle.replace(' ', '').split(',');

      var found = false;
      features.forEach(feature => {
        if (x.hasOwnProperty(feature) && x[feature]) {
          found = true;
        }
      });

      if (found) {
        this.hostElement.nativeElement.style.display = 'block';
        return;
      }

      let el: HTMLElement = this.hostElement.nativeElement;
      if (el && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    });
  }

  checkpermission(feature: string) {
    return new Promise((resolve) => {
      this.featuretoggle$.subscribe(x => {
        if (!x) {
          resolve(false);
          return;
        };

        if (x.hasOwnProperty(feature) && x[feature]) {
          resolve(true);
          return;
        }

        resolve(false);
      });
    });
  }
}
