import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRolloverState } from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.rolloverState);

export const memberViewRollover_RollInList = createSelector(
  state,
  (state: IRolloverState) => state.rollInList
);
