import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberModel } from 'src/app/model/member.model';
import { DealerGroupContactDetailsModel } from './state';

// export const RequestAction = createAction('[Member Listing view] get data', props<{ accountId: number }>());
// export const ResponseAction = createAction('[Member Listing view] set data',  props<{ payload: MemberModel[] }>());


export const SelectContactAction = createAction('[Dealer Group Details view] get data', props<{ payload: DealerGroupContactDetailsModel }>());




