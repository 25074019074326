import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { adviserListingView_Form, adviserListingView_List, adviserListingView_SearchFormatted } from './selectors';
import { MemberModel } from 'src/app/model/member.model';
import { NextPageAction, RequestAction } from './actions';
import { AdviserListingSearchModel } from './state';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-adviser-list-widget',
  templateUrl: './adviser-list-widget.component.html',
  styleUrls: ['./adviser-list-widget.component.scss']
})
export class AdviserListWidgetComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(adviserListingView_List));
  form$ = this.store.pipe(select(adviserListingView_Form));
  search$ = this.store.pipe(select(adviserListingView_SearchFormatted));
  query: AdviserListingSearchModel | undefined = undefined;
  helper = new Helper();
  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.onSelectedChanged(this.form$, async (x) => {
      var filter = await this.formatQuery(x.value);
      if (this.isQueryDifferent(x.value) && x.value.currentPage >= 0) {
        this.store.dispatch(RequestAction({ search: filter, page: 0 }))
      }
    }, 400)

  }

  async onLoadMore() {
    var form = await this.helper.getValue(this.form$);
    var search = await this.helper.getValue(this.search$);
    this.store.dispatch(NextPageAction());
    this.store.dispatch(RequestAction({ search: search, page: form.value.currentPage + 1 }));
  }


  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onMemberViewClick(member: MemberModel) {
    this.router.navigate(['adviser-view', member.memberId]);
  }

  isQueryDifferent(filter: AdviserListingSearchModel): boolean {
    if (JSON.stringify(this.query) !== JSON.stringify(filter)) {
      this.query = filter;
      return true;
    }
    return false;
  }

  async formatQuery(value: AdviserListingSearchModel): Promise<string> {
    var list = [];
    if (value.asAt) {
      list.push("asAt:" + value.asAt);
    }
    if (value.q) {
      list.push("search:" + value.q);
    }
    return list.join(';');
  }

}

