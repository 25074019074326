import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberModel } from 'src/app/model/member.model';
import { WidgetActiveMembersModel } from './state';

export const RequestAction = createAction('[widget Active Member] get data');
export const ResponseAction = createAction('[widget Active Member] set data',  props<{ payload: WidgetActiveMembersModel }>());



