
import { createReducer, on, Action } from '@ngrx/store';
import { AccountComponentsResponseAction, ResetFormAction } from './actions';
import { AccountComponents, IAccountComponentState } from './state';

export const accountComponentState: IAccountComponentState = {
  data: undefined,
};

const reducer = createReducer(accountComponentState,
  on(AccountComponentsResponseAction, (state, { payload }) => {
    return {
      ...state,
      data: payload
    }
  })
);

export function accountComponentReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
