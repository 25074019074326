<div id="dealer-group-detail-component" *ngIf="model$|async; let model">
  <div class="d-flex flex-row w-100">
    <div class=" d-flex flex-column d-col-1">

      <app-component-wrapper icon="edit" header="Complete Your Profile" class="m-3">
        <div class="d-flex flex-column align-items-center" slot="start">



          <circle-progress [percent]="84" [radius]="30" [outerStrokeWidth]="4" [innerStrokeWidth]="2"
            [showSubtitle]="false" [outerStrokeColor]="'CIRCLE_PROGRESS.OUTERSTROKECOLOR' | translate"
            [innerStrokeColor]="'CIRCLE_PROGRESS.INNERSTROKECOLOR' | translate"
            [animationDuration]="2000"></circle-progress>
        </div>

      </app-component-wrapper>

      <app-component-wrapper icon="edit" header="Client Details" class="m-3">
        <div class="d-flex flex-column align-items-center" slot="start">

          <div id="chart">
            <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [labels]="chartOptions.labels"
            [colors]="chartOptions.colors"
              [responsive]="chartOptions.responsive"></apx-chart>
          </div>
          <div class="d-flex flex-column w-100">
            <div class="d-flex flex-row clients-data align-items-center">
              <div class="mr-3 data-icon active">
                <mat-icon>how_to_reg</mat-icon>
              </div>
              <div class="d-data flex-fill">Active clients</div>
              <div class="d-value">$143.000,00</div>
            </div>
            <div class="d-flex flex-row clients-data align-items-center">
              <div class="mr-3 data-icon active">
                <mat-icon>person_remove</mat-icon>
              </div>
              <div class="d-data  flex-fill">Expiring clients</div>
              <div class="d-value">$43.000,00</div>

            </div>
            <div class="d-flex flex-row clients-data align-items-center">
              <div class="mr-3 data-icon active">
                <mat-icon>attach_money</mat-icon>
              </div>
              <div class="d-data flex-fill">Total</div>
              <div class="d-value">$183.000,00</div>

            </div>
          </div>

        </div>

      </app-component-wrapper>


    </div>
    <div class="d-flex flex-column flex-fill ">
      <app-component-wrapper icon="edit" header="Details" class="m-3 d-flex flex-fill">
        <div class="d-flex flex-column" slot="start">
          <div class="d-flex flex-row">
            <div class="flex-fill">
              <app-readonly title="Adviser name" [value]="model.adviserName" icon="business"
                class="p-1 flex-fill"></app-readonly>

              <app-readonly title="Adviser AR number" [value]="model.adviserArNumber" icon="tag"
                class="p-1 flex-fill"></app-readonly>


              <app-readonly title="Adviser Number" [value]="model.adviserNumber" icon="tag"
                class="p-1 flex-fill"></app-readonly>

              <app-readonly title="Adviser Business Name" [value]="model.adviserBusinessName" icon="business"
                class="p-1 flex-fill"></app-readonly>


              <app-readonly title="Adviser Address" [value]="helper.formatAddress(model.adviserAddress)" icon="room"
                class="p-1 flex-fill"></app-readonly>
            </div>
            <div class="w-25">
              <!-- <div class="d-flex flex-column align-items-center">
                <h4>Completye your profile</h4>
                <circle-progress [percent]="84" [radius]="80" [outerStrokeWidth]="4" [innerStrokeWidth]="2"
                  [showSubtitle]="false" [outerStrokeColor]="'CIRCLE_PROGRESS.OUTERSTROKECOLOR' | translate"
                  [innerStrokeColor]="'CIRCLE_PROGRESS.INNERSTROKECOLOR' | translate"
                  [animationDuration]="2000"></circle-progress>
              </div> -->
              <svg class="animated" id="freepik_stories-mobile-ui-ux" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs">
                <style>
                  svg#freepik_stories-mobile-ui-ux:not(.animated) .animable {
                    opacity: 0;
                  }

                  svg#freepik_stories-mobile-ui-ux.animated #freepik--Character_1--inject-37 {
                    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideDown;
                    animation-delay: 0s;
                  }

                  svg#freepik_stories-mobile-ui-ux.animated #freepik--Device--inject-37 {
                    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideUp;
                    animation-delay: 0s;
                  }

                  svg#freepik_stories-mobile-ui-ux.animated #freepik--Character_2--inject-37 {
                    animation: 1s 1 forwards cubic-bezier(.36, -0.01, .5, 1.38) slideLeft;
                    animation-delay: 0s;
                  }

                  @keyframes slideDown {
                    0% {
                      opacity: 0;
                      transform: translateY(-30px);
                    }

                    100% {
                      opacity: 1;
                      transform: translateY(0);
                    }
                  }

                  @keyframes slideUp {
                    0% {
                      opacity: 0;
                      transform: translateY(30px);
                    }

                    100% {
                      opacity: 1;
                      transform: inherit;
                    }
                  }

                  @keyframes slideLeft {
                    0% {
                      opacity: 0;
                      transform: translateX(-30px);
                    }

                    100% {
                      opacity: 1;
                      transform: translateX(0);
                    }
                  }
                </style>
                <g id="freepik--background-complete--inject-37" class="animable"
                  style="transform-origin: 250px 235.136px;">
                  <g id="el9ams1bvdhs" class="animable" style="transform-origin: 250px 235.136px;">
                    <g id="el27mp5izgfuy" class="animable" style="transform-origin: 250px 223.358px;">
                      <g id="elydphqkvp62" class="animable" style="transform-origin: 249.333px 192.699px;">
                        <path
                          d="M443.92039,330.1975h-180.7461c-3.2324,0-5.86229-2.6298-5.86229-5.8623V61.0618c0-3.2324,2.62989-5.8618,5.86229-5.8618h180.7461c3.2324,0,5.8623,2.6294,5.8623,5.8618V324.3352c0,3.2325-2.6299,5.8623-5.8623,5.8623ZM263.17429,55.45c-3.0947,0-5.61229,2.5176-5.61229,5.6118V324.3352c0,3.0948,2.51759,5.6123,5.61229,5.6123h180.7461c3.0947,0,5.6123-2.5175,5.6123-5.6123V61.0618c0-3.0942-2.5176-5.6118-5.6123-5.6118h-180.7461Z"
                          style="fill: rgb(224, 224, 224); transform-origin: 353.547px 192.699px;" id="elk97fsaz8tbp"
                          class="animable"></path>
                        <path
                          d="M235.49269,330.1975H54.7461c-3.2324,0-5.8618-2.6298-5.8618-5.8623V61.0618c0-3.2324,2.6294-5.8618,5.8618-5.8618H235.49269c3.2324,0,5.86231,2.6294,5.86231,5.8618V324.3352c0,3.2325-2.62991,5.8623-5.86231,5.8623ZM54.7461,55.45c-3.0942,0-5.6118,2.5176-5.6118,5.6118V324.3352c0,3.0948,2.5176,5.6123,5.6118,5.6123H235.49269c3.0947,0,5.61231-2.5175,5.61231-5.6123V61.0618c0-3.0942-2.51761-5.6118-5.61231-5.6118H54.7461Z"
                          style="fill: rgb(224, 224, 224); transform-origin: 145.12px 192.699px;" id="ele490iv8peu8"
                          class="animable"></path>
                      </g>
                      <rect x="0" y="391.2669" width="500" height=".25"
                        style="fill: rgb(224, 224, 224); transform-origin: 250px 391.392px;" id="el160dszgvm1"
                        class="animable">
                      </rect>
                    </g>
                    <rect x="51.6724" y="405.5314" width="16.06" height=".25"
                      style="fill: rgb(224, 224, 224); transform-origin: 59.7024px 405.656px;" id="el4jotbug0w5b"
                      class="animable"></rect>
                    <rect x="170.3524" y="402.8214" width="53.53" height=".25"
                      style="fill: rgb(224, 224, 224); transform-origin: 197.117px 402.946px;" id="elxbgynkua6o7"
                      class="animable"></rect>
                    <rect x="68.7324" y="414.8214" width="36.25" height=".25"
                      style="fill: rgb(224, 224, 224); transform-origin: 86.8574px 414.946px;" id="elm1ur28hf2nh"
                      class="animable"></rect>
                    <rect x="378.35239" y="402.6914" width="43.19" height=".25"
                      style="fill: rgb(224, 224, 224); transform-origin: 399.947px 402.816px;" id="el4a7s3wd2ih"
                      class="animable"></rect>
                    <rect x="430.45239" y="402.6914" width="6.33" height=".25"
                      style="fill: rgb(224, 224, 224); transform-origin: 433.617px 402.816px;" id="eluazedyp3lv"
                      class="animable"></rect>
                    <rect x="316.21239" y="408.9214" width="53.89" height=".25"
                      style="fill: rgb(224, 224, 224); transform-origin: 343.157px 409.046px;" id="el9zeeb1zvi8k"
                      class="animable"></rect>
                  </g>
                  <path
                    d="M189.4395,355.5918c-.6209-2.9954-1.8079-5.7803-3.4312-8.2535l2.9553-3.5696-6.3181-6.3185-3.5699,2.9549c-2.4734-1.6225-5.2583-2.8099-8.2532-3.4308l-.4334-4.6011h-8.9354l-.4336,4.6011c-2.995,.6211-5.78,1.8083-8.253,3.4308l-3.5698-2.9549-6.3184,6.3185,2.9551,3.5698c-1.6231,2.473-2.81,5.2579-3.4312,8.2533l-4.6012,.4337v8.9349l4.6012,.4338c.6212,2.995,1.8081,5.7793,3.4312,8.2528l-2.9551,3.5698,6.3184,6.3184,3.5698-2.9557c2.473,1.6237,5.258,2.8103,8.253,3.4314l.4336,4.6012h8.9354l.4334-4.6008c2.9949-.6207,5.7798-1.8081,8.2532-3.4318l3.5699,2.9557,6.3181-6.3184-2.9553-3.5694c1.6233-2.4739,2.8103-5.2582,3.4312-8.2532l4.6008-.4338v-8.9349l-4.6008-.4337Zm-11.422,4.901c0,6.6803-5.4156,12.0962-12.0963,12.0962s-12.0963-5.4159-12.0963-12.0962,5.4156-12.0964,12.0963-12.0964,12.0963,5.4158,12.0963,12.0964Z"
                    style="fill: rgb(224, 224, 224); transform-origin: 165.921px 360.493px;" id="elua15r8wnzl"
                    class="animable">
                  </path>
                  <path
                    d="M121.2673,254.9125c-.43-2.0741-1.2519-4.0024-2.3759-5.7149l2.0463-2.4717-4.3748-4.3751-2.4719,2.0461c-1.7126-1.1235-3.6409-1.9457-5.7147-2.3756l-.3001-3.1859h-6.1871l-.3002,3.1859c-2.0738,.4301-4.0023,1.2521-5.7146,2.3756l-2.4718-2.0461-4.375,4.3751,2.0462,2.4719c-1.1239,1.7123-1.9457,3.6406-2.3759,5.7147l-3.1859,.3004v6.1866l3.1859,.3004c.4302,2.0739,1.252,4.0018,2.3759,5.7145l-2.0462,2.4718,4.375,4.375,2.4718-2.0466c1.7123,1.1243,3.6408,1.946,5.7146,2.376l.3002,3.186h6.1871l.3001-3.1857c2.0738-.4298,4.0021-1.252,5.7147-2.3763l2.4719,2.0466,4.3748-4.375-2.0463-2.4716c1.124-1.7129,1.9459-3.6408,2.3759-5.7147l3.1857-.3004v-6.1866l-3.1857-.3004Zm-7.9089,3.3936c0,4.6256-3.7499,8.3757-8.3758,8.3757s-8.3758-3.7501-8.3758-8.3757c0-4.6259,3.7499-8.3758,8.3758-8.3758s8.3758,3.7499,8.3758,8.3758Z"
                    style="fill: rgb(224, 224, 224); transform-origin: 104.982px 258.306px;" id="el37lthnqx5yh"
                    class="animable"></path>
                  <path
                    d="M283.08609,92.3825c-.7489-3.613-2.1807-6.972-4.1387-9.9553l3.56461-4.3055-7.62081-7.6213-4.306,3.5641c-2.98319-1.957-6.3424-3.3892-9.9549-4.1381l-.5227-5.5498h-10.7778l-.5229,5.5498c-3.6126,.7491-6.97189,2.1811-9.9547,4.1381l-4.3058-3.5641-7.6212,7.6213,3.5644,4.3059c-1.9578,2.9829-3.3894,6.3419-4.1387,9.9549l-5.5498,.5232v10.7771l5.5498,.5232c.7493,3.6126,2.1809,6.971,4.1387,9.9545l-3.5644,4.3058,7.6212,7.6212,4.3058-3.5651c2.98281,1.9585,6.3421,3.3898,9.9547,4.1389l.5229,5.5498h10.7778l.5227-5.5493c3.6125-.7487,6.97171-2.1809,9.9549-4.1394l4.306,3.5651,7.62081-7.6212-3.56461-4.3054c1.958-2.9839,3.3898-6.3423,4.1387-9.9549l5.5493-.5232v-10.7771l-5.5493-.5232Zm-13.777,5.9116c0,8.0577-6.5323,14.5902-14.5905,14.5902s-14.5904-6.5325-14.5904-14.5902,6.5323-14.5905,14.5904-14.5905,14.5905,6.5324,14.5905,14.5905Z"
                    style="fill: rgb(240, 240, 240); transform-origin: 254.718px 98.2939px;" id="el6l3u67h6z6"
                    class="animable">
                  </path>
                  <path
                    d="M316.40649,68.7245c-.344-1.6598-1.0017-3.2029-1.9012-4.5733l1.6375-1.9779-3.5009-3.5012-1.9781,1.6374c-1.3705-.8991-2.9136-1.557-4.5732-1.9011l-.2401-2.5495h-4.9512l-.2402,2.5495c-1.6596,.3442-3.2028,1.002-4.57309,1.9011l-1.978-1.6374-3.50111,3.5012,1.6375,1.978c-.89939,1.3703-1.5571,2.9134-1.9013,4.5732l-2.5495,.2404v4.9508l2.5495,.2404c.3442,1.6595,1.00191,3.2024,1.9013,4.5729l-1.6375,1.9781,3.50111,3.5011,1.978-1.6378c1.37029,.8997,2.91349,1.5572,4.57309,1.9014l.2402,2.5495h4.9512l.2401-2.5493c1.6596-.344,3.2027-1.0019,4.5732-1.9016l1.9781,1.6378,3.5009-3.5011-1.6375-1.9779c.8995-1.3707,1.5572-2.9136,1.9012-4.5731l2.5493-.2404v-4.9508l-2.5493-.2404Zm-6.329,2.7157c0,3.7016-3.0008,6.7026-6.7027,6.7026-3.7017,0-6.7026-3.001-6.7026-6.7026,0-3.7018,3.0009-6.7027,6.7026-6.7027,3.7019,0,6.7027,3.0009,6.7027,6.7027Z"
                    style="fill: rgb(240, 240, 240); transform-origin: 303.375px 71.4401px;" id="elnkkzq0oeg1"
                    class="animable">
                  </path>
                  <g id="eltelwv84pqno" class="animable" style="transform-origin: 408.484px 137.42px;">
                    <path
                      d="M378.60519,138.992v-22.4218h13.7453v22.015c0,6.2319,2.3872,8.5604,6.2319,8.5604,3.8434,0,6.2319-2.3285,6.2319-8.5604v-22.015h13.51071v22.4218c0,12.2304-7.33751,19.2784-19.85991,19.2784-12.5212,0-19.8599-7.048-19.8599-19.2784Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 398.465px 137.42px;" id="elayte5sk9hvp"
                      class="animable"></path>
                    <path d="M424.61679,116.5702h13.7453v40.768h-13.7453v-40.768Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 431.489px 136.954px;" id="eljd693feov3e"
                      class="animable"></path>
                  </g>
                  <g id="elbkhahxuy9pr" class="animable" style="transform-origin: 120.001px 93.7552px;">
                    <path
                      d="M59.493,95.9439v-31.2065h19.1287v30.6386c0,8.6739,3.3231,11.9145,8.6721,11.9145,5.3499,0,8.673-3.2406,8.673-11.9145v-30.6386h18.8048v31.2065c0,17.0203-10.2126,26.8291-27.6393,26.8291s-27.6393-9.8088-27.6393-26.8291Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 87.1323px 93.7552px;" id="elmyi7f5uhs8g"
                      class="animable"></path>
                    <path
                      d="M158.6246,121.4757l-9.8079-15.1578-9.5639,15.1578h-21.8041l20.5068-28.6118-19.7774-28.1265h21.4793l9.6455,14.266,9.4024-14.266h20.5884l-19.6966,27.3971,20.9123,29.3412h-21.8848Z"
                      style="fill: rgb(240, 240, 240); transform-origin: 148.979px 93.1065px;" id="eln1vab33z0wh"
                      class="animable"></path>
                  </g>
                  <g id="elbqa7bukaemo" class="animable" style="transform-origin: 343.79px 195.222px;">
                    <path
                      d="M384.40709,186.7582c-1.0723-5.1732-3.1222-9.9827-5.9257-14.2541l5.1038-6.1647-10.9115-10.9122-6.1653,5.1032c-4.2715-2.8022-9.0811-4.8528-14.2535-5.9251l-.7485-7.9463h-15.4316l-.74879,7.9463c-5.17251,1.0726-9.98231,3.1229-14.25321,5.9251l-6.165-5.1032-10.9121,10.9122,5.1036,6.1652c-2.80329,4.2709-4.8529,9.0804-5.9259,14.2536l-7.9462,.7491v15.4306l7.9462,.7492c1.073,5.1725,3.12261,9.9811,5.9259,14.2529l-5.1036,6.165,10.9121,10.9121,6.165-5.1045c4.2709,2.8042,9.0807,4.8534,14.25321,5.9261l.74879,7.9462h15.4316l.7485-7.9455c5.1724-1.0721,9.982-3.1226,14.2535-5.9268l6.1653,5.1045,10.9115-10.9121-5.1038-6.1644c2.8035-4.2724,4.8534-9.081,5.9257-14.2535l7.9457-.7492v-15.4306l-7.9457-.7491Zm-19.7259,8.4641c0,11.5371-9.353,20.8904-20.8908,20.8904s-20.8906-9.3533-20.8906-20.8904,9.353-20.8907,20.8906-20.8907,20.8908,9.3531,20.8908,20.8907Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 343.79px 195.222px;" id="el8jb8d6m8x7d"
                      class="animable">
                    </path>
                    <g id="ellz7tc1vg88">
                      <path
                        d="M384.40709,186.7582c-1.0723-5.1732-3.1222-9.9827-5.9257-14.2541l5.1038-6.1647-10.9115-10.9122-6.1653,5.1032c-4.2715-2.8022-9.0811-4.8528-14.2535-5.9251l-.7485-7.9463h-15.4316l-.74879,7.9463c-5.17251,1.0726-9.98231,3.1229-14.25321,5.9251l-6.165-5.1032-10.9121,10.9122,5.1036,6.1652c-2.80329,4.2709-4.8529,9.0804-5.9259,14.2536l-7.9462,.7491v15.4306l7.9462,.7492c1.073,5.1725,3.12261,9.9811,5.9259,14.2529l-5.1036,6.165,10.9121,10.9121,6.165-5.1045c4.2709,2.8042,9.0807,4.8534,14.25321,5.9261l.74879,7.9462h15.4316l.7485-7.9455c5.1724-1.0721,9.982-3.1226,14.2535-5.9268l6.1653,5.1045,10.9115-10.9121-5.1038-6.1644c2.8035-4.2724,4.8534-9.081,5.9257-14.2535l7.9457-.7492v-15.4306l-7.9457-.7491Zm-19.7259,8.4641c0,11.5371-9.353,20.8904-20.8908,20.8904s-20.8906-9.3533-20.8906-20.8904,9.353-20.8907,20.8906-20.8907,20.8908,9.3531,20.8908,20.8907Z"
                        style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 343.79px 195.222px;"
                        class="animable">
                      </path>
                    </g>
                  </g>
                  <g id="elupkrcjrkk8" class="animable" style="transform-origin: 432.41px 207.545px;">
                    <path
                      d="M452.42239,203.3747c-.5283-2.5488-1.5383-4.9184-2.9196-7.0229l2.5146-3.0374-5.376-5.3764-3.0377,2.5143c-2.1045-1.3806-4.4742-2.3909-7.0226-2.9192l-.3688-3.9151h-7.6031l-.369,3.9151c-2.5484,.5285-4.91819,1.5386-7.0225,2.9192l-3.0375-2.5143-5.3763,5.3764,2.5145,3.0376c-1.3812,2.1043-2.391,4.4739-2.9197,7.0227l-3.9151,.3691v7.6026l3.9151,.3692c.5287,2.5484,1.5385,4.9176,2.9197,7.0223l-2.5145,3.0375,5.3763,5.3764,3.0375-2.515c2.10431,1.3816,4.4741,2.3913,7.0225,2.9198l.369,3.9151h7.6031l.3688-3.9148c2.5484-.5282,4.9181-1.5385,7.0226-2.9201l3.0377,2.515,5.376-5.3764-2.5146-3.0372c1.3813-2.105,2.3913-4.4742,2.9196-7.0226l3.9148-.3692v-7.6026l-3.9148-.3691Zm-9.7189,4.1703c0,5.6843-4.6082,10.2926-10.2929,10.2926s-10.2927-4.6083-10.2927-10.2926c0-5.6846,4.6082-10.2928,10.2927-10.2928s10.2929,4.6082,10.2929,10.2928Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 432.41px 207.545px;" id="elqt690kkptba"
                      class="animable">
                    </path>
                    <g id="eln0ia0bpmzon">
                      <path
                        d="M452.42239,203.3747c-.5283-2.5488-1.5383-4.9184-2.9196-7.0229l2.5146-3.0374-5.376-5.3764-3.0377,2.5143c-2.1045-1.3806-4.4742-2.3909-7.0226-2.9192l-.3688-3.9151h-7.6031l-.369,3.9151c-2.5484,.5285-4.91819,1.5386-7.0225,2.9192l-3.0375-2.5143-5.3763,5.3764,2.5145,3.0376c-1.3812,2.1043-2.391,4.4739-2.9197,7.0227l-3.9151,.3691v7.6026l3.9151,.3692c.5287,2.5484,1.5385,4.9176,2.9197,7.0223l-2.5145,3.0375,5.3763,5.3764,3.0375-2.515c2.10431,1.3816,4.4741,2.3913,7.0225,2.9198l.369,3.9151h7.6031l.3688-3.9148c2.5484-.5282,4.9181-1.5385,7.0226-2.9201l3.0377,2.515,5.376-5.3764-2.5146-3.0372c1.3813-2.105,2.3913-4.4742,2.9196-7.0226l3.9148-.3692v-7.6026l-3.9148-.3691Zm-9.7189,4.1703c0,5.6843-4.6082,10.2926-10.2929,10.2926s-10.2927-4.6083-10.2927-10.2926c0-5.6846,4.6082-10.2928,10.2927-10.2928s10.2929,4.6082,10.2929,10.2928Z"
                        style="fill: rgb(255, 255, 255); opacity: 0.8; transform-origin: 432.41px 207.545px;"
                        class="animable">
                      </path>
                    </g>
                  </g>
                  <g id="elck0jkrcnxht" class="animable" style="transform-origin: 164.967px 130.567px;">
                    <path
                      d="M187.9216,125.7836c-.6059-2.9235-1.7644-5.6416-3.3488-8.0555l2.8844-3.4839-6.1665-6.1669-3.4843,2.884c-2.414-1.5836-5.1321-2.7424-8.0552-3.3485l-.423-4.4907h-8.721l-.4231,4.4907c-2.9232,.6062-5.6414,1.7649-8.055,3.3485l-3.4841-2.884-6.1669,6.1669,2.8842,3.4842c-1.5842,2.4136-2.7425,5.1317-3.3489,8.0552l-4.4907,.4234v8.7204l4.4907,.4234c.6064,2.9232,1.7647,5.6407,3.3489,8.0548l-2.8842,3.4841,6.1669,6.1669,3.4841-2.8848c2.4136,1.5848,5.1318,2.7429,8.055,3.3491l.4231,4.4907h8.721l.423-4.4903c2.9231-.6059,5.6412-1.7647,8.0552-3.3495l3.4843,2.8848,6.1665-6.1669-2.8844-3.4837c1.5844-2.4145,2.7429-5.132,3.3488-8.0552l4.4904-.4234v-8.7204l-4.4904-.4234Zm-11.1478,4.7834c0,6.5201-5.2857,11.806-11.8062,11.806s-11.806-5.2859-11.806-11.806,5.2857-11.8061,11.806-11.8061,11.8062,5.2858,11.8062,11.8061Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 164.967px 130.567px;" id="el4zs3ykyi28i"
                      class="animable">
                    </path>
                    <g id="eld1j65q5vyd">
                      <path
                        d="M187.9216,125.7836c-.6059-2.9235-1.7644-5.6416-3.3488-8.0555l2.8844-3.4839-6.1665-6.1669-3.4843,2.884c-2.414-1.5836-5.1321-2.7424-8.0552-3.3485l-.423-4.4907h-8.721l-.4231,4.4907c-2.9232,.6062-5.6414,1.7649-8.055,3.3485l-3.4841-2.884-6.1669,6.1669,2.8842,3.4842c-1.5842,2.4136-2.7425,5.1317-3.3489,8.0552l-4.4907,.4234v8.7204l4.4907,.4234c.6064,2.9232,1.7647,5.6407,3.3489,8.0548l-2.8842,3.4841,6.1669,6.1669,3.4841-2.8848c2.4136,1.5848,5.1318,2.7429,8.055,3.3491l.4231,4.4907h8.721l.423-4.4903c2.9231-.6059,5.6412-1.7647,8.0552-3.3495l3.4843,2.8848,6.1665-6.1669-2.8844-3.4837c1.5844-2.4145,2.7429-5.132,3.3488-8.0552l4.4904-.4234v-8.7204l-4.4904-.4234Zm-11.1478,4.7834c0,6.5201-5.2857,11.806-11.8062,11.806s-11.806-5.2859-11.806-11.806,5.2857-11.8061,11.806-11.8061,11.8062,5.2858,11.8062,11.8061Z"
                        style="fill: rgb(255, 255, 255); opacity: 0.8; transform-origin: 164.967px 130.567px;"
                        class="animable">
                      </path>
                    </g>
                  </g>
                  <g id="el4oyzgq4y17m" class="animable" style="transform-origin: 355.117px 119.049px;">
                    <path
                      d="M369.40859,116.0711c-.3772-1.8202-1.0985-3.5125-2.085-5.0154l1.7959-2.1691-3.8393-3.8395-2.1693,1.7956c-1.503-.986-3.1953-1.7075-5.0152-2.0848l-.2634-2.7959h-5.4297l-.2635,2.7959c-1.82,.3774-3.5123,1.0988-5.01509,2.0848l-2.16921-1.7956-3.8395,3.8395,1.7958,2.1693c-.9864,1.5027-1.7076,3.195-2.08509,5.0152l-2.79591,.2636v5.4294l2.79591,.2636c.37749,1.8199,1.09869,3.5119,2.08509,5.0149l-1.7958,2.1693,3.8395,3.8395,2.16921-1.7961c1.50279,.9867,3.19509,1.7077,5.01509,2.0851l.2635,2.796h5.4297l.2634-2.7957c1.8199-.3772,3.5122-1.0987,5.0152-2.0854l2.1693,1.7961,3.8393-3.8395-1.7959-2.169c.9865-1.5033,1.7078-3.1953,2.085-5.0152l2.7958-.2636v-5.4294l-2.7958-.2636Zm-6.9407,2.9782c0,4.0594-3.29089,7.3504-7.3505,7.3504s-7.3505-3.291-7.3505-7.3504,3.2909-7.3506,7.3505-7.3506,7.3505,3.291,7.3505,7.3506Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 355.117px 119.049px;" id="el650fbrjwo0n"
                      class="animable">
                    </path>
                    <g id="elz3eqr24ugq">
                      <path
                        d="M369.40859,116.0711c-.3772-1.8202-1.0985-3.5125-2.085-5.0154l1.7959-2.1691-3.8393-3.8395-2.1693,1.7956c-1.503-.986-3.1953-1.7075-5.0152-2.0848l-.2634-2.7959h-5.4297l-.2635,2.7959c-1.82,.3774-3.5123,1.0988-5.01509,2.0848l-2.16921-1.7956-3.8395,3.8395,1.7958,2.1693c-.9864,1.5027-1.7076,3.195-2.08509,5.0152l-2.79591,.2636v5.4294l2.79591,.2636c.37749,1.8199,1.09869,3.5119,2.08509,5.0149l-1.7958,2.1693,3.8395,3.8395,2.16921-1.7961c1.50279,.9867,3.19509,1.7077,5.01509,2.0851l.2635,2.796h5.4297l.2634-2.7957c1.8199-.3772,3.5122-1.0987,5.0152-2.0854l2.1693,1.7961,3.8393-3.8395-1.7959-2.169c.9865-1.5033,1.7078-3.1953,2.085-5.0152l2.7958-.2636v-5.4294l-2.7958-.2636Zm-6.9407,2.9782c0,4.0594-3.29089,7.3504-7.3505,7.3504s-7.3505-3.291-7.3505-7.3504,3.2909-7.3506,7.3505-7.3506,7.3505,3.291,7.3505,7.3506Z"
                        style="fill: rgb(255, 255, 255); opacity: 0.8; transform-origin: 355.117px 119.049px;"
                        class="animable">
                      </path>
                    </g>
                  </g>
                </g>
                <g id="freepik--Shadow--inject-37" class="animable" style="transform-origin: 259.723px 450.962px;">
                  <path
                    d="M468.59839,450.9616c0,7.863-93.5165,14.2372-208.87499,14.2372s-208.87499-6.3742-208.87499-14.2372,93.5165-14.2374,208.87499-14.2374,208.87499,6.3742,208.87499,14.2374Z"
                    style="fill: rgb(245, 245, 245); transform-origin: 259.723px 450.962px;" id="elglif4lw88a7"
                    class="animable"></path>
                </g>
                <g id="freepik--Text--inject-37" class="animable" style="transform-origin: 430.642px 305.186px;">
                  <g id="elj04pmbwucq" class="animable" style="transform-origin: 430.642px 305.186px;">
                    <rect x="411.48679" y="286.0311" width="38.31" height="38.31" rx="8.7845" ry="8.7845"
                      style="fill: rgb(64, 123, 255); transform-origin: 430.642px 305.186px;" id="eldu8uexs9hii"
                      class="animable"></rect>
                    <g id="ela4zknd1kgm">
                      <rect x="411.48679" y="286.0311" width="38.31" height="38.31" rx="8.7845" ry="8.7845"
                        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 430.642px 305.186px;"
                        class="animable">
                      </rect>
                    </g>
                    <g id="elisnlf9a1bjb" class="animable" style="transform-origin: 430.465px 303.739px;">
                      <path
                        d="M427.25889,308.3489h-5.5l-.9717,2.5225h-4.9619l6.3262-14.4727h4.7968l6.3272,14.4727h-5.0449l-.9717-2.5225Zm-1.3438-3.5146l-1.4062-3.6387-1.4063,3.6387h2.8125Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 424.55px 303.635px;" id="elz1m8zf71g4"
                        class="animable"></path>
                      <path
                        d="M445.10459,304.7923v6.0791h-4.3428v-1.4883c-.5371,1.1367-1.6123,1.6953-3.2041,1.6953-2.7705,0-4.1973-1.5713-4.1973-3.5156,0-2.1914,1.6543-3.4111,5.169-3.4111h1.8818c-.1455-.8682-.8066-1.3233-2.1299-1.3233-1.0136,0-2.1504,.3311-2.8945,.8897l-1.4892-3.0811c1.3232-.8271,3.3291-1.2822,5.0869-1.2822,3.8867,0,6.1201,1.6748,6.1201,5.4375Zm-4.6729,2.3984v-.7851h-1.1582c-.9922,0-1.4267,.331-1.4267,.9511,0,.5166,.414,.9092,1.11721,.9092,.64059,0,1.21969-.3301,1.46769-1.0752Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 439.232px 305.217px;" id="elatnxf6ka4ru"
                        class="animable"></path>
                    </g>
                  </g>
                </g>
                <g id="freepik--Image--inject-37" class="animable" style="transform-origin: 397.321px 262.493px;">
                  <g id="elxr3asdw782" class="animable" style="transform-origin: 397.321px 262.493px;">
                    <rect x="374.83689" y="242.8563" width="44.9683" height="39.2134" rx="5.05019" ry="5.05019"
                      style="fill: rgb(64, 123, 255); transform-origin: 397.321px 262.463px;" id="el9ok83h4qeb5"
                      class="animable"></rect>
                    <g id="eljq4qgovvc5">
                      <rect x="374.83689" y="242.8563" width="44.9683" height="39.2134" rx="5.05019" ry="5.05019"
                        style="fill: rgb(255, 255, 255); opacity: 0.6; transform-origin: 397.321px 262.463px;"
                        class="animable">
                      </rect>
                    </g>
                    <path
                      d="M409.27039,261.1534l-10.58539,8.2869-6.55081-3.8874c-1.2148-.7209-2.7499-.6097-3.8481,.2787l-13.4007,10.8407h0c-.0533,2.9664,2.3344,5.4011,5.3013,5.4055l34.2956,.0519c2.9494-.0333,5.3229-2.4337,5.3229-5.3833v-12.0991l-6.6944-3.7823c-1.2206-.6896-2.7365-.5758-3.8404,.2884Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 397.345px 271.273px;" id="elcne366zhs6a"
                      class="animable"></path>
                    <path
                      d="M404.23729,254.8633c0,2.6839-2.1255,4.8597-4.74729,4.8597s-4.74751-2.1758-4.74751-4.8597c0-2.6841,2.1256-4.8598,4.74751-4.8598s4.74729,2.1757,4.74729,4.8598Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 399.49px 254.863px;" id="elmbpa1x14qf"
                      class="animable">
                    </path>
                  </g>
                </g>
                <g id="freepik--Colors--inject-37" class="animable" style="transform-origin: 414.26px 284.367px;">
                  <rect x="390.09069" y="289.5825" width="12.0369" height="12.0368"
                    style="fill: rgb(64, 123, 255); transform-origin: 396.109px 295.601px;" id="elil4hbwk88pj"
                    class="animable">
                  </rect>
                  <rect x="390.09069" y="304.4105" width="12.0369" height="12.0369"
                    style="fill: rgb(38, 50, 56); transform-origin: 396.109px 310.429px;" id="el2ro8paql6jw"
                    class="animable">
                  </rect>
                  <g id="elhbjo1g8k5b" class="animable" style="transform-origin: 432.41px 258.306px;">
                    <rect x="426.392" y="252.2875" width="12.03679" height="12.0369"
                      style="fill: rgb(64, 123, 255); transform-origin: 432.41px 258.306px;" id="eliamgb8km4t"
                      class="animable">
                    </rect>
                    <g id="el0kwr5u95sjyp">
                      <rect x="426.392" y="252.2875" width="12.03679" height="12.0369"
                        style="fill: rgb(255, 255, 255); opacity: 0.8; transform-origin: 432.41px 258.306px;"
                        class="animable">
                      </rect>
                    </g>
                  </g>
                </g>
                <g id="freepik--Speech_Bubble--inject-37" class="animable"
                  style="transform-origin: 124.407px 109.83px;">
                  <g id="el0wkfaz5rbmm" class="animable" style="transform-origin: 124.407px 109.83px;">
                    <g id="el3g1r59ujcdf" class="animable" style="transform-origin: 124.407px 109.83px;">
                      <path
                        d="M111.0363,95.7741c7.3779-8.2007,20.0067-8.8679,28.2073-1.4901,8.2008,7.3778,8.8679,20.0067,1.4901,28.2074-5.6958,6.331-14.5151,8.1508-22.0167,5.2664l-5.3353,2.7426-.5982-6.3102c-.084-.073-.1741-.1342-.2573-.2089-8.2006-7.3776-8.8676-20.0066-1.4899-28.2072Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 125.885px 109.83px;" id="elg7cc6a2bxao"
                        class="animable"></path>
                      <g id="el49z5d3b2084">
                        <g style="opacity: 0.3; transform-origin: 125.885px 109.83px;" class="animable">
                          <path
                            d="M111.0363,95.7741c7.3779-8.2007,20.0067-8.8679,28.2073-1.4901,8.2008,7.3778,8.8679,20.0067,1.4901,28.2074-5.6958,6.331-14.5151,8.1508-22.0167,5.2664l-5.3353,2.7426-.5982-6.3102c-.084-.073-.1741-.1342-.2573-.2089-8.2006-7.3776-8.8676-20.0066-1.4899-28.2072Z"
                            style="fill: rgb(255, 255, 255); transform-origin: 125.885px 109.83px;" id="elbl4a5wjiojg"
                            class="animable"></path>
                        </g>
                      </g>
                      <path d="M113.019,90.4576c-5.6706,3.1646-9.5442,9.336-9.9284,15.8185"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 108.055px 98.3669px;"
                        id="el3trjn5tpsq2" class="animable"></path>
                      <path d="M103.0196,109.2553c-.1318,.9818-.0581,1.9904,.2148,2.9426"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 103.095px 110.727px;"
                        id="el1jcpyq094jj" class="animable"></path>
                    </g>
                    <polygon
                      points="125.1412 117.1545 116.7103 109.4607 119.3952 106.5188 125.0487 111.6779 134.4424 102.505 137.2248 105.3544 125.1412 117.1545"
                      style="fill: rgb(255, 255, 255); transform-origin: 126.968px 109.83px;" id="elblt8s3w2nt"
                      class="animable"></polygon>
                  </g>
                </g>
                <g id="freepik--Character_1--inject-37" class="animable" style="transform-origin: 134.85px 292.691px;">
                  <g id="elhpplzne4gl" class="animable" style="transform-origin: 134.85px 292.691px;">
                    <g id="elfs48ialmvfa" class="animable" style="transform-origin: 163.706px 204.999px;">
                      <g id="elclw8xs1bizk" class="animable" style="transform-origin: 186.475px 193.52px;">
                        <path
                          d="M178.4408,198.0596s-1.2573-2.4819-.5161-4.6597,.7536-4.114,1.4166-5.695c.6629-1.5809,1.3807,.6188,1.5257,1.8664,.145,1.2474,.3221,2.66,1.2414,2.3993,.9194-.2603,6.6646-6.1369,7.9804-6.3528,1.3158-.2162,.6838,1.025,.6838,1.025,0,0,1.4337-1.3896,2.139-.9716,.1831,.1085,.433,.6582-.1211,1.3939-1.2467,1.655-4.0419,4.9938-4.0419,4.9938,.1565-.1948,3.9011-4.0696,4.5376-4.5767,.8321-.6628,1.5624-.5511,1.9309-.0561,.5245,.7044-3.9544,5.6668-3.997,6.0081,0,0,2.483-2.9431,3.5936-2.7179,1.1104,.2252-.3516,2.1631-1.9652,3.6271-1.4793,1.3419-4.0299,4.3619-5.666,5.5802-2.0161,1.5014-3.7071,2.0565-5.7706,.9174-2.0636-1.1393-2.9711-2.7814-2.9711-2.7814Z"
                          style="fill: rgb(233, 179, 118); transform-origin: 186.475px 193.52px;" id="el3jf8hv3eenl"
                          class="animable"></path>
                        <g id="elnzxg7np7jzs">
                          <path d="M190.5489,186.7886c-1.1211,1.1425-2.1901,2.3362-3.2027,3.576"
                            style="fill: none; opacity: 0.3; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.25px; transform-origin: 188.948px 188.577px;"
                            class="animable"></path>
                        </g>
                        <g id="el0u5kf4wrjqw">
                          <path d="M190.2952,194.6105c-1.044-1.7848-2.6242-3.2514-4.4819-4.1597"
                            style="fill: none; opacity: 0.3; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.25px; transform-origin: 188.054px 192.531px;"
                            class="animable"></path>
                        </g>
                        <g id="elnifgnmytgu">
                          <path
                            d="M182.2763,192.9888c.5073,.6728,.771,1.5254,.7321,2.3671-.0388,.8417-.3799,1.6663-.947,2.2895"
                            style="fill: none; opacity: 0.3; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.25px; transform-origin: 182.537px 195.317px;"
                            class="animable"></path>
                        </g>
                      </g>
                      <path
                        d="M132.729,185.2922s4.2893-.7396,11.4608,7.0934c9.0645,9.9006,16.3937,17.3191,16.3937,17.3191l19.2342-13.584,4.766,4.7085s-17.9456,22.2911-22.4407,23.481c-4.495,1.1898-6.4512-.3272-9.3597-2.7069-2.9086-2.3797-21.0209-20.6243-20.6243-20.4921,.3966,.1322,.57-15.819,.57-15.819Z"
                        style="fill: rgb(235, 179, 115); transform-origin: 158.368px 204.999px;" id="elub17vxafbw7"
                        class="animable"></path>
                    </g>
                    <path
                      d="M138.6486,431.0106c-.0652-.5855-2.1658-12.8149-2.1658-12.8149l-11.7173,2.6675,3.3073,12.5147,8.0193,1.3539,2.5565-3.7212Z"
                      style="fill: rgb(235, 179, 115); transform-origin: 131.707px 426.464px;" id="ellus2n4vneeq"
                      class="animable"></path>
                    <path
                      d="M43.7894,197.0035c.5902,.3612,21.4183,18.4203,21.6591,18.2999s21.7912-17.0959,23.1156-17.6979c1.3243-.602,8.2238,11.4374,7.6033,11.4374s-21.2012,18.1173-29.7849,18.5993c-8.5837,.4819-26.8012-27.5084-26.8012-27.5084l4.2081-3.1303Z"
                      style="fill: rgb(235, 179, 115); transform-origin: 67.894px 212.326px;" id="el8sj8rywnuf"
                      class="animable"></path>
                    <polygon
                      points="224.36409 406.9033 217.24209 413.1021 200.94869 398.9392 208.07069 392.7303 224.36409 406.9033"
                      style="fill: rgb(235, 179, 115); transform-origin: 212.656px 402.916px;" id="eljq9vnsfprs"
                      class="animable"></polygon>
                    <g id="el0nhl9yd9gey" class="animable" style="transform-origin: 218.331px 417.177px;">
                      <path
                        d="M210.80519,410.6711l2.6423-2.6634c.5978-.6026,1.4558-.8577,2.2953-.7314,3.62121,.5451,3.9735-1.3958,3.6589-5.0227,.9298-1.1849,2.43231-.2617,2.8458-.0234l9.2178,5.1942c.7764,.4271,1.0596,1.4026,.6325,2.179-.076,.138-.17179,.2643-.2845,.3744-3.2088,3.2344-4.7269,4.7646-8.7206,8.7902l-12.2139,12.3114c-3.3295,3.3561-7.40179,.6961-6.3432-.9966,4.7936-7.5795,7.3313-13.7964,7.4291-17.512,.0368-.6703-.5631-.5394-1.1595-1.8997Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 218.331px 417.177px;" id="elrz3zzf1pzb"
                        class="animable"></path>
                      <path
                        d="M208.23049,415.6891c1.1704-.1818,2.3189-.4853,3.42651-.9055,.10219-.0525,.14249-.178,.08989-.2804-.0284-.0552-.0801-.095-.1408-.1082-.5556-.1866-5.49109-1.7514-6.5489-.7732-.2,.1709-.2845,.4412-.2178,.6955,.0689,.4814,.3622,.9014,.7905,1.1318,.81911,.3551,1.7304,.4392,2.6006,.24Zm2.6612-1.0894c-1.3389,.4346-3.8446,1.1085-5.0043,.4575-.3022-.1797-.5125-.4807-.5776-.826-.036-.1066-.0022-.2242,.085-.2954,.5847-.5334,3.4548,.0666,5.4969,.6639Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 208.293px 414.549px;" id="el40dqmckodrx"
                        class="animable"></path>
                      <path
                        d="M211.60989,414.8022c.0494,.0079,.10011,.0012,.1458-.0191,.0549-.0624,.0675-.1514,.0321-.2265-.0255-.1943-1.71779-4.6294-4.2297-4.0041-.6204,.1676-.7628,.4951-.7537,.7532,.001,1.2351,3.2281,3.1471,4.701,3.5472l.1045-.0507Zm-3.7491-3.9188c1.6151-.2115,2.8999,2.3377,3.3555,3.365-1.623-.6027-3.9976-2.2311-3.961-2.9894-.008-.0607-.0302-.2307,.437-.3413l.1685-.0343Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 209.306px 412.673px;" id="elunjhoi2wd6d"
                        class="animable"></path>
                      <path d="M211.02619,414.5591c1.0353-.7931,2.4277-1.1004,3.7007-.8165"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 212.877px 414.099px;"
                        id="elwtnqwe9hffg" class="animable"></path>
                      <path d="M210.63,417.4505c1.03529-.7932,2.42769-1.1004,3.70079-.8164"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 212.48px 416.99px;"
                        id="el9semc73xp8v" class="animable"></path>
                      <path d="M209.63449,420.5492c1.0353-.7931,2.4278-1.1003,3.7008-.8164"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 211.485px 420.089px;"
                        id="elhhufd8q04" class="animable"></path>
                    </g>
                    <g id="elb5mj5s7hz79" class="animable" style="transform-origin: 70.5596px 295.919px;">
                      <g id="elpv3sbrci0f" class="animable" style="transform-origin: 85.4651px 426.041px;">
                        <g id="el7hp4dw4ytkd">
                          <ellipse cx="85.46507" cy="426.0412" rx="14.30722" ry="16.61996"
                            style="fill: rgb(64, 123, 255); transform-origin: 85.4651px 426.041px; transform: rotate(-7.2829deg);"
                            class="animable"></ellipse>
                        </g>
                      </g>
                      <polygon
                        points="39.3555 178.3315 48.8616 147.4965 67.7186 174.7071 99.4565 423.0718 71.0935 426.6963 39.3555 178.3315"
                        style="fill: rgb(64, 123, 255); transform-origin: 69.406px 287.096px;" id="elb2f24zwrm2f"
                        class="animable"></polygon>
                      <path
                        d="M53.8506,154.6744c-2.2579,2.2557-4.7549,2.5951-7.5076,.9797l-6.9875,22.6774s3.2627,6.076,7.0613-.4664c0,0,4.3369,5.4266,8.0877-1.0335,0,0,4.0685,4.2427,7.0552-.9016,0,0,3.5083,3.3424,6.1589-1.2229l-13.868-20.0327Z"
                        style="fill: rgb(185, 121, 100); transform-origin: 53.5371px 167.787px;" id="el56q7mblgym"
                        class="animable"></path>
                      <g id="el76xwdefqey8">
                        <path
                          d="M54.6058,176.8513l33.3457,248.0358,6.6029-.8438c1.0113-.4417-32.8934-248.0936-32.8934-248.0936-2.9867,5.1442-7.0552,.9016-7.0552,.9016Z"
                          style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 74.5912px 300.418px;"
                          class="animable"></path>
                      </g>
                      <g id="elh3rek89g4td">
                        <path
                          d="M46.4496,177.948c-2.0945,3.6076-4.0248,3.3768-5.3363,2.4453l33.1126,246.3022,6.603-.8438c.9628-.4205-29.7274-224.9497-32.6674-246.4415-1.0405-.6253-1.7119-1.4622-1.7119-1.4622Z"
                          style="fill: rgb(255, 255, 255); opacity: 0.3; transform-origin: 60.9822px 302.322px;"
                          class="animable"></path>
                      </g>
                      <g id="elfjzps8tlnrh">
                        <rect x="70.3221" y="423.10349" width="30.28589" height="4.88727"
                          style="fill: rgb(38, 50, 56); transform-origin: 85.465px 425.547px; transform: rotate(172.718deg);"
                          class="animable"></rect>
                      </g>
                    </g>
                    <g id="elktkodk3687k" class="animable" style="transform-origin: 43.4742px 195.013px;">
                      <path
                        d="M43.0681,200.0999s5.5456-2.8649,5.9914-4.5268c.4457-1.6621-1.9495-1.1204-1.9495-1.1204,0,0,2.5988-.7578,2.4261-1.9047-.207-1.3766-2.7139-.7979-2.7139-.7979,0,0,2.0966-.7058,1.8747-1.7155-.2303-1.0476-3.1061-.5956-3.1061-.5956,0,0,1.3823-.776,.8308-1.3909-.5517-.6146-6.6737,.2267-8.2652,1.6794s-.2693,9.2878,.4217,10.9149c.1985,.4671,.6758,1.203,2.983,1.5301,.4835,.0686,.9477-.2454,1.056-.7215l.2875-1.264,.1635-.0871Z"
                        style="fill: rgb(235, 179, 115); transform-origin: 43.4742px 195.013px;" id="elm253we0mi2i"
                        class="animable"></path>
                      <g id="elil6fgts3fod">
                        <path
                          d="M47.0816,194.3258l-.0108,.0031-4.4673,1.538c-.0598,.0209-.091,.0932-.0692,.1614,.022,.0684,.0907,.1077,.1481,.0868l4.4672-1.5382c.0598-.0208,.091-.0932,.0693-.1612-.0206-.0647-.0806-.1029-.1373-.0899Z"
                          style="opacity: 0.2; transform-origin: 44.8766px 195.222px;" class="animable"></path>
                      </g>
                      <g id="el7hdbzcqwic3">
                        <path
                          d="M46.6629,191.6526l-.0029,.0007-4.4083,1.1362c-.0614,.016-.0969,.0851-.0795,.1545,.0176,.0703,.0699,.1043,.143,.098l4.4083-1.1361c.0614-.0159,.0968-.0852,.0796-.1548-.0174-.0688-.0796-.1122-.1402-.0985Z"
                          style="opacity: 0.2; transform-origin: 44.4876px 192.346px;" class="animable"></path>
                      </g>
                      <g id="elagc5wej8ene">
                        <path
                          d="M45.4104,189.3464l-3.2998,.7505c-.0619,.0141-.0989,.0825-.0829,.1525,.0158,.0698,.0787,.1156,.1406,.1016l3.3-.7506c.0619-.014,.0988-.0824,.0829-.1524-.0158-.0699-.0789-.1156-.1408-.1016Z"
                          style="opacity: 0.2; transform-origin: 43.7894px 189.849px;" class="animable"></path>
                      </g>
                    </g>
                    <g id="ellvhwaad0c5i" class="animable" style="transform-origin: 125.504px 435.886px;">
                      <path
                        d="M126.615,429.9658h3.7517c.8489,0,1.6342,.4295,2.1358,1.1144,2.1635,2.9546,3.7895,1.8378,6.1427-.94,1.496-.1744,1.8987,1.5425,2.0208,2.0039l2.8046,10.202c.2436,.852-.2495,1.7401-1.1014,1.9837-.1516,.0432-.3086,.0641-.4662,.0617h-29.7242c-4.7276,0-5.7072-4.7644-3.76-5.2051,8.7569-1.9351,14.9576-4.512,17.6643-7.0594,.5018-.446-.0137-.7797,.5319-2.1612Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 125.504px 437.179px;" id="eltvhc2dxym9"
                        class="animable"></path>
                      <path
                        d="M121.2393,431.6722c.9535,.7028,1.9778,1.3044,3.0561,1.7947,.1093,.0356,.2268-.0242,.2624-.1336,.0192-.0591,.011-.1237-.0223-.1762-.2589-.5258-2.624-5.1317-4.0635-5.1937-.2622-.0216-.5135,.1088-.647,.3352-.2933,.388-.3849,.892-.2468,1.3583,.3247,.8316,.9068,1.5378,1.6611,2.0153Zm2.6477,1.1219c-1.2514-.6444-3.4946-1.9486-3.8493-3.2304-.0852-.3411-.0197-.7024,.1797-.9918,.0503-.1006,.1576-.1594,.2695-.1477,.7905,.0395,2.3859,2.4996,3.4001,4.3699Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 122.042px 430.719px;" id="elampomk0hy"
                        class="animable"></path>
                      <path
                        d="M124.2491,433.4466c.0292,.0406,.0696,.0719,.1162,.09,.0829-.0049,.1551-.0586,.1834-.1367,.12-.1549,2.0767-4.4799-.1364-5.8228-.5559-.3223-.8887-.1928-1.0655-.0045-.8761,.8706,.0393,4.5082,.7927,5.8355l.1096,.0385Zm.1416-5.4215c1.2875,.9976,.3827,3.7051-.0257,4.7521-.7151-1.5767-1.2316-4.4094-.6675-4.9174,.0375-.0484,.1425-.1839,.5501,.0698l.1431,.0955Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 124.225px 430.459px;" id="elw6v4uawncsl"
                        class="animable"></path>
                      <path d="M124.0105,432.8611c1.2922,.1763,2.491,.9484,3.1861,2.0521"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 125.604px 433.887px;"
                        id="elno4zlmjwccd" class="animable"></path>
                      <path d="M121.6788,434.6161c1.2924,.1764,2.491,.9485,3.186,2.0523"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 123.272px 435.642px;"
                        id="el1j5cv8deh7z" class="animable"></path>
                      <path d="M118.7779,436.0918c1.2923,.1764,2.491,.9486,3.1861,2.0523"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 120.371px 437.118px;"
                        id="elp2bmrigvio" class="animable"></path>
                    </g>
                    <g id="elyg7lb6zsec" class="animable" style="transform-origin: 121.797px 214.818px;">
                      <path
                        d="M98.3482,192.5655s-6.6325,14.8091-5.809,20.6163c1.1,7.7574,7.7751,17.4585,14.2491,32.2084l36.342-5.6317c-4.4133-14.7006-7.7576-22.9152-10.3732-55.5088-5.3799-.0042-7.8654,.2437-13.1844,1.3833-5.6685,1.1419-11.249,2.6845-16.6992,4.6162-7.0105,2.5871-4.5253,2.3163-4.5253,2.3163Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 117.799px 214.82px;" id="elb9wrjqkna95"
                        class="animable"></path>
                      <path
                        d="M130.5892,184.756s2.3427-1.4002,6.0371,.4373,14.5001,12.5169,14.5001,12.5169c0,0-12.8911,16.0506-13.0806,16.0506-.1896,0-7.0775-9.3524-7.0775-9.3524l-.3791-19.6524Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 140.858px 199.003px;" id="el10q108vmh11b"
                        class="animable"></path>
                    </g>
                    <path
                      d="M93.1358,210.1102l-8.8079-10.3035s10.8021-7.2291,13.8765-8.3924c3.0745-1.1633,4.6691-1.1651,4.6691-1.1651l-9.7377,19.861Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 93.6007px 200.18px;" id="ellmessc1vcj"
                      class="animable">
                    </path>
                    <g id="el9aafta8lqk" class="animable" style="transform-origin: 159.101px 329.412px;">
                      <path
                        d="M106.7883,245.3902c-6.2468,21.368-4.6227,70.6217,1.0361,106.1499,4.0604,32.0972,13.7931,72.6428,13.7931,72.6428l19.5547-3.0659s-6.5437-52.5631-8.1163-74.9742c-2.1305-30.8825-2.2571-104.4733-2.2571-104.4733l-25.9273-.6626,1.9168,4.3833Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 121.979px 332.595px;" id="elulygrigw18"
                        class="animable"></path>
                      <path
                        d="M105.059,241.0069s12.9279,13.9749,22.457,34.1603c9.924,21.0216,16.605,48.6121,23.9233,65.5985,11.586,26.8954,53.9632,64.4738,53.9632,64.4738l10.0134-8.3091s-30.2737-42.8337-38.2886-61.0751c-25.6677-58.3966-18.0485-83.131-33.997-96.178l-1.5047-5.0358-36.5666,6.3654Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 160.237px 319.94px;" id="eltm3czuzkst"
                        class="animable"></path>
                    </g>
                    <path
                      d="M92.2265,154.6918c.0614-.02,.1146-.0595,.1517-.1123,.0717-.1321,.0244-.2974-.1062-.3717-2.7978-2.023-3.6408-5.8186-1.9622-8.8356,2.8064-5.4543,9.8718-3.5219,9.9446-3.5083,.1385,.0638,.3025,.0032,.3663-.1353,.0637-.1386,.0031-.3025-.1354-.3663-.0261-.0119-.0538-.0199-.0822-.0233-.0637,.0183-7.5767-2.0735-10.5894,3.7844-1.7581,3.2667-.8468,7.3292,2.1382,9.532,.0807,.0547,.1825,.0682,.2746,.0364Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 94.7844px 147.85px;" id="el5os45tnl6nb"
                      class="animable">
                    </path>
                    <path
                      d="M92.7529,154.8859c.0886-.0261,.1592-.0934,.1897-.1805,.0419-.1426-.0366-.2928-.1775-.3399,0,0-3.312-1.0982-4.3392-3.527-.4893-1.2702-.3813-2.693,.294-3.8748,.0766-.1319,.0316-.3009-.1003-.3773l-.0089-.005c-.1361-.0719-.3047-.0237-.3823,.1093-.7489,1.3266-.8629,2.9192-.3108,4.3391,1.129,2.664,4.5274,3.7834,4.6791,3.8319,.0482,.0245,.1029,.0329,.1562,.0242Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 90.263px 150.719px;" id="elzvnpnpn6pe"
                      class="animable">
                    </path>
                    <path
                      d="M124.1751,173.8081c2.0549-.4027,3.7986-1.7528,4.7029-3.6414,.0718-.1321,.0245-.2974-.1062-.3717-.1256-.0751-.2884-.0342-.3636,.0915l-.0081,.0147c-1.8733,4.7397-11.2705,3.8527-11.3646,3.845-.1514-.0182-.2889,.0898-.3071,.2412l-.0009,.0077c-.0181,.1452,.085,.2777,.2302,.2959l.0157,.0015c2.4108,.2417,4.845,.078,7.2017-.4844Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 122.818px 172.079px;" id="elzfo8itf2tmh"
                      class="animable">
                    </path>
                    <path
                      d="M161.9716,158.1156c-1.8525,.9487-3.9593,1.3949-6.0374,1.2788,2.7932-2.471,3.8739-6.7006,2.608-10.2086-4.8166,3.6186-11.8497,3.9771-17.0094,.8671-3.8142-2.2991-6.6176-6.2575-10.8275-7.7106-3.6523-1.2607-11.2374-1.0075-12.2267,4.3924l1.6368,3.984c2.802-1.2394,7.1598,3.0393,8.6981,5.6891,1.5382,2.6498,1.7412,5.8342,2.0495,8.8826,.3081,3.0483,.8377,6.2654,2.8388,8.5856,1.7363,2.0133,4.3945,3.0687,7.0426,3.303,2.6482,.2343,5.3074-.2791,7.873-.9759,1.0563-.2868,2.1549-.6355,2.9087-1.429,.7537-.7936,1.0049-2.1704,.2516-2.9644-1.8446,.2892-3.7838-.0664-5.4059-.9913,3.884,.3163,7.9751-.3419,11.1559-2.593,3.1807-2.251,5.2398-6.2952,4.4439-10.1098Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 140.311px 159.475px;" id="elxyulnzj7xtb"
                      class="animable">
                    </path>
                    <path
                      d="M91.8579,156.7959c-2.1145-5.1085-.1026-10.9923,4.6971-13.7366,3.1744-2.0424,7.4192-2.313,11.4809-1.8554,8.8594,.9981,15.73,8.2981,16.3085,17.1947,.4756,7.3146-1.5817,15.2378-11.8757,17.307-20.3186,4.0843-20.6108-18.9097-20.6108-18.9097Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 107.714px 158.602px;" id="elrzxfvmx2di"
                      class="animable">
                    </path>
                    <path
                      d="M121.0159,185.4244s-3.1699,3.0674-7.204,7.4322c-.8405,.9093-1.7873,1.7208-2.808,2.4216-2.7539,1.8911-5.3245,3.0864-6.2092,1.7555-.1963-.2923-.3585-.6147-.4903-.9573-.8843-2.2966-1.0113-5.6524,.7283-7.3928,1.9612-1.9626,2.8337-3.55,2.18-5.8297-.3542-1.2942-.986-2.6-1.6939-3.8575l2.3204-1.7793,7.8562-9.9707c-.3895,5.609,.2746,15.4636,5.3205,18.178Z"
                      style="fill: rgb(235, 179, 115); transform-origin: 112.344px 182.423px;" id="el8obgj73i717"
                      class="animable"></path>
                    <g id="elwniaad2r1v8">
                      <path
                        d="M107.343,183.356c2.2764-1.7276,4.6687-4.5467,4.0203-7.3823-.2811-1.193-.6723-2.3093-1.0458-3.0108l-4.2109,7.1306c.5277,1.0119,.7563,1.6945,1.2364,3.2625Z"
                        style="opacity: 0.2; transform-origin: 108.789px 178.159px;" class="animable"></path>
                    </g>
                    <path
                      d="M116.2639,156.95c.2038,9.2162,.4835,13.1027-3.6782,18.4216-6.2599,8.0008-17.4278,6.8221-20.3333-2.0052-2.6152-7.9449-2.5092-21.6817,6.0084-26.5354,8.3914-4.7819,17.7991,.9027,18.0031,10.119Z"
                      style="fill: rgb(235, 179, 115); transform-origin: 103.556px 162.917px;" id="elucmkq4q4vtt"
                      class="animable"></path>
                    <path
                      d="M104.3119,161.3411c.0651,.9701-.3672,1.8221-.9657,1.9028-.5984,.0808-1.1361-.6402-1.2011-1.6103-.0654-.9701,.367-1.8221,.9654-1.9029,.5983-.0808,1.1361,.6401,1.2014,1.6104Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 103.228px 161.487px;" id="eljuva30oyra"
                      class="animable">
                    </path>
                    <path
                      d="M95.0411,162.3488c.0612,.9403-.3728,1.768-.9693,1.8487-.5967,.0804-1.13-.6164-1.1912-1.5568-.0611-.9402,.373-1.7679,.9696-1.8485,.5964-.0808,1.1297,.6162,1.1909,1.5566Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 93.9609px 162.495px;" id="ely91xbkws1j8"
                      class="animable">
                    </path>
                    <path
                      d="M106.3085,157.6874c-.1148,.0262-.2365,.0058-.3362-.067-1.5533-1.1411-3.0834-.504-3.1012-.4964-.2267,.0949-.479-.0107-.56-.2439-.0804-.2328,.0399-.5009,.2692-.5999,.0799-.0338,2-.8355,3.9277,.5811,.192,.1411,.2281,.4258,.0805,.6355-.0716,.1007-.1727,.1661-.28,.1906Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 104.483px 156.861px;" id="elungop09kme"
                      class="animable">
                    </path>
                    <path
                      d="M92.2434,158.031c-.0954-.0202-.1797-.08-.2313-.1754-.1065-.1972-.0349-.4711,.1595-.6116,1.9512-1.4095,3.5982-.6948,3.6678-.6628,.1959,.0882,.2696,.342,.1644,.5676-.1045,.2251-.3479,.3361-.5436,.2493-.0641-.029-1.3506-.562-2.902,.5593-.1004,.0723-.2139,.0953-.3148,.0736Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 94.0072px 157.205px;" id="elhgln40miyh7"
                      class="animable">
                    </path>
                    <path
                      d="M98.1865,161.5399s-1.0379,3.5768-2.4051,5.8886c-.239,.4039-.003,.9272,.453,1.0384,1.1735,.2861,2.4489-.2392,2.4489-.2392l-.4968-6.6878Z"
                      style="fill: rgb(228, 135, 62); transform-origin: 97.185px 165.046px;" id="el84i732tfjse"
                      class="animable"></path>
                    <path d="M103.4586,159.7314l-2.1498-.256s1.3227,1.4306,2.1498,.256Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 102.384px 159.835px;" id="elkanez9fc1bg"
                      class="animable">
                    </path>
                    <path d="M94.169,160.7902l-2.1499-.2561s1.3227,1.4307,2.1499,.2561Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 93.094px 160.894px;" id="el2vk1bimiy7l"
                      class="animable">
                    </path>
                    <path
                      d="M110.2713,158.9434s-4.2415-5.9669-1.638-11.0718c0,0-17.3211-2.8479-17.8307,13.7838,0,0-3.4749-15.1987,11.83-18.4292,15.305-3.2306,19.4462,19.2622,12.7573,27.4341-.0044,.0235-1.7173-7.583-5.1186-11.7169Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 104.471px 156.786px;" id="elzlpleolqqqn"
                      class="animable">
                    </path>
                    <path
                      d="M119.8705,164.0692c-.864,1.9934-2.4809,3.3676-4.0402,3.9531-2.3452,.8806-4.0343-2.8591-3.4859-5.3718,.4938-2.262,2.7019-3.9737,5.1057-3.7667,2.3681,.204,3.4086,2.9062,2.4204,5.1854Z"
                      style="fill: rgb(235, 179, 115); transform-origin: 116.241px 163.51px;" id="elcoztqqlssyt"
                      class="animable"></path>
                    <g id="elvb1nqam3l1r">
                      <path
                        d="M114.7132,164.1646c-.0409-.8703,.2761-1.7522,.8619-2.3973,.5856-.6453,1.4327-1.046,2.3029-1.0894"
                        style="fill: none; opacity: 0.2; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 116.294px 162.421px;"
                        class="animable"></path>
                    </g>
                    <path
                      d="M98.3886,171.2297c.6843,.8884,1.3049,1.8243,1.857,2.8005l.5735-.1639c2.5169-.9605,3.3588-2.2349,3.556-3.3136,.0778-.5487,.0205-1.108-.167-1.6295-.0954-.3001-.2313-.5859-.4036-.8495-1.2883,1.2837-2.8401,2.272-4.5482,2.8965-.5341,.1411-.8677,.2595-.8677,.2595Z"
                      style="fill: rgb(38, 50, 56); transform-origin: 101.399px 171.052px;" id="eljf2anicqnu"
                      class="animable">
                    </path>
                    <path
                      d="M99.232,170.8852l.5765,.8116c2.3607-.9043,3.9127-1.8647,4.3754-2.8584-.0955-.3002-.2313-.586-.4037-.8497-1.2883,1.2837-2.8401,2.272-4.5482,2.8965Z"
                      style="fill: rgb(255, 255, 255); transform-origin: 101.708px 169.843px;" id="eldlxm6vnzkl"
                      class="animable"></path>
                    <path
                      d="M100.807,173.8237c2.5168-.9604,3.3588-2.2348,3.5559-3.3135-.9505,.3814-1.8245,.9308-2.5806,1.6219-.4921,.4496-.8328,1.0405-.9753,1.6916Z"
                      style="fill: rgb(193, 88, 84); transform-origin: 102.585px 172.167px;" id="ellvbrqvr5vys"
                      class="animable"></path>
                  </g>
                </g>
                <g id="freepik--Device--inject-37" class="animable animator-active"
                  style="transform-origin: 250px 267.746px;">
                  <g id="eltv4se0hk4xa" class="animable" style="transform-origin: 250px 267.746px;">
                    <path
                      d="M194.1318,97.1379h111.7364c15.57002,0,28.2109,12.64088,28.2109,28.2109V416.86001c0,11.86346-9.63163,21.49509-21.49509,21.49509H184.234c-10.10728,0-18.3131-8.20582-18.3131-18.3131V125.3488c0-15.57002,12.64088-28.2109,28.2109-28.2109Z"
                      style="fill: rgb(64, 123, 255); transform-origin: 250px 267.746px;" id="elpg7en1mg08"
                      class="animable">
                    </path>
                    <rect x="172.7121" y="105.1629" width="154.5758" height="325.1672" rx="20.6766" ry="20.6766"
                      style="fill: rgb(255, 255, 255); transform-origin: 250px 267.746px;" id="el0pk1fph7giff"
                      class="animable">
                    </rect>
                    <rect x="215.776" y="120.3262" width="63.59609" height="3.9756"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 247.574px 122.314px;"
                      id="elz7qqcr9eox8" class="animable"></rect>
                    <g id="elc1n4efae5bb" class="animable" style="transform-origin: 301.853px 121.884px;">
                      <path
                        d="M303.37469,117.9723c0,.8492-.6814,1.5376-1.5219,1.5376-.8406,0-1.522-.6884-1.522-1.5376,0-.8491,.6814-1.5375,1.522-1.5375,.8405,0,1.5219,.6884,1.5219,1.5375Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 301.853px 117.972px;" id="eli08j6m78sm"
                        class="animable"></path>
                      <path
                        d="M303.37469,121.8841c0,.8491-.6814,1.5375-1.5219,1.5375-.8406,0-1.522-.6884-1.522-1.5375,0-.8492,.6814-1.5376,1.522-1.5376,.8405,0,1.5219,.6884,1.5219,1.5376Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 301.853px 121.884px;" id="elwcdb12rylts"
                        class="animable"></path>
                      <path
                        d="M303.37469,125.7958c0,.8492-.6814,1.5376-1.5219,1.5376-.8406,0-1.522-.6884-1.522-1.5376s.6814-1.5375,1.522-1.5375c.8405,0,1.5219,.6883,1.5219,1.5375Z"
                        style="fill: rgb(64, 123, 255); transform-origin: 301.853px 125.796px;" id="elsa6t1u2wqxr"
                        class="animable"></path>
                    </g>
                    <polyline points="198.15489 119.0492 195.2402 121.8486 198.15489 124.719"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 196.698px 121.884px;"
                      id="el4blovyu4or6" class="animable"></polyline>
                    <rect x="185.6368" y="148.9393" width="129.2001" height="22.8333"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.237px 160.356px;"
                      id="elh9vocs42b3" class="animable"></rect>
                    <path d="M172.6644,137.4203h155.3191"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.324px 137.42px;"
                      id="elix7yjckhbyc" class="animable"></path>
                    <rect x="185.6368" y="182.7324" width="129.2001" height="65.3134"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.237px 215.389px;"
                      id="elvuayt5amfkq" class="animable"></rect>
                    <path
                      d="M185.6368,248.0458l40.6818-32.8074c1.452-1.1709,3.4737-1.3,5.0601-.3231l21.1782,13.0421,27.6696-21.3778c1.6159-1.2484,3.8294-1.3518,5.5524-.2592l29.058,18.4264"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.237px 226.806px;"
                      id="el81ta3nuq2xq" class="animable"></path>
                    <ellipse cx="251.74912" cy="201.9332" rx="8.09183" ry="8.1749"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 251.749px 201.933px;"
                      id="elou64pv4ed0i" class="animable"></ellipse>
                    <rect x="185.6368" y="257.8908" width="28.723" height="6.6409"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 199.998px 261.211px;"
                      id="elbeggt59sv2c" class="animable"></rect>
                    <rect x="185.6368" y="272.2795" width="129.2001" height="4.4272"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.237px 274.493px;"
                      id="el04r5a0n9bt3h" class="animable"></rect>
                    <rect x="185.6368" y="282.2408" width="129.2001" height="4.4273"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.237px 284.454px;"
                      id="el4vpebno8yy5" class="animable"></rect>
                    <rect x="185.6368" y="292.2022" width="57.5938" height="4.4272"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 214.434px 294.416px;"
                      id="elxu832tewcr" class="animable"></rect>
                    <rect x="185.6368" y="373.2759" width="129.2001" height="4.4273"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.237px 375.49px;"
                      id="eljn0fbuvfkh" class="animable"></rect>
                    <rect x="185.6368" y="383.2372" width="129.2001" height="4.4273"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 250.237px 385.451px;"
                      id="el9ha7wp9lath" class="animable"></rect>
                    <rect x="185.6368" y="393.1986" width="57.5938" height="4.4273"
                      style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 214.434px 395.412px;"
                      id="elzt7hbb5u71f" class="animable"></rect>
                    <g id="el25q4cprq3a6" class="animable" style="transform-origin: 212.804px 334.274px;">
                      <rect x="185.6095" y="306.7097" width="54.5692" height="55.1293"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 212.894px 334.274px;"
                        id="elfdhbk7orkzn" class="animable"></rect>
                      <line x1="185.6095" y1="361.839" x2="240.17869" y2="306.7097"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 212.894px 334.274px;"
                        id="eldrqw1td0imd" class="animable"></line>
                      <line x1="239.99959" y1="361.839" x2="185.4303" y2="306.7097"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 212.715px 334.274px;"
                        id="el06fttnw3ghzh" class="animable"></line>
                    </g>
                    <g id="el1rq98mhi0yv" class="animable" style="transform-origin: 284.864px 334.775px;">
                      <rect x="257.66889" y="307.2106" width="54.5692" height="55.1293"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 284.953px 334.775px;"
                        id="ela7myfv01jsq" class="animable"></rect>
                      <line x1="257.66889" y1="362.3399" x2="312.23809" y2="307.2106"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 284.953px 334.775px;"
                        id="elaup03gycph9" class="animable"></line>
                      <line x1="312.059" y1="362.3399" x2="257.48969" y2="307.2106"
                        style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; transform-origin: 284.774px 334.775px;"
                        id="elnis439zezzk" class="animable"></line>
                    </g>
                  </g>
                </g>
                <g id="freepik--Character_2--inject-37" class="animable" style="transform-origin: 378.352px 366.186px;">
                  <g id="eln9kys2l6h29" class="animable" style="transform-origin: 378.352px 366.186px;">
                    <g id="eld02vh93truo" class="animable" style="transform-origin: 378.352px 393.08px;">
                      <g id="el60ilur5mo9y" class="animable" style="transform-origin: 386.694px 430.577px;">
                        <g id="el20awdqfuo6q">
                          <path
                            d="M347.54969,401.2996s89.7606-18.2571,93.45331,.4355c5.27089,26.6828-59.57291,42.6166-93.79911,40.5151-34.2261-2.1015,.3458-40.9506,.3458-40.9506Z"
                            style="isolation: isolate; opacity: 0.2; transform-origin: 386.694px 417.874px;"
                            class="animable">
                          </path>
                        </g>
                        <g id="el566d51mvcq">
                          <path
                            d="M411.58369,406.6018l-42.6486,25.3711,5.3532,8.105s-3.0709,4.2741-8.2789-9.2877l26.4059-16.3577s-8.0828-1.0527-7.8419-2.3144c.4057-2.0715,18.3323-5.5058,26.5074-7.0504,.4236-.1391,.8796,.0919,1.0186,.5155,.1389,.4237-.092,.8797-.5157,1.0186Z"
                            style="isolation: isolate; opacity: 0.2; transform-origin: 389.075px 422.872px;"
                            class="animable">
                          </path>
                        </g>
                        <g id="elvotvxybsube">
                          <path
                            d="M367.50529,428.9307l6.9149,11.9817c-9.0017,1.3-18.1178,1.7481-27.2163,1.3378-2.99189-.1068-5.9402-.7311-8.7017-1.8429l-2.4134-4.7404,5.8189-10.888,25.5976,4.1518Z"
                            style="isolation: isolate; opacity: 0.2; transform-origin: 355.254px 433.588px;"
                            class="animable">
                          </path>
                        </g>
                        <g id="elru4tg4d1dw" class="animable" style="transform-origin: 369.977px 446.25px;">
                          <polygon
                            points="381.50359 424.6598 362.53479 435.9199 365.60539 445.0557 387.75739 435.8753 381.50359 424.6598"
                            style="fill: rgb(137, 85, 85); transform-origin: 375.146px 434.858px;" id="elyanfaincegq"
                            class="animable"></polygon>
                          <g id="elk6a7z36ucah" class="animable" style="transform-origin: 361.649px 450.691px;">
                            <path
                              d="M352.19629,438.6444l1.6361,6.5535s1.3639,.3298,1.7511,1.7099c.21751,.7755-.4104,2.8772-.4104,2.8772l4.3657,18.0554c.0263-.0063,1.2379-.2955,1.725-.6256,.5938-.4025,1.0777-.8182,1.469-1.2433,1.3673-1.489,1.5781-3.6985,1.6758-5.3369,.1638-2.746,1.9795-10.7277,1.9795-10.7277,0,0,.6645-2.0739,2.9272-3.8511,1.2621-.9914,1.86971-2.2717,1.7784-3.078-.18369-1.645-1.3423-2.295-2.8796-2.1554l-1.7856,.1644c-1.2436-.985-1.5866-2.5974-.7362-4.1172,0,0,1.1674-3.8708-.4878-3.2626-.94449,.3474-5.1096,2.0604-8.44,3.3147-.776,.2931-1.5108,.5689-2.1553,.8111l-.00019,.0019c-1.43041,.5382-2.41271,.9097-2.41271,.9097Z"
                              style="fill: rgb(38, 50, 56); transform-origin: 361.649px 450.691px;" id="el1apmddsyobk"
                              class="animable"></path>
                            <path
                              d="M365.575,454.1681c.05339-.0991,.03039-.2256-.05141-.3051-.374-.3645-.8407-.6323-1.3741-.7604-.281-.0675-.42349,.3831-.1397,.4511,.4739,.1138,.8857,.3474,1.2133,.6655,.1095,.1063,.2781,.0861,.3497-.0468l.00221-.0043Z"
                              style="fill: rgb(64, 123, 255); transform-origin: 364.729px 453.691px;" id="elh0vdunl8lpr"
                              class="animable"></path>
                            <path
                              d="M365.98779,452.5935c.0534-.0991,.0301-.2256-.0515-.3052-.3741-.3644-.8408-.6323-1.374-.7604-.281-.0674-.4235,.3832-.1398,.4513,.4738,.1138,.8856,.3471,1.2133,.6653,.1095,.1063,.2781,.0862,.3495-.0467l.0025-.0043Z"
                              style="fill: rgb(64, 123, 255); transform-origin: 365.142px 452.117px;" id="eldw9po8nkhit"
                              class="animable"></path>
                            <path
                              d="M366.33919,450.988c.0533-.0991,.0302-.2256-.0515-.3051-.374-.3645-.8409-.6324-1.374-.7605-.281-.0673-.4236,.3832-.13969,.4513,.47389,.1136,.88549,.3471,1.21319,.6654,.1096,.1063,.2782,.0861,.3496-.0469l.0024-.0042Z"
                              style="fill: rgb(64, 123, 255); transform-origin: 365.494px 450.511px;" id="el5mwsuaicos"
                              class="animable"></path>
                          </g>
                          <g id="ellp8pxd9i2l">
                            <path
                              d="M354.60919,437.7329l-.00019,.0018c-1.43051,.5381-2.41281,.9097-2.41281,.9097l1.6362,6.5535s1.3639,.3298,1.7511,1.7099c.21751,.7755-.4104,2.8772-.4104,2.8772l4.3657,18.0554c.0263-.0065,1.2379-.2955,1.725-.6256,.2728-.1849,.5163-.3732,.7445-.5632l-7.04-29.0538c-.1178,.0442-.2467,.0927-.3591,.1351Z"
                              style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 357.102px 452.719px;"
                              class="animable"></path>
                          </g>
                          <path
                            d="M366.08979,450.8713c.613-.206,1.2299-.4132,1.8695-.5091,.63971-.0957,1.30971-.0751,1.91071,.1641,.27949,.1113,.56689,.3046,.61949,.6006,.0553,.3114-.1753,.6053-.4378,.7814-.50469,.3389-1.1593,.4057-1.753,.2748-.5936-.131-1.7253-.9434-2.2089-1.3118"
                            style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; stroke-width: 0.25px; transform-origin: 368.294px 451.277px;"
                            id="ellj1qjkxi1t" class="animable"></path>
                          <path
                            d="M366.07119,450.7949c.5185-.3867,1.04-.776,1.48281-1.2474,.44269-.4714,.80719-1.0341,.95269-1.6643,.0676-.2931,.0722-.6394-.1413-.8512-.2245-.2227-.5973-.2004-.8916-.0849-.566,.2223-.9936,.7224-1.22379,1.285-.23011,.5626-.20621,1.9555-.17881,2.5628"
                            style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; stroke-width: 0.25px; transform-origin: 367.301px 448.828px;"
                            id="elm0sxse8gr9" class="animable"></path>
                        </g>
                      </g>
                      <path
                        d="M386.05259,345.666s2.401,29.0794,3.2253,30.6599c2.787,5.3452,22.0119,14.9355,22.0119,14.9355l9.9993-1.7885,6.9456,6.157-4.8036,3.5395-5.4476-2.244c-3.0222,.4478-6.0782,.6249-9.1318,.5297-5.1333-.1513-30.714-6.5492-34.6421-13.5728-3.9282-7.0235-3.8728-39.6448-3.8728-39.6448l15.7158,1.4285Z"
                        style="fill: rgb(137, 85, 85); transform-origin: 399.286px 371.703px;" id="el34mau1i9gxk"
                        class="animable"></path>
                      <g id="eluffiabvayz" class="animable" style="transform-origin: 350.43px 362.858px;">
                        <path
                          d="M369.55039,356.5818s-6.8866-25.6786,2.8372-32.4644c9.7236-6.7858,16.259,28.433,16.259,28.433l-19.0962,4.0314Z"
                          style="fill: rgb(64, 123, 255); transform-origin: 377.915px 339.915px;" id="elwhtnzwjcuvo"
                          class="animable"></path>
                        <g id="el5w5uqpc0w1y">
                          <path
                            d="M369.55039,356.5818s-6.8866-25.6786,2.8372-32.4644c9.7236-6.7858,16.259,28.433,16.259,28.433l-19.0962,4.0314Z"
                            style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 377.915px 339.915px;"
                            class="animable"></path>
                        </g>
                        <path
                          d="M374.20459,403.3166l.9207-33.7387,.63671-46.2145-19.806-4.9118-13.871-.131-23.45971,7.305-.7948,11.3211s.723,52.1136,.5501,53.6788c-.212,1.919-6.16659,13.8265-6.16659,13.8265,24.771,6.4179,62.33249,.5964,61.99059-1.1354Z"
                          style="fill: rgb(64, 123, 255); transform-origin: 343.988px 362.858px;" id="elx6vkm2g1e4i"
                          class="animable"></path>
                        <g id="els7d1ztgtcsm">
                          <polygon points="375.26369 355.3756 375.59819 335.2504 379.13769 354.5578 375.26369 355.3756"
                            style="opacity: 0.2; transform-origin: 377.201px 345.313px;" class="animable"></polygon>
                        </g>
                        <g id="el4k1yt23sn2">
                          <path
                            d="M332.97049,357.3013c.6177-.1019,3.1367-.8461,3.1367-.8461l-5.7233-19.609,8.8592,21.5448-5.7695,2.1016-.5031-3.1913Z"
                            style="opacity: 0.2; transform-origin: 334.813px 348.669px;" class="animable"></path>
                        </g>
                      </g>
                      <g id="elw8exf6oulic" class="animable" style="transform-origin: 349.764px 364.176px;">
                        <path
                          d="M330.76969,341.2805s3.8872,32.32,6.5915,35.4102c4.57,5.2219,27.5533,19.3983,27.5533,19.3983l11.7902-3.7479c.8257-.2445,1.7258-.0709,2.3791,.4589l7.7991,6.3251-.78089,.6201c-.33711,.2677-.81551,.3099-1.19781,.1056l-1.2216-.6535,1.3467,1.4-.4502,.3574c-.4475,.3554-1.0575,.4699-1.61409,.303l-3.53971-1.062,3.228,2.2885c-.7529,.598-1.7826,.7831-2.7136,.4876l-5.1759-1.6418c-3.5836,.7096-7.2253,1.1222-10.8832,1.2329-6.157,.1972-34.8814-10.7992-40.0719-18.3832-5.19069-7.584-9.8598-38.578-9.8598-38.578l16.8208-4.3212Z"
                          style="fill: rgb(137, 85, 85); transform-origin: 350.416px 372.195px;" id="el1bsrmmw05r4"
                          class="animable"></path>
                        <g id="elagchuhp6gj7" class="animable" style="transform-origin: 324.376px 343.737px;">
                          <path
                            d="M314.69529,362.2311s-6.3727-29.3756,3.3511-36.1615c9.72361-6.7859,18.0608,30.3856,18.0608,30.3856l-21.4119,5.7759Z"
                            style="fill: rgb(64, 123, 255); transform-origin: 324.376px 343.737px;" id="elzdm3tlhteb"
                            class="animable"></path>
                          <g id="elawpgmmjznuk">
                            <path
                              d="M314.69529,362.2311s-6.3727-29.3756,3.3511-36.1615c9.72361-6.7859,18.0608,30.3856,18.0608,30.3856l-21.4119,5.7759Z"
                              style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 324.376px 343.737px;"
                              class="animable"></path>
                          </g>
                        </g>
                      </g>
                      <g id="elp37jummw9qp" class="animable" style="transform-origin: 396.109px 386.007px;">
                        <path
                          d="M391.48719,401.5953l-17.9489-.5292-19.0459-.5614-.0003,.0122c-.0324,1.4127,1.5114,2.599,3.4482,2.6493l19.0293,.5611,10.8857,.3208,.0501,.0018,.0028-.0002,30.294,.8931c1.9214,.0433,3.5003-1.0543,3.5322-2.4558l-30.2472-.8917Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 388.113px 402.724px;" id="el992ptbjs2w8"
                          class="animable"></path>
                        <path
                          d="M418.76089,404.8147l-33.7968-.9963c-.5832-.0211-1.0409-.4175-1.02209-.8856,.00339-.0878,.024-.1747,.06079-.2572l16.0509-34.0254c.4087-.9733,1.5604-1.6127,2.8446-1.5795l33.8175,.9969c.572,.0171,1.0245,.403,1.0106,.862-.0028,.0961-.0266,.1909-.0699,.2803l-16.0508,34.0254c-.4145,.9698-1.5625,1.6073-2.8448,1.5794Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 410.834px 385.943px;" id="elqtm1iu00mv"
                          class="animable"></path>
                        <g id="elxh5m82vvuvm">
                          <path
                            d="M436.71579,368.0676l-33.8174-.9969c-1.2843-.0332-2.4359,.6062-2.8447,1.5795l-15.4455,32.7424-11.0699-.3264-19.0459-.5615-.0003,.0122c-.0324,1.4127,1.5114,2.5989,3.4482,2.6493l19.0293,.5611,10.8857,.3208,.0501,.0018,.0028-.0002,30.294,.8931c.6093,.0137,1.17581-.0976,1.6777-.2866,.7937-.2417,1.4383-.748,1.7258-1.4209l16.0508-34.0254c.0433-.0894,.0671-.1842,.0699-.2805,.0139-.4588-.4386-.8447-1.0106-.8618Z"
                            style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.7; transform-origin: 396.109px 386.007px;"
                            class="animable"></path>
                        </g>
                        <path
                          d="M415.86509,385.8193c-.7792,1.6327-2.293,2.7248-3.3899,2.4612-1.0969-.2637-1.3467-1.7798-.589-3.4176s2.2879-2.7035,3.3847-2.4398c1.097,.2637,1.3838,1.7204,.5942,3.3962Z"
                          style="fill: rgb(255, 255, 255); transform-origin: 413.882px 385.351px;" id="el5kcmcn5ppt2"
                          class="animable"></path>
                        <g id="elf4o9ai6hvq">
                          <g style="opacity: 0.3; transform-origin: 393.443px 384.254px;" class="animable">
                            <line x1="383.98789" y1="401.3743" x2="384.60819" y2="401.3926"
                              style="fill: none; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0px; transform-origin: 384.298px 401.383px;"
                              id="elappelhwdg2h" class="animable"></line>
                            <path
                              d="M400.05369,368.6501l-15.4455,32.7424,1.5666,.0462,16.7236-34.368c-1.2843-.0332-2.4359,.6062-2.8447,1.5794Z"
                              id="elr5lik90pkzk" class="animable" style="transform-origin: 393.753px 384.254px;"></path>
                          </g>
                        </g>
                      </g>
                      <g id="elmxuttagp59e" class="animable" style="transform-origin: 378.352px 422.553px;">
                        <g id="eloxetqjx2g2s" class="animable" style="transform-origin: 422.23px 435.321px;">
                          <polygon
                            points="399.69449 440.1011 421.36049 444.2467 425.11839 435.3714 402.44789 427.5584 399.69449 440.1011"
                            style="fill: rgb(137, 85, 85); transform-origin: 412.406px 435.903px;" id="ela6uk3qgbw3k"
                            class="animable"></polygon>
                          <g id="els0ppij9zmvh" class="animable" style="transform-origin: 431.075px 435.321px;">
                            <path
                              d="M430.90689,449.061l3.1197-5.9912s-.8024-1.1509-.1773-2.4408c.3511-.7247,2.2146-1.8819,2.2146-1.8819l8.7016-16.4117c-.0239-.0126-1.1228-.5996-1.7062-.6753-.71129-.0923-1.3492-.1018-1.924-.0429-2.0108,.2081-3.6334,1.7226-4.7927,2.8844-1.9432,1.9474-8.5941,6.719-8.5941,6.719,0,0-1.8722,1.1123-4.74449,.9425-1.60221-.0945-2.90581,.4614-3.372,1.1253-.95321,1.3535-.51651,2.608,.727,3.5228l1.44569,1.0607c.278,1.5619-.5343,2.9965-2.1784,3.5705,0,0-3.4402,2.124-1.7979,2.7659,.93761,.366,5.1914,1.845,8.5162,3.1137,.77541,.2952,1.5083,.5758,2.1515,.8218l.0013-.0014c1.42761,.5455,2.4095,.9186,2.4095,.9186Z"
                              style="fill: rgb(38, 50, 56); transform-origin: 431.075px 435.321px;" id="el993sgynd6ua"
                              class="animable"></path>
                            <path
                              d="M431.18219,428.5694c-.1055,.0389-.17219,.1489-.1636,.2625,.03841,.521,.2102,1.0309,.5245,1.4803,.1657,.2369,.5711-.006,.4038-.2452-.2794-.3992-.433-.8471-.4672-1.3024-.0116-.1522-.1513-.2488-.2928-.1969l-.0047,.0017Z"
                              style="fill: rgb(64, 123, 255); transform-origin: 431.502px 429.481px;" id="elb8807xy11jf"
                              class="animable"></path>
                            <path
                              d="M429.82929,429.4748c-.1056,.0387-.1721,.1488-.1636,.2624,.0384,.5209,.2101,1.0309,.5245,1.4802,.1657,.2368,.5711-.0059,.4039-.245-.2794-.3993-.433-.8471-.4674-1.3025-.0113-.1522-.1512-.2489-.29269-.1968l-.00471,.0017Z"
                              style="fill: rgb(64, 123, 255); transform-origin: 430.149px 430.387px;" id="eleokdp7fso0p"
                              class="animable"></path>
                            <path
                              d="M428.50189,430.4438c-.1057,.0387-.1723,.1488-.1637,.2624,.0384,.5209,.2101,1.0309,.5245,1.4802,.1657,.2368,.5712-.0059,.4038-.2452-.27949-.3991-.4329-.8468-.4672-1.3024-.0115-.1523-.1512-.2488-.2928-.1968l-.0046,.0018Z"
                              style="fill: rgb(64, 123, 255); transform-origin: 428.822px 431.355px;" id="el8xy1eyvx2pb"
                              class="animable"></path>
                          </g>
                          <g id="els1kr8moheap">
                            <path
                              d="M433.84929,440.6289c.3511-.7246,2.2146-1.8818,2.2146-1.8818l8.7016-16.4117c-.0239-.0126-1.1228-.5996-1.7062-.6753-.3268-.0424-.634-.063-.9308-.0717l-13.9909,26.4183c.1177,.0448,.2461,.0941,.3585,.137l.0012-.0013c1.42771,.5455,2.4096,.9186,2.4096,.9186l3.1197-5.9912s-.8024-1.1509-.1773-2.4409Z"
                              style="fill: rgb(255, 255, 255); isolation: isolate; opacity: 0.2; transform-origin: 436.452px 435.325px;"
                              class="animable"></path>
                          </g>
                          <path
                            d="M428.61129,430.6963c-.5956-.2521-1.1948-.5059-1.73729-.8583-.54221-.3523-1.03021-.8121-1.32161-1.3894-.13539-.2686-.2225-.6039-.0655-.8604,.16511-.2696,.5324-.3369,.8458-.2948,.6025,.0809,1.1369,.4649,1.4945,.9565,.3577,.4916,.6661,1.8501,.7841,2.4464"
                            style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; stroke-width: 0.25px; transform-origin: 427.007px 428.989px;"
                            id="elymphcy8afc" class="animable"></path>
                          <path
                            d="M428.57439,430.7659c-.6445-.0543-1.2929-.1085-1.9369-.0491-.644,.0592-1.29,.239-1.8166,.6143-.245,.1747-.4781,.4308-.4586,.731,.0204,.3154,.3143,.546,.6113,.6547,.5709,.2089,1.2226,.1178,1.7679-.1507,.54551-.2687,1.4509-1.3273,1.8329-1.8002"
                            style="fill: none; stroke: rgb(64, 123, 255); stroke-miterlimit: 10; stroke-width: 0.25px; transform-origin: 426.468px 431.761px;"
                            id="elvkrk07wmzq" class="animable"></path>
                        </g>
                        <path
                          d="M318.18389,427.7633c-4.4747-7.6147-14.489-28.1913,7.16211-31.5039,21.651-3.3126,92.592,33.0003,92.592,33.0003l-4.96391,13.5967-66.65609-12.5308s8.80269,12.503-2.29431,11.6179c-16.1195-2.0765-19.887-6.0586-25.8398-14.1802Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 364.939px 419.451px;" id="elxp7a60ka4r"
                          class="animable"></path>
                        <g id="elqw7tep6gg39">
                          <path d="M347.89629,430.7407l-18.2015-6.3219s15.1348,9.8835,20.75521,11.1715l-2.55371-4.8496Z"
                            style="isolation: isolate; opacity: 0.2; transform-origin: 340.072px 430.005px;"
                            class="animable">
                          </path>
                        </g>
                      </g>
                    </g>
                    <g id="el6jzecfmar7r" class="animable" style="transform-origin: 348.951px 294.78px;">
                      <path
                        d="M338.17579,321.6194c4.8976-4.69,3.9176-17.4173,2.6117-23.9903l15.41551,11.8796c-.31121,.7695-.56881,1.5597-.77131,2.3646-.4161,1.441-.454,2.9648-.1104,4.4246,.3344,1.2017,1.888,4.5147,1.888,4.5147,0,0,.9551,3.7497-9.0427,4.1456-4.3675,.6469-9.9908-3.3388-9.9908-3.3388Z"
                        style="fill: rgb(137, 85, 85); transform-origin: 347.705px 311.329px;" id="ely727g5t1s5"
                        class="animable"></path>
                      <g id="elw1g8a3n3rie">
                        <path
                          d="M343.80689,304.2193c-.4332,7.7836,7.5328,11.0834,11.52211,12.0922-.34371-1.46-.30581-2.9838,.11019-4.4247l-11.6323-7.6675Z"
                          style="isolation: isolate; opacity: 0.2; transform-origin: 349.615px 310.265px;"
                          class="animable">
                        </path>
                      </g>
                      <g id="elhhpdjiwo94" class="animable" style="transform-origin: 348.456px 292.414px;">
                        <g id="elotvt92kwaf" class="animable" style="transform-origin: 348.456px 292.414px;">
                          <path
                            d="M337.93809,291.9974c2.3395,9.0322,3.4714,14.1733,9.1293,17.9295,8.5108,5.6501,19.0872-.5636,19.5749-10.1368,.4389-8.6167-3.4607-21.9398-13.375-23.761-9.7679-1.7944-17.6686,6.936-15.3292,15.9683Z"
                            style="fill: rgb(137, 85, 85); transform-origin: 352.1px 293.935px;" id="elwuxiybniu5"
                            class="animable"></path>
                          <path
                            d="M333.99259,279.7043c.3432-5.6406,14.21841-8.3029,18.6268-6.2802,.6588,.3022-6.7925,5.8576-6.328,5.5873l-3.6217,2.0269c-.6214-.0168-1.6624,.8801-1.9224,2.7938,0,0,3.326,14.8904-.2069,20.0436-3.8309-1.0046-5.1795-6.9007-6.9673-10.2268-2.6639-4.9556-6.13609-12.2944,.4195-13.9446Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 341.448px 288.313px;" id="el4f27k537187"
                            class="animable"></path>
                          <path
                            d="M335.69,300.3139c1.20559,2.2126,3.35589,3.6599,5.39629,4.2105,3.0694,.8284,3.5418-2.3504,2.7206-5.1803-.739-2.5471-2.1263-5.5586-5.2223-5.1396-3.0501,.4131-4.2731,3.58-2.89459,6.1094Z"
                            style="fill: rgb(137, 85, 85); transform-origin: 339.645px 299.412px;" id="el6s8tkpp11mp"
                            class="animable"></path>
                          <path
                            d="M350.719,291.7219c.18019,.9354,.83629,1.5929,1.46579,1.4687,.6291-.124,.99321-.9832,.813-1.9186-.1801-.9354-.8364-1.593-1.4655-1.4688-.62929,.1242-.9934,.9831-.81329,1.9187Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 351.858px 291.497px;" id="elvfz1fbo2le7"
                            class="animable"></path>
                          <path
                            d="M360.22719,290.6235c.18,.9356,.8362,1.5931,1.4655,1.469,.6292-.1243,.99331-.9832,.8131-1.9188-.1801-.9354-.8363-1.593-1.4654-1.4687-.6293,.1243-.9935,.9832-.8132,1.9185Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 361.367px 290.399px;" id="elmlz0vaofhi"
                            class="animable"></path>
                          <path
                            d="M358.22349,290.5799s3.9443,4.0762,5.286,5.7854c-1.1138,1.4275-3.5272,1.3182-3.5272,1.3182l-1.7588-7.1036Z"
                            style="fill: rgb(109, 57, 57); transform-origin: 360.866px 294.133px;" id="elq6gtsa8uzf"
                            class="animable"></path>
                          <path
                            d="M348.71289,288.1433c.1565,.0305,.3262-.0196,.4404-.1464,1.3593-1.5043,3.0349-1.3059,3.05171-1.3039,.25079,.0324,.48469-.1386,.52209-.3818,.0371-.2434-.1369-.4671-.3863-.5001-.0884-.0119-2.1882-.2612-3.8742,1.6057-.1685,.1862-.1511,.4673,.03841,.6274,.06149,.0522,.133,.0847,.20789,.0991Z"
                            style="fill: rgb(38, 50, 56); transform-origin: 350.541px 286.973px;" id="elw7c114v0beh"
                            class="animable"></path>
                        </g>
                        <g id="ellg2yj3h3ysh">
                          <path
                            d="M337.35229,297.3836c.2741-.6169,1.0086-.9625,1.6971-.9287,.6883,.034,1.3229,.3885,1.8267,.8434,.6045,.5464,1.05791,1.2494,1.3008,2.0175"
                            style="fill: none; opacity: 0.2; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 0.5px; transform-origin: 339.765px 297.884px;"
                            class="animable"></path>
                        </g>
                      </g>
                      <path
                        d="M364.803,285.793c.08039-.0333,.152-.0892,.20409-.1657,.14091-.2064,.0862-.4842-.123-.6201-2.0975-1.3625-4.036-.5457-4.1277-.5201-.2321,.0992-.3409,.3629-.2429,.5893,.0979,.2257,.3636,.3289,.5951,.2328,.07941-.0372,1.5809-.6476,3.26441,.446,.13179,.0858,.29339,.0946,.43,.0378Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 362.781px 285.025px;" id="el2mvaykqv64g"
                        class="animable"></path>
                      <g id="el9th1g00mwvf" class="animable" style="transform-origin: 356.345px 300.81px;">
                        <path
                          d="M359.81279,300.8313c-.7286,1.0605-1.3792,2.1721-1.9469,3.3267-.2155-.0652-.4376-.1264-.65-.202-2.9357-.9288-3.9961-2.3249-4.26489-3.5995-.12091-.6256-.09311-1.2708,.08129-1.8837,.09-.3476,.2278-.6812,.4096-.9908,1.2633,1.4235,3.9254,2.4989,5.3768,3.0127,.6354,.1923,.9941,.3366,.9941,.3366Z"
                          style="fill: rgb(38, 50, 56); transform-origin: 356.345px 300.82px;" id="elgwv8foz2z7g"
                          class="animable"></path>
                        <path
                          d="M358.85249,300.4756l-.6021,.9663c-2.7561-.8793-4.5795-1.8599-5.18439-2.9882,.09-.3477,.22779-.6812,.40959-.9908,1.271,1.437,3.9255,2.4988,5.3769,3.0127Z"
                          style="fill: rgb(255, 255, 255); transform-origin: 355.959px 299.452px;" id="el0esm2pysmejc"
                          class="animable"></path>
                        <path
                          d="M357.24959,303.9368c-2.9357-.9288-3.9961-2.3249-4.2647-3.5995,1.1088,.3732,2.14311,.9394,3.0548,1.6729,.594,.5006,1.0171,1.1741,1.2099,1.9266Z"
                          style="fill: rgb(193, 88, 84); transform-origin: 355.117px 302.137px;" id="elztrnzt5ox8p"
                          class="animable"></path>
                      </g>
                      <path
                        d="M338.46509,278.1433s4.255-13.9342,17.0151-13.6059c-1.1506,.4784-2.1991,1.1722-3.0893,2.0442,0,0,10.547-2.9116,15.2755,4.0028l-2.2776-.8162s3.8726,5.7244,1.4324,10.4281l-.9729-1.9557s-3.3864,6.2451-10.22829,5.9108l2.05209-1.5761s-11.3438,1.7329-14.6135-.615c-1.6698-1.0957-3.2107-2.376-4.5935-3.817Z"
                        style="fill: rgb(38, 50, 56); transform-origin: 353.066px 274.348px;" id="elbeg0hsh6r0m"
                        class="animable"></path>
                    </g>
                  </g>
                </g>
                <defs>
                  <filter id="active" height="200%">
                    <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
                    <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
                    <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
                    <feMerge>
                      <feMergeNode in="OUTLINE"></feMergeNode>
                      <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                  </filter>
                  <filter id="hover" height="200%">
                    <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
                    <feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
                    <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
                    <feMerge>
                      <feMergeNode in="OUTLINE"></feMergeNode>
                      <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                    <feColorMatrix type="matrix"
                      values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 ">
                    </feColorMatrix>
                  </filter>
                </defs>
              </svg>
            </div>
          </div>

        </div>

      </app-component-wrapper>
    </div>
    <div class=" d-flex flex-column d-col-1">

      <app-component-wrapper icon="edit" header="Contact" class="m-3">
        <div class="d-flex flex-column" slot="start">
          <div class="d-flex flex-row align-items-center mb-3">
            <div class="cd-icon mr-3">
              <mat-icon>email</mat-icon>
            </div>
            <div class="cd-details">
              <div class="header">Email:</div>
              <a href="mailto:{{model.adviserEmail}}"> {{model.adviserEmail}}</a>
            </div>
          </div>

          <div class="d-flex flex-row align-items-center mb-3">
            <div class="cd-icon mr-3">
              <mat-icon>phone</mat-icon>
            </div>
            <div class="cd-details">
              <div class="header">Phone:</div>

              <a href="tel:{{model.adviserPhone}}"> {{model.adviserPhone}}</a>
            </div>
          </div>

          <div class="d-flex flex-row align-items-center mb-3">
            <div class="cd-icon mr-3">
              <mat-icon>phone</mat-icon>
            </div>
            <div class="cd-details">
              <div class="header">Mobile:</div>

              <a href="tel:{{model.adviserMobile}}"> {{model.adviserMobile}}</a>
            </div>
          </div>

        </div>

      </app-component-wrapper>

      <app-component-wrapper icon="edit" header="Dealer Group Details" class="m-3 d-flex flex-fill">
        <div class="d-flex flex-column " slot="start">

          <app-readonly title="Adviser dealer group name" [value]="model.dealer?.adviserDealerGroupName" icon="business"
            class="p-1 flex-fill"></app-readonly>

          <app-readonly title="Adviser group AFSL" [value]="model.dealer?.dealerGroupAFSL" icon="tag"
            class="p-1 flex-fill"></app-readonly>
        </div>

      </app-component-wrapper>
    </div>
  </div>


</div>
