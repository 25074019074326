import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { InvestmentBalanceSwitchRequestDetailModel } from 'src/app/model/investment-balance-switchRequest-detail.model';


export const SwitchTransactionsRequestAction = createAction('[Switch transaction list] get transaction',
  props<{ memberId: number, accountId: number, page: number }>());

export const SwitchTransactionsResponseAction = createAction('[Switch transaction list] set transaction',
  props<{ payload: InvestmentBalanceSwitchRequestDetailModel[] }>());

export const SwitchTransactionIncrementPageAction = createAction('[Switch transaction list] transaction increment page');

export const ResetFormAction = createAction('[Switch transaction list] reset form');
