import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AdviserSearchModel } from 'src/app/model/adviser-search.model';

export const RequestAction = createAction('[Adviser Listing view] get data', props<{ search: string, page: number }>());
export const ResponseAction = createAction('[Adviser Listing view] set data',  props<{ payload: AdviserSearchModel[] }>());
export const SetAsAtDateAction = createAction('[Adviser Listing view] set as at date', props<{ payload: string }>());


export const NextPageAction = createAction('[Adviser Listing view]next page');


