import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { RequestAction } from './actions';
import { widgetNewMember_Data } from './selectors';

@Component({
  selector: 'app-members-new',
  templateUrl: './members-new.component.html',
  styleUrls: ['./members-new.component.scss']
})
export class MembersNewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(widgetNewMember_Data));

  constructor(
    public store: Store<AppState>,
    public apstore: Store<AdvisorPortalSharedState>,

    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.apstore.dispatch(RequestAction());

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }



}

