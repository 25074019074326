<div id="adviser-details-edit-view">
  <div class="page-header">
    <app-toolbar-options icon="price_change">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">Adviser Details Edit</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper icon="edit" header="Edit Details">
      <div class="d-flex flex-column" slot="start">
        <app-adviser-details-edit></app-adviser-details-edit>
      </div>

    </app-component-wrapper>
  </div>

</div>
