<div class="section-title d-flex ">
  Switch investments
</div>
<div class="card">
  <mat-progress-bar *ngIf="(list$|async) == undefined;" mode="indeterminate"></mat-progress-bar>

  <app-no-items message="No switch investments found" *ngIf="(list$ |async)?.length === 0"></app-no-items>

  <div class="table-responsive" *ngIf="(list$ |async)?.length > 0">
    <table class="table table-striped table-borderless ">
      <thead>
        <tr>
          <th>Account number</th>
          <th>Date of request</th>
          <th>Investment option</th>
          <th>Percentage</th>
          <th>Status</th>
          <th>Channel name</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of list$ | async">
          <td>{{item.accountNumber}}</td>
          <td>{{item.requestDate | date:'dd MMM yyyy'}}</td>
          <td>{{item.investmentOptionName}}</td>
          <td>{{item.percentage + '%'}}</td>
          <td>{{item.statusName}}</td>
          <td>{{item.sourceName}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
