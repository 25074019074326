import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HistoricalDataService } from 'src/app/services/historical-data.service';
import { ValuationService } from 'src/app/services/valuation.service';

@Injectable()
export class ValuationViewEffects {
  constructor(private actions$: Actions,
    private service: ValuationService,
    private serviceHistorical: HistoricalDataService) {
  }
  getChartData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestChartDataAction),
    switchMap((action: any) => this.serviceHistorical.getDataGraph(action.accountId,action.memberId, action.from, action.to).pipe(
      map((data: any) => {
        return ActionsList.ResponseChartDataAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

  getSummaryData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestValuationAction),
    switchMap((action: any) => this.service.getValuationSummary(action.memberId, action.accountId, action.to).pipe(
      map((data: any) => {
        return ActionsList.ResponseValuationAction({ payload: data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
