<div id="members-new-widget" class="members-widget-component">
  <div class="d-flex flex-row align-items-center">
    <div class="mr-3 data-icon">
      <mat-icon>group_add</mat-icon>
    </div>
    <div class="d-flex flex-fill flex-column">
      <div class="data-header">New members</div>
      <div class="data-value"  [countUp]="(data$ | async)?.value" [duration]="1500"></div>
      <div class="data-date">From {{(data$ | async)?.from | date:'dd MMM yyyy'}}</div>
    </div>
    <div *ngIf="data$ | async; let data">
      <div class="badge badge-success d-flex align-items-center " *ngIf="data.change >=0">
        <mat-icon class="animate__animated  animate__fadeInUp" *ngIf="data.change >0">arrow_upward</mat-icon> {{data.change}}%
      </div>
      <div class="badge  badge-danger d-flex align-items-center " *ngIf="data.change < 0">
        <mat-icon class="animate__animated animate__fadeInDown">arrow_downward</mat-icon> {{data.change}}%
      </div>
    </div>
  </div>
</div>
