
import { AddressModel } from '@ifaa-components/ui-components';
import { IAdviserDetailsEditState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction, updateGroup, wrapReducerWithFormStateUpdate
} from 'ngrx-forms';
import { AdviserDetailsModel } from '../adviser-details/state';

export const formName = 'adviserDetailsForm';


export const state: IAdviserDetailsEditState = {

  form: createFormGroupState(formName,
    {
      adviserName: 'name',
      adviserArNumber: '3333',
      adviserPhone: '4445554',
      adviserEmail: 'gg@gg.com',
      adviserAddress: {
        lineOne: '400 Ann st',
        suburb: 'Brisbane City',
        country: 'Australia',
        postCode: '4000',
        state: 'QLD'
      } as AddressModel,
      adviserNumber: '444',
      adviserBusinessName: 'test',
      adviserMobile: '444333444333'
    } as AdviserDetailsModel)
};

export const validateForm = updateGroup<AdviserDetailsModel>({

});


const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  // on(SelectContactAction, (state, { payload }) => {
  //   return {
  //     ...state,
  //     selectedContact: payload
  //   };
  // })
);

const reducerFormState = wrapReducerWithFormStateUpdate(
  reducer,
  s => s.form,
  validateForm,
);

export function adviserDetailsEditReducer(state: any | undefined, action: Action) {
  return reducerFormState(state, action);
}
