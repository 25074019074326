import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberCorrespondenceSummaryModel } from 'src/app/model/member-correspondence-summary.model';


export const MemberCorrespondenceRequestAction = createAction('[Member Correspondence list] get transaction',
  props<{ memberId: number, page: number }>());

export const MemberCorrespondenceResponseAction = createAction('[Member Correspondence list] set transaction',
  props<{ payload: MemberCorrespondenceSummaryModel[] }>());

export const MemberCorrespondenceFileRequestAction = createAction('[Member Correspondence list] get file ',
  props<{ memberId: number, accountId: number, correspondenceSource: number, correspondenceId: number }>());

export const ResetFormAction = createAction('[Member Correspondence list] reset form');
