import { cashTransactionList_List, cashTransactionList_Filter, cashTransactionList_TransactionTypes, cashTransactionList_ListTotal, cashTransactionList_TransactionDescription } from './selectors';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import {
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/store/app.states';
import { memberView_SelectedAccountId } from '../selectors';
import { CashTransactionsRequestAction, CashTransactionIncrementPageAction, CashTransactionsTypesRequestAction, ResetFormAction, CashTransactionsSetFromToDateAction, CashTransactionsDescriptionRequestAction, ResetFilterAction, CashTransactionsDownloadRequestAction } from './actions';
import { CashTransactionFilter } from './state';
import { SetValueAction, FormGroupControls } from 'ngrx-forms';
import { ComponentBase } from 'src/app/views/component-base';
import { KeyValueModel } from '@ifaa-components/ui-components';
import { Helper } from 'src/app/helper/helper-functions';

@Component({
  selector: 'app-cash-transactions-view',
  templateUrl: './cash-transactions-view.component.html',
  styleUrls: ['./cash-transactions-view.component.scss']
})
export class CashTransactionsViewComponent extends ComponentBase implements OnInit, OnDestroy {

  private _memberid: number;

  @Input() set memberid(value: number) {
    this._memberid = value;
    this.store.dispatch(ResetFormAction());
  }
  get memberid(): number {
    return this._memberid;
  }

  transactionTypes$ = this.store.pipe(select(cashTransactionList_TransactionTypes));
  transactionDescription$ = this.store.pipe(select(cashTransactionList_TransactionDescription));
  list$ = this.store.pipe(select(cashTransactionList_List));
  listTotal$ = this.store.pipe(select(cashTransactionList_ListTotal));
  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));
  filter$ = this.store.pipe(select(cashTransactionList_Filter));
  transactionType = 'All';
  transactionDescription = 'All';
  accountId: number;
  query: CashTransactionFilter = new CashTransactionFilter();
  filter : string;

  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.filter$.subscribe(async x => {
      if (!this.accountId) return;
      if (x) {
        this.filter = await this.formatQuery(x.value);
        if (this.isQueryDifferent(x.value)) {
          this.store.dispatch(CashTransactionsRequestAction({ memberId: this.memberid, accountId: this.accountId, page: x.value.currentPage, filter: this.filter }));
        }
      }
    });

    this.sub = this.selectedAccountId$.subscribe(x => {
      var val = x?.value?.value;
      if (val) {
        this.accountId = val;

        this.store.dispatch(CashTransactionsTypesRequestAction({ memberId: this.memberid, accountId: val }));
        this.store.dispatch(CashTransactionsDescriptionRequestAction({ memberId: this.memberid, accountId: val }));
        this.store.dispatch(CashTransactionsRequestAction({ memberId: this.memberid, accountId: val, page: 0, filter: '' }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  selectTransactionType(item: KeyValueModel) {
    this.transactionType = item.value;
    this.store.dispatch(new SetValueAction('cashtransactionsListFilter.transactionType', item.value));
  }
  onClearFilterClick() {
    this.transactionType = 'All';
    this.transactionDescription = 'All';
    this.store.dispatch(ResetFilterAction())
  }
  selectTransactionDescription(item: KeyValueModel) {
    this.transactionDescription = item.value;
    this.store.dispatch(new SetValueAction('cashtransactionsListFilter.transactionDescription', item.key));
  }

  isQueryDifferent(filter: CashTransactionFilter): boolean {
    if (filter.currentPage == -1)
      return false;

    if (JSON.stringify(this.query) !== JSON.stringify(filter)) {
      this.query = filter;
      return true;
    }
    return false;
  }

  async formatQuery(value: CashTransactionFilter): Promise<string> {
    if (!value) return '';

    var list = [];
    if (value.from) {
      list.push("from:" + value.from);
    }
    if (value.to) {
      list.push("to:" + value.to);
    }
    if (value.transactionType) {
      list.push("transactionType:" + value.transactionType);
    }
    if (value.transactionDescription) {
      var descKeyVal = await this.helper.getValue(this.transactionDescription$);
      var selected = descKeyVal.filter(x => x.key == value.transactionDescription)[0];
      if (selected && selected.value)
        list.push("description:" + selected.value);
    }
    return list.join(';');
  }

  onLoadMoreTransactions() {
    this.store.dispatch(CashTransactionIncrementPageAction());
  }

  currentFinancialYear(filter: FormGroupControls<CashTransactionFilter>) {
    var dates = this.helper.getFinancialYear(new Date());
    this.store.dispatch(CashTransactionsSetFromToDateAction({ from: dates.from.toISOString(), to: dates.to.toISOString() }));
  }

  previousFinancialYear(filter: FormGroupControls<CashTransactionFilter>) {
    var today = new Date();
    var dates = this.helper.getFinancialYear(new Date(today.getFullYear() - 1, today.getMonth(), today.getDay()));

    this.store.dispatch(CashTransactionsSetFromToDateAction({ from: dates.from.toISOString(), to: dates.to.toISOString() }));
  }

  customFinancialYear(filter: FormGroupControls<CashTransactionFilter>) {
    this.store.dispatch(CashTransactionsSetFromToDateAction({ from: '', to: '' }));
  }

  onExport()
  {
    this.store.dispatch(CashTransactionsDownloadRequestAction({ memberId: this.memberid, accountId: this.accountId, page:0, filter:this.filter }));
  }
}
