import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMemberPensionState } from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';


export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.memberPensionState);


export const memberPension_Data = createSelector(
  state,
  (state: IMemberPensionState) => state.data
);

