import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IMemberListingViewState } from './state';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const getmemberListingView = createSelector(portalState, (state) => state.memberListingViewState);

export const memberListingView_List = createSelector(
  getmemberListingView,
  (state: IMemberListingViewState) => state.list
);

export const memberListingView_Form = createSelector(
  getmemberListingView,
  (state: IMemberListingViewState) => state.form
);


export const memberListingView_SearchFormatted = createSelector(
  getmemberListingView,
  (state: IMemberListingViewState) => {
    if (state.form.value.q) {
      return 'all:' + state.form.value.q
    }
    else {
      return '';
    }

  });
