
export interface IWidgetExpiringMembersViewState {
  data: WidgetExpiringMembersModel;
}


export class WidgetExpiringMembersModel {
  value: number = 0;
  days: number = 0;
  change: number = 0;
}
