import { environment as base } from "./environment.smrt";

export const environment = {
  ...base,
  production: true,
  bugsnagEnv:'#{bugsnagEnv}#',
  apiUrl: '#{apiUrl_AdviserPortal}#',
  b2cApiEndpoint: '#{b2cApiEndpoint_AdviserPortal}#',
  b2cClientId: '#{b2cClientId_AdviserPortal}#',
  b2cReadScope: '#{b2cReadScope_AdviserPortal}#',
  b2cWriteScope: '#{b2cWriteScope_AdviserPortal}#',
  b2cLogoutUrl: '#{b2cLogoutUrl_AdviserPortal}#',
  b2cPolicySignIn: '#{b2cPolicySignIn_AdviserPortal}#',
  b2cSignInAuthority:'#{b2cSignInAuthority_AdviserPortal}#',
  b2cPolicyElevatedSignIn: '#{b2cPolicyElevatedSignIn_AdviserPortal}#',
  b2cElevatedSignInAuthority:'#{b2cElevatedSignInAuthority_AdviserPortal}#',
  b2cAuthorityDomain: '#{b2cAuthorityDomain_AdviserPortal}#',
};
