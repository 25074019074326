import { AppState } from 'src/app/store/app.states';
import { Directive, ElementRef, Renderer2, Input, HostListener, forwardRef, Host, Optional, Self } from '@angular/core';
import { NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms';
import { EntryFieldComponent, EntryFieldDateComponent, EntryFieldAddressComponent } from '@ifaa-components/ui-components';
import { select, Store } from '@ngrx/store';

@Directive({
  selector: '[control]',
})
export class InputScrollDirective {
  isScrollEnabled = false;
  constructor(public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef,
    @Host() @Self() @Optional() public entry: EntryFieldComponent) {

    // store.pipe(select(commomState_ScreenWidth)).subscribe(x => {
    //   this.isScrollEnabled = x < 992;
    // });


    entry?.onFocusChange.subscribe(() => {
      this.scrollToElement();
    });

  }

  scrollToElement() {
    if (this.isScrollEnabled) {
      this.hostElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
      // window.scrollTo(0, window.scrollY - 60)
    }
  }

}

@Directive({
  selector: '[date]',
})
export class InputDateScrollDirective {
  isScrollEnabled = false;
  constructor(public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef,
    @Host() @Self() @Optional() public entry: EntryFieldDateComponent) {

    setTimeout(() => {
      // store.pipe(select(commomState_ScreenWidth)).subscribe(x => {
      //   this.isScrollEnabled = x < 992;
      // });

      // entry?.inputDate?.onFocusChange.subscribe(() => {
      //   this.scrollToElement();
      // });
    }, 500);


  }

  scrollToElement() {
    if (this.isScrollEnabled) {
      this.hostElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
      //window.scrollTo(0, window.scrollY - 60)
    }
  }

}


@Directive({
  selector: '[address]',
})
export class InputAddressScrollDirective {
  isScrollEnabled = false;
  constructor(public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef,
    @Host() @Self() @Optional() public entry: EntryFieldAddressComponent,) {

    setTimeout(() => {
      // store.pipe(select(commomState_ScreenWidth)).subscribe(x => {
      //   this.isScrollEnabled = x < 992;
      // });

      entry?.inputAddress?.onFocusChange.subscribe(() => {
        this.scrollToElement();
      });
    }, 500);

  }

  scrollToElement() {
    if (this.isScrollEnabled) {
      this.hostElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
      //window.scrollTo(0, window.scrollY - 60)
    }
  }

}
