import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IWidgetActiveMembersViewState } from './state';

export const state = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const componentState = createSelector(state, (state) => state.widgetActiveMemberState);

export const widgetActiveMember_Data = createSelector(
  componentState,
  (state: IWidgetActiveMembersViewState) => state.data
);


