<div id="dealer-detail-header-component" class="">
  <div class="d-flex flex-row align-items-center pl-3 pr-3">
    <div class="d-icon">
      <mat-icon>account_circle</mat-icon>
    </div>
    <div class="d-data flex-fill">
      <h4 class="name">Henrique Teles</h4>
      <div class="position d-flex align-items-center">
        <mat-icon>work</mat-icon>
        Adviser
      </div>
      <div class="address d-flex align-items-center">
        <mat-icon>room</mat-icon>

        400 Ann st, Brisbae city, Australia
      </div>
    </div>
    <div>
      <app-button class="w-100" title="Edit details" icon="edit" (onClick)="onEditDetailsClick()">
      </app-button>
    </div>
  </div>
</div>
