// Angular
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.states';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  constructor(public store: Store<AppState>, protected _sanitizer: DomSanitizer) {
  }

  transform(value: boolean): string {
    if (value)
      return "Yes";
    return "No";
  }
}
