<div class="d-flex">
    <div class="section-title d-flex flex-fill ">
        Rollovers
    </div>

</div>
<div class="card">
    <app-no-items message="No rollovers found" *ngIf="(rollInList$ |async)?.length === 0"></app-no-items>

    <div class="table-responsive" *ngIf="(rollInList$ |async)?.length > 0">
        <table class="table table-striped table-borderless">
            <thead>
                <tr>
                    <th>Transaction date</th>
                    <th>Amount</th>
                    <th>USI</th>
                    <th>Received from name</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of rollInList$ | async">
                    <td>{{item.transactionDate | date:'dd MMM yyyy'}}</td>
                    <td>{{item.amount | currency}}</td>
                    <td>{{item.receivedFromUniqueSuperannuationIdentifier}}</td>
                    <td>{{item.receivedFromName}}</td>
                    <td>{{item.status}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
