import { featureKey } from './../../store/smrt.reducers';
import { SMRTState } from './../../store/smrt.states';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMainLayoutState } from './state';

export const memberPortalState = createFeatureSelector<SMRTState>(featureKey);

export const mainLayout = createSelector(memberPortalState, (state) => state.mainLayout);


export const mainLayout_Expanded = createSelector(
  mainLayout,
  (state: IMainLayoutState) => state.expanded
);

export const mainLayout_Animated = createSelector(
  mainLayout,
  (state: IMainLayoutState) => state.animated
);
