
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, setValue, SetValueAction
} from 'ngrx-forms';
import { ElevatedAccessResetAction, ElevatedAccessResponseAction, MemberViewResetAction, ResponseAction } from './actions';
import { MemberModel } from 'src/app/model/member.model';
import { IMemberViewState, MemberselectedAccount } from './state';

export const formName = 'memberListingForm';
export const memberview_selectedAccound = 'memberview_selectedAccound';


export const state: IMemberViewState = {
  data: undefined,
  selectedAccountId: createFormGroupState(memberview_selectedAccound, { value: 1 } as MemberselectedAccount),
  currentElevatedAccess: null,

};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResponseAction, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      selectedAccountId: setValue({ value: payload.accounts[0].accountId })(state.selectedAccountId)
    };
  }),
  on(ElevatedAccessResponseAction, (state, { payload }) => {
    return {
      ...state,
      currentElevatedAccess: payload
    };
  }),
  on(ElevatedAccessResetAction, (state, {  }) => {
    return {
      ...state,
      currentElevatedAccess: null
    };
  }),
  on(MemberViewResetAction, (state) => {
    return {
      ...state,
      data: undefined,
      currentElevatedAccess: null

      // selectedAccountId: createFormGroupState(memberview_selectedAccound, new MemberselectedAccount()),
    };
  }),

);

export function memberViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
