import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { OkModel } from '../model/ok.model';

@Injectable()
export class MemberCorrespondenceService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }



  getMemberCorrespondece(memberId: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/correspondence`, { headers: this.headers(), params: params });
  }

  searchAccountDocument(search: string, page: number): Observable<any> {
    const params = new HttpParams()
      .set('filter', search)
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/accounts/correspondence`, { headers: this.headers(), params: params });
  }

  getAccountDocument(accountCorrespondenceId: string): Observable<any> {
    return this.http.get(environment.apiUrl + `/members/accounts/correspondence/${accountCorrespondenceId}`, { headers: this.headers() });
  }

  getAccountDocumentContent(memberId:number, accountId: number, accountCorrespondenceId: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/member/${memberId}/account/${accountId}/correspondence/${accountCorrespondenceId}/file-content`, { headers: this.headers() });
  }

  getMemberCorrespondeceFile(memberId: number, correspondenceSource: number, correspondenceId: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('correspondenceSource', correspondenceSource.toString())
      .set('correspondenceId', correspondenceId.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/correspondence/${correspondenceSource}/${correspondenceId}`, { headers: this.headers(), params: params });
  }


  verifiedValid(accountCorrespondenceId: number): Observable<any> {
    return this.http.post<OkModel<string>>(environment.apiUrl + `/members/accounts/correspondence/${accountCorrespondenceId}/verified-valid`, accountCorrespondenceId, { headers: this.headers() });
  }

  verifiedInvalid(accountCorrespondenceId: number): Observable<any> {
    return this.http.post<OkModel<string>>(environment.apiUrl + `/members/accounts/correspondence/${accountCorrespondenceId}/verified-invalid`, accountCorrespondenceId, { headers: this.headers() });
  }

}
