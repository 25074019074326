import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { environment } from 'src/environments/environment.base';
import { NgrxFormsModule } from 'ngrx-forms';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { clearState } from './store/meta-reducer';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './app.material.module';
import { CommonEffects } from './store/common/common.effects';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { reducers } from './store/app.reducers';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserServiceConfig } from '@ifaa-components/ui-components/lib/services/userServiceConfig';
import { HelperService } from './services/helper.service';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { AdviserPortalSharedModule } from './modules/shared/shared.module';
import { GetTenantModule } from './tenant.helper';
import { DealerGroupDetailsViewComponent } from './views/dealer-group-details-view/dealer-group-details-view.component';
import { ClientListViewComponent } from './views/client-list-view/client-list-view.component';
import { AdviserDetailsViewComponent } from './views/adviser-details-view/adviser-details-view.component';
import { AdviserDetailsEditViewComponent } from './views/adviser-details-edit-view/adviser-details-edit-view.component';
import { ClientViewComponent } from './views/client-view/client-view.component';
import { MemberService } from './services/member.service';
import { BeneficiaryService } from './services/beneficiary.service';
import { TransactionService } from './services/transaction.service';
import { Helper } from './helper/helper-functions';
import { RollInService } from './services/rollin.service';
import { InvestmentService } from './services/investment.service';
import { InsuranceService } from './services/insurance.service';
import { MemberAccountComponentService } from './services/member-account-components-service';
import { EmploymentService } from './services/employment.service';
import { MemberCorrespondenceService } from './services/member-correspondence.service';
import { MemberPensionService } from './services/member-pension.service';
import { HistoricalDataService } from './services/historical-data.service';
import { ValuationService } from './services/valuation.service';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, ProtectedResourceScopes, MsalModule
} from '@azure/msal-angular';
import { msalConfig, msalElevatedConfig, loginRequest, protectedResources } from './auth-config';
import { HttpCustomInterceptor } from './services/http-interceptor';
import { AuthService } from './services/auth.service';
import { DashboardService } from './services/dashboard.service';
import { TRANSLATE_MODULE_CONFIG } from './translateHttpLoader';
import { BrokerService } from './services/broker.service';
import { AdviserListViewComponent } from './views/adviser-list-view/adviser-list-view.component';
import { MemberAdviserAuthorityService } from './services/member-adviser-authority-service';
import { FeatureToggleGuardService } from './route-guard/feature-toggle-guard.service';
import { FeatureToggleService } from './services/featureToggle.service';
import { AdviserGroupStatementsViewComponent } from './views/group-statements/group-statements-view.component';
import { CommomService } from './views/component-base';
import { MemberPortalElevatedAccessDialog } from './dialogs/member-portal-elevated-access/member-portal-elevated-access-dialog.component';

export let AppInjector: Injector;

export const metaReducers: MetaReducer<any>[] = [clearState];
Bugsnag.start({
  apiKey: environment.bugsnagApiKey,
  releaseStage: environment.bugsnagEnv,
  enabledReleaseStages: ['Production', 'Staging', 'Uat', 'unknown'],
  onError: function (event) {
    event.addMetadata('tenant', {
      tenant: environment.code
    })
  }
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  if (inIframe()) {
    return new PublicClientApplication(msalElevatedConfig);
  }
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint.replace('api/**', 'assets/**'), null);
  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint.replace('**', 'helper/systemconfig'), null);
  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint.replace('**', 'web-user/isMaintenanceMode'), null);
  protectedResourceMap.set(protectedResources.apiEndpoint.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.apiEndpoint.scopes.read]
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.apiEndpoint.scopes.write]
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.apiEndpoint.scopes.write]
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.apiEndpoint.scopes.write]
    }
  ]);

  return {
    interactionType: inIframe() ? InteractionType.Popup : InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {

  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

const myImports = [
  NgrxFormsModule,
  BrowserModule,
  HttpClientModule,
  SharedDirectivesModule,
  AdviserPortalSharedModule,
  FormsModule,
  CommonModule,
  ToastrModule.forRoot({
    timeOut: 6000,
    positionClass: 'toast-bottom-center',
    preventDuplicates: true,
  }),
  MaterialModule,
  HttpClientModule,
  FormsModule,
  IfaaUiComponentsModule.forRoot({ entryAppearance: 'fill' } as UserServiceConfig),
  BrowserAnimationsModule,
  AppRoutingModule,
  TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
  StoreModule.forRoot(reducers, { metaReducers }),
  StoreDevtoolsModule.instrument({
    maxAge: 20, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode
  }),
  ReactiveFormsModule,
  EffectsModule.forRoot([
    CommonEffects,
  ])
];
GetTenantModule.getModule(myImports);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DealerGroupDetailsViewComponent,
    ClientListViewComponent,
    AdviserDetailsViewComponent,
    AdviserDetailsEditViewComponent,
    ClientViewComponent,
    AdviserListViewComponent,
    AdviserGroupStatementsViewComponent,
    MemberPortalElevatedAccessDialog
  ],
  entryComponents:[
    MemberPortalElevatedAccessDialog
  ],
  imports: myImports,
  providers: [
    Helper,
    HelperService,
    MemberService,
    BeneficiaryService,
    TransactionService,
    RollInService,
    InvestmentService,
    InsuranceService,
    MemberCorrespondenceService,
    MemberAccountComponentService,
    EmploymentService,
    MemberPensionService,
    ValuationService,
    HistoricalDataService,
    AuthService,
    DashboardService,
    BrokerService,
    MemberAdviserAuthorityService,
    FeatureToggleGuardService,
    FeatureToggleService,
    CommomService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCustomInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
