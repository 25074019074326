import {
  getValuationView_Model, getValuationView_SelectedInvestmentOption,
  getValuationView_hasMultipleInvestments, getValuationView_noOfInvestments,
  getValuationView_isConsolidatedView, getValuationView_Model_investmentOptions, getValuationView_Form, getValuationView_chartData
} from './selectors';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input, NgZone } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestValuationAction, InvestmentOptionChangedAction, ShowInvestmentOptionsAction, RequestChartDataAction } from './actions';
import {
  ChartComponent, ApexTooltip, ApexXAxis, ApexYAxis, ApexNoData, ApexTitleSubtitle,
  ApexStates, ApexAxisChartSeries, ApexGrid, ApexFill, ApexStroke, ApexMarkers
} from "ng-apexcharts";
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexLegend, ApexDataLabels } from "ng-apexcharts";
import { AppState } from 'src/app/store/app.states';
import { MatChip } from '@angular/material/chips';
import {
  InvestmentSummary, InvestmentOptionDetails,
  AccountUnderlyingInvestmentSummaryModel
} from 'src/app/model/account-underlying-investment-summary.model';
import { Helper, KeyValueModel } from '@ifaa-components/ui-components';
import { DatePipe } from '@angular/common';
import { memberView_SelectedAccountId } from '../selectors';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ComponentBase } from 'src/app/views/component-base';
import { TranslateService } from '@ngx-translate/core';
import { HistoricalGraphItemModel } from 'src/app/model/historical-graph.model';

export type ChartOptions1 = {
  title: ApexTitleSubtitle;
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  colors: any[];
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  noData: ApexNoData;
  states: ApexStates;
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  colors: string[];
  fill: ApexFill;
  markers: ApexMarkers;
};

@Component({
  selector: 'app-valuation-view',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.scss']
})
export class ValuationViewComponent extends ComponentBase implements OnInit, OnDestroy {
  accountId: number = 0;
  private _memberid: number;
  @Input() set memberid(value: number) {
    this._memberid = value;
  }
  get memberid(): number {
    return this._memberid;
  }
  pipe = new DatePipe('en-AU');
  selectedDate = new Date();
  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));
  selectedInvestmentOption$ = this.store.pipe(select(getValuationView_SelectedInvestmentOption));
  hasMultipleInvestments$ = this.store.pipe(select(getValuationView_hasMultipleInvestments));
  noOfInvestments$ = this.store.pipe(select(getValuationView_noOfInvestments));
  isConsolidatedView$ = this.store.pipe(select(getValuationView_isConsolidatedView));
  modelSummary$ = this.store.pipe(select(getValuationView_Model));
  investmentOptions$ = this.store.pipe(select(getValuationView_Model_investmentOptions));
  form$ = this.store.pipe(select(getValuationView_Form));
  chartData$ = this.store.pipe(select(getValuationView_chartData));
  helper = new Helper();
  noOfInvestments: number = 0;
  hasMultipleInvestments: boolean = false;
  isConsolidatedView: boolean = true;
  public chartOptions1: Partial<ChartOptions1>;
  public chartOptions: Partial<ChartOptions>;

  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("chart1") chart1: ChartComponent;
  @ViewChild('chip') chip: MatChip;
  @ViewChild('chip2') chip2: MatChip;
  @Output() titleEvent = new EventEmitter<string>();
  currentInvestmentSummary: InvestmentSummary;
  drawerOpened = false;
  data: HistoricalGraphItemModel[] = [];
  lastSearch: any = {
    accountId: 0,
    from: null,
    to: null
  }
  datepipe: DatePipe = new DatePipe('en-AU');
  selectedOption: number = 0;
  step = -1;

  constructor(private store: Store<AppState>,
    public ngZone: NgZone,
    public translateService: TranslateService) {
    super();
  }

  setStep(index: number, data: InvestmentSummary) {
    this.step = index;
    if (data.investmentOptionId == '-1') {
      this.viewConsolidation();
    }
    else {
      this.onSelectedOptionChanged(index);
    }
  }

  ngOnInit() {

    super.ngOnInitBase();

    this.sub = this.investmentOptions$.subscribe(x => {
      this.drawerOpened = x?.length > 1;
    });

    this.sub = this.form$.subscribe(x => {

      if (x && x.value && x.value.investmentOptions) {
        this.onOptionsSelected(x.value.investmentOptions);
      }
    })
    this.sub = this.selectedAccountId$.subscribe(x => {

      var val = x?.value?.value;
      this.accountId = val;
      if (val) {
        this.dispatch(this.store, RequestValuationAction({ memberId: this.memberid, accountId: val, to: undefined }));
      }
    });

    this.sub = this.hasMultipleInvestments$.subscribe(x => {
      this.hasMultipleInvestments = x;
      this.setTitle();
    });

    this.sub = this.noOfInvestments$.subscribe(x => {
      this.noOfInvestments = x;
      this.setTitle();
    });

    this.sub = this.isConsolidatedView$.subscribe(x => {
      this.isConsolidatedView = x;
      this.setTitle();
    });

    this.sub = this.modelSummary$.subscribe(x => { });

    this.sub = this.selectedInvestmentOption$.subscribe(async x => {
      if (x) {
        this.currentInvestmentSummary = x;
        await this.setChartData();
        this.setAssetTitle();
      }
    });

    this.chartOptions = {
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return '<div class="arrow_box p-3 chart_infobox" style="min-width: 150px;"><b>Click to select date</b>' +
            '<div class="d-block"> <div class="d-flex flex-row"><div style="width: 60px">Date:</div> ' + this.pipe.transform(this.data[dataPointIndex].x, 'dd/MM/yyyy') + '</div></div>' +
            '<div class="d-block"> <div class="d-flex flex-row"><div style="width: 60px">Amount:</div> $' + series[seriesIndex][dataPointIndex] + '</div></div>' +
            `<div class="d-block"> <div class="d-flex flex-row"><div style="width: 60px">Units:</div> ` + this.data[dataPointIndex].unit + '</div></div>' +
            '</div>'
        }
      },
      series: [],

      chart: {
        height: 150,
        type: 'line',
        id: 'historicalchart',
        selection: {

        },
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true
        },

        events: {
          click: (e: any, chart: any, options: any) => {
            this.ngZone.runOutsideAngular(() => {
              this.ngZone.run(() => {
                var index = options.dataPointIndex;
                var data = this.data[index];
                if (!data || !data.x) {
                  console.log('no X found');
                  return;
                }
                this.selectedDate = null;
                setTimeout(() => {
                  this.selectedDate = data.x;
                }, 20);
                this.step = -1;

                this.dispatch(this.store, RequestValuationAction({ memberId: this.memberid, accountId: this.accountId, to: data.x.toString() }));
              });
            });


          },
          dataPointSelection: function (e, chart, options) {
            console.log(options.dataPointIndex);
          }
        },
      },
      markers: {
        colors: ['#259ffb'],
        strokeColors: 'white',
      },
      colors: ['#259ffb'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: {
        text: "Select the date",
        align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.1
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return "$" + Number(value).toFixed(2);;
          }
        },
      },
      xaxis: {
        type: 'datetime',

        tooltip: {
          enabled: false,
          formatter: (value) => {
            return this.pipe.transform(new Date(value).toISOString(), 'dd/MM/yy');
          }
        },

      },
    }

    this.sub = this.selectedAccountId$
      .subscribe(async x => {
        if (x) {

          // if (this.chart)
          //   this.chart.updateOptions(this.updateOptionsData[x.filter], false, true, true);

          var nextSearch = {
            accountId: x.value.value,
            from: undefined,
            to: undefined
          };
          if (JSON.stringify(this.lastSearch) != JSON.stringify(nextSearch)) {
            this.lastSearch = nextSearch;
            this.dispatch(this.store, RequestChartDataAction({
              accountId: x.value.value,
              memberId: this._memberid,
              from: undefined,
              to: undefined
            }));
          }
        }
      });

    this.sub = this.chartData$.subscribe(model => {

      this.data = model.data;

      if (model.data.length > 0) {

        if (this.chart)
          this.chart.updateSeries([{
            data: model.data
          }])

      }
    });
  }

  getInvestmentOptionDetailColors(investmentOptionDetails: InvestmentOptionDetails[]) {
    var defaultColors = this.translateService.instant('VIEWS.UNDERLYING_INVESTMENTS.CHART_COLORS.OPTIONS') as KeyValueModel[];
    if (defaultColors) {
      try {
        let colors: string[] = [];
        investmentOptionDetails.forEach(element => {
          var defaultColor = defaultColors.filter(o => o.key == element.consolidateByName);
          if (defaultColor && defaultColor.length > 0) {
            colors.push(defaultColor[0].value);
          }
          else {
            var defaultColor = defaultColors.filter(o => o.key == 'Default');
            if (defaultColor) {
              colors.push(defaultColor[0].value);
            }
          }
        });
        return colors;
      } catch (error) {
        return undefined;
      }
    }
    else
      return undefined;

  }

  async setChartData() {

    var investmentOptionDetails = [...this.currentInvestmentSummary.investmentOptionDetails];
    investmentOptionDetails = investmentOptionDetails.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);

    var colors = this.getInvestmentOptionDetailColors(investmentOptionDetails);
    var sortedLabels = investmentOptionDetails.map(p => { return p.consolidateByName });
    var sortedSeries = investmentOptionDetails.map(p => { return p.amountInvested });

    this.chartOptions1 = {
      chart: {
        width: 400,
        type: "donut",
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
      },
      title: {
        text: 'Assets',
      },
      noData: {
        text: 'Loading...'
      },
      labels: sortedLabels,
      dataLabels: {
        enabled: false,
        formatter: function (val, opts) {
          return val + " %"
        },
        style: {
          fontSize: '11px',
        },
      },
      series: sortedSeries,
      legend: {
        show: false,
      },
      colors: colors,
      xaxis: {},
      yaxis: {
        show: false,
        labels: {
          formatter: (value) => {
            return value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          }
        },
      },

    };

  }
  viewConsolidation() {
    this.dispatch(this.store, InvestmentOptionChangedAction({ investmentOptionId: '-1' }));
  }

  viewSplitInvestments() {
    this.selectedOption = 0;

    this.dispatch(this.store, ShowInvestmentOptionsAction());
  }

  onOptionsSelected(value: string) {
    this.dispatch(this.store, InvestmentOptionChangedAction({ investmentOptionId: value }));
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  setTitle() {
    var title = 'Underlying Investment';

    if (this.isConsolidatedView && this.hasMultipleInvestments) {
      title = title.concat(' - Consolidated');
    }
    if (!this.isConsolidatedView && this.hasMultipleInvestments) {
      title = title.concat(' - Split');
    }
    if ((this.isConsolidatedView && !this.hasMultipleInvestments) || this.noOfInvestments == 0) {
      title = title;
    }

    this.titleEvent.emit(title);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {

    if (tabChangeEvent.index == 0) {
      this.viewConsolidation()
      this.drawerOpened = false;
    }
    else {
      this.viewSplitInvestments()
      setTimeout(() => {
        this.drawerOpened = true;
      }, 500)

    }

  }

  async onSelectedOptionChanged(index: number) {
    this.selectedOption = index;
    var list = await this.helper.getValue(this.investmentOptions$);
    var selected = list[index];
    this.onOptionsSelected(selected.key.toString());
  }

  getPercentage(id: number, summary: AccountUnderlyingInvestmentSummaryModel) {
    var model = summary.investmentSummaries;
    var selected = model.filter(x => x.investmentOptionId.toString() == id.toString());
    return selected[0].percentAllocated
  }

  setAssetTitle() {
    var assetName = 'Asset classes - ';

    if (this.currentInvestmentSummary.summaryLink) {
      return `${assetName}<a href=${this.currentInvestmentSummary.summaryLink} target='_blank' class='summary-link'>${this.currentInvestmentSummary?.investmentOptionName}</a>`
    }

    return assetName.concat(this.currentInvestmentSummary?.investmentOptionName);
  }

  getInvestmentOptionPercentageTotal() {
    //Consolidated Percentage Total
    if (this.currentInvestmentSummary.investmentOptionId == '-1') {
      return this.currentInvestmentSummary.percentAllocated;
    }
    else {
      //Sum Investment Option Details percentage
      var percenatgeTotal = 0;
      this.currentInvestmentSummary.investmentOptionDetails.forEach(element => {
        percenatgeTotal += element.percentage;
      });

      return percenatgeTotal;
    }
  }

  getInvestmentDetailColor(consolidateByName: string) {
    var defaultColors = this.translateService.instant('VIEWS.UNDERLYING_INVESTMENTS.CHART_COLORS.OPTIONS') as KeyValueModel[];

    //    var defaultColors = environment.underlyingInvestmentsChartColors as KeyValueModel[];
    if (defaultColors) {
      var defaultColor = defaultColors.filter(o => o.key == consolidateByName);
      if (defaultColor && defaultColor.length > 0)
        return defaultColor[0].value;
      else {
        var defaultColor = defaultColors.filter(o => o.key == 'Default');
        return defaultColor[0].value;

      }
    }
  }

  getUnitPriceDate() {
    if (this.currentInvestmentSummary.investmentOptionId == '-1') {
      return null;
    }
    else {
      var message = "Unit price is as at ";
      var formattedDate = this.datepipe.transform(this.currentInvestmentSummary.asAt, 'dd-MMM-YYYY')
      return message.concat(formattedDate);
    }
  }
}

