<footer id="footer-layout-container" class="mt-4 " >
  <div class="container p-4">
    <div class="d-flex flex-row main">
      <div class="left-side d-flex align-items-center">
        <div style="">
          <div class="d-flex flex-column monday align-items-start">
            <div>Make</div>
            <div>mondays matter.</div>
          </div>
          <img src="https://sharedassetstorage.blob.core.windows.net/web/SMRT/smartMonday_logo_white.png"
            routerLink="/dashboard" class="logo">

        </div>
      </div>
      <div class="flex-fill d-flex links">
        <div class="d-flex w-100 link-container">
          <div class="d-flex flex-fill flex-column link-col">
            <h4 class="mb-1">Important</h4>
            <a target="_blank" class="footer-link" href="https://smartmonday.com.au/Governance">Governance</a>
            <a target="_blank" class="footer-link" href="https://smartmonday.com.au/Legal">Legal</a>
            <a target="_blank" class="footer-link" href="https://smartmonday.com.au/news/Privacy-and-Cookies">Privacy Policy & Cookies</a>
            <a target="_blank" class="footer-link" href="https://www.eqt.com.au/global/privacystatement">ETSL Privacy Policy</a>
          </div>
          <div class="d-flex flex-fill flex-column link-col-2">
            <h4 class="mb-1">Useful links</h4>
            <a target="_blank" href="mailto:enquiry@smartmonday.com.au">Contact us</a>
            <a target="_blank" class="footer-link" href="https://smartmonday.com.au/Superannuation/news-and-market-updates">News</a>
            <a target="_blank" class="footer-link" href="https://mysuperdashboard.smartmonday.com.au/">smartMonday Lifecycle Mysuper dashboard</a>
          </div>
          <div class="d-flex flex-fill flex-column link-col">
            <h4 class="mb-1">About us</h4>
            <a target="_blank" class="footer-link" href="https://smartmonday.com.au/AboutUs">About smartMonday</a>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="container disclaimer d-flex ">
    <div class="left-side d-flex align-items-start imgs">
     </div>
    <div class="flex-fill text">
      <p>Your access to this website is subject to these terms and conditions, our <a class="d-inline"
          href="https://smartmonday.com.au/news/Privacy-and-Cookies" target="_blank">privacy statement</a>, the
        trustee’s <a class="d-inline" href="https://www.eqt.com.au/global/privacystatement" target="_blank">privacy statement</a> and all
        disclaimers on this website.</p>
      <p>
        The information on this website is general in nature and does not take account of your personal financial
        objectives, situation or needs. Before deciding whether a particular product is appropriate for you, please read
        and consider the relevant Product Disclosure Statement, Target Market Determination and Financial Services Guide
        which is available at <strong>smartmonday.com.au/documents</strong> or by calling us. Contact us about the
        intra-fund advice
        services you may be able to access through your membership. Past performance is not a reliable indicator of
        future performance. The content of this website is not intended to represent or be a substitute for specific
        taxation or legal advice and should not be relied on as such. You should obtain advice from a registered tax
        agent or legal practitioner. Any taxation, legal and other matters referred to on this website are based on
        smartMonday and the Trustee’s interpretation of existing laws and should not be relied upon in place of
        appropriate professional advice. Those laws may change from time to time.
      </p>
      <p>
        smartMonday is a registered trading name of smartMonday Solutions Limited ABN 48 002 288 646 AFSL 236667, the
        sponsor of the Smart Future Trust ABN 68 964 712 340 (the Fund). The trustee of the Fund and issuer of the
        information on this website is Equity Trustees Superannuation Limited ABN 50 055 641 757 AFSL 229757 RSE Licence
        L0001458. smartMonday products are part of the Fund.
      </p>
      <p>
        smartMonday and/or the trustee are either the owner or licensed user of the copyright in all material contained
        on this website. Where a third-party source has been relied on, smartMonday and/or the trustee have attributed
        credit for that information. Except to the extent necessary for personal use, you are not permitted to
        reproduce, adapt, upload, link, frame, broadcast, transmit, or distribute any material on this website without
        the permission of smartMonday or the trustee, or as permitted under law.
      </p>
      <p>
        This website, the information and materials on the website, and the software made available on the website, are
        provided 'as is' without any representation or warranty, express or implied, of any kind. To the maximum extent
        possible, smartMonday and/or the trustee do not warrant or guarantee the accuracy or completeness of the
        materials or the reliability of any advice, opinion, statement or other information displayed or distributed
        through the website. You acknowledge that you are responsible for assessing the accuracy and completeness of
        content on this website, and reliance on any opinion, advice, statement, memorandum or information shall be at
        your own risk. In no event shall smartMonday and/or the trustee be liable to any website user or any other
        entity for any damages whatsoever, related to the inability to access or use the material or functions of this
        website.
      </p>
      <p>
        © 2023 smartMonday Solutions Limited - <a target="_blank" class="disclaimer-link"
          href="https://smartmonday.com.au/smartmonday/media/smartmonday/docs/FSG-Document.pdf">Financial Services
          Guide</a>
      </p>
    </div>
  </div>
</footer>
