import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ContributionDetailModel } from 'src/app/model/contribution-detail.model';

export const RequestAction = createAction('[Contribution] get',
  props<{ memberId: number, accountId: number }>());

export const ResponseAction = createAction('[Contributions] set transaction',
  props<{ payload: ContributionDetailModel }>());

  export const ResetFormAction = createAction('[Contributions] reset form');
