import { ToastrService } from 'ngx-toastr';
import { documentViewer_List, documentViewer_SelectedFile } from './selectors';
import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { RequestAction, FileRequestAction, ResetFileAction } from './actions';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GroupCorrespondenceSummaryModel } from 'src/app/model/group-correspondence-summary.model';
import { commomState_ScreenWidth } from 'src/app/store/common/common.selectors';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerViewComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() category: string = '';
  currentPDF: string = null;
  list$ = this.store.pipe(select(documentViewer_List));
  selectedFile$ = this.store.pipe(select(documentViewer_SelectedFile));
  screenWidth$ = this.store.pipe(select(commomState_ScreenWidth));
  downloading: number = -1;
  isSafari: boolean = false;
  dialogRef: MatDialogRef<any,any>;
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;

  constructor(public store: Store<AppState>,
    public toastr: ToastrService,
    public dialog: MatDialog,
    private http: HttpClient) {
    super();

  }

  ngOnInit() {
    super.ngOnInitBase();

    this.dispatch(this.store, RequestAction());

    // Close the mobile version of the viewer if screen goes larger than 992px
    this.sub = this.screenWidth$.subscribe(x => {
      if (x >= 992 && this.dialogRef != null){
        this.dialogRef.close();
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  selectPDF(item: GroupCorrespondenceSummaryModel, index: number) {
    if (this.screenWidth < 992) {
      this.dialogRef = this.dialog.open(this.customTemplate, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'full-screen-modal'
      });
    }

    this.downloading = index;
    this.store.dispatch(ResetFileAction());
    setTimeout(() => {
      this.store.dispatch(FileRequestAction({ documentId: item.adviserGroupCorrespondenceId }));
    }, 10);
  }

  downloadFile(base64: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = "document.pdf";
    downloadLink.click();
    this.downloading = -1;
    this.toastr.success('File downloaded');
  }
}
