import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IWidgetNewMembersViewState } from './state';

export const state = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const componentState = createSelector(state, (state) => state.widgetNewMemberState);

export const widgetNewMember_Data = createSelector(
  componentState,
  (state: IWidgetNewMembersViewState) => state.data
);


