// import { MemberNotesModel } from './../model/member-notes.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable()
export class HistoricalDataService extends BaseService {
  pipe = new DatePipe('en-AU');
  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getDataGraph(accountId: number,memberId:number, from?: Date, to?: Date): Observable<any> {
    const params = new HttpParams()
      .set('accountId', accountId.toString())
      .set('memberId', memberId.toString())
      .set('from', from == undefined ? '' : this.pipe.transform(from, 'yyyy-MM-dd'))
      .set('to', to == undefined ? '' : this.pipe.transform(to, 'yyyy-MM-dd'));

    return this.http.get(environment.apiUrl + `/members/HistoricalData/account/${accountId}/member/${memberId}/graph`, { headers: this.headers(), params: params });
  }


}
