<div class="section-title d-flex ">
  Account Components
</div>
<div class="card ">
  <div class="table-responsive">


    <app-no-items message="No account components found" *ngIf="(data$ | async) === null"></app-no-items>

    <table *ngIf="(data$ | async); let data" class="table table-striped table-borderless">
      <thead>
        <tr>
          <th>Eligible Service Date</th>
          <th>Tax Free Component</th>
          <th>Taxed Component</th>
          <th>Preserved</th>
          <th>Restricted Non Preserved</th>
          <th>Unrestricted Non Preserved</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{data.eligibleServiceDate | date:'dd/MM/yyyy'}}</td>
          <td>{{data.taxFreeComponent | currency}}</td>
          <td>{{data.taxableComponentTaxed | currency}}</td>
          <td>{{data.preserved | currency}}</td>
          <td>{{data.restrictedNonPreserved | currency}}</td>
          <td>{{data.unrestrictedNonPreserved | currency}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
