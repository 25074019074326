import { Component } from '@angular/core';

@Component({
  selector: 'svg-elderly',
  templateUrl: './svg-elderly.view.html',
  styleUrls: ['./svg-elderly.view.scss']
})
export class SVGElderlyComponent {

  constructor() {
  }

}
