import { FooterComponent } from './layout/footer/footer.master';
import { MainLayoutEffects } from './layout/main/effects';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { SMRTRoutingModule } from './smrt-routing.module';
import { featureKey, reducers } from './store/smrt.reducers';
import { MainMasterComponent } from './layout/main/main.master';
import { MaterialModule } from 'src/app/app.material.module';
import { IfaaUiComponentsModule } from '@ifaa-components/ui-components';
import { AdviserPortalSharedModule } from '../shared/shared.module';
import { TenantDashboardComponent } from './views/dashboard/dashboard.component';

import { TranslateModule } from '@ngx-translate/core';
import { FeatureTogglePipe } from '../shared/pipes/featureToggle';
import { NgrxFormsModule } from 'ngrx-forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    MainMasterComponent,
    FooterComponent,
    TenantDashboardComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NgrxFormsModule,
    CommonModule,
    FormsModule,
    SMRTRoutingModule,
    HttpClientModule,
    MaterialModule,
    IfaaUiComponentsModule,
    StoreModule.forFeature(featureKey, reducers),
    EffectsModule.forFeature([
      MainLayoutEffects
    ]),
    AdviserPortalSharedModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  exports: [
    MainMasterComponent,
    TenantDashboardComponent,
    FooterComponent
  ],
  providers: [
    FeatureTogglePipe
  ]
})
export class SMRTModule { }
