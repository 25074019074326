import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IDealerGroupDetailsState } from './state';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.dealerGroupDetailsState);

export const dealerGroupDetail_Model = createSelector(
  state,
  (state: IDealerGroupDetailsState) => state.model
);


export const dealerGroupDetail_selectedContact = createSelector(
  state,
  (state: IDealerGroupDetailsState) => state.selectedContact
);


