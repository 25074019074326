import { IInterimStatementState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';

export const interimStatementState: IInterimStatementState = {

};

const reducer = createReducer(interimStatementState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  // on(ResponseAction, (state, { payload }) => {
  //   return {
  //     ...state
  // }),

);

export function interimStatementReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
