import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class BrokerService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  linkedAdviserList(search: string, page: number): Observable<any> {
    const params = new HttpParams()
      .set('search', search)
      .set('page', page.toString());

    return this.http.get(environment.apiUrl + `/broker/linkedAdviserList`, { headers: this.headers(), params: params });
  }

  getDocuments(): Observable<any> {
    return this.http.get(environment.apiUrl + `/broker/documents`, { headers: this.headers() });
  }

  getDocument(correspondence: number): Observable<any> {
    return this.http.get(environment.apiUrl + `/broker/document/${correspondence}/file-content`, { headers: this.headers() });
  }
}
