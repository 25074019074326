<div class="p-0 h-100 d-flex flex-column member-portal-elevated-access">
  <h1 mat-dialog-title>Member portal</h1>
  <div mat-dialog-content class="h-100 flex-fill iframe-content">
    <!-- <iframe [src]="data.url | safe:'resourceUrl'" class="w-100 h-100"></iframe> -->
    <iframe id="mpIframe" *ngIf="data.url" [src]="data.url | safe:'resourceUrl'" class="w-100 h-100" (load)="memberPortalLoaded()"></iframe>

  </div>
  <div mat-dialog-actions class=" jc-flex-end ml-3 mb-2">

    <app-button class="mr-2" color="light" title="{{ 'CLOSE' | translate }}" icon="close" (onClick)="onNoClick()">
    </app-button>
    <!-- <app-button title="Ok" (onClick)="onOkClick()" icon="check"></app-button> -->

  </div>
</div>
