import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IEmployerHistoryViewState } from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.employerHistoryViewState);


export const employerHistoryViewView_List = createSelector(
  state,
  (state: IEmployerHistoryViewState) => state.list
);

