import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';

@Injectable()
export class AdviserDetailsEditEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private router: Router,
    //private service: BeneficiaryService
    ) {
  }

  // getData$ = createEffect(() => this.actions$.pipe(
  //   ofType(ActionsList.RequestAction),
  //   switchMap((action: any) => this.service.getMemberBeneficiaries(action.accountId).pipe(
  //     map((data: any) => {
  //       return ActionsList.ResponseAction({ payload: data.data });
  //     }),
  //     catchError(err => {
  //       return EMPTY;
  //     })
  //   )),
  // ));


}
