import { KeyValueModel } from '@ifaa-components/ui-components';
import { createAction, props } from '@ngrx/store';
import { InsuranceModel } from 'src/app/model/insurance.model';

export const MemberInsuranceInformationRequestAction = createAction('[Member Insurance] get Insurance Information',
  props<{ memberId: number, page: number }>());

export const MemberInsuranceInformationResponseAction = createAction('[Member Insurance] set Insurance Information',
  props<{ payload: InsuranceModel[] }>());

export const MemberInsurance_SetAccountDataAction = createAction('[Member Insurance] set account data', props<{ accountData: KeyValueModel[] }>())
