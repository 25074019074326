
import { DealerGroupContactDetailsModel, DealerGroupDetailsModel, IDealerGroupDetailsState } from './state';
import { createReducer, on, State, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { SelectContactAction } from './actions';
import { AddressModel } from '@ifaa-components/ui-components';

export const formName = 'memberListingForm';


export const state: IDealerGroupDetailsState = {
  selectedContact: {
    id: '1',
    firstName: 'Brad',
    lastName: 'Pitt',
    email: 'test@test.com',
    isActive: true,
    position: 'Director',
    isPrimary: true,
    phone: '222334455'
  } as DealerGroupContactDetailsModel,
  model: {
    dealerGroupName: 'Name',
    dealerGroupAFSL: '123',
    dealerGroupPhone: '0753645363',
    dealerGroupAddress: {
      lineOne: '404 Ann st',
      postCode: '4000',
      country: 'Australia',
      state: 'QLD',
      suburb: 'Brisbane City'
    } as AddressModel,
    dealerGroupNumber: '444',
    abn: '12344566',
    dealerGroupEmail: 'test@test.com',
    contacts: [
      {
        id: '1',
        firstName: 'Brad',
        lastName: 'Pitt',
        email: 'test@test.com',
        isActive: true,
        position: 'Director',
        isPrimary: true,
        phone: '222334455'
      } as DealerGroupContactDetailsModel,
      {
        id: '2',
        firstName: 'Henrique',
        lastName: 'Teles',
        email: 'test@test.com',
        isActive: true,
        position: 'Developer'
      } as DealerGroupContactDetailsModel,
      {
        id: '3',
        firstName: 'Michael',
        lastName: 'Jackson',
        email: 'test@test.com',
        isActive: false,
        position: 'Manager',
        phone: '222334455'

      } as DealerGroupContactDetailsModel,
      {
        id: '4',
        firstName: 'Michael',
        lastName: 'Jackson',
        email: 'test@test.com',
        isActive: false,
        position: 'Manager',
        phone: '222334455'

      } as DealerGroupContactDetailsModel,
      {
        id: '5',
        firstName: 'Michael',
        lastName: 'Jackson',
        email: 'test@test.com',
        isActive: false,
        position: 'Manager',
        phone: '222334455'

      } as DealerGroupContactDetailsModel,
      {
        id: '6',
        firstName: 'Michael',
        lastName: 'Jackson',
        email: 'test@test.com',
        isActive: false,
        position: 'Manager',
        phone: '222334455'

      } as DealerGroupContactDetailsModel,
      {
        id: '7',
        firstName: 'Michael',
        lastName: 'Jackson',
        email: 'test@test.com',
        isActive: false,
        position: 'Manager',
        phone: '222334455'

      } as DealerGroupContactDetailsModel,
    ]
  } as DealerGroupDetailsModel

};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(SelectContactAction, (state, { payload }) => {
    return {
      ...state,
      selectedContact: payload
    };
  })
);

export function dealerGroupDetailsReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
