import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BeneficiaryGroupedByAccountModel } from '../model/beneficiary-grouped-by-account.model';
import { ListResponse } from '../model/user.model';

@Injectable()
export class BeneficiaryService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getMemberBeneficiariesGroupedByAccount(memberId: number, page: number): Observable<ListResponse<BeneficiaryGroupedByAccountModel>> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('page', page.toString());
    return this.http.get<ListResponse<BeneficiaryGroupedByAccountModel>>(environment.apiUrl + `/members/${memberId}/beneficiaries-grouped-by-account`, { headers: this.headers(), params: params });
  }


}
