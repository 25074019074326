import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IMemberListingWidgetViewState } from './state';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.memberListingWidgetViewState);

export const memberListingView_List = createSelector(
  state,
  (state: IMemberListingWidgetViewState) => state.list
);

export const memberListingView_Form = createSelector(
  state,
  (state: IMemberListingWidgetViewState) => state.form
);

export const memberListingView_SearchFormatted = createSelector(
  state,
  (state: IMemberListingWidgetViewState) => {
    if (state.form.value.q) {
      return 'all:' + state.form.value.q
    }
    else {
      return '';
    }

  }
);
