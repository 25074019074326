import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class TransactionService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getMemberTransactionsDownload(memberId: number, accountId: number, page: number, filter: string): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString())
      .set('filter', filter)
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/transactions-download`, { observe: 'response' ,headers: this.headers(), responseType: 'blob' as 'json', params: params });
  }

  getMemberTransactions(memberId: number, accountId: number, page: number, filter: string): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString())
      .set('filter', filter)
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/transactions`, { headers: this.headers(), params: params });
  }

  getMemberTransactionTypes(memberId: number, accountId: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/transactiontypes`, { headers: this.headers(), params: params });
  }

  getMemberTransactionDescription(memberId: number, accountId: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/descriptions`, { headers: this.headers(), params: params });
  }

  getContributions(memberId: number, accountId: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/contributionlimits`, { headers: this.headers(), params: params });
  }

  getInterimStatement(memberId: number, accountId: number, from: string, to: string): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('from', from)
      .set('to', to)
      .set('accountId', accountId.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/interimstatement`, { observe: 'response' ,headers: this.headers(), responseType: 'blob' as 'json', params: params });
  }
}
