import { TransactionDetailModel } from 'src/app/model/transaction-detail.model';
import { KeyValueModel } from '@ifaa-components/ui-components';
import { FormGroupState } from 'ngrx-forms';

export interface ICashTransactionListState {
  list: TransactionDetailModel[] | undefined;
  transactionTypes: KeyValueModel[];
  transactionDescription: KeyValueModel[];
  filter: FormGroupState<CashTransactionFilter>;
  isLoading: boolean;
}

export class CashTransactionFilter {
  from: string = '';
  to: string = '';
  transactionType: string = 'All';
  transactionDescription: string = 'alldescriptions';
  currentPage: number = 0;
}
