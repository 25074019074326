import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IWidgetTotalBalanceViewState } from './state';

export const state = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const componentState = createSelector(state, (state) => state.widgetTotalBalanceState);

export const widgetTotalBalance_Data = createSelector(
  componentState,
  (state: IWidgetTotalBalanceViewState) => state.data
);


