<div permission="Members_CanViewInterimStatements">

  <div class="section-title d-flex ">
    Account Summary
  </div>
  <div class="card">
    <div class="row">
      <div class="col-6">
        <app-button class="w-100" type="submit" title="Current Financial Year to Date" icon="cloud_download"
                    (onClick)="currentFinancialYear()"></app-button>
      </div>
      <div class="col-6">
        <app-button class="w-100" type="submit" title="Previous financial year" icon="cloud_download"
                    (onClick)="previousFinancialYear()"></app-button>
      </div>
    </div>
  </div>
</div>
