import { IMemberInsuranceState, MemberInsuranceDropdown } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  createFormGroupState,
  onNgrxForms, onNgrxFormsAction, SetValueAction } from 'ngrx-forms';
import { MemberInsuranceInformationResponseAction, MemberInsurance_SetAccountDataAction } from './actions';


export const memberInsuranceState: IMemberInsuranceState = {
  form: createFormGroupState("memberInsuranceForm", new MemberInsuranceDropdown()),
  insuranceInformationList: undefined,
  accountData:[]
};

const reducer = createReducer(memberInsuranceState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(MemberInsuranceInformationResponseAction, (state, { payload }) => {
    return {
      ...state,
      insuranceInformationList: payload,
    };
  }),
  on(MemberInsurance_SetAccountDataAction, (state,{accountData}) => {
    return {
      ...state,
      accountData:accountData
    }
  }),
);

export function memberInsuranceReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
