<div id="members-listing">
  <app-search-filter>
    <div filters class="d-flex flex-row flex-fill">
      <div class="mr-1">
        <app-entry *ngIf="form$|async; let form" title="Search" icon="search" [control]="form.controls.q"></app-entry>
      </div>
    </div>
  </app-search-filter>
  <div class="mt-4">
    <mat-progress-bar *ngIf="(memberList$|async) == undefined;" mode="indeterminate"></mat-progress-bar>
    <app-no-data *ngIf="(memberList$|async)?.length == 0"></app-no-data>
  </div>
  <table class="table table-striped table-borderless table-hover" *ngIf="(memberList$|async)?.length > 0">
    <thead>
      <tr>
        <th scope="col">Member name</th>
        <th scope="col">Member number</th>
        <th scope="col">Account number</th>
        <th scope="col">Date of birth</th>
        <th scope="col">Total balance</th>
        <th scope="col">Adviser authority expiry date</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let member of memberList$|async" (click)="onMemberViewClick(member)">
        <th scope="row">{{member.firstName}} {{member.lastName}}</th>
        <td>{{member.memberNumber}}</td>
        <td>
          <div class="badge badge-secondary m-1" *ngFor="let account of member.accounts">
            {{account.accountNumber}} - {{account.fundCode}}</div>
        </td>
        <td>{{member.dateOfBirth | date:"dd/MM/yyyy"}}</td>
        <td>{{member.totalBalance | currency}}
        </td>
        <td>
          <app-expiry-date [date]="member.expiryDate"></app-expiry-date>

        </td>
        <td >
          <mat-icon>navigate_next</mat-icon>
        </td>
      </tr>

    </tbody>
  </table>
  <app-load-more [page]="(form$ |async).value.page" [listCount]="(memberList$ | async)?.length" (onClick)="onLoadMore()"></app-load-more>

</div>
