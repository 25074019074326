<div class="previous-fy-summary">
        <mat-expansion-panel>
                <mat-expansion-panel-header>
                        <mat-panel-title class="d-flex align-items-center ">
                                <p class="financial-year">{{previousFySummary.financialYear}}</p>
                        </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="d-flex flex-row flex-wrap">
                        <div class="col-12 col-md-6"><app-readonly title="Concessional Contribution Cap"
                                        [value]="previousFySummary.concessionalContributionCap | currency"></app-readonly>
                        </div>
                        <div class="col-12 col-md-6"><app-readonly title="Non-Concessional Contribution Cap"
                                        [value]="previousFySummary.nonConcessionalContributionCap | currency"></app-readonly>
                        </div>
                </div>
                <div class="d-flex flex-row flex-wrap">
                        <div class="col-12 col-md-6"
                                [ngClass]="{'cap-exceeded' : (previousFySummary.concessionalContributionCap - previousFySummary.concessionalAmountAllocated) < 0}">
                                <app-readonly title="Concessional Amount Allocated"
                                        [value]="previousFySummary.concessionalAmountAllocated | currency"></app-readonly>
                        </div>
                        <div class="col-12 col-md-6"
                                [ngClass]="{'cap-exceeded' : (previousFySummary.nonConcessionalContributionCap - previousFySummary.nonConcessionalAmountAllocated) < 0}">
                                <app-readonly title="Non-Concessional Amount Allocated"
                                        [value]="previousFySummary.nonConcessionalAmountAllocated | currency"></app-readonly>
                        </div>
                </div>
                <div class="d-flex flex-row">
                        <div class="col-12"><app-readonly title="Amount Claimed As Tax Deduction"
                                        [value]="previousFySummary.amountClaimedTaxDeduction | currency"></app-readonly>
                        </div>
                </div>
        </mat-expansion-panel>
</div>