import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import {
  switchTransactionList_List
} from './selectors';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helper/helper-functions';
import { memberView_SelectedAccountId } from '../selectors';
import { AppState } from 'src/app/store/app.states';
import { SwitchTransactionsRequestAction, ResetFormAction } from './actions';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
  selector: 'app-switch-transactions-view',
  templateUrl: './switch-transactions-view.component.html',
  styleUrls: ['./switch-transactions-view.component.scss']
})
export class SwitchTransactionsViewComponent extends ComponentBase implements OnInit, OnDestroy {

  list$ = this.store.pipe(select(switchTransactionList_List));

  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));

  private _memberid: number;
  @Input() set memberid(value: number) {
    this._memberid = value;
    this.store.dispatch(ResetFormAction());
  }
  get memberid(): number {
    return this._memberid;
  }


  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccountId$.subscribe(x => {
      var val = x?.value?.value;
      if (val) {
        this.store.dispatch(SwitchTransactionsRequestAction({ memberId: this.memberid, accountId: val, page: 0 }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
