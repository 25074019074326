import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { GroupCorrespondenceSummaryModel } from 'src/app/model/group-correspondence-summary.model';

export const RequestAction = createAction('[Document Viewer list] get ');

export const ResponseAction = createAction('[Document Viewer list] set ',
  props<{ payload: GroupCorrespondenceSummaryModel[] }>());

export const FileRequestAction = createAction('[Document Viewer list] get file ',
  props<{ documentId: number }>());

export const ResetFileAction = createAction('[Document Viewer list] reset selected file');