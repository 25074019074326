import { IContributionsState } from './state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../../store/shared.states';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.contributionsState);


export const contributions_Data = createSelector(
  state,
  (state: IContributionsState) => state.data
);
