import { environment } from 'src/environments/environment';
import { ShowErrorAction, IsLoadingAction, LogoutRequestAction } from './../store/common/common.actions';
import { Injectable, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';

import { Observable, of, Subscription, throwError, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from '../../../node_modules/rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { finalize } from "rxjs/internal/operators/finalize";

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
  requestCount = 0;
  loadingSub: Subscription;
  constructor(private store: Store<any>,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  showError(errors) {
    const toastr = this.toastr;
    errors.forEach(function (element) {
      toastr.error(element);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.endsWith('.json') && request.url.startsWith(environment.apiUrl)) {


      setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: true })); }, 0)

      this.requestCount++;

      this.loadingSub?.unsubscribe();
      this.loadingSub = timer(10000).subscribe(() => {
        this.requestCount = 0;
        setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: false })); }, 0)
      });
    }

    const authReq = request.clone({
      headers: new HttpHeaders({
        'View-As':  sessionStorage.getItem('selected')
      })
    });


    return next.handle(authReq).pipe(
      tap((event: HttpEvent<any>) => {
      }),

      finalize(() => {
        this.requestCount--;
        if (this.requestCount <= 0) {
          this.requestCount = 0;
          setTimeout(() => { this.store.dispatch(IsLoadingAction({ payload: false })); }, 0)
        }
      }),
      catchError((error, caught) => {
        if (error.error)
        {
          if (error.error.message) {
            this.showError([error.error.message]);
          }
        }
        return throwError(error);
      }) as any);
  }
}
