<div id="adviser-listing">
  <app-search-filter>
    <div filters class="d-flex flex-row flex-fill" *ngIf="form$|async; let form">
      <div class="mr-1">
        <app-entry title="Search" icon="search" [control]="form.controls.q"></app-entry>
      </div>
      <div class="d-flex flex-fill">
        <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center" mat-button
          [matMenuTriggerFor]="menuFrom">
          As at:<span class="badge badge-secondary m-1">
            {{form.value.asAt | date:'dd/MM/yyyy'| dash}}</span>

          <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #menuFrom="matMenu" class="mat-menu p-3">
          <div class="badge badge-light filter-option w-100 d-flex flex-row align-items-center"
            (click)="asAtTodayClick()">
            <div class="flex-fill d-flex flex-row align-items-center">
              <mat-icon>event</mat-icon>
              <div class="flex-fill pl-3 title" style="text-align: left;">Today</div>
            </div>
            <mat-icon>chevron_right</mat-icon>

          </div>
          <!-- <app-button class="w-100" title="Today" icon="navigate_next" (onClick)="asAtTodayClick()">
          </app-button> -->
          <!--
          <mat-expansion-panel hideToggle class="pl-0 ">
            <mat-expansion-panel-header (click)="asAtTodayClick()">
              <mat-panel-title class="d-flex align-items-center flex-fill">
                <mat-icon class="mr-2">date_range</mat-icon>
                Today
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon>navigate_next</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-4">As at:</div>
              <div class="col-8">{{form?.controls.asAt.value | date:"dd/MM/yyyy"}}</div>
            </div>

          </mat-expansion-panel> -->
          <h4 class="mt-3 mb-1">Custom date</h4>
          <app-entry-date title="As at" [date]="form.controls.asAt"></app-entry-date>
        </mat-menu>
      </div>
      <!-- <div class="ml-3">
        <app-entry-date title="As at" [date]="form.controls.asAt"></app-entry-date>
      </div> -->
    </div>
  </app-search-filter>
  <div class="mt-4">
    <mat-progress-bar *ngIf="(list$|async) == undefined;" mode="indeterminate"></mat-progress-bar>
    <app-no-data *ngIf="(list$|async)?.length == 0"></app-no-data>
  </div>
  <table class="table table-striped table-borderless table-hover" *ngIf="(list$|async)?.length > 0">
    <thead>
      <tr>
        <th scope="col">Adviser name</th>
        <th scope="col">Adviser number</th>
        <th scope="col">Active clients</th>
        <th scope="col">FUM Active Clients</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let member of list$|async" (click)="onAdviserViewClick(member)">
        <th scope="row">{{member.name}}</th>
        <td>{{member.number}}</td>
        <td>{{member.activeClients }}</td>
        <td>{{member.fumActiveClients | currency}}
        </td>

        <td style="width: 100px;">
          <mat-icon>navigate_next</mat-icon>
        </td>
      </tr>

    </tbody>
  </table>
  <app-load-more [page]="(form$ |async).value.currentPage" [listCount]="(list$ | async)?.length" (onClick)="onLoadMore()"></app-load-more>

</div>
