import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { ElevatedAccessModel } from 'src/app/model/elevated-access.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'dialog-member-portal-elevated-access',
  styleUrls: ['./member-portal-elevated-access-dialog.component.scss'],
  templateUrl: 'member-portal-elevated-access-dialog.component.html',
})
export class MemberPortalElevatedAccessDialog extends ComponentBase {

  constructor(
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<MemberPortalElevatedAccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ElevatedAccessModel) {
    super();

  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onNoClick(): void {
    var iframe = document.getElementById('mpIframe') as HTMLIFrameElement;

    iframe.contentWindow.postMessage({ action: 'elevatedAccessClearData' }, "*")

    this.dialogRef.close();
  }

  memberPortalLoaded(): void {
    var iframe = document.getElementById('mpIframe') as HTMLIFrameElement;
    iframe.contentWindow.postMessage({ action: 'command', token: this.data.securityToken }, "*")
  }

}
