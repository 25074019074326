import { KeyValueModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { InsuranceModel } from "src/app/model/insurance.model";

export interface IMemberInsuranceState {
  form:FormGroupState<MemberInsuranceDropdown>;
  insuranceInformationList: InsuranceModel[] | undefined;
  accountData: KeyValueModel[];
}

export class MemberInsuranceDropdown{
  accountId:string = '';
}
