import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EmploymentService } from 'src/app/services/employment.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class EmployerHistoryViewEffects {
  constructor(private actions$: Actions,
    private service: EmploymentService) {
  }

  getData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.service.getBalances(action.memberId, action.accountId).pipe(
      map((data: any) => {
        return ActionsList.ResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
