<div id="adviser-listing-widget">

  <app-entry title="Search" [control]="form.controls.q" icon="search" *ngIf="form$|async; let form">
  </app-entry>
<div class="badge badge-info asat">As at: {{(form$|async)?.value.asAt | date:'dd/MM/yyyy'}}</div>
  <!-- <app-readonly class="asat" title="As at"  [value]="(form$|async)?.value.asAt | date:'dd/MM/yyyy'"></app-readonly> -->

  <mat-progress-bar *ngIf="(list$|async) == undefined;" mode="indeterminate"></mat-progress-bar>

  <app-no-items message="No advisers found" *ngIf="(list$|async)?.length == 0"></app-no-items>



  <div class="card" *ngFor="let member of list$|async" (click)="onMemberViewClick(member)">
    <div class="d-flex flex-row align-items-center">
      <div class="data-icon">
        <mat-icon>person</mat-icon>
      </div>
      <div class="data-member d-flex flex-column flex-fill">
        <div class="d-flex flex-row data-name">
          {{member.name}}

        </div>
        <div class="d-flex flex-row data-other">
          <div class="info-1 d-flex flex-row align-items-center">
            <mat-icon>tag</mat-icon>
            {{member.number}}
          </div>
          <!-- <div class="info-2 d-flex flex-row align-items-center">
            <mat-icon>email</mat-icon>
            {{member.email}}
          </div> -->

        </div>
      </div>
      <div class="data-info d-flex flex-row">
        <div class="badge badge-info mr-2 p-2">
          Clients<br>
          {{member.activeClients}}
        </div>
        <div class="badge badge-info p-2">
          FUM<br>
          {{member.fumActiveClients | currency}}
        </div>
      </div>
      <div class="data-selectmember">
        <mat-icon>chevron_right</mat-icon>

      </div>
    </div>
  </div>
  <app-load-more [page]="(form$ |async).value.currentPage" [listCount]="(list$ | async)?.length" (onClick)="onLoadMore()"></app-load-more>

</div>
