import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { BeneficiaryGroupedByAccountModel } from 'src/app/model/beneficiary-grouped-by-account.model';

export const MemberViewBeneficiariesGroupedByAccountRequestAction = createAction('[Beneficiaries] get beneficiaries grouped by account',
  props<{ memberId: number, page: number }>());

  export const MemberViewBeneficiariesGroupedByAccountResponseAction = createAction('[Beneficiaries] set beneficiaries grouped by account',
  props<{ payload: BeneficiaryGroupedByAccountModel[] }>());

export const ResetFormAction = createAction('[Beneficiaries] reset form');
