import { ResponseAction, ResetFormAction } from './actions';
import { IMemberPensionState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction,
} from 'ngrx-forms';

export const memberPensionState: IMemberPensionState = {
  data: null,
};

const reducer = createReducer(memberPensionState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, (state, { }) => {
    return {
      data: null
    }
  }),
  on(ResponseAction, (state, { payload }) => {
    if (!payload.accountNumber)
      return {
        ...state,
        data: null
      };

    return {
      ...state,
      data: payload
    }
  }),
);

export function memberPensionReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
