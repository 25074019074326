import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.states';
import { ComponentBase } from '../component-base';
import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { memberView_Data } from 'src/app/modules/shared/components/members-view/selectors';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss']
})
export class ClientViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(memberView_Data));

  constructor(public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}
