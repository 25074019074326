import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { TransactionService } from 'src/app/services/transaction.service';
import { EMPTY } from 'rxjs';
import { ListResponse } from 'src/app/model/user.model';
import { BeneficiaryGroupedByAccountModel } from 'src/app/model/beneficiary-grouped-by-account.model';
import { BeneficiaryService } from 'src/app/services/beneficiary.service';

@Injectable()
export class BeneficiariesEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private beneficiaryservice: BeneficiaryService,

    private transactionervice: TransactionService) {
  }
  getBeneficiariesGroupedByAccountView$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.MemberViewBeneficiariesGroupedByAccountRequestAction),
    switchMap((action: { memberId: number, page: number }) => this.beneficiaryservice.getMemberBeneficiariesGroupedByAccount(action.memberId, action.page).pipe(
      map((data: ListResponse<BeneficiaryGroupedByAccountModel>) => {
        return ActionsList.MemberViewBeneficiariesGroupedByAccountResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
