import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as ActionsList from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { TransactionService } from 'src/app/services/transaction.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class InterimStatementEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private transactionervice: TransactionService) {
  }

  getInterimStatement$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.RequestAction),
    switchMap((action: any) => this.transactionervice.getInterimStatement(action.memberId, action.accountId, action.from, action.to).pipe(
      map((response: any) => {
        let dataType = response.body.type;
        let binaryData = [];
        binaryData.push(response.body);
        let contentDisposition = response.headers.get('content-disposition');
        let filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        return ActionsList.ResponseAction({ payload: '' });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
