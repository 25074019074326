import { environment as base } from "./environment.base";

export const environment = {
  ...base,
  code: 'smrt',
  dashboard: '',
  ga: 'G-1YD7GB9EE5',
  b2cPolicySignIn: 'B2C_1A_SMRT_STG_AP_SignupSignin',
  b2cSignInAuthority:'https://apexproductdevelopment.b2clogin.com/apexproductdevelopment.onmicrosoft.com/B2C_1A_SMRT_STG_AP_SignupSignin',
  b2cAuthorityDomain: 'apexproductdevelopment.b2clogin.com',
  b2cClientId: '01a5af7b-3e1d-4b00-829c-54a2c6224194',
  b2cReadScope: 'https://ApexProductDevelopment.onmicrosoft.com/d461443d-bca5-40cf-8c4d-2d275fbee9b9/Adviser.Read',
  b2cWriteScope: 'https://ApexProductDevelopment.onmicrosoft.com/d461443d-bca5-40cf-8c4d-2d275fbee9b9/Adviser.Write',
  postLogoutRedirectUri:'/'
};


