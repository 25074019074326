import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IAdviserDetailsState } from './state';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.adviserDetailsState);

export const adviserDetail_Model = createSelector(
  state,
  (state: IAdviserDetailsState) => state.model
);



