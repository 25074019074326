import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class DashboardService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  widgetActiveMembers(): Observable<any> {
    return this.http.get(environment.apiUrl + `/dashboard/adviser/widget/active-members`, { headers: this.headers() });
  }

  widgetNewMembers(): Observable<any> {
    return this.http.get(environment.apiUrl + `/dashboard/adviser/widget/new-members`, { headers: this.headers() });
  }

  widgetExpiringMembers(): Observable<any> {
    return this.http.get(environment.apiUrl + `/dashboard/adviser/widget/expiring-members`, { headers: this.headers() });
  }

  widgetTotalBalance(): Observable<any> {
    return this.http.get(environment.apiUrl + `/dashboard/adviser/widget/total-balance`, { headers: this.headers() });
  }

  widgetMembersOverview(): Observable<any> {
    return this.http.get(environment.apiUrl + `/dashboard/adviser/widget/members-overview`, { headers: this.headers() });
  }


}
