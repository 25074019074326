import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import { Helper } from 'src/app/helper/helper-functions';
import { memberView_SelectedAccountId } from '../selectors';
import { AppState } from 'src/app/store/app.states';
import { RequestAction, ResetFormAction } from './actions';
import { contributions_Data } from './selectors';
import { ComponentBase } from 'src/app/views/component-base';
import { PreviousFinancialYearContributionTotalModel } from 'src/app/model/contribution-detail.model';

@Component({
  selector: 'app-contributions-view',
  templateUrl: './contributions-view.component.html',
  styleUrls: ['./contributions-view.component.scss']
})
export class ContributionsViewComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));
  data$ = this.store.pipe(select(contributions_Data));

  @Input() set memberid(value: number) {
    this._memberid = value;
    this.store.dispatch(ResetFormAction());
  }

  private _memberid: number;

  get memberid(): number {
    return this._memberid;
  }
  accountId: number = 0;

  constructor(public store: Store<AppState>,
    public helper: Helper) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccountId$.subscribe(x => {
      var val = x?.value?.value;
      if (val) {
        this.accountId = val;
        this.store.dispatch(RequestAction({ memberId: this.memberid, accountId: val }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  calculatePercentage(amount: number, cap: number) {
    if (amount == 0)
      return 180;

    if (amount > 0)
      var tt = '';
    var value = 180 - ((amount * 180) / cap);
    if (value < 0 || isNaN(value))
      return 0;
    return value;
  }

  onExport(fyContributions: PreviousFinancialYearContributionTotalModel[]) {
    var headers = ['Financial Year', 'Concessional Contribution Cap', 'Non-Concessional Contribution Cap', 'Concessional Amount Allocated',
      'Non-Concessional Amount Allocated', 'Amount Claimed As Tax Deduction'];

    const csvRows = [];
    csvRows.push(headers.join(','))

    for (const financialYear of fyContributions) {
      const values = Object.values(financialYear).map(value => {
        const escaped = ('' + value).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('download', 'previous-financial-year-contributions.csv');
    a.click()
  }
}