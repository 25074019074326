import { KeyValueModel } from '@ifaa-components/ui-components';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { TransactionDetailModel } from 'src/app/model/transaction-detail.model';

export const CashTransactionsSetFromToDateAction = createAction('[Cash transaction list] set  from to dates',
  props<{ from: string, to: string }>());

  export const CashTransactionsDownloadRequestAction = createAction('[Cash transaction list] get transaction downalod',
  props<{ memberId: number, accountId: number, page: number, filter: string }>());

  export const CashTransactionsDownloadResponseAction = createAction('[Interim statement] set transaction download',
  props<{ payload: any }>());

export const CashTransactionsRequestAction = createAction('[Cash transaction list] get transaction',
  props<{ memberId: number, accountId: number, page: number, filter: string }>());

export const CashTransactionsResponseAction = createAction('[Cash transaction list] set transaction',
  props<{ payload: TransactionDetailModel[] }>());

export const CashTransactionsTypesRequestAction = createAction('[Cash transaction list] get transaction types',
  props<{ memberId: number, accountId: number }>());

export const CashTransactionsTypesResponseAction = createAction('[Cash transaction list] set transaction types',
  props<{ payload: KeyValueModel[] }>());

  export const CashTransactionsDescriptionRequestAction = createAction('[Cash transaction list] get transaction Description',
  props<{ memberId: number, accountId: number }>());

export const CashTransactionsDescriptionResponseAction = createAction('[Cash transaction list] set transaction Description',
  props<{ payload: KeyValueModel[] }>());


export const CashTransactionIncrementPageAction = createAction('[Cash transaction list] transaction increment page');

export const ResetFormAction = createAction('[Cash transaction list] reset form');

export const ResetFilterAction = createAction('[Cash transaction list] reset filter');
