import { memberCorrespondence_List, memberCorrespondence_SelectedFileUrl } from './selectors';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';
import {
} from '../actions';
import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helper/helper-functions';
import { AppState } from 'src/app/store/app.states';
import { MemberCorrespondenceRequestAction, MemberCorrespondenceFileRequestAction, ResetFormAction } from './actions';
import { MemberCorrespondenceSummaryModel } from 'src/app/model/member-correspondence-summary.model';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
  selector: 'app-member-correspondence-view',
  templateUrl: './member-correspondence-view.component.html',
  styleUrls: ['./member-correspondence-view.component.scss']
})
export class MemberCorrespondenceViewComponent extends ComponentBase implements OnInit, OnDestroy {

  private _memberid: number;

  @Input() set memberid(value: number) {
    this._memberid = value;
    this.store.dispatch(ResetFormAction());
  }
  get memberid(): number {
    return this._memberid;
  }

  currentPDF: string = null;

  list$ = this.store.pipe(select(memberCorrespondence_List));
  selectedFilePdf$ = this.store.pipe(select(memberCorrespondence_SelectedFileUrl));

  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(MemberCorrespondenceRequestAction({ memberId: this.memberid, page: 0 }));

  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  selectPDF(item: MemberCorrespondenceSummaryModel) {
    this.store.dispatch(MemberCorrespondenceFileRequestAction({ memberId: item.memberId, accountId: item.accountId, correspondenceSource: item.correspondenceSource, correspondenceId: item.correspondenceId }));
  }

}
