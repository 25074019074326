import { commomState_LoggedinInfo } from './../store/common/common.selectors';
import { Directive, ElementRef, Renderer2, Input, ViewContainerRef } from '@angular/core';
import { AppState } from '../store/app.states';
import { select, Store } from '@ngrx/store';

@Directive({
  selector: '[permission]',
})
export class PermissionDirective {
  loggeinInInfo$ = this.store.pipe(select(commomState_LoggedinInfo));
  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef) {
  }


  ngAfterViewInit(): void {

  }

  @Input() set permission(value: string) {
    this.loggeinInInfo$.subscribe(permissionList => {

      if (!permissionList.permission) return;

      var found = permissionList.permission.filter(x => {
        if (x.toLowerCase() == value.toLowerCase() || x.toLowerCase() == 'super user')
          return x;
      });

      if (found.length == 0) {
        this.hostElement.nativeElement.remove();
      }
    })
  }

  checkpermission(value: string) {
    return new Promise((resolve) => {
      this.loggeinInInfo$.subscribe(permissionList => {

        if (!permissionList.permission) {
          resolve(false);
          return;
        }

        var found = permissionList.permission.filter(x => {
          if (x.toLowerCase() == value.toLowerCase() || x.toLowerCase() == 'super user') {
            resolve(true);
            return;
          }
        });

        if (found.length == 0) {
          resolve(false);
          return;
        }

      });

    });
  }

}
