import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  isLoggedin(): Observable<any> {
    return this.http.get(environment.apiUrl + `/auth/isloggedin`, { headers: this.headers() });
  }

  permission(): Observable<any> {
    return this.http.get(environment.apiUrl + `/auth/permissions`, { headers: this.headers() });
  }


}
