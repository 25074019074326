import { commomState_SystemConfig_Messages } from './../store/common/common.selectors';
import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/app.states';

@Directive({
  selector: '[message]',
})
export class MessageDirective {

  messages$ = this.store.pipe(select(commomState_SystemConfig_Messages));

  @Input() message: string;

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef) {
  }

  ngOnChanges() {

  }

  ngAfterViewInit(): void {
    this.messages$.subscribe(x => {
      if (!x) return;

      if (x.hasOwnProperty(this.message) ) {
        this.renderer.setProperty(this.hostElement.nativeElement, 'innerHTML', x[this.message]);
      }
    });
  }
}
