<div id="member-view-component" class="member-view-header">
  <div class="d-flex flex-row align-items-center ">
    <div class="d-icon">
      <mat-icon>account_circle</mat-icon>
    </div>

    <div class="d-data flex-fill" *ngIf="data$|async; let data;else userLoading">
      <h4 class="name">
        {{data.firstName}} {{data.lastName}} <span class="ml-2" *ngIf="data.preferredName">
          ({{data.preferredName}})</span>
        <span class="badge d-inline"
          [ngClass]="(data.memberStatus?.name==='Active')?'badge-success':'badge-danger'">{{data.memberStatus?.name}}</span>
      </h4>
      <div class="position d-flex align-items-center">
        <mat-icon>event</mat-icon>
        {{data.dateOfBirth | date:'dd MMM yyyy'}}

      </div>
      <div class="address d-flex align-items-center">
        <mat-icon>tag</mat-icon>
        {{data.memberNumber}}
      </div>
    </div>
    <div *ngIf="data$|async; let data">
      <app-button color="warning" class="mr-2" title="{{ 'LOGIN_AS' | translate }}" (onClick)="onLogInAsClick(data)"
      [isDisabled]="!data.isElevatedAccessEnabled" icon="login">
    </app-button>
    </div>
  </div>
  
  <div *ngIf="data$|async; let data;" class="">
    <mat-form-field appearance="outline" class="float-right mt-1" *ngIf="data.accounts.length>1">
      <mat-label>Select an account</mat-label>
      <mat-select [ngrxFormControlState]="(selectedAccountId$ | async).controls.value">
        <mat-option *ngFor="let item of data.accounts" [value]="item.accountId">{{item.accountNumber}}
          ({{item.fundCode}})
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>


<div id="member-view-component">

  <div class="d-flex flex-column">
    <app-component-wrapper icon="grid_view" id="member-view-details-wrapper" header="Details" class=" ">
      
      <div class="d-flex flex-column" slot="start" *ngIf="data$|async; let data; else loading" appanimatecss>
        
        <div class="details d-flex flex-row flex-fill ">
          <div class="data-col-0  ">
            <div class="options-container">
              <ul class="list-group ">
                <li class="list-group-item d-flex" [ngClass]="{selected:currentTab ==='overview'}"
                  (click)="currentTab='overview'">
                  <mat-icon>account_box</mat-icon>
                  <div>
                    <h3>Overview</h3>
                    <h6>Member details, Adviser authority, Accounts, Bpay, Beneficiaries</h6>
                  </div>
                </li>
                <li class="list-group-item d-flex" [ngClass]="{selected:currentTab ==='transactions'}"
                  (click)="currentTab='transactions'">
                  <mat-icon>paid</mat-icon>
                  <div>
                    <h3>Transactions</h3>
                    <h6>Transactions, Rollovers, Contribution limits, Account summary</h6>
                  </div>
                </li>
                <li class="list-group-item d-flex" [ngClass]="{selected:currentTab ==='investments'}"
                  (click)="currentTab='investments'">
                  <mat-icon>savings</mat-icon>
                  <div>
                    <h3>Investments</h3>
                    <h6>Switch investments, Valuation screen</h6>
                  </div>
                </li>
                <li class="list-group-item d-flex" [ngClass]="{selected:currentTab ==='insurance'}"
                  (click)="currentTab='insurance'">
                  <mat-icon>health_and_safety</mat-icon>
                  <div>
                    <h3>Insurance</h3>
                    <h6>Insurance list</h6>
                  </div>
                </li>
                <li class="list-group-item d-flex" [ngClass]="{selected:currentTab ==='components'}"
                  *featuretoggle="canViewMemberAccountComponents" (click)="currentTab='components'">
                  <mat-icon>account_balance</mat-icon>
                  <div>
                    <h3>Account Components</h3>
                    <h6>Eligible Service Date, Taxable Components</h6>
                  </div>
                </li>
                <li class="list-group-item d-flex" [ngClass]="{selected:currentTab ==='employments'}"
                  (click)="currentTab='employments'">
                  <mat-icon>history</mat-icon>
                  <div>
                    <h3>Employments</h3>
                    <h6>Employment History</h6>
                  </div>
                </li>
                <li class="list-group-item d-flex" [ngClass]="{selected:currentTab ==='documents'}"
                  (click)="currentTab='documents'">
                  <mat-icon>content_copy</mat-icon>
                  <div>
                    <h3>Documents</h3>
                    <h6>Document list</h6>
                  </div>
                </li>
                <li class="list-group-item d-flex" *ngIf="showPensionTab" [ngClass]="{selected:currentTab ==='pension'}"
                  (click)="currentTab='pension'">
                  <mat-icon>elderly</mat-icon>
                  <div>
                    <h3>Pension</h3>
                    <h6>Pension Details</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="data-col-1 flex-fill">
            <div appanimatecss class="" *ngIf="currentTab ==='overview'" style="overflow: hidden;">
              <div class="section-title d-flex flex-row">
                <div class="flex-fill">Member details
                </div>

              </div>
              <div class="details d-flex flex-row flex-fill align-items-center">
                <div class="card flex-fill">

                  <div class="d-flex flex-row">
                    <div class="w-25">
                      <app-readonly title="Member No" value="{{data.memberNumber}}"></app-readonly>
                    </div>
                    <div class="w-25">
                      <app-readonly title="Communication Preferences"
                        value="{{data.communicationPreferenceDescription}}"></app-readonly>
                    </div>
                    <div class="w-25"> <app-readonly title="Joined date"
                        value="{{data.dateJoinedFund | date:'dd MMM yyyy'}}">
                      </app-readonly></div>
                    <div class="w-25"> <app-readonly title="{{ 'PREFERRED_NAME' | translate }}"
                        value="{{data.preferredName}}"></app-readonly></div>
                  </div>

                  <div class="d-flex flex-row">
                    <div class="w-25"><app-readonly title="First name" value="{{data.firstName}}"></app-readonly></div>
                    <div class="w-25"> <app-readonly title="Second name" value="{{data.secondName}}"></app-readonly>
                    </div>
                    <div class="w-25"> <app-readonly title="Last name" value="{{data.lastName}}"></app-readonly></div>
                    <div class="w-25"> <app-readonly title="Gender" value="{{data.gender}}"></app-readonly></div>
                  </div>

                  <div class="d-flex flex-row">
                    <div class="w-25"> <app-readonly title="Date of birth"
                        value="{{data.dateOfBirth | date:'dd MMM yyyy'}}">
                      </app-readonly></div>
                    <div class="w-25"> <app-readonly title="Mobile" value="{{data.mobile}}"></app-readonly></div>
                    <div class="w-25"> <app-readonly title="Email" value="{{data.email}}"></app-readonly></div>
                    <div class="w-25"> <app-readonly title="Occupation" value="{{data.occupation}}"></app-readonly>
                    </div>

                  </div>

                  <div class="d-flex flex-row">
                    <div class="w-50"><app-readonly title="Postal address"
                        [value]="uihelper.formatAddress(data.postalAddress)"></app-readonly></div>
                    <div class="w-50"><app-readonly title="Residential address"
                        [value]="uihelper.formatAddress(data.residentialAddress)">
                      </app-readonly></div>
                  </div>



                </div>


              </div>


              <app-adviser-authority-view [memberid]="memberId"></app-adviser-authority-view>


              <div class="col-md-12 p-0 mt-4">
                <div class="section-title d-flex flex-row">
                  <div class="flex-fill">Accounts
                  </div>

                </div>
                <div class="table-responsive card">
                  <table class="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>Number</th>
                        <th>Type</th>
                        <th *ngIf="displayAccountDetailsWithUnits">Number of units</th>
                        <th *ngIf="displayAccountDetailsWithUnits">Unit price</th>
                        <th>Product</th>
                        <th>USI</th>
                        <th>Balance</th>
                        <th>As at</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of data.accounts">
                        <td>{{item.accountNumber}}</td>
                        <td>{{item.accountType}}</td>
                        <td *ngIf="displayAccountDetailsWithUnits">{{item.units|dash}}</td>
                        <td *ngIf="displayAccountDetailsWithUnits">{{item.unitPrice |dash}}</td>
                        <td>{{item.product}}</td>
                        <td>{{item.usi}}</td>
                        <td>{{item.balance | currency |dash}}</td>
                        <td>{{item.balanceCalculationDate | date:'dd/MM/yyyy' | dash}}</td>
                        <td>
                          <div class="badge "
                            [ngClass]="(item.accountStatus==='Active')?'badge-success':'badge-danger'">
                            {{item.accountStatus}}
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12 p-0">
                <div class="section-title d-flex ">
                  Account BPay details
                </div>
                <div class="table-responsive card ">

                  <app-no-items message="No active accounts" *ngIf="getActiveAccounts(data.accounts).length === 0">
                  </app-no-items>
                  <table class="table table-striped table-borderless"
                    *ngIf="getActiveAccounts(data.accounts).length > 0">
                    <thead>
                      <tr>
                        <th width="33%">Number</th>
                        <th width="33%">Biller code</th>
                        <th width="33%">Reference</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of getActiveAccounts(data.accounts)">
                        <td>{{item.accountNumber}}</td>
                        <td>{{item.bPayDetails.billerCode}}</td>
                        <td>{{item.bPayDetails.reference}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-md-12 p-0">
                <div class="section-title d-flex ">
                  Beneficiaries
                </div>
                <div class="table-responsive card">
                  <app-beneficiaries-view [memberid]="memberId"></app-beneficiaries-view>
                </div>
              </div>

            </div>
            <div appanimatecss class=" " *ngIf="currentTab ==='transactions'" style="overflow: hidden;">

              <div class="d-flex flex-column">
                <div>
                  <mat-tab-group>
                    <mat-tab label="Transactions">
                      <div class="pt-4" title="Contributions"
                        text="You haven’t had any contribution for the last 6 months">
                        <app-cash-transactions-view [memberid]="memberId"></app-cash-transactions-view>
                      </div>

                    </mat-tab>
                    <mat-tab label="Rollovers">
                      <div class="pt-4">
                        <app-rollovers-view [memberid]="memberId"></app-rollovers-view>
                      </div>
                    </mat-tab>

                    <mat-tab label="Contributions Limits">
                      <div class="pt-4">
                        <app-contributions-view [memberid]="memberId"></app-contributions-view>
                      </div>
                    </mat-tab>

                    <mat-tab label="Account Summary">
                      <div class="pt-4">
                        <app-interim-statement-view [memberid]="memberId"></app-interim-statement-view>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </div>

            <div appanimatecss class=" " *ngIf="currentTab ==='investments'" style="overflow: hidden;">
              <div class="d-flex flex-column">
                <div>

                  <div class="row" style="clear: both">
                    <div class="col-md-12">
                      <app-switch-transactions-view [memberid]="memberId"></app-switch-transactions-view>
                    </div>
                  </div>

                  <div class="row" style="clear: both">
                    <div class="col-md-12">
                      <!-- *ngIf="displayMultipleUnderlyingInvestmentsChart" -->
                      <app-valuation-view [memberid]="memberId"></app-valuation-view>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div appanimatecss class="" *ngIf="currentTab ==='insurance'">
              <div class="row">

                <div class="col-md-12" title="Insurance" joyrideStep="insuranceStep" text="Your insurance has lapsed">
                  <app-member-insurance [memberid]="memberId" [accounts]="data.accounts"
                    *ngIf="data?.accounts?.length>0"></app-member-insurance>
                </div>
              </div>
            </div>
            <div *featuretoggle="canViewMemberAccountComponents">
              <div appanimatecss class="" *ngIf="currentTab ==='components'">

                <div class="d-flex flex-column">
                  <div>

                    <div class="row " style="clear: both">
                      <div class="col-md-12">
                        <app-account-components-view class="h-100" [memberid]="memberId"></app-account-components-view>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div appanimatecss class="" *ngIf="currentTab ==='pension'">
              <div class="d-flex flex-column">
                <div>
                  <div class="row " style="clear: both">
                    <div class="col-md-12">
                      <app-member-pension-view [memberid]="memberId"></app-member-pension-view>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div appanimatecss class="" *ngIf="currentTab ==='employments'">
              <app-employment-history-view class="h-100" [memberid]="memberId"></app-employment-history-view>
            </div>

            <div appanimatecss class="" *ngIf="currentTab ==='documents'">
              <app-member-correspondence-view class="h-100" [memberid]="memberId"></app-member-correspondence-view>
            </div>

          </div>

        </div>
      </div>

    </app-component-wrapper>
  </div>
</div>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<ng-template #userLoading>
  <div class="d-flex w-100 flex-column">
    <app-content-loader id="loading-member-name"></app-content-loader>
    <app-content-loader id="loading-member-data"></app-content-loader>
    <app-content-loader id="loading-member-data"></app-content-loader>
  </div>
</ng-template>