
import { createReducer, on, Action } from '@ngrx/store';
import { AdviserAuthorityResponseAction, ResetFormAction } from './actions';
import { AdviserAuthorityModel, IAdviserAuthorityState } from './state';

export const adviserAuthorityState: IAdviserAuthorityState = {
  list: undefined,
};

const reducer = createReducer(adviserAuthorityState,
  on(AdviserAuthorityResponseAction, (state, { payload }) => {
    return {
      ...state,
      list: payload
    }
  })
);

export function adviserAuthorityReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
