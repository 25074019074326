import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { LogoutRequestAction } from 'src/app/store/common/common.actions';
import { Router } from '@angular/router';
import { dealerGroupDetail_Model, dealerGroupDetail_selectedContact } from './selectors';
import { Helper } from '@ifaa-components/ui-components';
import { DealerGroupContactDetailsModel } from './state';
import { SelectContactAction } from './actions';

@Component({
  selector: 'app-dealer-group-details',
  templateUrl: './dealer-group-details.component.html',
  styleUrls: ['./dealer-group-details.component.scss']
})
export class DealerGroupDetailsComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.store.pipe(select(dealerGroupDetail_Model));
  selectedContact$ = this.store.pipe(select(dealerGroupDetail_selectedContact));
  helper = new Helper();

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSelectContactClicked(contact: DealerGroupContactDetailsModel) {
    this.store.dispatch(SelectContactAction({ payload: null }))
    setTimeout(() => {
      this.store.dispatch(SelectContactAction({ payload: contact }))
    }, 0);
  }

}

