<div id="group-statements-container">
  <div class="page-header">
    <app-toolbar-options icon="text_snippet">
      <div class="d-flex flex-column" slot="start">
        <div class="page-title">View Statements</div>

      </div>
    </app-toolbar-options>
  </div>
  <div class="page-content" appanimatecss>

    <app-component-wrapper  icon="file_present" header="Document list">
      <div class="d-flex flex-column" slot="start">
        <app-document-viewer category="adviser-group"></app-document-viewer>
      </div>
    </app-component-wrapper>

  </div>
</div>
