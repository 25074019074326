import { Component, Input, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';


@Component({
  selector: 'app-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss']
})
export class ContentLoaderComponent extends ComponentBase implements OnInit, OnDestroy {


  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}

