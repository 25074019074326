
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { ISelectorWidgetViewState } from './state';
import { SelectOptionAction, SelectOptionsAction } from './actions';

export const state: ISelectorWidgetViewState = {
  list: [],
  selected: '',
};

const reducer = createReducer(state,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(SelectOptionAction, (state, { payload }) => {
    return {
      ...state,
      selected: payload
    };
  }),
  on(SelectOptionsAction, (state, { payload }) => {
    return {
      ...state,
      list: payload
    };
  })
);

export function selectorWidgetViewReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
