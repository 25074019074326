import { AppListDataDirective } from './app-listdata.directive';
import { PermissionDirective } from './permission.directive';
import { MessageDirective } from './message.directive';
import { FeatureToggleDirective } from './feature-toggle.directive';
import { CustomErrorStateMatcherDirective } from './custom-error-state-matcher.directive';
import { NgrxMatSelectViewDirective } from './ngrx-mat-select-view-adapter';
import { AnimateCssDirective } from './animatecss.directive';
import { InputDisabledDirective } from './ngrx-mat-input.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDisableDirective } from './permission-disable.directive';
import { FeaturePermissionDisableDirective } from './feature-permission-disable.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InputDisabledDirective,
    AnimateCssDirective,
    NgrxMatSelectViewDirective,
    CustomErrorStateMatcherDirective,
    FeatureToggleDirective,
    MessageDirective,
    PermissionDirective,
    PermissionDisableDirective,
    AppListDataDirective,
    FeaturePermissionDisableDirective

  ],
  providers:[
    PermissionDirective,
    FeatureToggleDirective,
  ],
  exports: [
    InputDisabledDirective,
    AnimateCssDirective,
    NgrxMatSelectViewDirective,
    CustomErrorStateMatcherDirective,
    FeatureToggleDirective,
    MessageDirective,
    PermissionDirective,
    PermissionDisableDirective,
    AppListDataDirective,
    FeaturePermissionDisableDirective
  ]
})
export class SharedDirectivesModule { }
