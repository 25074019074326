import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { memberListingView_Form, memberListingView_List, memberListingView_SearchFormatted } from './selectors';
import { MemberModel } from 'src/app/model/member.model';
import { NextPageAction, RequestAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-members-list-widget',
  templateUrl: './members-list-widget.component.html',
  styleUrls: ['./members-list-widget.component.scss']
})
export class MembersListWidgetComponent extends ComponentBase implements OnInit, OnDestroy {

  memberList$ = this.store.pipe(select(memberListingView_List));
  form$ = this.store.pipe(select(memberListingView_Form));
  search$ = this.store.pipe(select(memberListingView_SearchFormatted));
  helper = new Helper();
  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.onSelectedChanged(this.search$, (value) => {
      this.store.dispatch(RequestAction({ search: value, page: 0 }));
    }, 400)

  }

  async onLoadMore() {
    var form = await this.helper.getValue(this.form$);
    var search = await this.helper.getValue(this.search$);
    this.store.dispatch(NextPageAction());
    this.store.dispatch(RequestAction({ search: search, page: form.value.page + 1 }));
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onMemberViewClick(member: MemberModel) {
    this.router.navigate(['client-view', member.memberId]);
  }
}

