import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction
} from 'ngrx-forms';
import { IMemberCorrespondenceListState } from './state';
import { MemberCorrespondenceResponseAction, ResetFormAction, MemberCorrespondenceFileRequestAction } from './actions';
import { environment } from 'src/environments/environment';

export const memberCorrespondenceListState: IMemberCorrespondenceListState = {
  list: undefined,
  selectedFileUrl: ''
};

const reducer = createReducer(memberCorrespondenceListState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    return state;
  }),
  on(ResetFormAction, (state) => {
    return {
      ...state,
      list: undefined,
      selectedFileUrl: ''
    }
  }),
  on(MemberCorrespondenceResponseAction, (state, { payload }) => {
    return {
      ...state,
      list: payload
    }
  }),
  on(MemberCorrespondenceFileRequestAction, (state, { memberId, accountId, correspondenceSource, correspondenceId }) => {

    var link = environment.apiUrl + `/members/${memberId}/correspondence/${correspondenceSource}/${correspondenceId}`;

    return {
      ...state,
      selectedFileUrl: link
    }
  }),
);

export function memberCorrespondenceListReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
