import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from '@ngrx/effects';
import * as ActionsList from './actions';
import { switchMap, map, catchError } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { MemberAdviserAuthorityService } from "src/app/services/member-adviser-authority-service";

@Injectable()
export class AdviserAuthorityEffects {
  constructor(private actions$: Actions,
    private memberService: MemberAdviserAuthorityService) {
  }

  getAdviserAuthority$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.AdviserAuthorityRequestAction),
    switchMap((action: any) => this.memberService.getAdviserAuthority(action.memberId).pipe(
      map((data: any) => {
        return ActionsList.AdviserAuthorityResponseAction({ payload: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
