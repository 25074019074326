import { AppState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { Directive, ElementRef, Renderer2, Input, HostListener } from '@angular/core';
import { ChangeScreenWidthAction } from 'src/app/store/common/common.actions';

@Directive({
  selector: '[screen-width]',
})
export class ScreenWidthDirective {
  constructor(public store: Store<AppState>, public renderer: Renderer2, public hostElement: ElementRef) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.store.dispatch(ChangeScreenWidthAction({ payload: event.target.innerWidth }));
  }

  ngOnChanges() {
    var dd = '';
  }
  ngAfterViewInit(): void {


  }


}
