import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { widgetTotalBalance_Data } from './selectors';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { RequestAction } from './actions';

@Component({
  selector: 'app-members-total-balance',
  templateUrl: './members-total-balance.component.html',
  styleUrls: ['./members-total-balance.component.scss']
})
export class MembersTotalBalanceComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(widgetTotalBalance_Data));

  constructor(
    public store: Store<AppState>,
    public apstore: Store<AdvisorPortalSharedState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.apstore.dispatch(RequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}

