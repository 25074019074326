import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { OkModel } from '../model/ok.model';

@Injectable()
export class InvestmentService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getMemberSwitchTransactions(memberId: number, accountId: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString())
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/switchtransactions`, { headers: this.headers(), params: params });
  }

  getAccumulationInvestmentOptions(): Observable<any> {
    return this.http.get(environment.apiUrl + `/members/accumulation-investment-options`, { headers: this.headers()});
  }


}
