import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAccountComponentState} from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.accountComponentState);

export const accountComponent_Data = createSelector(
  state,
  (state: IAccountComponentState) => state.data
);
