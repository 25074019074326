<div id="contributions-view">
  <div class="section-title d-flex ">
    Contributions Limits
  </div>
  <div class="card" *ngIf="data$ | async; let data">

    <div class="mb-3">
      <div class="description" *ngIf="data.customWording">
        {{data.customWording.aboveDiagramText}}
      </div>

      <div class="d-flex flex-row  widgets-row ">
        <div class="w-50 widget">
          <h4 class="title">Concessional</h4>
          <div class="gauge gauge__liveupdate" id="gauge">
            <div class="gauge--container" id="gauge-container">
              <div class="gauge--marker"></div>
              <div class="gauge--background"></div>
              <div class="gauge--center"></div>
              <div class="gauge--data"
                style="transform: rotate(-{{calculatePercentage(data.concessionalAmount,data.concessionalCap)}}deg);">
              </div>
              <div class="gauge--data over" *ngIf="data.concessionalExceeded" style="transform: rotate(170deg);"></div>
            </div>
            <div class="gauge--labels mdl-typography--headline">
              <span class="gauge--label__low">
                <div class="flex-fill in-wrapper d-flex flex-column processed">
                  <label class="in">
                    {{data.concessionalAmount | currency}}
                  </label>
                  <h5>Processed</h5>
                </div>
              </span>
              <span class="gauge--label__spacer"></span>
              <span class="gauge--label__high">
                <div class="flex-fill out-wrapper d-flex  flex-column cap">
                  <label class="out">
                    {{data.concessionalCap | currency}}
                  </label>
                  <h5 [ngClass]="{'exceeded': data.concessionalExceeded}">Cap </h5>
                </div>
              </span>
            </div>
          </div>

        </div>
        <div class="w-50 widget">
          <h4 class="title">Non-Concessional</h4>

          <div class="gauge gauge__liveupdate" id="gauge">
            <div class="gauge--container" id="gauge-container">
              <div class="gauge--marker"></div>
              <div class="gauge--background"></div>
              <div class="gauge--center"></div>
              <div class="gauge--data"
                style="transform: rotate(-{{calculatePercentage(data.nonConcessionalAmount,data.nonConcessionalCap)}}deg);">
              </div>
              <div class="gauge--data over" *ngIf="data.nonConcessionalExceeded" style="transform: rotate(170deg);">
              </div>
            </div>
            <div class="gauge--labels mdl-typography--headline">
              <span class="gauge--label__low">
                <div class="flex-fill in-wrapper d-flex flex-column processed">
                  <label class="in">
                    {{data.nonConcessionalAmount | currency}}
                  </label>
                  <h5>Processed</h5>
                </div>
              </span>
              <span class="gauge--label__spacer"></span>
              <span class="gauge--label__high">
                <div class="flex-fill out-wrapper d-flex  flex-column cap">
                  <label class="out">
                    {{data.nonConcessionalCap | currency}}
                  </label>
                  <h5 [ngClass]="{'exceeded': data.nonConcessionalExceeded}">Cap </h5>
                </div>
              </span>
            </div>
          </div>

        </div>
      </div>

      <div class="description" *ngIf="data.customWording">
        {{data.customWording.belowDiagramText}}
      </div>

    </div>

    <!-- previous fy summaries -->
    <div class="previous-fy-summary-container">
      <div class="download">
        <app-button [matMenuTriggerFor]="menuExport" type="submit" title="Export" icon="cloud_download"></app-button>
        <mat-menu #menuExport="matMenu">
          <button mat-menu-item (click)="onExport(data.previousFyContributions)">
            <span>Save as CSV</span>
          </button>
        </mat-menu>
      </div>
      <h3 class="mb-1">Previous financial year contribution summaries:</h3>
      <div *ngFor="let fySummary of data.previousFyContributions" class="summary-container">
        <app-previous-fy-contribution-summary [fySummary]="fySummary"></app-previous-fy-contribution-summary>
      </div>
    </div>

  </div>
</div>