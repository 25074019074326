import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from '@ngrx/effects';
import * as ActionsList from './actions';
import { switchMap, map, catchError } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { MemberAccountComponentService } from "src/app/services/member-account-components-service";

@Injectable()
export class AccountComponentEffects {
  constructor(private actions$: Actions,
    private memberService: MemberAccountComponentService) {
  }

  getAccountComponent$ = createEffect(() => this.actions$.pipe(
    ofType(ActionsList.AccountComponentsRequestAction),
    switchMap((action: any) => this.memberService.getAccountComponents(action.memberId, action.accountId).pipe(
      map((data: any) => {
        return ActionsList.AccountComponentsResponseAction({ payload: data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));
}
