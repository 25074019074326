import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { BeneficiaryGroupedByAccountModel } from 'src/app/model/beneficiary-grouped-by-account.model';
import { ElevatedAccessModel } from 'src/app/model/elevated-access.model';
import { MemberModel } from 'src/app/model/member.model';

export const MemberViewResetAction = createAction('[Member view] reset');
export const RequestAction = createAction('[Member view] get data', props<{ memberId: number }>());
export const ResponseAction = createAction('[Member view] set data',  props<{ payload: MemberModel }>());

export const ElevatedAccessRequestAction = createAction('[Member View] get elevated access',
  props<{ memberId: number }>());

export const ElevatedAccessResponseAction = createAction('[Member View] set elevated access',
  props<{ payload: ElevatedAccessModel }>());

  export const ElevatedAccessResetAction = createAction('[Member View] reset elevated access');
