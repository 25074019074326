import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { MemberModel } from 'src/app/model/member.model';

export const RequestAction = createAction('[Member Listing view] get data', props<{ search: string, page: number }>());
export const ResponseAction = createAction('[Member Listing view] set data',  props<{ payload: MemberModel[] }>());

export const NextPageAction = createAction('[Member Listing view]next page');


