import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';


export const RequestAction = createAction('[Interim statement] get',
  props<{ memberId: number, accountId: number, from: string, to: string }>());

export const ResponseAction = createAction('[Interim statement] set transaction',
  props<{ payload: any }>());

