import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IAdviserListingViewState } from './state';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.adviserListingViewState);

export const adviserListingView_List = createSelector(
  state,
  (state: IAdviserListingViewState) => state.list
);

export const adviserListingView_Form = createSelector(
  state,
  (state: IAdviserListingViewState) => state.form
);


export const adviserListingView_SearchFormatted = createSelector(
  state,
  (state: IAdviserListingViewState) => {
    if (state.form.value.q) {
      return 'all:' + state.form.value.q
    }
    else {
      return '';
    }

  });
