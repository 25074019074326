import { AppState } from 'src/app/store/app.states';
// import { commomState_LoggedinInfo } from './../store/common/common.selectors';
import { Directive, ElementRef, Renderer2, Input, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';

@Directive({
  selector: '[permission]',
})
export class PermissionDirective {
  // loggeinInInfo$ = this.store.pipe(select(commomState_LoggedinInfo));

  constructor(
    public store: Store<AppState>,
    public renderer: Renderer2,
    public hostElement: ElementRef) {
  }


  ngAfterViewInit(): void {

  }

  @Input() set permission(value: string) {
    // this.loggeinInInfo$.subscribe(permissionList => {

    //   if (!permissionList.permission) return;

    //   var found = permissionList.permission.filter(x => {
    //     if (x.toLowerCase() == value.toLowerCase() || x.toLowerCase() == 'super user')
    //       return x;
    //   });

    //   if (found.length == 0) {
    //     this.hostElement.nativeElement.remove();
    //   }
    // })
  }

}
