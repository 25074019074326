import { IsLoggedInResponseAction, IsLoggedInRequestAction } from './common.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { AuthService } from 'src/app/services/auth.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class CommonEffects {
  constructor(private store: Store<any>,
    private actions$: Actions,
    private authService: AuthService) {
  }

  isLoggedIn$ = createEffect(() => this.actions$.pipe(
    ofType(IsLoggedInRequestAction),
    switchMap((action: any) => this.authService.isLoggedin().pipe(
      map((data: any) => {
        return IsLoggedInResponseAction({ data: data.data });
      }),
      catchError(err => {
        return EMPTY;
      })
    )),
  ));

}
