import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { Router } from '@angular/router';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { RequestAction } from './actions';
import { widgetActiveMember_Data } from './selectors';


@Component({
  selector: 'app-members-active',
  templateUrl: './members-active.component.html',
  styleUrls: ['./members-active.component.scss']
})
export class MembersActiveComponent extends ComponentBase implements OnInit, OnDestroy {


  data$ = this.store.pipe(select(widgetActiveMember_Data));

  constructor(
    public store: Store<AppState>,
    public apstore: Store<AdvisorPortalSharedState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.apstore.dispatch(RequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }


}

