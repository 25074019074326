import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IAdviserDetailsEditState } from './state';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.adviserDetailsEditState);

export const adviserDetailEdit_Form = createSelector(
  state,
  (state: IAdviserDetailsEditState) => state.form
);



