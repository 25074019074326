import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable()
export class ValuationService extends BaseService {
  pipe = new DatePipe('en-AU');

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getValuation(memberId: number, accountId: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/valuation`, { headers: this.headers(), params: params });
  }

  getValuationSummary(memberId: number, accountId: number, to: string): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('accountId', accountId.toString())
      .set('to', to == undefined ? '' : this.pipe.transform(to, 'yyyy-MM-dd'));

    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/valuation-summary`, { headers: this.headers(), params: params });
  }

}
