import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { IMemberViewState } from './state';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const getmemberView = createSelector(portalState, (state) => state.memberViewState);

export const memberView_Data = createSelector(
  getmemberView,
  (state: IMemberViewState) => state.data
);


export const memberView_SelectedAccountId = createSelector(
  getmemberView,
  (state: IMemberViewState) => state.selectedAccountId
);

export const memberView_CurrentElevatedAccess = createSelector(
  getmemberView,
  (state: IMemberViewState) => state.currentElevatedAccess
);
