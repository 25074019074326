import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from 'src/app/store/app.states';
import { ActivatedRoute, Router } from '@angular/router';
import { memberView_CurrentElevatedAccess, memberView_Data, memberView_SelectedAccountId } from './selectors';
import { ElevatedAccessRequestAction, ElevatedAccessResetAction, MemberViewResetAction, RequestAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';
import { AccountModel } from 'src/app/model/account.model';
import { FeatureToggleName } from 'src/app/services/featureToggle.service';
import { MemberModel } from 'src/app/model/member.model';
import { MemberPortalElevatedAccessDialog } from 'src/app/dialogs/member-portal-elevated-access/member-portal-elevated-access-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { adviserAuthority_List } from './adviser-authority/selectors';
import { AdviserAuthorityRequestAction } from './adviser-authority/actions';
import { AdviserAuthorityModel } from './adviser-authority/state';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-members-view',
  templateUrl: './members-view.component.html',
  styleUrls: ['./members-view.component.scss']
})
export class MemberViewComponent extends ComponentBase implements OnInit, OnDestroy {

  canViewMemberAccountComponents = FeatureToggleName.canViewMemberAccountComponents;
  elevatedAccess$ = this.store.pipe(select(memberView_CurrentElevatedAccess));

  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));
  data$ = this.store.pipe(select(memberView_Data));
  memberAuthoritylist$ = this.store.pipe(select(adviserAuthority_List));
  memberId: number;
  showPensionTab: boolean = false;
  currentTab = 'overview';
  uihelper = new Helper();
  adviserAuthorities: AdviserAuthorityModel[];

  constructor(
    public store: Store<AppState>,
    public dialog: MatDialog,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.elevatedAccess$.subscribe(x => {
      if (!x) return;
      const dialogRef = this.dialog.open(MemberPortalElevatedAccessDialog, {
        width: '95%',
        height: '95%',
        data: x,
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        this.store.dispatch(ElevatedAccessResetAction());
      });
    });

    this.sub = this.memberAuthoritylist$.subscribe(x => {
      this.adviserAuthorities = x;
    });


    this.sub = combineLatest([this.selectedAccountId$, this.data$]).subscribe(x => {
      if (x[0] && x[1]) {
        var currentAccountId = x[0].value.value;
        var accountData = x[1].accounts.find(x => x.accountId == currentAccountId);
        this.showPensionTab = this.canSeePensionTab(accountData);
      }
    })

    this.selectedChanged(() => {
      this.getRouteParam(this.route, 'id', (data: any) => {
        if (this.memberId == parseInt(data)) return;
        this.memberId = parseInt(data);
        this.store.dispatch(MemberViewResetAction());
        this.store.dispatch(RequestAction({ memberId: this.memberId }));
        this.store.dispatch(AdviserAuthorityRequestAction({ memberId: this.memberId }));
      }, null);
    })
  }


  onLogInAsClick(member: MemberModel): void {
    this.store.dispatch(ElevatedAccessRequestAction({ memberId: member.memberId }))
  }

  getTabUrl(tab: string) {
    return '/client-view/' + this.memberId + '/' + tab;
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  getActiveAccounts(accounts: AccountModel[]) {
    return accounts.filter(o => o.accountStatus === "Active")
  }

  canSeePensionTab(account: AccountModel) {
    // if account is not pension then dont show tab
    if (!account.isPensionType) return false;

    // check the pension account has the correct authority codes
    var hasAuthorityToActorView = this.adviserAuthorities?.filter(x => (x.authorityTypeCode === 'AU' || x.authorityTypeCode === 'AE') && x.accountNumber == account.accountNumber);

    return hasAuthorityToActorView != null;
  }
}