<div class="section-title d-flex ">
  Adviser Authority
</div>
<div class="card ">
  <div class="table-responsive">
    <mat-progress-bar *ngIf="(list$|async) == undefined;" mode="indeterminate"></mat-progress-bar>
    <app-no-items message="No adviser authority found" *ngIf="(list$ |async)?.length === 0"></app-no-items>

    <table *ngIf="(list$ | async)?.length>0" class="table table-striped table-borderless">
      <thead>
        <tr>
          <th>Adviser Name</th>
          <th>Account Number</th>
          <th>Authority Start Date</th>
          <th>Authority End Date</th>
          <th>Authority Type</th>
          <th>Adviser Fee Type </th>
          <th>Adviser Fee Amount</th>
          <th>Annual Percentage</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of (list$ | async)">
          <td>{{data.adviserName }}</td>
          <td>{{data.accountNumber }}</td>
          <td>{{data.effectiveDate | date:'dd/MM/yyyy'}}</td>
          <td>{{data.expiryDate | date:'dd/MM/yyyy'}}</td>
          <td>{{data.authorityType }}</td>
          <td>{{data.adviserFeeType }}</td>
          <td>{{data.adviserFeeAmount | currency}}</td>
          <td><span *ngIf="data?.adviserFeePercentage">{{ data?.adviserFeePercentage }} %</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
