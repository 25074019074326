import { FormGroupState } from "ngrx-forms";
import { MemberModel } from "src/app/model/member.model";

export interface IWidgetActiveMembersViewState {
  data:WidgetActiveMembersModel;
}


export class WidgetActiveMembersModel{
  value: number = 0;
  monthYear: string = '';
  change: number = 0;
}
