import { Directive, Host, Input, Optional } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';


@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngrxFormControlState]',
})
export class InputDisabledDirective {
  @Input() set ngrxFormControlState(state: FormControlState<any>) {

    if (this.input) {
      this.setup(this.input, state.isDisabled);
    }

    if (this.toggle) {
      this.setup(this.toggle, state.isDisabled);
    }

    if (this.select) {
      this.setup(this.select, state.isDisabled);
    }
  }

  setup(el: any, isDisabled: boolean) {
    el.disabled = isDisabled;
  }

  constructor(
    @Host() @Optional() private toggle: MatSlideToggle,
    @Host() @Optional() private input: MatInput,
    @Host() @Optional() private select: MatSelect,
  ) { }
}
