import { employerHistoryViewView_List } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';

import { memberView_SelectedAccountId } from '../selectors';
import { AppState } from 'src/app/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
  selector: 'app-employment-history-view',
  templateUrl: './employment-history-view.component.html',
  styleUrls: ['./employment-history-view.component.scss']
})
export class EmploymentHistoryViewComponent extends ComponentBase implements OnInit, OnDestroy {
  private _memberid: number;

  @Input() set memberid(value: number) {
    this._memberid = value;
  }

  get memberid(): number {
    return this._memberid;
  }

  list$ = this.store.pipe(select(employerHistoryViewView_List));
  accountId: number = 0;
  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));

  constructor(public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccountId$.subscribe(x => {
      var val = x?.value?.value;
      if (val) {
        this.accountId = val;
        this.store.dispatch(RequestAction({ memberId: this.memberid, accountId: val }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onEditClick() {
  }
}

