import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICashTransactionListState } from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';

export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const cashTransactionList = createSelector(portalState, (state) => state.cashTransactionListState);

export const cashTransactionList_List = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.list
);

export const cashTransactionList_CurrentPage = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.filter.value.currentPage
);

export const cashTransactionList_Filter = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.filter
);

export const cashTransactionList_TransactionTypes = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.transactionTypes
);

export const cashTransactionList_TransactionDescription = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.transactionDescription
);

export const cashTransactionList_ListTotal = createSelector(
  cashTransactionList,
  (state: ICashTransactionListState) => state.list.reduce((sum, item) => sum + item.amount, 0)
);
