<div class="section-title d-flex align-items-center">
  <div class="flex-fill ">Transactions</div>
  <app-button *ngIf="(list$ |async)?.length > 0" [matMenuTriggerFor]="menuExport" class="w-10" type="submit"
    title="Export" icon="cloud_download"></app-button>
  <mat-menu #menuExport="matMenu">
    <button mat-menu-item (click)="onExport()">
      <span>Save as CSV</span>
    </button>
  </mat-menu>
</div>
<div class="card" style="height:630px">
  <mat-drawer-container class="drawer-container" hasBackdrop="false">
    <mat-drawer-content>
      <div class="flex-fill">

        <app-search-filter>
          <div filters class="d-flex flex-row flex-fill">
            <div>
              <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center" mat-button
                [matMenuTriggerFor]="menu">
                Type <span class="badge badge-light ml-1">
                  {{ transactionType | dash}}
                </span>
                <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="mat-menu cash-transactions">
                <button mat-menu-item *ngFor="let item of (transactionTypes$ | async)"
                  (click)="selectTransactionType(item)">
                  <mat-icon>arrow_right</mat-icon>{{item.value}}
                </button>
              </mat-menu>
            </div>
            <div>
              <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center" mat-button
                [matMenuTriggerFor]="menuDesc">
                Description <span class="badge badge-light ml-1">
                  {{ transactionDescription | dash}}
                </span>
                <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
              </button>
              <mat-menu #menuDesc="matMenu" class="mat-menu cash-transactions">
                <button mat-menu-item *ngFor="let item of (transactionDescription$ | async)"
                  (click)="selectTransactionDescription(item)">
                  <mat-icon>arrow_right</mat-icon>{{item.value}}
                </button>
              </mat-menu>
            </div>
            <div class="d-flex flex-fill" *ngIf="filter$ | async; let filter">
              <button class="badge badge-primary p-1 mr-1 btn-trancation-type d-flex align-items-center" mat-button
                [matMenuTriggerFor]="menuFrom">
                From:<span class="badge badge-secondary m-1">
                  {{filter.value.from | date:'dd/MM/yyyy'| dash}}</span>
                To:<span class="badge badge-secondary m-1">
                  {{filter.value.to | date:'dd/MM/yyyy' | dash}}</span>
                <mat-icon class="show_more">keyboard_arrow_down</mat-icon>
              </button>

              <mat-menu #menuFrom="matMenu" class="mat-menu p-3">

                <mat-expansion-panel hideToggle class="pl-0 date">
                  <mat-expansion-panel-header (click)="currentFinancialYear(filter)">
                    <mat-panel-title class="d-flex align-items-center">
                      <mat-icon class="mr-2">date_range</mat-icon>
                      Current financial year
                    </mat-panel-title>
                    <mat-panel-description>
                      <mat-icon>navigate_next</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div class="row">
                    <div class="col-4">From:</div>
                    <div class="col-8">{{(filter$ | async)?.controls.from.value | date:"dd/MM/yyyy"}}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">To:</div>
                    <div class="col-8">{{(filter$ | async)?.controls.to.value | date:"dd/MM/yyyy"}}</div>
                  </div>


                </mat-expansion-panel>
                <mat-expansion-panel hideToggle class="pl-0 date">
                  <mat-expansion-panel-header (click)="previousFinancialYear(filter)">
                    <mat-panel-title class="d-flex align-items-center">
                      <mat-icon class="mr-2">date_range</mat-icon>
                      Previous financial year
                    </mat-panel-title>
                    <mat-panel-description>
                      <mat-icon>navigate_next</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div class="row">
                    <div class="col-4">From:</div>
                    <div class="col-8">{{(filter$ | async)?.controls.from.value | date:"dd/MM/yyyy"}}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">To:</div>
                    <div class="col-8">{{(filter$ | async)?.controls.to.value | date:"dd/MM/yyyy"}}</div>
                  </div>
                </mat-expansion-panel>


                <h4 class="mt-3 mb-1">Custom date</h4>
                <app-entry-date title="From" [date]="(filter$ | async)?.controls.from"></app-entry-date>
                <app-entry-date title="To" [date]="(filter$ | async)?.controls.to"></app-entry-date>

              </mat-menu>
            </div>
            <div end>
              <button mat-icon-button [matMenuTriggerFor]="menuMore">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menuMore="matMenu">
                <button mat-menu-item (click)="onClearFilterClick()">Clear filters</button>
              </mat-menu>
            </div>
          </div>
        </app-search-filter>

        <mat-progress-bar *ngIf="(list$|async) == undefined;" mode="indeterminate"></mat-progress-bar>

        <app-no-items class="mt-1" message="No transaction found" *ngIf="(list$ |async)?.length === 0"></app-no-items>

        <div class="table-responsive mt-1" *ngIf="(list$ |async)?.length > 0">
          <table class="table table-striped table-borderless ">
            <thead>
              <tr>
                <th>Transaction date</th>
                <th>Transaction type</th>
                <th>Description</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of list$ | async">
                <td>{{item.transactionDate | date:'dd MMM yyyy'}}</td>
                <td>{{item.accountDetailType}}</td>
                <td>{{item.description}}</td>
                <td class="text-right">{{item.amount | currency}}</td>
              </tr>
              <tr class="list-total">
                <th></th>
                <td></td>
                <td></td>
                <td class="text-right">{{listTotal$ | async | currency}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
</div>
