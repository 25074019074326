import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from '../../../../views/component-base';
import { commomState_LoggedinInfo } from 'src/app/store/common/common.selectors';
import { AppState } from 'src/app/store/app.states';
import { LogoutRequestAction } from 'src/app/store/common/common.actions';
import { Router } from '@angular/router';
import { FeatureToggleName } from 'src/app/services/featureToggle.service';


@Component({
  selector: 'app-adviser-details-widget',
  templateUrl: './adviser-details-widget.component.html',
  styleUrls: ['./adviser-details-widget.component.scss']
})
export class AdviserDetailsWidgetComponent extends ComponentBase implements OnInit, OnDestroy {
  canEditAdviserDetails = FeatureToggleName.canEditAdviserDetails;

  loggedinInfo$ = this.store.pipe(select(commomState_LoggedinInfo));

  constructor(
    public store: Store<AppState>,
    private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onEditDetailsClick() {
    this.router.navigate(['adviser-details'])
  }
}

