import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMemberCorrespondenceListState } from './state';
import { AdvisorPortalSharedState } from '../../../store/shared.states';
import { featureKey } from '../../../store/shared.reducers';


export const portalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(portalState, (state) => state.memberCorrespondenceListState);

export const memberCorrespondence_List = createSelector(
  state,
  (state: IMemberCorrespondenceListState) => state.list
);

export const memberCorrespondence_SelectedFileUrl = createSelector(
  state,
  (state: IMemberCorrespondenceListState) => state.selectedFileUrl
);
