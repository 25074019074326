<div id="adviser-details-component-widget" class="members-widget-component">

  <mat-progress-bar *ngIf="!(loggedinInfo$|async);" mode="indeterminate"></mat-progress-bar>

  <div class="d-flex flex-column  flex-fill" *ngIf="(loggedinInfo$|async); let data">

    <div class="d-flex flex-fill flex-column ">
      <app-readonly title="Email" value="{{data.email}}" class="p-1 flex-fill"></app-readonly>

      <div class="d-flex flex-row">
        <app-readonly title="Adviser number" value="{{data.number}}" class="p-1 flex-fill"></app-readonly>

        <app-readonly title="Dealer group name" value="{{data.dealerGroupName}}" *ngIf="data.dealerGroupName"
          class="p-1 flex-fill"></app-readonly>
      </div>

    </div>
    <div class="d-flex w-100 flex-row options mt-2">
      <app-button class="w-100" title="Edit details" icon="edit" (onClick)="onEditDetailsClick()"
        *featuretoggle="canEditAdviserDetails">
      </app-button>
    </div>
  </div>


</div>
