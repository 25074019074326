import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable()
export class InsuranceService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  getMemberInsurance(memberId: number, page: number): Observable<any> {
    const params = new HttpParams()
      .set('memberId', memberId.toString())
      .set('page', page.toString());
    return this.http.get(environment.apiUrl + `/members/${memberId}/insurance`, { headers: this.headers(), params: params });
  }

  getMemberInsuranceQuestionnaire(memberId:number, accountId:string): Observable<any> {
    return this.http.get(environment.apiUrl + `/members/${memberId}/account/${accountId}/insurance-questionnaire`, { headers: this.headers() });
  }
}
