import { FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select } from '@ngrx/store';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { commomState_IsLoading } from 'src/app/store/common/common.selectors';
import { Helper } from '@ifaa-components/ui-components';

@Component({
  selector: 'app-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss']
})
export class LoadMoreComponent extends ComponentBase implements OnInit, OnDestroy {

  @Input() page: number = 0;
  @Input() listCount: number = 0;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  helper = new Helper();
  isLoading$ = this.store.pipe(select(commomState_IsLoading));

  constructor(public store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
  }

  async onLoadMore() {
    var isLoading = await this.helper.getValue(this.isLoading$);
    if (isLoading) return;
    this.onClick?.emit();
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

}
