import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/shared.reducers';
import { AdvisorPortalSharedState } from '../../store/shared.states';
import { ISelectorWidgetViewState } from './state';

export const advisorPortalState = createFeatureSelector<AdvisorPortalSharedState>(featureKey);

export const state = createSelector(advisorPortalState, (state) => state.selectorWidgetViewState);

export const selectorWidgetView_Selected = createSelector(
  state,
  (state: ISelectorWidgetViewState) => state.selected
);

export const selectorWidgetView_List = createSelector(
  state,
  (state: ISelectorWidgetViewState) => state.list
);
