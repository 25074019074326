import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';

import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helper/helper-functions';
import { memberView_SelectedAccountId } from '../selectors';
import { AppState } from 'src/app/store/app.states';
import { memberViewRollover_RollInList } from './selectors';
import { MemberViewRollInsRequestAction } from './actions';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
  selector: 'app-rollovers-view',
  templateUrl: './rollovers-view.component.html',
  styleUrls: ['./rollovers-view.component.scss']
})
export class RolloversViewComponent extends ComponentBase implements OnInit, OnDestroy {

  rollInList$ = this.store.pipe(select(memberViewRollover_RollInList));

  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));
  currentAccoundId = 0;
  @Input() memberid: number = 0;

  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }


  ngOnInit() {
    super.ngOnInitBase();

    this.sub = this.selectedAccountId$.subscribe(x => {
      var val = x?.value?.value;
      if (val) {
        this.currentAccoundId = val;
        this.store.dispatch(MemberViewRollInsRequestAction({ memberId: this.memberid, accountId: val, page: 0 }));
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
