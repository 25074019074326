<div id="member-insurance">

  <div class="section-title d-flex ">
    Insurance
  </div>
  <div class="card">
    <mat-progress-bar *ngIf="(insuranceList$|async) == undefined;" mode="indeterminate"></mat-progress-bar>


    <app-no-items message="No insurance found" *ngIf="(insuranceList$ |async)?.length === 0">
    </app-no-items>


    <div class="table-responsive" *ngIf="(insuranceList$ |async)?.length > 0">
      <h4 class="mb-3" *ngIf="(insuranceList$ |async); let data">Your insurance cover {{getCoverText(data)}}</h4>
      <table class="table table-striped table-borderless">
        <thead>
          <tr>
            <th>Type of cover</th>
            <th>Cover amount</th>
            <th>{{'VIEWS.Insurance.PREMIUM_AMOUNT_LABEL' | translate}}</th>
            <th>Waiting period</th>
            <th>Lapsed</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of insuranceList$ | async">
            <td>{{item.insuranceCoverTypeName}}</td>
            <td>{{item.amount| currency}}</td>
            <td>{{item.premium| currency}}</td>
            <td>{{item.waitingPeriod | dash}}</td>
            <td>{{hasLapsed(item)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
