import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { select } from '@ngrx/store';

import { MatDialog } from '@angular/material/dialog';
import { Helper } from 'src/app/helper/helper-functions';
import { memberView_SelectedAccountId } from '../selectors';
import { AppState } from 'src/app/store/app.states';
import { RequestAction } from './actions';
import { ComponentBase } from 'src/app/views/component-base';

@Component({
  selector: 'app-interim-statement-view',
  templateUrl: './interim-statement-view.component.html',
  styleUrls: ['./interim-statement-view.component.scss']
})
export class InterimStatementViewComponent extends ComponentBase implements OnInit, OnDestroy {

  selectedAccountId$ = this.store.pipe(select(memberView_SelectedAccountId));
  @Input() memberid: number = 0;
  accountId: number = 0;

  constructor(public store: Store<AppState>,
    public helper: Helper,
    public dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.sub = this.selectedAccountId$.subscribe(x => {
      var val = x?.value?.value;
      if (val) {
        this.accountId = val;
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  currentFinancialYear() {
    var dates = this.helper.getFinancialYear(new Date());
    this.downloadStatement(dates.from, new Date());
  }
  previousFinancialYear() {
    var today = new Date();
    var dates = this.helper.getFinancialYear(new Date(today.getFullYear() - 1, today.getMonth(), today.getDay()));
    this.downloadStatement(dates.from, dates.to);
  }

  downloadStatement(from: Date, to: Date) {
    this.store.dispatch(RequestAction({ memberId: this.memberid, accountId: this.accountId, from: from.toISOString(), to: to.toISOString() }));
  }
}
