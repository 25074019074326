import { CashTransactionFilter, ICashTransactionListState } from './state';
import { createReducer, on, Action } from '@ngrx/store';
import {
  onNgrxForms, onNgrxFormsAction, SetValueAction, createFormControlState, createFormGroupState, setValue
} from 'ngrx-forms';
import { CashTransactionsResponseAction, CashTransactionIncrementPageAction, CashTransactionsTypesResponseAction, ResetFormAction, CashTransactionsSetFromToDateAction, CashTransactionsDescriptionResponseAction, ResetFilterAction, CashTransactionsDownloadResponseAction, CashTransactionsRequestAction } from './actions';
import { Helper } from '@ifaa-components/ui-components';

export const cashtransactionsListFilter = 'cashtransactionsListFilter';
var helper = new Helper();

export const cashTransactionListState: ICashTransactionListState = {
  list: undefined,
  transactionTypes: [],
  transactionDescription: [],
  filter: createFormGroupState(cashtransactionsListFilter, new CashTransactionFilter()),
  isLoading: false
};

const reducer = createReducer(cashTransactionListState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state, action) => {
    if (action.controlId == 'cashtransactionsListFilter.from'
      || action.controlId == 'cashtransactionsListFilter.to'
      || action.controlId == 'cashtransactionsListFilter.transactionType'
      || action.controlId == 'cashtransactionsListFilter.transactionDescription') {
      var newFilter = {
        ...state.filter.value,
        currentPage: 0
      };
      return {
        ...state,
        filter: setValue(newFilter)(cashTransactionListState.filter)
      };
    }

    return state;
  }),
  on(CashTransactionsSetFromToDateAction, (state, { from, to }) => {
    var clone = helper.clone(state.filter.value);
    clone.from = from;
    clone.to = to;
    return {
      ...state,
      filter: setValue(clone)(state.filter),
    }
  }),
  on(ResetFilterAction, (state, { }) => {
    return {
      ...state,
      filter: createFormGroupState(cashtransactionsListFilter, new CashTransactionFilter()),
    }
  }),
  on(CashTransactionsResponseAction, (state, { payload }) => {
    var clone = helper.clone(state.filter.value);
    return {
      ...state,
      isLoading: false,
      list: payload,
      filter: setValue(clone)(state.filter),
    }
  }),
  on(CashTransactionsRequestAction, (state, { memberId, accountId, page, filter }) => {
    return {
      ...state,
      list: undefined
    }
  }),
  on(CashTransactionsTypesResponseAction, (state, { payload }) => {
    return {
      ...state,
      transactionTypes: payload
    }
  }),
  on(CashTransactionsDescriptionResponseAction, (state, { payload }) => {
    return {
      ...state,
      transactionDescription: payload
    }
  }),
  on(ResetFormAction, (state) => {
    return {
      list: [],
      transactionTypes: [],
      transactionDescription: [],
      isLoading: false,
      filter: createFormGroupState(cashtransactionsListFilter, new CashTransactionFilter()),

    }
  }),
  on(CashTransactionIncrementPageAction, (state) => {
    if (state.filter.value.currentPage === -1 || state.isLoading)
      return state;

    var clone = helper.clone(state.filter.value);

    clone.currentPage = state.filter.value.currentPage + 1;

    return {
      ...state,
      isLoading: true,
      filter: setValue(clone)(cashTransactionListState.filter)
    };
  }),
);

export function cashTransactionListReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
